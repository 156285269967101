<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mr-2 {
    margin-right: 12px;
  }
</style>

<h2 mat-dialog-title class="modal-title">Log a Call</h2>
<mat-dialog-content class="mat-typography content">
  <div>
    <mat-form-field appearance="outline" floatLabel="always" class="mr-2">
      <mat-label>Customer Phone</mat-label>
      <input type="text" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>CSR Phone</mat-label>
      <input type="text" matInput>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
      <mat-label>Enter customer note here</mat-label>
      <textarea matInput rows="10"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Submit</button>
</mat-dialog-actions>