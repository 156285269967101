<style>
  #alertMessageDialog {
    padding: 15px;
    min-width: 300px;
    white-space: pre-line;

    .success-color {
      color: green;
    }

    .fail-color {
      color: red;
    }

    .warning-color {
      color: orange;
    }
  }

  #alertMessageDialog > h2 {
    margin: 0;
    margin-bottom: 50px;
    font-weight: normal;
    font-size: 18px;
  }

  #alertMessageDialog > div.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 30px;
  }

  #alertMessageDialog > div.buttons > button {
    padding: 8px 10px;
    min-width: 90px;
    cursor: pointer;
  }

  #alertMessageDialog > div.section {
    > div {
      margin-bottom: 5px;

      > span {
        display: block;
      }
    }
  }
</style>

<div id="alertMessageDialog">
  <h2 md-dialog-title>{{message}}</h2>
  
  <div class="section">
    massId: {{ massId }}
  </div>

  <div class="section">
    <h3>UxConfig</h3>
    @for (info of uxConfig; track info.value.id) {
      <div>
        <span>ID: {{ info.value.id }}</span>
        <span>Status: <span [ngClass]="info.status === 'fulfilled' ? 'success-color' : 'fail-color'">{{ info.status }}</span></span>
        <span>Message: <span [ngClass]="info.value.message === 'success' ? 'success-color' : 'fail-color'">{{ info.value.message }}</span></span>
        <span>Changed: <span [ngClass]="info.value.changed ? 'success-color' : 'warning-color'">{{ info.value.changed }}</span></span>
      </div>
    }
  </div>
  
  <div class="section">
    <h3>UxLayout</h3>
    @for (info of uxLayout; track info.value.id) {
      <div>
        <span>ID: {{ info.value.id }}</span>
        <span>Status: <span [ngClass]="info.status === 'fulfilled' ? 'success-color' : 'fail-color'">{{ info.status }}</span></span>
        <span>Message: <span [ngClass]="info.value.message === 'success' ? 'success-color' : 'fail-color'">{{ info.value.message }}</span></span>
        <span>Changed: <span [ngClass]="info.value.changed ? 'success-color' : 'warning-color'">{{ info.value.changed }}</span></span>
      </div>
    }
  </div>

  <div class="section">
    <h3>UxComponentCollection</h3>
    @for (info of uxComponentCollection; track info.value.id) {
      <div>
        <span>ID: {{ info.value.id }}</span>
        <span>Status: <span [ngClass]="info.status === 'fulfilled' ? 'success-color' : 'fail-color'">{{ info.status }}</span></span>
        <span>Message: <span [ngClass]="info.value.message === 'success' ? 'success-color' : 'fail-color'">{{ info.value.message }}</span></span>
        <span>Changed: <span [ngClass]="info.value.changed ? 'success-color' : 'warning-color'">{{ info.value.changed }}</span></span>
      </div>
    }
  </div>

  <div class="section">
    <h3>UxComponent</h3>
    @for (info of uxComponent; track info.value.id) {
      <div>
        <span>ID: {{ info.value.id }}</span>
        <span>Status: <span [ngClass]="info.status === 'fulfilled' ? 'success-color' : 'fail-color'">{{ info.status }}</span></span>
        <span>Message: <span [ngClass]="info.value.message === 'success' ? 'success-color' : 'fail-color'">{{ info.value.message }}</span></span>
        <span>Changed: <span [ngClass]="info.value.changed ? 'success-color' : 'warning-color'">{{ info.value.changed }}</span></span>
      </div>
    }
  </div>

  <div class="buttons">
    <button class="mdc-button mdc-button--raised mat-mdc-raised-button mat-mdc-button-base" (click)="dialogRef.close(false)">Close</button>
    <button class="mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base" (click)="dialogRef.close(true)">Close And Refresh</button>
  </div>
</div>