<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items; let i = index;">
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
        <span>{{child.name}}</span>
      </button>
      <app-menu-item #menu [items]="child.children" (onClickedMenu)="handleClick($event, i)"></app-menu-item>
    </span>
    <span *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item (click)="handleClick(child.name, -1)">
        <span>{{child.name}}</span>
      </button>
    </span>
  </span>
</mat-menu>