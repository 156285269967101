<style>
    .transaction-detail {
      padding: 12px 24px;
    }
  
    .expand-label {
      font-size: 12px;
      font-weight: 600;
      display: inline-block;
      margin-right: 4px;
    }
  
    .expand-value {
      font-size: 12px;
      display: inline-block;
      margin-right: 8px;
    }
  
    code {
      white-space: pre-wrap;
      font-size: 0.7em;
    }
  </style>
  
  <div class="transaction-detail">
    <ng-container *ngIf="isOrder && commercePayment">
      <ng-container *ngIf="!commercePayment.parentId">
        <div>
          <span class="expand-label">Order ID</span>
          <span class="expand-value">{{commerceOrder._id}}</span>
          <ng-container *ngIf="commerceOrder.parentId">
            <span class="expand-label">Parent ID</span>
            <span class="expand-value">{{commerceOrder.parentId}}</span>
          </ng-container>
          <span class="expand-label">Order Status</span>
          <span class="expand-value">{{commerceOrder.status}}</span>
          <span class="expand-label">Order Sub Status</span>
          <span class="expand-value">{{commerceOrder.subStatus}}</span>
          <ng-container *ngIf="commerceOrder.statusIdentifier">
            <span class="expand-label">Order Status Identifier</span>
            <span class="expand-value">{{commerceOrder.statusIdentifier}}</span>
          </ng-container>
          <ng-container *ngIf="commerceOrder.statusReason">
            <span class="expand-label">Order Status Reason</span>
            <span class="expand-value">{{commerceOrder.statusReason}}</span>
          </ng-container>
          <ng-container *ngIf="commerceOrder.statusDescription">
            <span class="expand-label">Order Status Description</span>
            <span class="expand-value">{{commerceOrder.statusDescription}}</span>
          </ng-container>
          <ng-container>
            <span class="expand-label">People Reports Pulled (Usage)</span>
            <span class="expand-value">{{commerceOrder.processed}}</span>
          </ng-container>
          <span class="expand-label">UXC</span>
          <span class="expand-value">{{commerceOrder.uxcId}}</span>
          <span class="expand-label">UXL</span>
          <span class="expand-value">{{commerceOrder.uxlId}}</span>
          <span class="expand-label">Total Value</span>
          <span class="expand-value">${{commerceOrder.calculateValue().amount}}</span>
        </div>
        <br />
        <div>
          <span class="expand-label">Offer Name</span>
          <span class="expand-value">{{commerceOrder.commerceOffer.name}}</span>
        </div>
        <div>
          <span class="expand-label">Offer Description</span>
          <span class="expand-value">{{commerceOrder.commerceOffer.description}}</span>
        </div>
        <br />
        <ng-container *ngIf="commerceOrder.commerce3ds">
          <span class="expand-label">Offer 3DS Info</span>
          <span class="expand-value">
            (
            3DS
            Enrolled:{{commerceOrder.commerce3ds.enrolled}}
            Auth:{{commerceOrder.commerce3ds.status}}
            ECI:{{commerceOrder.commerce3ds.eci}}
            )
          </span>
        </ng-container>
        <ng-container *ngIf="commerceOrder.tempClient.vmpi">
          <span class="expand-label">VMPI</span>
          <span class="expand-value">VMPI</span>
        </ng-container>
  
        <br />
  
        <div>
          <span class="expand-label">Order DateTime:</span>
          <span class="expand-value">{{formatTimestamp(commerceOrder.orderTimestamp) |
            date:timeFormat:custom.timezone}}
            {{custom.timezone}}</span>
          <span class="expand-label">Sequence:</span>
          <span class="expand-value">{{commerceOrder.sequence + 1}}.{{commerceOrder.retry}}</span>
          <span class="expand-label">IP Address:</span>
          <span class="expand-value">{{commerceOrder.ipAddress}}</span>
          <span class="expand-label">Last Updater:</span>
          <span class="expand-value">{{userInfo[commerceOrder.updaterId]?.getFullName()}}</span>
        </div>
        <br />
      </ng-container>
      <ng-container *ngIf="!commercePayment.parentId">
        <div *ngFor="let update of commerceOrder.tempClient.updates" class="commerceOrderUpdate">
          <div *ngIf="userInfo[update.updaterId]?.getFullName()">
            <div>
              <span class="expand-label">TimeStamp</span>
              <span class="expand-value">
                {{formatTimestamp(update.timestamp) | date:timeFormat: custom.timezone}}
                {{custom.timezone}}
              </span>
              <span class="expand-label">Sequence</span>
              <span class="expand-value">{{update.sequence}}.{{update.retry}}</span>
              <span class="expand-label">Status</span>
              <span class="expand-value">{{update.status}}</span>
              <span class="expand-label">Sub Status</span>
              <span class="expand-value">{{update.subStatus}}</span>
              <span class="expand-label">Status Identifier &amp; Reason</span>
              <span class="expand-value">
                ({{update.statusIdentifier}}:{{update.statusReason}})
              </span>
              <span class="expand-label">Updater</span>
              <span class="expand-value">{{userInfo[update.updaterId]?.getFullName()}}</span>
            </div>
            <br />
          </div>
        </div>
      </ng-container>
      <p *ngIf="!commercePayment.parentId">------------ Commerce Offer Rule Key ------------</p>
      <div
        *ngIf="!commercePayment.parentId && commerceOrder.tempClient.commerceOfferRuleExplained as ruleExplained">
  
        <code>{{codeOutput(commerceOrder.commerceOfferRuleKey)}}</code>
        <div *ngFor="let value of ruleExplained">
          <div>
            <span class="expand-label">Type</span>
            <span class="expand-value">{{value.type}}</span>
            <span class="expand-label">Key</span>
            <span class="expand-value">{{value.key}}</span>
            <span class="expand-label">UxComposite ID</span>
            <span class="expand-value">{{uxComposite.ids[value.key]}}</span>
          </div>
          <code>{{codeOutput(value.rule)}}</code>
          <br />
        </div>
  
      </div>
      <p *ngIf="!commercePayment.parentId">------------ Refer ------------</p>
      <div *ngIf="!commercePayment.parentId">
        <code>{{codeOutput(commerceOrder.refer)}}</code>
        <code>{{codeOutput(commerceOrder.meta)}}</code>
        <code>{{codeOutput(commerceOrder.prevUx)}}</code>
      </div>
  
      <br />
  
      <ng-container *ngIf="!commercePayment.parentId">
        <div style="display: inline-block">
          <ng-container *ngFor="let price of commerceOrder.draft.commercePrice.prices; index as i " class="commercePrice">
            <div>
              <mat-form-field appearance="outline" floatLabel="always" class="mr-2">
                <mat-label>Sequence</mat-label>
                <input matInput [placeholder]="commerceOrder.commercePrice.prices[i].sequence" [(ngModel)]="price.sequence">
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="mr-2">
                <mat-label>Amount</mat-label>
                <input matInput type="number" [placeholder]="commerceOrder.commercePrice.prices[i].amount"
                  [(ngModel)]="price.amount">
                <span matPrefix>$</span>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="mr-2">
                <mat-label>Qty</mat-label>
                <input matInput [placeholder]="commerceOrder.commercePrice.prices[i].period.quantity"
                  [(ngModel)]="price.period.quantity">
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="mr-2">
                <mat-label>Unit</mat-label>
                <mat-select name="unit" [placeholder]="commerceOrder.commercePrice.prices[i].period.unit"
                  [(ngModel)]="price.period.unit">
                  <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                    {{ unit.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div style="display: inline-block">
          <button mat-raised-button color="primary" [disabled]="!isValidUpdateCommerceOrder(commerceOrder)"
            (click)="handleUpdateOrder(commerceOrder)">Update</button>
        </div>
        <br />
  
        <div>
          <span class="expand-label">Offer ID</span>
          <span class="expand-value">{{commerceOrder.commerceOffer._id}}</span>
        </div>
        <div>
          <span class="expand-label">Offer Revision ID</span>
          <span class="expand-value">{{commerceOrder.commerceOfferRevision._id}}</span>
        </div>
        <div>
          <span class="expand-label">Offer Revision Reference Name</span>
          <span class="expand-value">{{commerceOrder.commerceOfferRevision.reference.name}}</span>
        </div>
        <div>
          <span class="expand-label">Offer Revision Reference Description</span>
          <span class="expand-value">{{commerceOrder.commerceOfferRevision.reference.description}}</span>
        </div>
        <div>
          <code>{{codeOutput(commerceOrder.commerceOfferDetail)}}</code>
        </div>
      </ng-container>
    </ng-container>
  
    <ng-container *ngIf="!isOrder">
      <p>------------ Data ------------</p>
      <code>{{ codeOutput(commercePayment.tempClient?.data )}}</code>
      <p>------------ Payment Info ------------</p>
      <div class="row" *ngIf="commercePayment.tempClient?.parentId">
        <span class="expand-label">Payment</span>
        <span class="expand-value">{{commercePayment.tempClient?._id}}</span>
        <span class="expand-label">GatewayTxID</span>
        <span class="expand-value">{{commercePayment.tempClient?.gatewayTxId}}</span>
        <span class="expand-label">Sequence</span>
        <span class="expand-value">{{commercePayment.tempClient?.sequence}}.{{commercePayment.tempClient?.retry}}</span>
        <ng-container *ngIf="commercePayment.commerceToken">
          <div class="row">
            <span class="expand-label">Token</span>
            <span class="expand-value">{{commercePayment.commerceToken._id}}</span>
            <span class="expand-label">Token Status</span>
            <span class="expand-value">{{commercePayment.commerceToken.status}}</span>
            <span class="expand-label">Token Sub Status</span>
            <span class="expand-value">{{commercePayment.commerceToken.subStatus}}</span>
            <span class="expand-label">Card Type</span>
            <span class="expand-value">{{commercePayment.commerceToken.type}}</span>
            <span class="expand-label">BIN</span>
            <span class="expand-value">{{commercePayment.commerceToken.bin}}</span>
            <span class="expand-label">Last4</span>
            <span class="expand-value">{{commercePayment.commerceToken.last4}}</span>
            <span class="expand-label">Exp Date</span>
            <span
              class="expand-value">{{commercePayment.commerceToken.expiration?.month}}/{{commercePayment.commerceToken.expiration?.year}}</span>
            <span class="expand-label">Last4</span>
            <span class="expand-value">{{commercePayment.commerceToken.last4}}</span>
            <span class="expand-label">IP Address</span>
            <span class="expand-value">{{commercePayment.commerceToken.ipAddress}}</span>
            <span class="expand-label">ZIP</span>
            <span class="expand-value">{{commercePayment.commerceToken.billingAddress?.zip}}</span>
            <span class="expand-label">Email</span>
            <span class="expand-value">{{commercePayment.commerceToken.billingAddress?.email}}</span>
            <span class="expand-label">Name</span>
            <span class="expand-value">{{commercePayment.commerceToken.billingAddress?.firstName}}
              {{commercePayment.commerceToken.billingAddress?.lastName}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="commercePayment?.tempClient?.paymentInfo?.params as info">
          <div class="row">
            <ng-container *ngIf="'avsresponse' as key">
              <ng-container *ngIf="info[key] as value">
                <span class="expand-label">{{key}}</span>
                <span class="expand-value">{{value}} ({{details[key][value]}})</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="'cvvresponse' as key">
              <ng-container *ngIf="info[key] as value">
                <span class="expand-label">{{key}}</span>
                <span class="expand-value">{{value}} ({{details[key][value]}})</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="'response_code' as key">
              <ng-container *ngIf="info[key] as value">
                <ng-container *ngIf="value !== '100'">
                  <span class="expand-label">{{key}}</span>
                  <span class="expand-value">{{value}}</span>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <code>{{ codeOutput(commercePayment.paymentInfo )}}</code>
      <ng-container *ngIf="commercePayment.parentId">
        <p>------------ Commerce Billing Routing ------------</p>
        <code>{{codeOutput(commercePayment?.commerceBillingRouting)}}</code>
      </ng-container>
      @if (!commercePayment.parentId) {
        <div class="row">
          @if (detailWithoutParentId.commerceTransactionCollection) {
            <div class="row">
              <span class="expand-label">TxCollection</span>
              <span class="expand-value">{{ detailWithoutParentId.commerceTransactionCollection._id }}</span>
              <span class="expand-label">TxCollection Status</span>
              <span class="expand-value">{{ detailWithoutParentId.commerceTransactionCollection.status }}</span>
            </div>
          }
          @if (detailWithoutParentId.commerceTransaction) {
            <div class="row">
              <span class="expand-label">Transaction</span>
              <span class="expand-value">{{ detailWithoutParentId.commerceTransaction._id }}</span>
              <span class="expand-label">Transaction Status</span>
              <span class="expand-value">{{ detailWithoutParentId.commerceTransaction.status }}</span>
            </div>
          }
          @if (commercePayment) {
            <div class="row">
              <span class="expand-label">Payment</span>
              <span class="expand-value">{{commercePayment._id}}</span>
              <span class="expand-label">GatewayTxID</span>
              <span class="expand-value">{{commercePayment.gatewayTxId}}</span>
              <span class="expand-label">Sequence</span>
              <span class="expand-value">{{commercePayment.sequence}}.{{commercePayment.retry}}</span>
            </div>
            <ng-container *ngIf="commercePayment.commerceToken">
              <div class="row">
                <span class="expand-label">Token</span>
                <span class="expand-value">{{commercePayment.commerceToken._id}}</span>
                <span class="expand-label">Token Status</span>
                <span class="expand-value">{{commercePayment.commerceToken.status}}</span>
                <span class="expand-label">Token Sub Status</span>
                <span class="expand-value">{{commercePayment.commerceToken.subStatus}}</span>
                <span class="expand-label">Card Type</span>
                <span class="expand-value">{{commercePayment.commerceToken.type}}</span>
                <span class="expand-label">BIN</span>
                <span class="expand-value">{{commercePayment.commerceToken.bin}}</span>
                <span class="expand-label">Last4</span>
                <span class="expand-value">{{commercePayment.commerceToken.last4}}</span>
                <span class="expand-label">Exp Date</span>
                <span
                  class="expand-value">{{commercePayment.commerceToken.expiration?.month}}/{{commercePayment.commerceToken.expiration?.year}}</span>
                <span class="expand-label">Last4</span>
                <span class="expand-value">{{commercePayment.commerceToken.last4}}</span>
                <span class="expand-label">IP Address</span>
                <span class="expand-value">{{commercePayment.commerceToken.ipAddress}}</span>
                <span class="expand-label">ZIP</span>
                <span class="expand-value">{{commercePayment.commerceToken.billingAddress?.zip}}</span>
                <span class="expand-label">Email</span>
                <span class="expand-value">{{commercePayment.commerceToken.billingAddress?.email}}</span>
                <span class="expand-label">Name</span>
                <span class="expand-value">{{commercePayment.commerceToken.billingAddress?.firstName}}
                  {{commercePayment.commerceToken.billingAddress?.lastName}}</span>
    
                <p>------------ Token Commerce Billing Routing ------------</p>
                <code>{{codeOutput(commercePayment.commerceToken.commerceBillingRouting)}}</code>
    
                <p>------------ Commerce Billing Routing ------------</p>
                <code>{{codeOutput(commercePayment.commerceBillingRouting)}}</code>
              </div>
            </ng-container>
            <ng-container *ngIf="commercePayment?.paymentInfo?.params as info">
              <div class="row">
                <ng-container *ngIf="'avsresponse' as key">
                  <ng-container *ngIf="info[key] as value">
                    <span class="expand-label">{{key}}</span>
                    <span class="expand-value">{{value}} ({{details[key][value]}})</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="'cvvresponse' as key">
                  <ng-container *ngIf="info[key] as value">
                    <span class="expand-label">{{key}}</span>
                    <span class="expand-value">{{value}} ({{details[key][value]}})</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="'response_code' as key">
                  <ng-container *ngIf="info[key] as value">
                    <ng-container *ngIf="value !== '100'">
                      <span class="expand-label">{{key}}</span>
                      <span class="expand-value">{{value}}</span>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          }
        </div>
      }
    </ng-container>
  </div>
  