import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../clientCommon/services/index';
import {User} from '../../common/models/user/user';
import {adminPaths} from '../../common/helpers/pathHelpers';

@Component({
  template: ''
})

export class DefaultLoginComponent implements OnInit {
  model: any = {};
  returnUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {}

  ngOnInit() {
    const user = this.authenticationService.getUser();
    if (!user) {
      this.router.navigate([adminPaths.login]);
      return;
    }
    if (user.hasRoles([User.ROLES.admin])) {
      this.router.navigate([adminPaths.uxcFullMgmt]);
    } else if (user.hasRoles([User.ROLES.csr])) {
      this.router.navigate([adminPaths.csrCustomers]);
    }
  }
}
