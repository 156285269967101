import { ExpandedTextComponent } from './expanded-text/expanded-text.component'
import { InfoOverlayComponent } from './info-overlay/info-overlay.component'

export const COMPONENT_DECLARATIONS = [
    InfoOverlayComponent,
    ExpandedTextComponent,
]

export const COMPONENT_EXPORTS = [
    InfoOverlayComponent,
    ExpandedTextComponent
]