<style>
  code {
    white-space: pre-wrap;
    font-size: 0.7em;
  }
</style>
<mat-card id="tracking">
  <mat-card-content>
    <button mat-fab (click)="init()"><i class="material-icons">cached</i></button>
    <ng-container *ngFor="let tracking of trackings">
      <mat-card>
        <mat-card-content>
          {{tracking.createdTimestamp | toDateTime}}
          <!--{{tracking._id}}-->
          UID:{{tracking.userId}}
          UXC:{{tracking.uxcId}}
          UXL:{{tracking.uxlId}}
          Session:{{tracking.sessionHash}}
          <code>{{getRaw(tracking)}}</code>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </mat-card-content>
</mat-card>
