<style>
    .inline {
      display: inline-block;
    }
  
    mat-form-field {
      font-size: 0.9em;
    }
  
    mat-form-field.id {
      width: 230px;
    }
  </style>
  
  <mat-card id="permissions">
    <mat-card-content>
      <button mat-fab (click)="init()"><i class="material-icons">cached</i></button>
  
      <mat-card>
        <mat-card-content>
          <mat-form-field>
            <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newPermission.draft.name"/>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" name="description" placeholder="Description" [(ngModel)]="newPermission.draft.description"/>
          </mat-form-field>
  
          <div class="inline paths">
            <div *ngFor="let path of newPermission.draft.paths; let i = index">
              <mat-form-field>
                <input matInput type="text" [placeholder]="path.key" [(ngModel)]="path.key"/>
                <button mat-icon-button matPrefix><i class="material-icons" (click)="removeItemInArray(newPermission.draft.paths,i)">clear</i></button>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <input matInput #newPath type="text" placeholder="Path" (change)="addPath(newPermission.draft, newPath)"/>
              </mat-form-field>
            </div>
          </div>
  
          <div class="inline properties">
            <div *ngFor="let property of newPermission.draft.properties; let i = index">
              <mat-form-field>
                <input matInput type="text" [placeholder]="property.key" [(ngModel)]="property.key"/>
                <button mat-icon-button matPrefix><i class="material-icons" (click)="removeItemInArray(newPermission.draft.properties,i)">clear</i></button>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <input matInput #newProperty type="text" placeholder="Property" (change)="addProperty(newPermission.draft, newProperty)"/>
              </mat-form-field>
            </div>
          </div>
  
          <button [disabled]="(!newPermission.draft.name)" (click)="add()">Add</button>
        </mat-card-content>
      </mat-card>
  
  
      <ng-container *ngFor="let permission of permissions">
        <mat-card>
          <mat-card-content>
            <mat-form-field>
              <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="permission.draft.name"/>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" name="description" placeholder="Description" [(ngModel)]="permission.draft.description"/>
            </mat-form-field>
  
            <div class="inline paths">
              <div *ngFor="let path of permission.draft.paths; let i = index">
                <mat-form-field>
                  <input matInput type="text" [placeholder]="path.key" [(ngModel)]="path.key"/>
                  <button mat-icon-button matPrefix><i class="material-icons" (click)="removeItemInArray(permission.draft.paths,i)">clear</i></button>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <input matInput #newPath type="text" placeholder="Path" (change)="addPath(permission.draft, newPath)"/>
                </mat-form-field>
              </div>
            </div>
  
            <div class="inline properties">
              <div *ngFor="let property of permission.draft.properties; let i = index">
                <mat-form-field>
                  <input matInput type="text" [placeholder]="property.key" [(ngModel)]="property.key"/>
                  <button mat-icon-button matPrefix><i class="material-icons" (click)="removeItemInArray(permission.draft.properties,i)">clear</i></button>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <input matInput #newProperty type="text" placeholder="Property" (change)="addProperty(permission.draft, newProperty)"/>
                </mat-form-field>
              </div>
            </div>
  
            <button [disabled]="(!permission.isDiffDraft())" (click)="update(permission)">Update</button>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-card-content>
  </mat-card>
  