<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    padding-right: 24px;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .row,
  .full-width {
    padding-bottom: 15px;
  }

  .col {
    width: 45%;
  }

  label {
    font-size: 12px;
  }

  input {
    margin-top: 5px;
    background: #F8F9FE;
    padding: 10px 15px;
    width: 90%;
    border: 1px solid #CBD6E2;
    border-radius: 2px;
  }

  #subject,
  #body {
    width: 95%;
  }

  .button-wrapper {
    width: 100%;
    text-align: center;
  }

  #submit {
    background: #313AA1;
    color: #fff;
    padding: 10px 25px;
    border: none;
    border-radius: 2px;
  }

  .d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close {
    background-color: #673ab7;
    color: #fff;
  }

  h2 {
    margin: 0 !important;
    color: #ffffff !important;
  }
  .modal-body-container {
    padding: 24px;
  }
</style>
<div class="modal">
  <div class="d-flex modal-title">
    <h2 mat-dialog-title>SEARCH EMAIL</h2>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body-container">
    <form [formGroup]="nameForm">
      <div class="row">
        <div class="col">
          <label for="">CSR Owner:</label><br>
          <input type="text" class="form-control purple-bg" matInput (input)="searchSubject.next($event)"
            (focus)="searchSubject.next($event)" [matAutocomplete]="auto" id="csrowner" name="csrowner"
            formControlName="csrowner">
          <mat-autocomplete (optionSelected)="selectOption($event)" [displayWith]="displayFn" #auto="matAutocomplete">
            <mat-option *ngFor="let option of searchedCsrs" [value]="option">
              {{option.obj?.firstName + " " + option.obj?.lastName}}
            </mat-option>
          </mat-autocomplete>

          <!-- <input type="text" id="csrowner" name="csrowner" formControlName="csrowner"> -->
        </div>
        <div class="col">
          <label for="">Name:</label><br>
          <input type="text" id="name" name="name" formControlName="name">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="">To:</label><br>
          <input type="text" id="from" name="from" formControlName="from">
        </div>
        <div class="col">
          <label for="">From:</label><br>
          <input type="text" id="to" name="to" formControlName="to">
        </div>
      </div>

      <div class="row">
        <div class="col">
          <label for="">Start Date:</label><br>
          <input type="date" id="startdate" name="startdate" formControlName="startdate">
        </div>
        <div class="col">
          <label for="">End Date:</label><br>
          <input type="date" id="endate" name="enddate" formControlName="enddate">
        </div>
      </div>

      <div class="full-width">
        <label for="">Subject:</label><br>
        <input type="text" id="subject" name="subject" formControlName="subject">
      </div>

      <div class="full-width">
        <label for="">Body:</label><br>
        <input id="body" type="text" name="body" formControlName="body">
      </div>

      <div class="button-wrapper">
        <button id="submit" type="button" (click)="handleSave()">Submit</button>
      </div>
    </form>

  </div>
</div>