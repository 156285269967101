import {Injectable} from '@angular/core';
import {JsonService} from './json.service';
import {RequestEvent} from '../../common/event/requestEvent';
import {ResponseEvent} from '../../common/event/responseEvent';
import {LogUtils} from '../../common/utils/logUtils';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {EmailRecord} from '../../common/models/emailRecord';

@Injectable()
export class ManageEmailService {

  constructor(private jsonService: JsonService) {
  }

  /**
   * this FN is to request findEmailsByStatus API against the provided status in request params
   * @param status will be used to provide as request params to the findEmailsByStatus API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public getEmailsByStatus(status: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {status};
    return this.jsonService.json(serverPaths.emailsByStatus, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve(responseEvent.getResponse().data.emails);
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }

   /**
   * this FN is to request getEmailsBySender API against the provided sender in request params
   * @param sender will be used to provide as request params to the getEmailsBySender API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public getEmailsBySender(sender: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {sender};
    return this.jsonService.json(serverPaths.emailsBySender, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve(responseEvent.getResponse().data.emails);
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }

  /**
   * this FN is to request getEmailsByUpdater API against the provided updater in request params
   * @param updater will be used to provide as request params to the getEmailsByUpdater API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public getEmailsByUpdater(updater: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {updater};
    return this.jsonService.json(serverPaths.emailsByUpdater, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve(responseEvent.getResponse().data.emails);
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }

  /**
   * this FN is to request getAllEmails API against the provided updater in request params
   * @param foldername will be used to provide as request params to the getAllEmails API
   * @param curPage will be used to provide as request params to the getAllEmails API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public getAllEmails(foldername, curPage): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {directory: foldername, curPage: curPage};
    return this.jsonService.json(serverPaths.emailsAll, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve(responseEvent);
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }

    /**
   * this FN is to request emailReply API against the provided updater in request params
   * @param parentId will be used to provide as request params to the emailReply API
   * @param message will be used to provide as request params to the emailReply API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public replyToEmail(parentId: string, message: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {parentId, message};
    return this.jsonService.json(serverPaths.emailReply, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve(true);
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }

    /**
   * this FN is to request emails status update API against the provided updater in request params
   * @param id will be used to provide as request params to the status update API
   * @param status will be used to provide as request params to the status update API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public updateEmailStatus(id: string, status: string): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {id, status};
    return this.jsonService.json(serverPaths.emailsUpdateStatus, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve(responseEvent.getResponse().data.message);
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }

    /**
   * this FN is to request emails set owner API against the provided updater in request params
   * @param id will be used to provide as request params to the emails set owner API
   * @param status will be used to provide as request params to the emails set owner API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public linkUnlink(id, ownerId, isLink): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {id, ownerId, isLink};
    return this.jsonService.json(serverPaths.emailsSetOwner, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve('Success');
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }

    /**
   * this FN is to request email move to API against the provided updater in request params
   * @param id will be used to provide as request params to theemail move to API
   * @param status will be used to provide as request params to theemail move to API
   * @returns resolved promise with the response for content data or rejected promise with error data
   */
  public moveDirectory(id, directory): Promise<any> {
    let requestEvent = new RequestEvent();
    requestEvent.param = {id, directory};
    return this.jsonService.json(serverPaths.emailsMoveTo, requestEvent).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        return Promise.resolve('Success');
      } else {
        return Promise.reject('Failed to find Emails Record');
      }
    }).catch((responseEvent: ResponseEvent) => {
      LogUtils.error(responseEvent.rawResponse);
      return Promise.reject('Failed to find Emails Record');
    });
  }
}

