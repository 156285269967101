import {Component, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {ResponseEvent} from '../../common/event/responseEvent';
import {ActivatedRoute} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {OptOut} from '../../common/models/optOut';
import {LogUtils} from '../../common/utils/logUtils';

@Component({
  templateUrl: './optOutV1.component.html'
})

export class OptOutComponentV1 implements OnInit {
  optOuts: OptOut[] = [];

  constructor(private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrOptOutView, {}).then((responseEvent: ResponseEvent) => {
      this.optOuts = responseEvent.getDocs();
      this.optOuts.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  getClasses(obj) {
    let def = collectionClassHelper.getCollectionName(obj);
    return `${def} ${obj.status} ${obj.subStatus}`;
  }
}
