<div class="container">
    <table class="table">
        <thead>
            <th>
                ACTIONS <br />
                <div style="margin-bottom: -10px">
                    <span class="sm-title">Expand</span>
                </div>
            </th>
            <th>NAME</th>
            <th>ID</th>
            <th>CREATED AT</th>
            <th>UPDATED AT</th>
            <th>STATUS</th>
        </thead>
        <tbody *ngIf="tableData && tableData.length > 0">
            <ng-container *ngFor="let record of tableData; let i = index;">
                <tr>
                    <td class="td mw-160">
                        <div class="d-flex justify-content-center">
                            <span class="cursor-pointer"
                                *ngIf="!(expandable.isExpanded && expandable.id==record.obj?._id)"
                                (click)="expandable.isExpanded=true;expandable.id=record.obj?._id">
                                <img src="assets/icons/expand.svg" alt="">
                            </span>
                            <span class="cursor-pointer" *ngIf="expandable.isExpanded && expandable.id==record.obj?._id"
                                (click)="expandable.isExpanded=false;expandable.id=''">
                                <img src="assets/icons/collapse.svg" alt="">
                            </span>
                        </div>
                    </td>


                    <td class="td">{{record?.obj?.name ||'N/A'}}</td>
                    <td class="td">{{record?.obj?._id||'N/A'}}</td>
                    <td class="td">{{record?.obj?.createdTimestamp | date : 'medium' ||'N/A'}}</td>
                    <td class="td">{{record?.obj?.changedTimestamp | date : 'medium' ||'N/A'}}</td>

                    <td class="td">
                        <span *ngIf="record?.obj?.originalRevisionId == null" >
                            <span *ngIf="record?.obj?.status !='removed'" class="add">Added</span>
                            <span *ngIf="record?.obj?.status =='removed'" class="remove">Removed</span>
                        </span>
                        <span *ngIf="record?.obj?.originalRevisionId">
                            <span *ngIf="record?.obj?.status =='active'" class="update">Updated</span>
                            <span *ngIf="record?.obj?.status =='removed'" class="remove">Removed</span>
                        </span>
                    </td>



                    <!-- <td class="td">{{record?.obj?.fromName}}</td>
                    <td class="td"> {{record?.obj?.customer?.email || 'N/A'}}</td>
                    <td class="td">{{record?.obj?.subject}}</td> -->
                    <!-- <td class="td">{{record | json}}</td> -->
                </tr>
                <tr class="row-expanded" *ngIf="expandable.isExpanded && expandable.id==record.obj?._id">
                    <td colspan="8" style="max-width: 100%;">
                        <ng-container *ngIf="record?.obj?.uxComponentIds?.length>0">
                            <span *ngFor="let item of record?.obj?.uxComponentIds; let i = index;">
                                <div class="p-2"
                                    [innerHTML]="'<strong>UXCOMP ID: </strong>'+record?.obj?.uxComponentIds[i]"
                                    style="width: 100%;"></div>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="record?.obj?.childUxccIds?.length>0">
                            <span *ngFor="let item of record?.obj?.childUxccIds; let i = index;">
                                <div class="p-2"
                                    [innerHTML]="'<strong>CHILD UXCS ID: </strong>'+record?.obj?.childUxccIds[i]"
                                    style="width: 100%;"></div>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="record?.obj?.uxComponentCollectionIds?.length>0">
                            <span *ngFor="let item of record?.obj?.uxComponentCollectionIds; let i = index;">
                                <div class="p-2"
                                    [innerHTML]="'<strong>UXCC ID: </strong>'+record?.obj?.uxComponentCollectionIds[i]"
                                    style="width: 100%;"></div>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="record?.obj?.uxLayoutIds?.length>0">
                            <span *ngFor="let item of record?.obj?.uxLayoutIds; let i = index;">
                                <div class="p-2" [innerHTML]="'<strong>UXL ID: </strong>'+record?.obj?.uxLayoutIds[i]"
                                    style="width: 100%;"></div>
                            </span>
                        </ng-container>
                        <ng-container *ngIf="!record?.obj?.uxComponents?.length">
                            <div class="p-2"
                                [innerHTML]="record.obj?.content"
                                style="width: 100%;"></div>
                        </ng-container>

                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <!-- <div class="d-flex" *ngIf="pagination.totalRecords > 0">
        <div class="ml-auto d-flex">
            <span class="m-auto m-10">Results per page</span>

            <mat-form-field class="m-auto" appearance="outline" floatLabel="always" style="width: 85px; margin-bottom: -18px;">
                <mat-select (valueChange)="onSelectChange($event)" [(ngModel)]="pagination.perPage">
                    <mat-option *ngFor="let page of [5, 25, 50, 75, 100, 125]" [value]="page">{{ page }}</mat-option>
                </mat-select>
            </mat-form-field>

            <span class="m-auto m-10">
                {{pagination.currentPage}} of {{pagination.totalPages}} pages
            </span>
            <span class="mt-auto mb-auto m-10">
                <mat-icon [ngClass]="{'disabled': !pagination.hasPrev, 'dark-gray': pagination.hasPrev}" aria-hidden="false" (click)="_onPrevPage()" aria-label="Example home icon">keyboard_arrow_left</mat-icon>
                <mat-icon [ngClass]="{'disabled': !pagination.hasNext, 'dark-gray': pagination.hasNext}" aria-hidden="false" (click)="_onNextPage()" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
            </span>
        </div>
    </div> -->
</div>
