<style>
    code {
      white-space: pre-wrap;
      font-size: 0.7em;
    }
  </style>
  <mat-card id="mailSent">
    <mat-card-content>
      <mat-form-field><input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="Email" [(ngModel)]="input.email"/></mat-form-field>
      <mat-form-field><input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="Brand" [(ngModel)]="input.brandId"/></mat-form-field>
      <button (click)="search(input)" [disabled]="!isValid()">Search</button>
  
      <ng-container *ngFor="let managedMail of managedMails">
        <mat-card>
          <mat-card-content>
            {{managedMail._id}}
            {{managedMail.email}}
            {{managedMail.status}}
            {{managedMail.subStatus}}
            <button *ngIf="!managedMail.isUnsubscribed()" (click)="unsubscribe(managedMail)" [disabled]="!isValid()">Unsubscribe</button>
            <button (click)="managedMail.toggle('show')"><i class="material-icons">format_line_spacing</i></button>
            <code *ngIf="managedMail.getToggle('show')" >{{getRaw(managedMail)}}</code>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-card-content>
  </mat-card>
  