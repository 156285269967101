<div class="container">
    <table class="table">
        <thead>
            <th>Attachments</th>
            <th>Complete</th>
            <th>Owner</th>
            <th>Customer Name</th>
            <th>Target Report PDF</th>
            <th>Request Date/Time</th>
            <th>Request #</th>
            <th>Details of Request</th>
            <th>Last Modified</th>
        </thead>
        <tbody *ngIf="tempData && tempData.length > 0">
            <ng-container *ngFor="let re of arrLength let i = index;">
                <tr>
                    <td class="td mw-160">
                        <div class="d-flex justify-content-start">
                            <span class="cursor-pointer tdsyles" *ngIf="re.attachments">{{tempData[i].attachments.length}}</span>
                            <span class="cursor-pointer tdsyles" *ngIf="!re.attachments">0</span>
                            <span class="cursor-pointer">
                                <img src="assets/icons/expand.svg" alt=""
                                    (click)="openAddAttachmentModal(re?.referenceId)">
                            </span>
                        </div>
                    </td>

                    <td class="td mw-190 text-center">
                        <input id="targetCheckBox" type="checkbox" [checked]="re?.status === 'done'"
                            (click)="onChecked(re?.referenceId)" />
                    </td>
                    <!-- CSR Owner -->
                    <td class="td">
                        <span *ngIf="selectCheck === i && show">
                            <mat-form-field appearance="fill">
                                <mat-label>Select CSR</mat-label>
                                <mat-select [(ngModel)]="selected" [value]="selected" required>
                                    <mat-option *ngFor="let csrPerson of getCSRPersons;let i = index;" [value]="csrPerson">
                                        {{ csrPerson.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button class="btn saveSelectedValue btn-close" (click)="savePersonName(re?.referenceId)"
                                aria-label="Close">Save</button>
                        </span>
                        <span *ngIf="selectCheck ===i">
                            <span *ngIf="showSelectedValue">
                                {{holdTempValue?.name ? holdTempValue?.name : "None"}}
                            </span>
                            <span class="cursor-pointer" style="float: right;" *ngIf="showSelectedValue">
                                <img src="assets/icons/Edit.png" alt="" (click)="selectHideShow(i)">
                            </span>
                        </span>
                        <span *ngIf="selectCheck !==i">
                            <span>
                                {{re?.output?.ownerName ? re?.output?.ownerName: 'None'}}
                            </span>
                            <span class="cursor-pointer">
                                <img src="assets/icons/Edit.png" alt="" (click)="selectHideShow(i)">
                            </span>
                        </span>
                    </td>
                    <td class="td customerName">
                        <a (click)="openCustomerDetail(re?.customerId)">{{re?.customerName ? re?.customerName : ''}}</a>
                    </td>
                    <td class="td">
                        <span class="tdsyles">{{re?.data?.requestType ? re?.data?.requestType : ''}}</span>&nbsp;
                        <a class="viewPdf" (click)="downloadViewPDFReport(re)">View PDF</a>
                    </td>
                    <td class="td tdsyles">
                        {{re?.data?.requestDate ? re?.data?.requestDate : ''}}
                    </td>
                    <td class="td tdsyles">
                        {{re?.data?.requestId ? re?.data?.requestId : ''}}
                    </td>
                    <td class="td tdsyles">
                        <app-expanded-text [text]="re?.data?.requestDetails">
                            <span class="detailSection" prefix> {{re?.data?.requestType ? re?.data?.requestType : ''}}:</span>
                        </app-expanded-text>
                    </td>
                    <td class="td tdsyles">
                        {{re?.data?.requestDate.slice(0,20) ? re?.data?.requestDate.slice(0,20) : ''}}
                    </td>
                </tr>
                <tr class="row-expanded">
                    <ng-container>
                        <td colspan="8" style="max-width: 100%;">
                            <div style="width: 100%;" [(innerHTML)]="emailBodyData"></div>
                        </td>
                    </ng-container>
                    <ng-template #showObject>
                        <td colspan="8" style="max-width: 100%;">
                            <div [(innerHTML)]="emailBodyData" style="width: 100%;"></div>
                        </td>
                    </ng-template>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <mat-paginator [length]="tempData?.length" [pageSize]="5" [pageSizeOptions]="[5,10,25,100]" aria-label="Select page"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>