import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommerceOrder } from 'src/common/models/commerce/commerceOrder';
import { CommercePayment } from 'src/common/models/commerce/commercePayment';
import {UxComposite} from 'src/common/models/ux/uxComposite';
import {custom} from '../../custom/custom.class';
import * as moment from "moment";
import { CommerceTransactionCollection } from 'src/common/models/commerce/commerceTransactionCollection';
import { CommerceTransaction } from 'src/common/models/commerce/commerceTransaction';
import { LogUtils } from 'src/common/utils/logUtils';

@Component({
  selector: 'app-transaction-detail-v3',
  templateUrl: './transaction-detail-v3.component.html',
  styleUrls: [],
})
export class TransactionDetailComponentV3 implements OnInit {
  @Input() commerceOrder: CommerceOrder;
  @Input() commercePayment: CommercePayment;
  @Input() uxComposite: UxComposite;
  @Input() userInfo: any;
  @Input() isOrder: boolean;
  @Output() updateCommerceOrder = new EventEmitter<CommerceOrder>();

  custom = custom;

  details = {
    avsresponse: {
      X: 'Street Address and 9 Digit Zip Exact Match',
      Y: 'Street Address and 5 Digit Zip Exact Match',
      M: 'Street Address and 5 Digit Zip Exact Match',
      A: 'Street Address Exact Match Only, No Zip Match',
      W: 'No Street Address Match, 9 Digit Zip Exact Match Only',
      Z: 'No Street Address Match, 5 Digit Zip Exact Match Only',
      N: 'No Street Address Match, No Zip Match',
      U: 'AVS System Unavailable',
      R: 'Issuer AVS System Unavailable',
      E: 'AVS Data Invalid Error',
      S: 'AVS Not Supported',
      B: 'AVS Not Available',
      O: 'AVS Not Available',
      0: 'AVS Not Available',
      D: 'International - Street Address and Zip Exact Match',
      P: 'International - No Street Address Match, Zip Exact Match Only',
      L: 'International - No Street Address Match, Zip Exact Match Only',
      C: 'International - No Address or ZIP Match',
      G: 'International - International Issuer System Unavailable',
      I: 'International -Address Information Not Verified by Issuer',
      F: 'United Kingdom Only -Street Address and Zip Exact Match',
    },
    cvvresponse: {
      M: 'CVV2/CVC2 Match',
      N: 'CVV2/CVC2 No Match',
      P: 'Not Processed',
      S: 'Merchant has indicated that CVV2/CVC2 is not present on card',
      U: 'Issuer is not certified and/or has not provided Visa encryption keys',
      X: 'No Response From Issuer – Issuer Error',
    }
  };

  detailWithoutParentId: {
    commerceTransactionCollection: CommerceTransactionCollection,
    commerceTransaction: CommerceTransaction,
  } = {
    commerceTransactionCollection: null,
    commerceTransaction: null,
  };

  ngOnInit() {
    this.setDetailWithoutParentId();
  }

  handleUpdateOrder(order: CommerceOrder) {
    this.updateCommerceOrder.emit(order);
  }

  codeOutput(obj) {
    return JSON.stringify(obj, null, 4);
  }

  formatTimestamp(timestamp) {
    if (!timestamp) {
      timestamp = 0;
    }
    return moment(timestamp).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss")
  }

  isValidUpdateCommerceOrder(commerceOrder: CommerceOrder) {
    return commerceOrder.isDiffDraft();
  }

  private setDetailWithoutParentId() {
    if (this.commercePayment.parentId) {
      return;
    }

    for (const commerceTransactionCollection of this.commerceOrder.commerceTransactionCollections) {
      for (const commerceTransaction of commerceTransactionCollection.commerceTransactions) {
        for (const commercePayment of commerceTransaction.commercePayments) {
          if (commercePayment._id === this.commercePayment._id) {
            this.detailWithoutParentId.commerceTransactionCollection = commerceTransactionCollection;
            this.detailWithoutParentId.commerceTransaction = commerceTransaction;
            return;
          }
        }
      }
    }

    LogUtils.warn('TransactionDetailComponentV3.setDetailWithoutParentId : Not Found');

    this.detailWithoutParentId.commerceTransactionCollection = null;
    this.detailWithoutParentId.commerceTransaction = null;
  }
}
