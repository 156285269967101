<div class="tracking">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>
          Tracking
        </span>
        <div>
          <a class="mr-2" mat-stroked-button color="primary" [routerLink]="['/'+adminPaths.trackingV1]">Tracking v1</a>
          <button mat-icon-button color="primary" (click)="init()">
            <i class="material-icons">cached</i>
          </button>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>User ID or Session Hash</span>
            <i class="material-icons" matTooltip="Input User ID or Session Hash here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="User ID"
              [(ngModel)]="input.user" />
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search(input)" [disabled]="!input.user">Search</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div>
        <table mat-table [dataSource]="trackings" multiTemplateDataRows>
          <ng-container matColumnDef="expander">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary">
                <i class="material-icons" (click)="expandedElement = expandedElement === element ? null : element">add_box</i>
              </button>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.createdTimestamp | toDateTime}} </td>
          </ng-container>
  
          <ng-container matColumnDef="uid">
            <th mat-header-cell *matHeaderCellDef class="id"> User Id </th>
            <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef class="id"> Location </th>
            <td mat-cell *matCellDef="let element"> {{element.param.location}} </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="type"> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.param.type}} </td>
          </ng-container>
  
          <ng-container matColumnDef="uxc">
            <th mat-header-cell *matHeaderCellDef class="id"> UXC </th>
            <td mat-cell *matCellDef="let element"> {{element.uxcId}} </td>
          </ng-container>
  
          <ng-container matColumnDef="uxl">
            <th mat-header-cell *matHeaderCellDef class="id"> UXL </th>
            <td mat-cell *matCellDef="let element"> {{element.uxlId}} </td>
          </ng-container>
  
          <ng-container matColumnDef="session">
            <th mat-header-cell *matHeaderCellDef> Session </th>
            <td mat-cell *matCellDef="let element"> {{element.sessionHash}} </td>
          </ng-container>
  
          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <br>
                <p><code>{{getRaw(element)}}</code></p>
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [class.example-expanded-row]="expandedElement === element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
