<div id="email">
  <mat-sidenav-container class="mailbox">
    <mat-sidenav mode="side" opened class="mailbox-sidenav">
      <h2 class="mailbox-sidenav__title">Logo Name</h2>
      <mat-card-content>
        <ul class="email-sidebar__list">
          <li class="email-sidebar__list--item" (click)="onMoveDirectory('INBOX')">
            <i class="material-icons">inbox</i>
            <span>Inbox</span>
          </li>
        </ul>
        <ng-container *ngIf="directoryItems">
          <span *ngFor="let item of directoryItems">
            <span *ngIf="item.children.length > 0">
              <app-list-item #menu [items]="item.children" (onClickedList)="onMoveDirectory($event)">
              </app-list-item>
            </span>
          </span>
        </ng-container>
      </mat-card-content>
    </mat-sidenav>
    <mat-sidenav-content class="mailbox-viewport">
      <mat-card class="mailbox-viewport__container">
        <mat-card-title class="{{ isShowingDetail ? 'detail' : '' }}">
          <mat-toolbar class="mailbox-viewport__toolbar {{
              isShowingDetail ? 'detail' : ''
            }}">
            <ng-container *ngIf="!isShowingDetail">
              <mat-checkbox color="primary"></mat-checkbox>
              <button mat-icon-button color="primary" [matMenuTriggerFor]="batchMenu"
                class="mailbox-viewport__toolbar--batch">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #batchMenu="matMenu">
                <button mat-menu-item>
                  <span>Select All</span>
                </button>
                <button mat-menu-item>
                  <span>Deselect All</span>
                </button>
              </mat-menu>
              <button mat-icon-button color="primary">
                <mat-icon>refresh</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="isShowingDetail">
              <button mat-icon-button color="primary" (click)="goBackToList()">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </ng-container>
            <button mat-icon-button color="primary" [matMenuTriggerFor]="actionMenu" *ngIf="!isShowingDetail">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button mat-menu-item>
                <span>Mark as read</span>
              </button>
              <button mat-menu-item>
                <span>Mark as unread</span>
              </button>
              <button mat-menu-item>
                <span>Archive</span>
              </button>
              <button mat-menu-item>
                <span>Delete</span>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="moveToMenu">
                <span>Move to</span>
              </button>
            </mat-menu>
            <mat-menu #moveToMenu="matMenu">
              <button mat-menu-item>
                <span>Spam</span>
              </button>
              <button mat-menu-item>
                <span>Folder</span>
              </button>
              <button mat-menu-item>
                <span>ABC</span>
              </button>
              <button mat-menu-item>
                <span>DEF</span>
              </button>
            </mat-menu>
            <span class="mailbox-viewport__toolbar--spacer"></span>
            <mat-paginator [hidePageSize]="true" [pageSize]="100" color="primary" [length]="totalCount" *ngIf="!isShowingDetail">
            </mat-paginator>
          </mat-toolbar>
        </mat-card-title>
        <mat-card-content>
          <div class="mailbox-viewport__content">
            <table mat-table [dataSource]="emails" class="mailbox-viewport__content--table" matSort
              *ngIf="!isShowingDetail">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef width="16px" class="selecth"></th>
                <td mat-cell *matCellDef="let row" width="16px" class="select">
                  <mat-checkbox color="primary"></mat-checkbox>
                </td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="dateh">
                  Date
                </th>
                <td mat-cell *matCellDef="let element" class="date">
                  {{ element.date }}
                </td>
              </ng-container>

              <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
                <td mat-cell *matCellDef="let element" class="brand">
                  PrivateRecords
                </td>
              </ng-container>

              <ng-container matColumnDef="from">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>From</th>
                <td mat-cell *matCellDef="let element" class="from">
                  {{ element.from }}
                </td>
              </ng-container>

              <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Subject
                </th>
                <td mat-cell *matCellDef="let element" class="subject">
                  {{ element.subject }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Status
                </th>
                <td mat-cell *matCellDef="let element" class="status">
                  {{ element.status }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="inboxDisplayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: inboxDisplayedColumns" (click)="setCurrentContent(row)"></tr>

              <tr mat-row *cdkNoDataRow>
                <td mat-cell colspan="5">No Emails Found</td>
              </tr>
            </table>
            <ng-container *ngIf="isShowingDetail">
              <div class="mailbox-detail">
                <div class="mailbox-detail__heading">
                  <div class="mailbox-detail__heading--left">
                    <div class="mailbox-detail__heading--options">
                      <span *ngFor="let item of directoryItems">
                        <!-- Handle branch node buttons here -->
                        <span *ngIf="item.children && item.children.length > 0">
                          <button mat-raised-button color="primary" [matMenuTriggerFor]="menu.childMenu">
                            {{item.name}}
                            <mat-icon>expand_more</mat-icon>
                          </button>
                          <app-menu-item #menu [items]="item.children" (onClickedMenu)="handleMoveDirectory($event)">
                          </app-menu-item>
                        </span>
                        <!-- Leaf node buttons here -->
                        <span *ngIf="item.children.length === 0">
                          <button color="primary" (click)="handleMoveDirectory(item)">
                            {{item.name}}
                          </button>
                        </span>
                      </span>
                    </div>
                    <div class="mailbox-detail__heading--delivery">
                      <!-- <div>
                        <label
                          for="cus_state"
                          class="mailbox-detail__heading--label"
                          >From</label
                        >
                        <p
                          class="mailbox-detail__heading--content"
                          id="cus_state"
                        >
                          {{ currentEmail.from }}
                        </p>
                      </div> -->
                      <div>
                        <label for="cus_state" class="mailbox-detail__heading--label">Received</label>
                        <p class="mailbox-detail__heading--content" id="cus_state">
                          {{ currentEmail.date }}
                        </p>
                      </div>
                    </div>
                    <!-- <mat-form-field
                      appearance="outline"
                      floatLabel="always"
                      class="mailbox-detail__heading--input"
                    >
                      <mat-label>To</mat-label>
                      <input type="email" matInput placeholder="Email" />
                    </mat-form-field> -->
                  </div>
                  <div class="mailbox-detail__heading--right">
                    <ng-container *ngIf="!isUnlink">
                      <div class="mailbox-detail__heading--info">
                        <p class="mailbox-detail__heading--name">{{ currentCustomer.firstName }} {{
                          currentCustomer.lastName }}</p>
                        <button mat-raised-button color="primary" (click)="linkOrUnlinkCustomer(false)">
                          Unlink
                        </button>
                      </div>
                      <div>
                        <label for="cus_email" class="mailbox-detail__heading--label">Customer email</label>
                        <p class="mailbox-detail__heading--content" id="cus_email">
                          {{ currentCustomer.email }}
                        </p>
                      </div>
                      <div>
                        <label for="cus_state" class="mailbox-detail__heading--label">Customer state</label>
                        <p class="mailbox-detail__heading--content" id="cus_state">
                          {{ currentCustomer.status }}
                        </p>
                      </div>
                      <div>
                        <label for="cus_last_billed" class="mailbox-detail__heading--label">Last billed</label>
                        <p class="mailbox-detail__heading--content" id="cus_last_billed">
                          {{ lastBilled }}
                        </p>
                      </div>
                      <div>
                        <label for="cus_total_value" class="mailbox-detail__heading--label">Total value</label>
                        <p class="mailbox-detail__heading--content" id="cus_total_value">
                          ${{ total }}
                        </p>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isUnlink">
                      <div class="mailbox-detail__heading--info">
                        <p class="mailbox-detail__heading--name">
                          Link Email -> Customer
                        </p>
                      </div>
                      <div>
                        <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                          <mat-label>Customer Id</mat-label>
                          <input type="text" matInput [(ngModel)]="customerId" />
                        </mat-form-field>
                      </div>
                      <div>
                        <button mat-raised-button color="primary" (click)="linkOrUnlinkCustomer(true)" class="w-100">
                          Link
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="mailbox-detail__content">
                  <div class="mailbox-detail__content--reply">
                    <div class="mailbox-detail__content--replyinfo" [innerHTML]="currentEmail.tempClient?.child?.body">
                    </div>
                  </div>
                  
                  <h2 class="mailbox-detail__content--subject">Subject line</h2>
                  <p class="mailbox-detail__content--content main">
                    {{ currentEmail.subject }}
                  </p>

                  <div class="mailbox-detail__content--content">
                    On {{ currentEmail.date }}
                    {{ currentEmail.fromName }} &lt;{{ currentEmail.from }}&gt;
                    wrote:
                    <div [innerHTML]="emailContent"></div>
                    <br />
                    <br />
                  </div>

                  <mat-card class="mailbox-detail__reply--container">
                    <mat-card-title>
                      <span class="mailbox-detail__content--replyhead">support&#64;privaterecords.com</span>
                      <mat-select (selectionChange)="handleChangeTemplate($event)" class="mailbox-detail__content--replytemplate">
                        <mat-option *ngFor="let template of templates;" [value]="template">{{ template.name }}</mat-option>
                      </mat-select>
                    </mat-card-title>
                    <mat-card-content class="mailbox-detail__content--replycontent">
                      <editor [init]="{
                        height: 500,
                        menubar: true,
                        plugins: [
                          'advlist autolink lists link image charmap print',
                          'preview anchor searchreplace visualblocks code',
                          'fullscreen insertdatetime media table paste',
                          'help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | image bold italic | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | help'
                      }" [(ngModel)]="replyMessage"></editor>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="mailbox-detail__action">
                  <button mat-raised-button color="primary" class="mailbox-detail__action--send" (click)="onReplyTo()"
                    [disabled]="!replyMessage">
                    Send
                  </button>
                  <button mat-stroked-button color="primary" class="mailbox-detail__action--send" (click)="onReplyTo()"
                    [disabled]="!replyMessage">
                    Send &amp; Archive
                  </button>
                  <button mat-stroked-button color="primary" (click)="onStatusUpdate()">
                    Update Status
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>