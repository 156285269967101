<span>
  <ng-content select="[prefix]"></ng-content>
  {{ text | slice: 0 : effectiveMaxLength }}
  <span *ngIf="expandable && !expanded">{{ collapsedString }}</span>
</span>
<span 
  *ngIf="expandable" 
  (click)="toggleExpandButton()"
  class="cursor-pointer" 
  style="float: right;"
>
  <img 
    [src]="expanded ? collapseSrc : expandSrc" 
    style="cursor: pointer;"
    alt="add"
  >
</span>