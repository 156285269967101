<div class="timesheet">
  <div class="timesheet__container">
    <h2 class="timesheet-title">
      Customer Support Timesheets
    </h2>
    <div class="timesheet-datepick">
      <mat-form-field appearance="outline">
        <mat-label>Enter start date of week (Sunday)</mat-label>
        <input matInput [matDatepickerFilter]="rangeFilter" [matDatepicker]="picker" [ngModel]="startDate" (ngModelChange)="handleChangeStartDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="timesheet__card">
      <table mat-table [dataSource]="timesheetRecords" class="w-100 timesheet-table">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element">
            {{element.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef> Contact Info </th>
          <td mat-cell *matCellDef="let element">
            {{element.contact[0]}}
          </td>
        </ng-container>

        <ng-container matColumnDef="timeInToday">
          <th mat-header-cell *matHeaderCellDef> Time In Today </th>
          <td mat-cell *matCellDef="let element">
            {{sanitizeDate(element.timeInToday)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            {{element.status}}
          </td>
        </ng-container>

        <ng-container matColumnDef="hoursToday">
          <th mat-header-cell *matHeaderCellDef> Hours Today </th>
          <td mat-cell *matCellDef="let element" [class.invalid]="checkInvalid(element.hoursToday)">
            {{sanitizeHours(element.hoursToday)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="weeklyHours">
          <th mat-header-cell *matHeaderCellDef> Weekly Hours </th>
          <td mat-cell *matCellDef="let element">
            {{sanitizeHours(element.weeklyHours)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef> Hours Yesterday {{sanitizeHours(totalHoursYesterday)}}</th>
          <td mat-cell *matCellDef="let element" [class.invalid]="checkInvalid(element.hoursYesterday)">
            {{sanitizeHours(element.hoursYesterday)}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="timesheetColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: timesheetColumns;" (click)="viewDetailPage(row)"></tr>
      </table>
    </div>
  </div>
</div>