import { Pipe, PipeTransform } from "@angular/core";
import { UxcFullMgmtUpdateInfo, UxcFullMgmtUpdateTypeValue } from "../uxc/uxcFullMgmt.component";
import diff from 'simple-text-diff'
import { LogUtils } from "src/common/utils/logUtils";

@Pipe({
  name: 'replaceRegexPipe',
  pure: true,
})
export class ReplaceRegexPipe implements PipeTransform {
  private lastValue: string;

  private lastUpdateInfo: UxcFullMgmtUpdateInfo;

  private effectiveValue: string;

  transform(value: string, updateInfo: UxcFullMgmtUpdateInfo, type: UxcFullMgmtUpdateTypeValue) {
    if (this.lastValue === value && updateInfo.equals(this.lastUpdateInfo)) {
      return this.chooseReturnValue(value);
    }

    this.lastValue = value;
    this.lastUpdateInfo = updateInfo.copyWith();
    
    if (updateInfo.isEffective(type)) {
      try {
        const safeString = typeof value === 'string'
          ? value
          : '';

        const replaced = safeString.replace(new RegExp(updateInfo.pattern, updateInfo.options), updateInfo.replacement);
        
        const diffResult = diff.diffPatch(safeString, replaced);
        
        this.effectiveValue = updateInfo.showDiff 
          ? safeString === diffResult.after && safeString === diffResult.before
            ? this.escapeHtmlTags(safeString)
            : '<div class="replaceRegexContainer">' + 
              '<div class="before"><strong>Before:</strong><br>' + this.escapeHtmlTags(diffResult.before) + '</div>' + 
              '<div class="after"><strong>After:</strong><br>' + this.escapeHtmlTags(diffResult.after) + '</div>' + 
              '</div>'
          : this.escapeHtmlTags(diffResult.after);
      } catch (e) {
        LogUtils.error('ReplaceRegexPipe.transform error', e);

        this.effectiveValue = null;
      } 
    } else {
      this.effectiveValue = null;
    }

    return this.chooseReturnValue(value);
  }

  private chooseReturnValue(value: string) {
    return this.effectiveValue === null ? value : this.effectiveValue;
  }

  private escapeHtmlTags(html: string): string {
    // Replace all tags except <ins> and <del>
    return html.replace(/(<(?!del|\/del|ins|\/ins))|((?<!del|ins)>)/gi, (tag) => {
      return tag.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    });
  }
}