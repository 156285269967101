<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mr-2 {
    margin-right: 12px;
  }
</style>

<h2 mat-dialog-title class="modal-title">Add a Timesheet</h2>
<mat-dialog-content class="mat-typography content">
  <div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="editData.date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Input time in</mat-label>
        <input matInput [(ngModel)]="editData.timeIn" placeholder="Time In (HH:mm)">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Input time out</mat-label>
        <input matInput [(ngModel)]="editData.timeOut" placeholder="Time out (HH:mm)">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="w-100">
        <mat-label>Input note</mat-label>
        <input matInput [(ngModel)]="editData.note" placeholder="Note">
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="handleAddTimesheetEntry()" class="mr-2">Add</button>
  <button mat-stroked-button color="warn" (click)="closeModal()">Cancel</button>
</mat-dialog-actions>