<style>
  .email-sidebar__list {
    list-style-type: none;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    padding-left: 28px;
  }

  .email-sidebar__list--item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    transition: color .2s;
  }

  .email-sidebar__list--item:hover {
    color: #673ab7 !important;
  }

  .email-sidebar__list--item i {
    color: #acaec2;
  }

  .email-sidebar__list--item span {
    margin-left: 8px;
  }
</style>
<ng-container *ngFor="let child of items; let i = index;">
  <ul *ngIf="child.children && child.children.length > 0" class="email-sidebar__list">
    <li class="email-sidebar__list--item">
      <i class="material-icons">inbox</i>
      <span>{{child.name}}</span>
    </li>
    <app-list-item [items]="child.children" (onClickedList)="handleClick($event, i)"></app-list-item>
  </ul>
  <ul *ngIf="!child.children || child.children.length === 0" class="email-sidebar__list">
    <li class="email-sidebar__list--item" (click)="handleClick(child.name, -1)">
      <i class="material-icons">inbox</i>
      <span>
        {{child.name}}
      </span>
    </li>      
  </ul>
</ng-container>