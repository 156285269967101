import {Component, OnInit} from '@angular/core';
import {UxConfig} from '../../common/models/ux/uxConfig';
import {UxLayout} from '../../common/models/ux/uxLayout';
import {UxComponentCollection} from '../../common/models/ux/uxComponentCollection';
import {UxComponent} from '../../common/models/ux/uxComponent';
import {ModelBase} from '../../common/models/modelBase';
import {CrudService} from '../../clientCommon/services/crud.service';
import {LogUtils} from '../../common/utils/logUtils';
import {ArrayUtils} from '../../common/utils/arrayUtils';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {StorageService} from '../../clientCommon/services/storage.service';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {ResponseEvent} from '../../common/event/responseEvent';
import {JsonService} from '../../clientCommon/services';
import {CrudHelperSearchOptions, CrudHelperService} from "../services/crudHelper.service";

@Component({
  templateUrl: 'uxcFullMgmtV1.component.html'
})

export class UxcFullMgmtComponentV1 implements OnInit {
  addFlag = false;
  newUxc: UxConfig = new UxConfig();
  newUxl: UxLayout = new UxLayout();
  newUxcc: UxComponentCollection = new UxComponentCollection();
  newUxComponent: UxComponent = new UxComponent();

  uxComponentTypes: { key: string, value: string }[] = [];
  alertDuration = 5000;

  SLOT_TYPES = UxConfig.SLOT_TYPES;
  slotTypes = ArrayUtils.toArray(UxConfig.SLOT_TYPES);
  fullContentFlag = false;
  idRegex = new RegExp('^([0-9a-f]{24}|[|])*$');
  dragIndex = -1;
  ModelBase = ModelBase;

  uxDetailTypes = ['uxLayouts', 'uxComponentCollections', 'uxComponents'];

  results: {
    uxConfigs: UxConfig[]
    uxLayouts: UxLayout[]
    uxComponentCollections: UxComponentCollection[]
    uxComponents: UxComponent[]
  } = {
    uxConfigs: [],
    uxLayouts: [],
    uxComponentCollections: [],
    uxComponents: [],
  };

  searchOptions: CrudHelperSearchOptions = {
    key: 'adminUxcSearchOptionFilters',
    filters: {
      uxConfigs: {
        className: collectionClassHelper.getCollectionName(UxConfig),
        fields: ['_id', 'name'],
        value: '',
      },
      uxLayouts: {
        className: collectionClassHelper.getCollectionName(UxLayout),
        fields: ['_id', 'name'],
        value: '',
      },
      uxComponentCollections: {
        className: collectionClassHelper.getCollectionName(UxComponentCollection),
        fields: ['_id', 'name'],
        value: '',
      },
      uxComponents: {
        className: collectionClassHelper.getCollectionName(UxComponent),
        fields: ['_id', 'name'],
        value: '',
      },
    },
  };

  constructor(private crudService: CrudService,
              private crudHelperService: CrudHelperService,
              private snackBar: MatSnackBar,
              private storageService: StorageService,
              private jsonService: JsonService,
              private spinnerService: SpinnerService) {
    this.uxComponentTypes = ArrayUtils.toArray(UxComponent.TYPE);
  }

  ngOnInit() {
    this.newUxComponent.draft.type = UxComponent.TYPE.JSON;

    const filters = this.storageService.get(this.searchOptions.key);
    if (filters) {
      this.searchOptions.filters = filters;
    }
  }

  addUxc() {
    if (this.newUxc.draft.name) {
      this.newUxc.draft.status = ModelBase.STATUS.active;
      this.newUxc.draft.removeUnusedSlotTypes();
      this.newUxc.draft.removeRemoved();
      return this.crudHelperService.add(this.newUxc, this.alertDuration, this.searchOptions, this.results).then(() => {
        this.newUxc = new UxConfig();
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxc.draft.name, 'Error', {duration: this.alertDuration});
    }
  }

  addUxl() {
    if (this.newUxl.draft.name) {
      this.newUxl.draft.status = ModelBase.STATUS.active;
      return this.crudHelperService.add(this.newUxl, this.alertDuration, this.searchOptions, this.results).then(() => {
        this.newUxl = new UxLayout();
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxl.draft.name, 'Error', {duration: this.alertDuration});
    }
  }

  addUxcc() {
    if (this.newUxcc.draft.name) {
      this.newUxcc.draft.status = ModelBase.STATUS.active;
      return this.crudHelperService.add(this.newUxcc, this.alertDuration, this.searchOptions, this.results).then(() => {
        this.newUxcc = new UxComponentCollection();
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxcc.draft.name, 'Error', {duration: this.alertDuration});
    }
  }

  addUxComponent() {
    if (this.newUxComponent.draft.name && (this.newUxComponent.getToggle('uncheck') || this.newUxComponent.draft.check())) {
      this.newUxComponent.draft.status = ModelBase.STATUS.active;
      return this.crudHelperService.add(this.newUxComponent, this.alertDuration, this.searchOptions, this.results).then(() => {
        this.newUxComponent = new UxComponent();
        this.newUxComponent.draft.type = UxComponent.TYPE.JSON;
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxComponent.draft.name, 'Error', {duration: this.alertDuration});
    }
  }

  search(): Promise<any> {
    return this.crudHelperService.search(this.searchOptions, this.results).then(() => {
      // nothing
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  update(obj: ModelBase<any>) {
    try {
      if (obj instanceof UxConfig) {
        ArrayUtils.removeEmpty(obj.draft.uxLayoutIds);
        obj.draft.removeUnusedSlotTypes();
        obj.draft.removeRemoved();
      } else if (obj instanceof UxLayout) {
        ArrayUtils.removeEmpty(obj.draft.uxComponentCollectionIds);
      } else if (obj instanceof UxComponentCollection) {
        ArrayUtils.removeEmpty(obj.draft.childUxccIds);
        ArrayUtils.removeEmpty(obj.draft.uxComponentIds);
      } else if (obj instanceof UxComponent) {
        // Nothing to do
        if ((!obj.getToggle('uncheck')) && (!obj.draft.check())) {
          this.snackBar.open('Error updating ' + obj.draft.name, 'Error', {duration: this.alertDuration});
          return;
        }
      }

      return this.crudHelperService.update(obj, this.alertDuration, this.searchOptions, this.results);
    } catch (e) {
      LogUtils.error(e);
    }
  }

  addItemToArray(array: any[], form) {
    if (form.value) {
      array.push(form.value);
      form.value = null;
    }
  }

  removeItemInArray(array: any[], index) {
    array.splice(index, 1);
  }

  toggleStatus(object: ModelBase<any>) {
    if (object.draft.status === ModelBase.STATUS.active) {
      object.draft.status = ModelBase.STATUS.removed;
    } else {
      object.draft.status = ModelBase.STATUS.active;
    }
  }

  copyId(obj) {
    return this.crudHelperService.copyId(obj, this.alertDuration);
  }

  copyName(obj) {
    return this.crudHelperService.copyName(obj, this.alertDuration);
  }

  isWeightedUxLayout(uxc: UxConfig, uxlId) {
    return uxc.slotTypes[uxlId] && uxc.slotTypes[uxlId].slotType === UxConfig.SLOT_TYPES.default;
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search();
    }
  }

  buildUxComposites() {
    const parallel = 36;
    const uxcIds = [];
    this.results.uxConfigs.forEach((uxConfig, index) => {
      uxcIds.push(uxConfig._id);
    });

    this.spinnerService.spin();
    return Promise.resolve().then(() => {
      const promises = [];

      for (let i = 0; i < parallel; i++) {
        promises.push(this.executeBuildUxComposite(uxcIds));
      }

      return Promise.all(promises);
    }).catch((e) => {
      LogUtils.error(e, uxcIds);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  executeBuildUxComposite(uxcIds: string[]) {
    return Promise.resolve().then(() => {
      if (uxcIds.length) {
        const uxcId = uxcIds.pop();
        return this.buildUxComposite(uxcId).then(() => {
          return this.executeBuildUxComposite(uxcIds);
        });
      }
    });
  }

  buildUxComposite(uxcId) {
    const url = '/api/manage/admin/build/uxComposites';
    const params = {uxcIds: [uxcId]};

    return this.jsonService.json(url, params).then((responseEvent: ResponseEvent) => {
      this.snackBar.open('UXC build done:' + uxcId, 'Build', {duration: this.alertDuration});
      LogUtils.info(uxcId, responseEvent.data);
    }).catch((e) => {
      this.snackBar.open('UXC build failed:' + uxcId, 'Fail', {duration: this.alertDuration});
      LogUtils.error(e);
    });
  }

  attachUxDetail(uxDetails) {
    LogUtils.info('UxDetails', uxDetails);

    if (uxDetails && uxDetails.length) {
      uxDetails.forEach((uxDetail) => {
        let flag = false;
        this.uxDetailTypes.some((type) => {
          if (this.results[type] && this.results[type].length) {
            this.results[type].some((ux: ModelBase<any>) => {
              if (ux._id === uxDetail.baseId) {
                ux.tempClient.uxDetail = uxDetail;
                flag = true;
                return flag;
              }
            });
          }
          return flag;
        });
      });
    }
  }

  findUxDetail(uxId) {
    const url = '/api/manage/admin/find/uxDetails';
    const params = {uxIds: [uxId]};

    this.spinnerService.spin();
    return this.jsonService.json(url, params).then((responseEvent: ResponseEvent) => {
      this.attachUxDetail(responseEvent.data.uxDetails);
      this.snackBar.open('Find UX detail done:' + uxId, 'Find', {duration: this.alertDuration});
    }).catch((e) => {
      this.snackBar.open('Find UX detail failed:' + uxId, 'Fail', {duration: this.alertDuration});
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  findOrphanUx() {
    const url = '/api/manage/admin/find/uxDetails';
    const uxIds = [];
    const params = {uxIds: uxIds, type: 'orphanCheck'};

    this.uxDetailTypes.forEach((type) => {
      this.results[type].forEach((ux: ModelBase<any>) => {
        uxIds.push(ux._id);
      });
    });

    this.spinnerService.spin();
    return this.jsonService.json(url, params).then((responseEvent: ResponseEvent) => {
      this.attachUxDetail(responseEvent.data.uxDetails);
      this.snackBar.open('Find orphan done', 'Find', {duration: this.alertDuration});
    }).catch((e) => {
      this.snackBar.open('Find orphan failed', 'Fail', {duration: this.alertDuration});
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }


  duplicate(obj: ModelBase<any>) {
    const newObj = new (obj.constructor as any)(obj.getSaveSet());
    newObj.draft.name = 'Copy of ' + newObj.draft.name;
    return this.crudHelperService.add(newObj, this.alertDuration, this.searchOptions, this.results);
  }

  dragStart(event, array: [], index) {
    this.dragIndex = index;
  }

  dragOver(event, array: [], index) {
    event.preventDefault();
  }

  drop(event, array: [], index) {
    if (index >= 0 && this.dragIndex >= 0) {
      const swap = array[this.dragIndex];
      array.splice(this.dragIndex, 1);
      array.splice(index, 0, swap);
    }
  }

  dragEnd(event, array: [], index) {
    this.dragIndex = -1;
  }

  isOrphan(modelBase: ModelBase<any>) {
    return modelBase.tempClient.uxDetail && modelBase.tempClient.uxDetail.minDepth === 0;
  }

  hasDetail(modelBase: ModelBase<any>) {
    return modelBase.tempClient.uxDetail && (!modelBase.tempClient.uxDetail.type);
  }

  isOrphanCheckable() {
    let flag = false;

    this.uxDetailTypes.some((type) => {
      this.results[type].some((ux: ModelBase<any>) => {
        flag = true;
        return flag;
      });
    });
    return flag;
  }


}
