import {Injectable} from '@angular/core';
import {User} from '../../common/models/user/user';
import {Guard} from '../../clientCommon/helper/guard';
import {ServiceHelperService} from '../../clientCommon/services/serviceHelper.service';

@Injectable()
export class AdminAuthGuard extends Guard {

  constructor(serviceHelperService: ServiceHelperService) {
    super(serviceHelperService);
    this.role = User.ROLES.admin;
  }
}
