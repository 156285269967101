import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import {JsonService} from '../../clientCommon/services/json.service';
import {ResponseEvent} from '../../common/event/responseEvent';
import {ActivatedRoute} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {adminPaths, serverPaths} from '../../common/helpers/pathHelpers';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {objectUtils} from '../../common/utils/objectUtils';
import {Tracking} from '../../common/models/tracking';
import {LogUtils} from '../../common/utils/logUtils';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { inputUtils } from 'src/clientCommon/utils/inputUtils';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { configUtils } from '../utils/ConfigUtils';

@Component({
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TrackingComponent extends BaseDirective implements OnInit, AfterViewInit {
  trackings = new MatTableDataSource<Tracking>([]);
  adminPaths = adminPaths;

  input: any = {};
  prevInput: any = {};
  more = false;
  brands = [];

  columnsToDisplay = ['expander', 'date','uid', 'location', 'type', 'uxc', 'uxl', 'session'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              public serviceHelperService: ServiceHelperService,
              private snackBar: MatSnackBar) {
    super(serviceHelperService, route);
  }

  ngOnInit() {
    super.baseInit().then(() => this.init());
  }

  ngAfterViewInit() {
    this.trackings.paginator = this.paginator;
  }

  init() {
    this.spinnerService.spin();
    this.brands = configUtils.getBrands(this.uxComposite);
    this.jsonService.json(serverPaths.manageCsrTrackingView, {}).then((responseEvent: ResponseEvent) => {
      this.trackings.data = responseEvent.getDocs();
      this.trackings.data.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  getClasses(obj) {
    const def = collectionClassHelper.getCollectionName(obj);
    return `${def} ${obj.status} ${obj.subStatus}`;
  }

  getRaw(obj) {
    const param = objectUtils.clone(obj.param);
    return JSON.stringify(param, null, 4);
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search(this.input);
    }
  }

  search(input, aggregate?: boolean) {
    objectUtils.deleteEmptyProperties(input);
    this.prevInput = objectUtils.clone(input);
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrTrackingView, input).then((responseEvent: ResponseEvent) => {
      this.trackings.data = responseEvent.getDocs();
      this.trackings.data.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });

  }

  isValid() {
    return this.input && this.input.email;
  }
}
