import {customClientPaths, customDoubleServePaths} from "../../common/custom/customPathHelpers";
import {clientPaths} from "../../common/helpers/pathHelpers";
import {stringUtils} from "../../common/utils/stringUtils";
import {urlUtils} from "./urlUtils";
import {LogUtils} from "../../common/utils/logUtils";


export class CustomPathChanger {
  salt:string = "907db4e2806c4a8d4bf570913c76960f";
  hashedDomainKey:string|null = null

  types = {
    wi: "wi",
  };
  type;

  hashedDomainKeyMapping = {};

  mappedPaths: { [oldPath: string]: string } = {};
  reverseMappedPaths: { [oldPath: string]: string } = {};

  constructor() { }

  init = () => {
    this.hashedDomainKeyMapping = {
      "6afdd67ad3d593b1b3ce44311ff37f50ab1b8561": this.types.wi, // weinform
      "2ee77001a48d3ed63532534bdd2e551fb5eef945": this.types.wi, // truthrecord
    };

    this.hashedDomainKey = this.getHashedDomainKey();
    this.type = this.hashedDomainKeyMapping[this.hashedDomainKey];
    this.replacePaths(this.hashedDomainKeyMapping[this.hashedDomainKey]);
  }

  private getHashedDomainKey = ():string => {
    return stringUtils.SHA1(this.salt + "|" + urlUtils.get2ndLevelDomain(window.location.hostname));
  }

  private replacePaths = (type):void => {
    if (type) {
      switch(type) {
        case this.types.wi:
          this.replacePathsWi();
          return;
        default:
          return
      }
    }
  }

  private replacePathsWi = ():void => {
    const customClientPathMap = [
      {src:"salesNameSearchLanding", dest: "name_search/landing_page"},
      {src:"salesNameSearchLoader", dest: "name_search/loader"},
      {src:"salesNameSearchReportReview", dest: "name_search/report_review"},
      {src:"salesNameSearchSearchResult", dest: "name_search/search_result"},
      {src:"salesNameSearchSignup", dest: "name_search/sign_up"},
      {src:"salesPhoneSearchLanding", dest: "reverse_phone/landing_page"},
      {src:"salesPhoneSearchLoader", dest: "reverse_phone/loader"},
      {src:"salesPhoneSearchReportReview", dest: "reverse_phone/report_review"},
      {src:"salesPhoneSearchSearchResult", dest: "reverse_phone/search_result"},
      {src:"salesPhoneSearchSignup", dest: "reverse_phone/sign_up"},
      {src:"salesNameSearchOptOutLanding", dest: "opt_out/name/landing_page"},
      {src:"salesNameSearchOptOutLoader", dest: "opt_out/name/loader"},
      {src:"salesNameSearchOptOutSearchResult", dest: "opt_out/name/search_result"},
      {src:"salesNameSearchOptOutInfoInput", dest: "opt_out/name/info_input"},
      {src:"salesReviewPeopleSearchList", dest: "my_review/people_search/list"},
      {src:"salesReviewPeopleSearchDetail", dest: "my_review/people_search/detail"},
    ];
    customClientPathMap.forEach((item) => this.replacePath(customClientPaths, item.src, item.dest));

    const clientPathMap = [
      {src:"landingIntermediate", dest: "landing_Intermediate"},
      {src:"article", dest: "my_articles"},
      {src:"page", dest: "my_page"},
      {src:"terms", dest: "terms_and_conditions"},
      {src:"privacy", dest: "privacy_and_policy"},
      {src:"contactUs", dest: "contact_us_for_help"},
      {src:"savedReportsPage", dest: "my_saved_reports"},
      {src:"contactUsFull", dest: "customer_support"},
      {src:"refund", dest: "request_refund"},
      {src:"unsubscribe", dest: "unsubscribe_phones"},
      {src:"suppressionList", dest: "suppression_list"},
      {src:"memberLogin", dest: "member/account_login"},
      {src:"mailUnsubscribe", dest: "email/unsubscribe_email"},
      {src:"mailClick", dest: "email/click"},
      {src:"optOutConfirmation", dest: "Unsub/email_confirmation"}
    ];
    clientPathMap.forEach((item) => this.replacePath(clientPaths, item.src, item.dest));
  }

  private replacePath = (pathObject, pathPropoerty, newPath):void => {
    this.mappedPaths[pathObject[pathPropoerty]] = newPath;
    this.reverseMappedPaths[newPath] = pathObject[pathPropoerty];

    pathObject[pathPropoerty] = newPath;
  }

  private trimSlashes = (path):string => {
    if (path?.replace) {
      return path.replace(/^\/|\/$/g, '');
    }
    return path
  }

  public getNewPathFromOldPath= (path) => {
    return this.mappedPaths[this.trimSlashes(path)];
  }

  public getOldPathFromNewPath = (path) => {
    const oldPath = this.reverseMappedPaths[this.trimSlashes(path)];
    if (oldPath?.length && !(oldPath?.startsWith("/"))) {
      return `/${oldPath}`;
    }
    return oldPath;
  }

  public getNewAPIPath = (path):void => {
    const enableAPIDoubleServe = false;
    const pathSplitted = path?.substr(3)?.split('?');
    try {
      const apiPath = pathSplitted?.[0]
      if (customDoubleServePaths?.[this.type]?.[apiPath] && enableAPIDoubleServe) {
        path = `api${customDoubleServePaths[this.type][apiPath]}`;
        if (pathSplitted?.[1]) {
          path += `?${pathSplitted[1]}`;
        }
      }
    } catch (e) {
      LogUtils.error(e, path);
    }

    return path;
  }
}

export const customPathChanger = new CustomPathChanger();
