<style>
    .inline {
      display: inline-block;
    }
  
    mat-card {
      margin-bottom: 5px;
    }
  
    mat-form-field {
      font-size: 0.9em;
    }
  
    mat-form-field.id {
      width: 230px;
    }
  
    .quantity,
    .period,
    .unit,
    .amount,
    .sequence {
      margin-right: 5px;
    }
  
    .product .quantity,
    #products .quantity,
    .period,
    .unit {
      width: 60px;
    }
  
    .amount {
      width: 60px;
    }
  
    .offer .name,
    .offer .desc,
    .offer .extName,
    .offer .extDesc {
      width: 340px;
      margin: 0 10px 0 0;
    }
  
    .offer .prices {
      width: 350px;
    }
  
    .offer .prices .add {
      display: block;
      margin: auto;
    }
  
    .offer .quantity {
      width: 45px;
    }
  
    .offer .options textarea {
      height: 120px;
    }
  
    .sequence {
      width: 50px;
    }
  
    .unit {
      width: 50px;
      font-size: 10px;
    }
  
    .extraFlag {
      font-size: 10px;
      margin-right: 10px;
    }
  
    .extraFlag ::ng-deep .mat-checkbox-label {
      margin-left: -5px;
    }
  
  </style>
  <mat-card id="productsFullMgmt" *ngIf="uxComposite">
    <mat-card-content>
      <mat-card-header>
        <mat-card-title>
          Product Mgmt Tool
        </mat-card-title>
      </mat-card-header>
  
  
      <mat-card id="creations">
        <mat-card-content>
          <mat-card class="stock">
            <mat-card-header>
              <mat-card-subtitle>
                Stock
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newCommerceStock.draft.name"/>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="text" name="description" placeholder="Description"
                       [(ngModel)]="newCommerceStock.draft.description"/>
              </mat-form-field>
              <button [disabled]="(!newCommerceStock.draft.name)" (click)="add(newCommerceStock)">Add</button>
            </mat-card-content>
          </mat-card>
  
  
          <mat-card class="product">
            <mat-card-header>
              <mat-card-subtitle>
                Product
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <mat-form-field>
                <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newCommerceProduct.draft.name"/>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="text" name="description" placeholder="Description"
                       [(ngModel)]="newCommerceProduct.draft.description"/>
              </mat-form-field>
              <mat-form-field>
                <input matInput type="text" name="commerceStockId" placeholder="Stock ID"
                       #newCommerceProductCommerceStockId/>
              </mat-form-field>
              <mat-form-field class="quantity">
                <input matInput type="number" name="quantity" placeholder="Quantity" [(ngModel)]="newCommerceProduct.draft.commerceStockDetails[0].quantity"/>
              </mat-form-field>
              <mat-form-field class="period">
                <input matInput type="number" name="period" placeholder="Period" [(ngModel)]="newCommerceProduct.draft.commerceStockDetails[0].period.quantity"/>
              </mat-form-field>
              <mat-select class="unit" name="unit" placeholder="Unit" [(ngModel)]="newCommerceProduct.draft.commerceStockDetails[0].period.unit">
                <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                  {{ unit.value }}
                </mat-option>
              </mat-select>
              <mat-form-field>
                <input matInput type="text" placeholder="Content Type" [(ngModel)]="newCommerceProduct.draft.contentType"/>
              </mat-form-field>
              <button [disabled]="(!newCommerceProduct.draft.name || !newCommerceProduct.draft.contentType || !newCommerceProductCommerceStockId.value)"
                      (click)="add(newCommerceProduct,newCommerceProductCommerceStockId.value)">Add
              </button>
            </mat-card-content>
          </mat-card>
  
  
          <mat-card class="offer">
            <mat-card-header>
              <mat-card-subtitle>
                Offer
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
  
              <div class="inline">
                <div>
                  <mat-form-field class="name">
                    <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newCommerceOffer.draft.name"/>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="desc">
                    <input matInput type="text" name="description" placeholder="Description" [(ngModel)]="newCommerceOffer.draft.description"/>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="extName">
                    <input matInput type="text" name="extName" placeholder="Ext.Title" [(ngModel)]="newCommerceOffer.draft.extName"/>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="extDesc">
                    <input matInput type="text" name="extDesc" placeholder="Ext.Desc" [(ngModel)]="newCommerceOffer.draft.extDesc"/>
                  </mat-form-field>
                </div>
              </div>
              <div class="inline">
                <div *ngFor="let id of newCommerceOffer.draft.commerceProductIds; let i = index ">
                  <mat-form-field class="id">
                    <input matInput [placeholder]="newCommerceOffer.getObjById('commerceProducts',id)?.name" [(ngModel)]="newCommerceOffer.draft.commerceProductIds[i]">
                    <button mat-icon-button matPrefix><i class="material-icons" (click)="removeItemInArray(newCommerceOffer.draft.commerceProductIds,i)">clear</i></button>
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyId(newCommerceOffer.getObjById('commerceProducts',id))">perm_device_information</i>
                    </button>
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyName(newCommerceOffer.getObjById('commerceProducts',id))">perm_identity</i></button>
                  </mat-form-field>
                </div>
                <mat-form-field>
                  <input matInput #newProductId placeholder="Product ID" (change)="addItemToArray(newCommerceOffer.draft.commerceProductIds,newProductId)">
                </mat-form-field>
              </div>
              <div class="prices inline">
                <mat-list *ngFor="let price of newCommerceOffer.draft.commercePrice.prices; let i = index ">
                  <mat-list-item>
                    <button mat-icon-button *ngIf="newCommerceOffer.draft.commercePrice.prices.length>1">
                      <i class="material-icons" (click)="removeItemInArray(newCommerceOffer.draft.commercePrice.prices,i)">clear</i>
                    </button>
                    <mat-form-field class="amount">
                      <input matInput type="number" name="amount" placeholder="Amount" [(ngModel)]="price.amount"/>
                    </mat-form-field>
                    <mat-form-field class="quantity">
                      <input matInput type="number" name="period" placeholder="Period" [(ngModel)]="price.period.quantity"/>
                    </mat-form-field>
                    <mat-form-field class="sequence">
                      <input matInput type="text" name="sequence" placeholder="Sequence" [(ngModel)]="price.sequence"/>
                    </mat-form-field>
                    <mat-select class="unit" name="unit" placeholder="Unit" [(ngModel)]="price.period.unit">
                      <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                        {{ unit.value }}
                      </mat-option>
                    </mat-select>
                    <mat-checkbox class="extraFlag bateFlag" [(ngModel)]="price.options[commercePriceOptions.bypassOnActiveTokenExists]" matTooltip="bypassOnActiveTokenExists">BATE</mat-checkbox>
                  </mat-list-item>
                </mat-list>
                <button class="add" (click)="addPrice(newCommerceOffer.draft)">
                  Add more
                </button>
              </div>
              <div class="inline">
                <div>
                  <mat-form-field class="options">
                      <textarea matInput placeholder="options"
                                [ngModel]="newCommerceOffer.draft.tempClient.optionsString"
                                (input)="processCommerceOfferOptionsEvent($event, newCommerceOffer.draft)">
                      </textarea>
                  </mat-form-field>
                </div>
                <div>
                  <div class="inline">
                    <mat-checkbox [(ngModel)]="newCommerceOffer.draft.nonMemberOnly">Signup</mat-checkbox>
                  </div>
                  <div class="inline">
                    <button [disabled]="!isValid(newCommerceOffer)" (click)="add(newCommerceOffer)"> Add</button>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
  
  
        </mat-card-content>
      </mat-card>
  
  
      <mat-card id="search">
        <mat-card-content>
          <mat-form-field>
            <input matInput type="text" name="stockNameFilter" placeholder="Stock.name" (keydown)="keyDownEvent($event)"
                   [(ngModel)]="searchFilter.stockName"/>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" name="productNameFilter" placeholder="Product.name" (keydown)="keyDownEvent($event)"
                   [(ngModel)]="searchFilter.productName"/>
          </mat-form-field>
          <mat-form-field>
            <input matInput type="text" name="offerNameFilter" placeholder="Offer.name" (keydown)="keyDownEvent($event)"
                   [(ngModel)]="searchFilter.offerName"/>
          </mat-form-field>
          <button (click)="search()"
                  [disabled]="!searchFilter.stockName && !searchFilter.productName && !searchFilter.offerName">
            Search
          </button>
        </mat-card-content>
      </mat-card>
  
  
      <mat-card id="edit">
  
  
        <mat-card id="stocks">
          <mat-card-header>
            <mat-card-subtitle>
              Stocks
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngFor="let commerceStock of commerceStocks" class="commerceStock baseline">
              <mat-card>
                <mat-card-content>
                  <mat-form-field class="id">
                    <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="commerceStock._id">
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyId(commerceStock)">perm_device_information</i></button>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="text" placeholder="Name" [(ngModel)]="commerceStock.draft.name"/>
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyName(commerceStock.draft)">perm_identity</i></button>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="text" placeholder="Desc" [(ngModel)]="commerceStock.draft.description"/>
                  </mat-form-field>
                  <button (click)="update(commerceStock)" [disabled]="!commerceStock.draft.name">Update</button>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </mat-card-content>
        </mat-card>
  
  
        <mat-card id="products">
          <mat-card-header>
            <mat-card-subtitle>
              Products
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngFor="let commerceProduct of commerceProducts" class="commerceProduct baseline">
              <mat-card>
                <mat-card-content>
                  <mat-form-field class="id">
                    <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="commerceProduct._id">
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyId(commerceProduct)">perm_device_information</i></button>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="text" placeholder="Name" [(ngModel)]="commerceProduct.draft.name"/>
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyName(commerceProduct.draft)">perm_identity</i></button>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="text" placeholder="Desc" [(ngModel)]="commerceProduct.draft.description"/>
                  </mat-form-field>
                  <mat-form-field class="id">
                    <input matInput type="text" [placeholder]="commerceProduct.commerceStocks[0].name" [(ngModel)]="commerceProduct.draft.commerceStockIds[0]"/>
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyId(commerceProduct.commerceStocks[0])">perm_device_information</i></button>
                    <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyName(commerceProduct.commerceStocks[0])">perm_identity</i></button>
                  </mat-form-field>
                  <mat-form-field class="quantity">
                    <input matInput type="number" name="quantity" placeholder="Quantity" [(ngModel)]="commerceProduct.draft.commerceStockDetails[0].quantity"/>
                  </mat-form-field>
                  <mat-form-field class="period">
                    <input matInput type="number" name="period" placeholder="Period" [(ngModel)]="commerceProduct.draft.commerceStockDetails[0].period.quantity"/>
                  </mat-form-field>
                  <mat-select class="unit" name="unit" placeholder="Unit" [(ngModel)]="commerceProduct.draft.commerceStockDetails[0].period.unit">
                    <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                      {{ unit.value }}
                    </mat-option>
                  </mat-select>
                  <mat-form-field>
                    <input matInput type="text" placeholder="Content Type" [(ngModel)]="commerceProduct.draft.contentType"/>
                  </mat-form-field>
                  <button (click)="update(commerceProduct)" [disabled]="!commerceProduct.draft.name || !commerceProduct.draft.commerceStockIds[0]">Update</button>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </mat-card-content>
        </mat-card>
  
  
        <mat-card id="offers">
          <mat-card-header>
            <mat-card-subtitle>
              Offers
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngFor="let commerceOffer of commerceOffers" class="commerceOffer baseline">
              <mat-card class="offer">
                <mat-card-content>
                  <div class="inline">
                    <div>
                      <mat-form-field class="id">
                        <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="commerceOffer._id">
                        <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyId(commerceOffer)">perm_device_information</i></button>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="name">
                        <input matInput type="text" placeholder="Name" [(ngModel)]="commerceOffer.draft.name"/>
                        <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyName(commerceOffer.draft)">perm_identity</i></button>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="desc">
                        <input matInput type="text" placeholder="Desc" [(ngModel)]="commerceOffer.draft.description"/>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="extName">
                        <input matInput type="text" placeholder="Ext.Title" [(ngModel)]="commerceOffer.draft.extName"/>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="extDesc">
                        <input matInput type="text" placeholder="Ext.Desc" [(ngModel)]="commerceOffer.draft.extDesc"/>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="inline">
                    <div *ngFor="let id of commerceOffer.draft.commerceProductIds; let i = index ">
                      <mat-form-field class="id">
                        <input matInput [placeholder]="commerceOffer.getObjById('commerceProducts',id)?.name" [(ngModel)]="commerceOffer.draft.commerceProductIds[i]">
                        <button mat-icon-button matPrefix><i class="material-icons" (click)="removeItemInArray(commerceOffer.draft.commerceProductIds,i)">clear</i></button>
                        <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyId(commerceOffer.getObjById('commerceProducts',id))">perm_device_information</i>
                        </button>
                        <button mat-icon-button matSuffix=""><i class="material-icons" (click)="copyName(commerceOffer.getObjById('commerceProducts',id))">perm_identity</i></button>
                      </mat-form-field>
                    </div>
                    <mat-form-field>
                      <input matInput #newProductId placeholder="Product ID" (change)="addItemToArray(commerceOffer.draft.commerceProductIds,newProductId)">
                    </mat-form-field>
                  </div>
                  <div class="prices inline">
                    <mat-list *ngFor="let price of commerceOffer.draft.commercePrice.prices; let i = index ">
                      <mat-list-item>
                        <button mat-icon-button *ngIf="commerceOffer.draft.commercePrice.prices.length>1">
                          <i class="material-icons" (click)="removeItemInArray(commerceOffer.draft.commercePrice.prices,i)">clear</i>
                        </button>
                        <mat-form-field class="sequence">
                          <input matInput type="text" name="sequence" placeholder="Sequence"
                                 [(ngModel)]="price.sequence"/>
                        </mat-form-field>                        
                        <mat-form-field class="amount">
                          <input matInput type="number" name="amount" placeholder="Amount"
                                 [(ngModel)]="price.amount"/>
                        </mat-form-field>
                        <mat-form-field class="quantity">
                          <input matInput type="number" name="period" placeholder="Period"
                                 [(ngModel)]="price.period.quantity"/>
                        </mat-form-field>
                        <mat-select class="unit" name="unit" placeholder="Unit" [(ngModel)]="price.period.unit">
                          <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                            {{ unit.value }}
                          </mat-option>
                        </mat-select>
                        <mat-checkbox class="extraFlag bateFlag" [(ngModel)]="price.options[commercePriceOptions.bypassOnActiveTokenExists]" matTooltip="bypassOnActiveTokenExists">BATE</mat-checkbox>
                      </mat-list-item>
                    </mat-list>
                    <button class="add" (click)="addPrice(commerceOffer.draft)">
                      Add more
                    </button>
                  </div>
                  <div class="inline">
                    <div>
                      <mat-form-field class="options">
                      <textarea matInput placeholder="options"
                                [ngModel]="commerceOffer.draft.tempClient.optionsString"
                                (input)="processCommerceOfferOptionsEvent($event, commerceOffer.draft)">
                      </textarea>
                      </mat-form-field>
                    </div>
                    <div>
                      <div class="inline">
                        <mat-checkbox [(ngModel)]="commerceOffer.draft.nonMemberOnly">Signup</mat-checkbox>
                      </div>
                      <div class="inline">
                        <button (click)="update(commerceOffer)" [disabled]="!isValid(commerceOffer)"> Update</button>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </mat-card-content>
        </mat-card>
  
  
      </mat-card>
  
    </mat-card-content>
  </mat-card>
  