import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: 'app-expanded-text',
  templateUrl: './expanded-text.component.html',
})
export class ExpandedTextComponent implements OnChanges {
  @Input() text: string = '';

  @Input() maxLength: number = 30;

  @Input() collapsedString: string = '...';

  expandable: boolean = false;

  expanded: boolean = false;

  effectiveMaxLength: number = this.maxLength;

  expandSrc: string = 'assets/icons/expand.svg';

  collapseSrc: string = 'assets/icons/collapse.svg';

  ngOnChanges(changes: SimpleChanges): void {
    this.expandable = this.text?.length > this.maxLength;

    if (!this.expandable) {
      this.expanded = false;
    }

    this.update();
  }

  toggleExpandButton() {
    if (!this.expandable) {
      return;
    }

    this.expanded = !this.expanded;

    this.update();
  }

  private update() {
    this.effectiveMaxLength = this.expanded ? undefined : this.maxLength;
  }
}