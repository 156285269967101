<div class="users">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>
          User management
        </span>
        <a mat-stroked-button color="primary" [routerLink]="['/'+adminPaths.userMgmtV1]">Users v1</a>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Email</mat-label>
            <input matInput type="text" placeholder="email" [(ngModel)]="newUser.draft.email"/>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>First Name</mat-label>
            <input matInput type="text" placeholder="First Name" [(ngModel)]="newUser.draft.firstName"/>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Last Name</mat-label>
            <input matInput type="text" placeholder="Last Name" [(ngModel)]="newUser.draft.lastName"/>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Password</mat-label>
            <input matInput type="password" placeholder="Password" [(ngModel)]="newUser.draft.password"/>
          </mat-form-field>
        </div>
        <div class="col select">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Permission</mat-label>
            <mat-select placeholder="Permission" [ngModel]="newUser.draft?.permissionIds[0]" (selectionChange)="updatePermission(newUser, $event)">
              <mat-option *ngFor="let permission of permissions" [value]="permission._id">{{ permission.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col select">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Role</mat-label>
            <mat-select placeholder="Role" [(ngModel)]="newUser.draft.roles[0]">
              <mat-option [value]="roles.csr">{{ roles.csr }}</mat-option>
              <mat-option [value]="roles.admin">{{ roles.admin }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col select">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Status</mat-label>
            <mat-select placeholder="Status" [(ngModel)]="newUser.draft.status">
              <mat-option [value]="status.active">{{ status.active }}</mat-option>
              <mat-option [value]="status.inactive">{{ status.inactive }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="d-flex">
            <button mat-raised-button color="primary" (click)="create()" [disabled]="!isValid(newUser)" class="ml-auto">Add User</button>  
          </div>
        </div>
      </div>
      <table mat-table [dataSource]="users" multiTemplateDataRows>
        <ng-container matColumnDef="expander">
          <th mat-header-cell *matHeaderCellDef width="40px"></th>
          <td mat-cell *matCellDef="let element" width="40px">
            <button mat-icon-button color="primary" (click)="expandedElement = expandedElement === element ? null : element">
              <i class="material-icons">add_box</i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Email</mat-label>
              <input matInput type="email" [(ngModel)]="element.draft.email" [readonly]="!editData[element._id].email">
              <button *ngIf="!editData[element._id].email" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="editData[element._id].email = true">
                <i class="material-icons">create</i>
              </button>
              <button *ngIf="editData[element._id].email" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="update(element)">
                <i class="material-icons">save</i>
              </button>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>First Name</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>First Name</mat-label>
              <input matInput type="text" [(ngModel)]="element.draft.firstName" [readonly]="!editData[element._id].firstName">
              <button *ngIf="!editData[element._id].firstName" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="editData[element._id].firstName = true">
                <i class="material-icons">create</i>
              </button>
              <button *ngIf="editData[element._id].firstName" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="update(element)">
                <i class="material-icons">save</i>
              </button>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>Last Name</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Last Name</mat-label>
              <input matInput type="text" [(ngModel)]="element.draft.lastName" [readonly]="!editData[element._id].lastName">
              <button *ngIf="!editData[element._id].lastName" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="editData[element._id].lastName = true">
                <i class="material-icons">create</i>
              </button>
              <button *ngIf="editData[element._id].lastName" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="update(element)">
                <i class="material-icons">save</i>
              </button>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="password">
          <th mat-header-cell *matHeaderCellDef>Password</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Password</mat-label>
              <input matInput type="password" [(ngModel)]="element.draft.password" [readonly]="!editData[element._id].password" placeholder="****">
              <button *ngIf="!editData[element._id].password" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="editData[element._id].password = true">
                <i class="material-icons">create</i>
              </button>
              <button *ngIf="editData[element._id].password" matSuffix mat-icon-button aria-label="Edit" color="primary" (click)="update(element)">
                <i class="material-icons">save</i>
              </button>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Role</mat-label>
              <mat-select [ngModel]="element.draft?.roles[0]" (selectionChange)="updateRole(element, $event)">
                <mat-option [value]="roles.admin">Admin</mat-option>
                <mat-option [value]="roles.csr">CSR</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="permission">
          <th mat-header-cell *matHeaderCellDef>Permission</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Permission</mat-label>
              <mat-select [ngModel]="element.draft?.permissionIds[0]" (selectionChange)="updatePermission(element, $event)">
                <mat-option *ngFor="let permission of permissions" [value]="permission._id">{{ permission.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Status</mat-label>
              <mat-select placeholder="Status" [ngModel]="element.draft.status" (selectionChange)="updateStatus(element, $event)">
                <mat-option [value]="status.active">{{ status.active }}</mat-option>
                <mat-option [value]="status.inactive">{{ status.inactive }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="userColumns.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              {{ element._id }}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: userColumns;" class="example-element-row" [class.example-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>

      <!-- <div>
        <mat-form-field appearance="outline" floatLabel="always"><input matInput type="text" placeholder="email" [(ngModel)]="newUser.draft.email" />
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always"><input matInput type="text" placeholder="First Name" [(ngModel)]="newUser.draft.firstName" />
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always"><input matInput type="text" placeholder="Last Name" [(ngModel)]="newUser.draft.lastName" />
        </mat-form-field>
        <mat-form-field appearance="outline" floatLabel="always"><input matInput type="password" placeholder="Password" [(ngModel)]="newUser.draft.password" />
        </mat-form-field>

        <mat-select placeholder="Permission" [ngModel]="newUser.draft?.permissionIds[0]"
          (selectionChange)="updatePermission(newUser, $event)">
          <mat-option *ngFor="let permission of permissions" [value]="permission._id">{{ permission.name }}</mat-option>
        </mat-select>
        <mat-select placeholder="Role" [(ngModel)]="newUser.draft.roles[0]">
          <mat-option [value]="roles.csr">{{ roles.csr }}</mat-option>
          <mat-option [value]="roles.admin">{{ roles.admin }}</mat-option>
        </mat-select>
        <mat-select placeholder="Status" [(ngModel)]="newUser.draft.status">
          <mat-option [value]="status.active">{{ status.active }}</mat-option>
          <mat-option [value]="status.inactive">{{ status.inactive }}</mat-option>
        </mat-select>
        <button (click)="create()" [disabled]="!isValid(newUser)">Create</button>
      </div> -->
    </mat-card-content>
  </mat-card>
</div>