export class CustomerSearchUtils {


  public static SEARCH_PREFIX = {
    fname: 'fname',
    lname: 'lname',
    email: 'email',
    phone: 'phone',
    l4: 'l4',
    brand: 'brand',
    state: 'state',
    fname_short: 'fn',
    lname_short: 'ln',
    email_short: 'em',
    phone_short: 'ph',
    brand_short: 'br',
    state_short: 'st',

    f4: 'f4',
    // sup: 'sup',
    // charged: 'charged',
  };


  isSmartSearchNameCandidate(query) {
    return query?.match(/[a-zA-Z]/) && query?.match(/^[*a-zA-Z0-9_\-.,]+$/);
  }

  isSmartSearchEmailCandidate(query) {
    return query.indexOf('@') > 0;
  }

}

export const customerSearchUtils = new CustomerSearchUtils();
