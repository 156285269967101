import {LogUtils} from "../../common/utils/logUtils";
class ClipboardUtils {
    /**
     * 
     * @param text the current text to be copied to clipboard
     * set flag to false
     * gets the textarea from document and sets it to textArea
     * sets the textArea value to text
     * appends the textarea to body of document
     * 
     * 
     * @returns 
     */
    copyTextToClipboard(text) {
        var flag = false;
        var textArea = document.createElement("textarea");

        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
            flag = true;
        } catch (e) {
            LogUtils.error(e);
        }

        document.body.removeChild(textArea);

        return flag;
    }
}

export var clipboardUtils = new ClipboardUtils();