<mat-card id="userMgmt">
    <mat-card-header>
      <mat-card-title>
        Orders
      </mat-card-title>      
    </mat-card-header>
    <div class="parameter-sample">
       <b>[ Search Criteria ]</b> id|61 brandId|private type|sale from|2024-01-08_12:00:00 to|2024-12-30_12:00:00 <br/>
       date format: timestamp or datetime<br/>
       field/value seperator : '|'
    </div>
    <mat-card-content>
      <div>
        <mat-form-field class="full" [class.searching]="onSmartSearch" [class.unique]="commerceOrders.length === 1">
          <input matInput #smartSearchInput type="text" (keydown)="keyDownSmartSearchEvent($event)"
            placeholder="id|id brandId|brandId type|type substatus|subStatus amount|amount"
            [ngModel]="smartSearch" (ngModelChange)="onSmartSearchChange($event)">
        </mat-form-field>
      </div>
      <div class="usersMgmt-filter">
      </div>
  
      <div class="users-wrapper">
        <table mat-table [dataSource]="filteredOrders" class="w-100">
  
          <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef> Brand </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)"> {{getBrandName(element.brandId)}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="paymentId">
            <th mat-header-cell *matHeaderCellDef> OrderID </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
              {{element._id}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)"> 
                    ${{element.commerceOfferDetail.price.amount}}                                 
            </td>
          </ng-container>
  
          <ng-container matColumnDef="payerId">
            <th mat-header-cell *matHeaderCellDef> PayerID </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
              {{element.payerId}}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
                {{element.type}}
            </td>
          </ng-container>
  
          <!-- <ng-container matColumnDef="subType">
            <th mat-header-cell *matHeaderCellDef> SubType </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
              {{ element.subType }}
            </td>
          </ng-container> -->
  
  
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassStatus(element.status)" (click)="viewDetailPage(element)">
              {{ element.status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="subStatus">
            <th mat-header-cell *matHeaderCellDef> Sub Status </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
              {{ element.subStatus }}
            </td>
          </ng-container>          

          <ng-container matColumnDef="commerceOfferId">
            <th mat-header-cell *matHeaderCellDef> CommerceOffer ID </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
              {{ element.commerceOfferId }}
            </td>
          </ng-container>
          <ng-container matColumnDef="commerceTokenId">
            <th mat-header-cell *matHeaderCellDef> CommerceToken ID </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
              {{ element.commerceTokenId }}
            </td>
          </ng-container>          
                

          <ng-container matColumnDef="timeStamp">
            <th mat-header-cell *matHeaderCellDef> Timestamp </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
              {{ element.createdTimestamp | formatTimestamp }}
            </td>
          </ng-container>        
                    
          <!-- <ng-container matColumnDef="Order Status">
            <th mat-header-cell *matHeaderCellDef> Order Status </th>
            <td mat-cell *matCellDef="let element" [ngClass]="getClassOrderStatus(element.commerceOrder.status)"  (click)="viewDetailPage(element)">
                {{ element?.commerceOrder.status }}
            </td>
          </ng-container>    
          
          <ng-container matColumnDef="Order subStatus">
            <th mat-header-cell *matHeaderCellDef> Order subStatus </th>
            <td mat-cell *matCellDef="let element" class="id" (click)="viewDetailPage(element)">
                {{ element?.commerceOrder.subStatus }}
            </td>
          </ng-container>               -->
  
          <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: userColumns;" [class.recursive]="row.tempClient?.recursive"></tr>
        </table>
      </div>
      <button [disabled]="!more" (click)="loadMore()">Load More</button>
    </mat-card-content>
  </mat-card>