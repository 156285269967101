<style>
    code {
      white-space: pre-wrap;
      font-size: 0.7em;
    }
  </style>
  <mat-card id="optOut">
    <mat-card-content>
      <button mat-fab (click)="init()"><i class="material-icons">cached</i></button>
      <ng-container *ngFor="let optOut of optOuts">
        <mat-card>
          <mat-card-content>
            {{optOut.createdTimestamp | toDateTime}}
            {{optOut._id}}
            {{optOut.brandId}}
            {{optOut.status}}
            {{optOut.type}}
            {{optOut.provider}}
            {{optOut.email}}
            {{optOut.targetId}}
          </mat-card-content>
        </mat-card>
      </ng-container>
    </mat-card-content>
  </mat-card>
  