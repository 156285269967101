<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mr-2 {
    margin-right: 12px;
  }

  .mb-sm {
    margin-bottom: 4px;
  }
</style>

<h2 mat-dialog-title class="modal-title">Add Order</h2>
<mat-dialog-content class="mat-typography content">
  <div>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Select Order</mat-label>
      <mat-select [ngModel]="commerceOfferId" (ngModelChange)="handleChangeOfferId($event)">
        <mat-option [value]="commerceOffer._id" *ngFor="let commerceOffer of data.commerceOffers">
          {{data.brandName}} {{commerceOffer.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <br>
  <p class="mb-sm"><strong>Trial Length: {{getDuration(data.commerceOffers[selectedIndex], true)}}</strong></p>
  <p class="mb-sm"><strong>Trial Payment: {{selectedIndex > -1 ? '$' : ''}}{{data.commerceOffers[selectedIndex]?.tempClientSecured?.prices[0][0]?.amount}}</strong></p>
  <p class="mb-sm"><strong>Billing Frequency: {{getDuration(data.commerceOffers[selectedIndex])}}</strong></p>
  <p class="mb-sm"><strong>Subscription amount: {{selectedIndex > -1 ? '$' : ''}}{{data.commerceOffers[selectedIndex]?.tempClientSecured?.prices[1][0]?.amount}}</strong></p>
  <br>
  <p class="mb-sm"><strong>Offer Description</strong></p>
  <p>{{data.commerceOffers[selectedIndex]?.extDesc}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button [disabled]="!commerceOfferId" color="primary" (click)="handleAddCommerceOffer()" cdkFocusInitial>Create Order</button>
</mat-dialog-actions>