import { Component, Input, ChangeDetectorRef } from "@angular/core";

@Component({
    selector: 'scheduleHistory',
    templateUrl: 'scheduleHistory.component.html'
})

export class ScheduleHistoryComponent {
    @Input() commerceSchedules = [];
    @Input() type: string = '';

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {  
      //this.uxHistory = this.uxHistory;
      this.cdr.detectChanges();
    }

    codeOutput(obj) {
        return JSON.stringify(obj, null, 4);
    }
}
