<style>
    mat-card {
      background: #ffffff;
      box-shadow: 0px 4px 25px rgba(201, 213, 216, 0.6);
      border-radius: 5px;
      margin-top: 48px;
      margin-left: -32px;
      margin-right: -32px;
    }

    mat-card-header {
      border-bottom: 2px solid #e3e8ff;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 32px;
      padding-right: 32px;
    }

    mat-card-title {
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    mat-card-content {
      padding: 8px 0;
    }
  .history-row {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
  }
  .w-20 {
    width: 20%;
  }
  .w-30 {
    width: 30%;
  }
  .w-40 {
    width: 40%;
  }

table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  padding: 5px;
}
.mr-10 {
  margin-right: 10px;
}
</style>
<mat-card *ngIf="uxHistory" class="mat-elevation-z6">
  <mat-card-content>

      <ng-container *ngFor="let history of uxHistory">
            <div class="history-row">
                <table>
                    <tr>
                        <!-- For all UXs -->
                        <td class="w-40">
                            <span>{{ history.createdTimestamp | date: "M/d/yy, h:mm a" || "N/A"}}</span> <br><br>
                            <span>{{ history.referenceId || "N/A" }} </span> <br><br>
                            <span>{{ history.reference.name || "N/A" }} </span> <br><br>
                            <span>{{ history.reference.description || "N/A" }} </span> <br><br>
                            <span *ngIf="type === 'uxComponents'">{{ history.reference.type || "N/A" }} </span>
                        </td>

                        <!-- For UXConfigs -->
                        <td class="w-20" *ngIf="history.referenceCollection === 'uxConfigs'">
                            <ng-container *ngFor="let item of history.reference.uxLayoutIds">
                                <span>{{item}}</span> <br/> <br/>
                            </ng-container>
                        </td>
                        <td class="w-20" *ngIf="history.referenceCollection === 'uxConfigs'">
                            <ng-container *ngFor="let uxIdss of history.reference.uxLayoutIds">
                                <span>{{history.reference.slotTypes[uxIdss]?.desc || "NA"}}</span> <br/> <br/>
                            </ng-container>
                        </td>
                        <td class="w-20" *ngIf="history.referenceCollection === 'uxConfigs'">
                            <ng-container *ngFor="let uxIdss of history.reference.uxLayoutIds">
                                <span>{{history.reference.slotTypes[uxIdss]?.slotType}} / </span>
                                <span>{{history.reference.slotTypes[uxIdss]?.weight || "N/A"}}</span>
                                <br/> <br/>
                            </ng-container>
                        </td>

                        <!-- For UXLayouts -->
                        <td *ngIf="history.referenceCollection === 'uxLayouts'">
                            <ng-container *ngFor="let item of history.reference.uxComponentCollectionIds">
                                <span>{{item}}</span> <br/> <br/>
                            </ng-container>
                        </td>

                        <!-- For UXComponentCollections -->
                        <td class="w-30" *ngIf="history.referenceCollection === 'uxComponentCollections'">
                            <ng-container *ngFor="let item of history.reference.childUxccIds">
                                <span>{{item}}</span> <br><br>
                            </ng-container>
                        </td>
                        <td class="w-30" *ngIf="history.referenceCollection === 'uxComponentCollections'">
                            <ng-container *ngFor="let item of history.reference.uxComponentIds">
                                <span>{{item}}</span> <br><br>
                            </ng-container>
                        </td>

                        <!-- For UXComponents -->
                        <td *ngIf="history.referenceCollection === 'uxComponents'">
                            <span>{{ history.reference.content || "N/A" }} </span>
                        </td>
                    </tr>
                </table>
            </div>
      </ng-container>
  </mat-card-content>
</mat-card>
