import {permissionUtils} from '../../common/utils/permissionUtils';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {User} from '../../common/models/user/user';
import {ServiceHelperService} from '../services/serviceHelper.service';

// Angular CanActivate Guard can be applied for the routes protection
export class Guard  {

  role: string;

  constructor(public serviceHelperService: ServiceHelperService) {
  }

  /**
   *
   * @returns boolean, by checking, user saved in localStoarage have the admin role
   */
  hasAdminRole() {
    const user = this.serviceHelperService.authenticationService.getUser();
    if (user && user.isValid([User.ROLES.admin])) {
      return true;
    }
    return false;
  }

  /**
   *
   * @returns boolean, by checking, if the role is in the user saved in localStoarage
   */
  hasRole(): boolean {
    if (this.role) {
      const user = this.serviceHelperService.authenticationService.getUser();
      if (user && user.isValid([this.role])) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   * @param route for which we want to apply canActivate guard
   * @param state last state of the user
   * @returns
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivatePath(route.url.join('/'));
  }

  /**
   * this:
   *  - first check if the user have admin role then simply retuens true
   *  - else, it check user have other role beside admin, it check the route path permission against the current user & return boolean respectively
   *  - if none of the above cases, returns false
   * @param path full route url path
   * @returns boolean based on role permissions it check
   */
  canActivatePath(path): boolean {
    if (this.hasAdminRole()) {
      return true;
    } else if (this.hasRole()) {
      const user = this.serviceHelperService.authenticationService.getUser();
      return permissionUtils.hasPermissionPath(user, path);
    }

    return false;
  }
}
