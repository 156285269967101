<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .row {
    display: flex;
    align-items: baseline;
  }

  .label {
    width: 60px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 900;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .card {
    font-size: 12px;
    font-weight: 900;
    margin-bottom: 24px;
  }

  .w-50 {
    display: inline;
    width: 50%;
    display: inline-block;
    width: calc(50% - 16px);
    margin: 0 8px;
  }

  .w-100 {
    display: inline;
    width: 100%;
  }
</style>

<h2 mat-dialog-title class="modal-title">Edit Payment Method</h2>
<mat-dialog-content class="mat-typography content">
  <div class="card">
    {{ creditCardInputHelper.cardTypeName }} {{ creditCardInputHelper.ccNumberFormatted }}
  </div>
  <div>
    <div>
      <mat-form-field appearance="outline" class="w-50">
        <mat-label>First Name</mat-label>
        <input matInput [placeholder]="data.commerceToken.billingAddress.firstName" type="text" name="firstName" [(ngModel)]="creditCardInputHelper.billingAddress.firstName" required />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50">
        <mat-label>Last Name</mat-label>
        <input matInput [placeholder]="data.commerceToken.billingAddress.lastName" type="text" name="lastName" [(ngModel)]="creditCardInputHelper.billingAddress.lastName" required />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50">
        <mat-label>CCNumber</mat-label>
        <input matInput [placeholder]="'CCNumber'" type="tel" name="ccNumber" [ngModel]="creditCardInputHelper.ccNumberFormatted" (input)="creditCardInputHelper.formatCCNumber($event);creditCardInputHelper.findCardType(creditCardInputHelper.ccNumber)" required />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field floatLabel="always" appearance="outline" class="w-50">
        <mat-label>Exp Month</mat-label>
        <mat-select name="ccExpMon" [(ngModel)]="creditCardInputHelper.ccExpMonth" required>
          <mat-option *ngFor="let month of creditCardInputHelper.ccExpMonths" [value]="month.key">{{month.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" class="w-50">
        <mat-label>Exp Year</mat-label>
        <mat-select name="ccExpYear" [(ngModel)]="creditCardInputHelper.ccExpYear" required>
          <mat-option *ngFor="let year of creditCardInputHelper.ccExpYears" [value]="year">{{year}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field class="ccCvv" appearance="outline" floatLabel="always" class="w-50">
      <mat-label>CVV</mat-label>
      <input matInput [placeholder]="'CVV'" type="tel" name="ccCvv" [ngModel]="creditCardInputHelper.ccCvv" (input)="creditCardInputHelper.formatCvv($event)" required />
    </mat-form-field>
    <mat-form-field class="ccZip" appearance="outline" floatLabel="always" class="w-50">
      <mat-label>ZIP</mat-label>
      <input matInput [placeholder]="data.commerceToken.billingAddress.zip" type="tel" name="zip" [(ngModel)]="creditCardInputHelper.billingAddress.zip" maxlength="5" required />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="validateCardInfo()" cdkFocusInitial>Save</button>
</mat-dialog-actions>