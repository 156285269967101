import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/csr/notes-management/confirm-dialog/confirm-dialog.component';
import { configUtils } from 'src/app/utils/ConfigUtils';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { JsonService } from 'src/clientCommon/services';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { SpinnerService } from 'src/clientCommon/services/spinner.service';
import { RequestEvent } from 'src/common/event/requestEvent';
import { ResponseEvent } from 'src/common/event/responseEvent';
import { serverPaths } from 'src/common/helpers/pathHelpers';
import { User } from 'src/common/models/user/user';
import { LogUtils } from 'src/common/utils/logUtils';
import { permissionUtils } from 'src/common/utils/permissionUtils';
import { AcknowledgeDialogComponent } from './acknowledge-dialog/acknowledge-dialog.component';
import { PreviewDetailDialogComponent } from './preview-detail-dialog/preview-detail-dialog.component';

@Component({
  selector: 'app-notes-management',
  templateUrl: './notes-management.component.html',
  styleUrls: ['./notes-management.component.scss']
})
export class NotesManagementComponent extends BaseDirective implements OnInit {
  

  brands: any[] = []
  pagination: IPagination;
  selectedBrand: any = "";
  noteId: string = "";
  perPage = 25;
  notes: any[] = [];
  confirmationDialog:any = null;

  constructor(public dialog: MatDialog, 
              // public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              // @Inject(MAT_DIALOG_DATA) public data: any,
              private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              public serviceHelperService: ServiceHelperService,
              private snackBar: MatSnackBar) { 
                super(serviceHelperService, route);                
    this.pagination = {
      totalPages: 1,
      currentPage: 1,
      totalRecords: 0,
      perPage: 50,
      hasNext: false,
      hasPrev: false
    }
    
  }

  ngOnInit(): void {
    super.baseInit()
    .then(() => {
      this.init()
    });
  }

  init() {
    const pag = this.pagination;
    this.brands = configUtils.getBrands(this.uxComposite);
    this.brands = [{id: "", name: "All Brands"}, ...this.brands];
    this.getDocs(pag);
  }

  getDocs(pag) {
    this.spinnerService.spin();
    this.brands = configUtils.getBrands(this.uxComposite);
    this.brands = [{id: "", name: "All Brands"}, ...this.brands];    
    this.jsonService.json(serverPaths.manageCsrNotesView, { pagination: pag, searchID: this.noteId, brandId: this.selectedBrand })
    .then((responseEvent: ResponseEvent) => {
      this.notes = responseEvent.docs;
      let pagination = responseEvent.data;
      this.notes.map(note => {
        if(note.obj && note.obj.user && note.obj.user.brandId) {
          note.obj.brandName = this.brands.find(brand => brand.id == note.obj.user.brandId).name;
        }
        return {...note}
      });
      this.setPagination(pagination);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }
  

  search() {
    this.getDocs(this.pagination);
  }

  onSelectChange(event) {
    this.search();
  }

  onDeleteWithConfirm(id, index) {
    this.confirmationDialog = this.dialog.open(ConfirmDialogComponent);
    
    let requestEvent = new RequestEvent();
    requestEvent.param = {
      noteId: id,
      isAdminCsr: true
    }

    this.confirmationDialog.afterClosed()
    .subscribe(result => {
        return Promise.resolve(true)
        .then(() => {
          const user = this.serviceHelperService.authenticationService.getUser();
          if (permissionUtils.hasPermissionPath(user, "/" + serverPaths.noteDelete) || user.roles.includes(User.ROLES.admin)) {
            return;
          }
          return Promise.reject('This action is for management use only.');
        })
        .then(() => {
            return this.jsonService.json(serverPaths.noteDelete, requestEvent)
        .then((res) => {
            this.openAcknowledge();
            this.notes.splice(index, 1);
        })
        .catch(err => this.snackBar.open(err.message, 'Delete Failed'))
      })
    })
  }

  // same as above but does not open confirm dialog for testing
  onDelete(id, index) {
    this.confirmationDialog = this.dialog.open(ConfirmDialogComponent);
    
    let requestEvent = new RequestEvent();
    requestEvent.param = {
      noteId: id,
      isAdminCsr: true
    }

    // this.dialogRef.afterClosed()
    // .subscribe(result => {
        return Promise.resolve(true)
        .then(() => {
          const user = this.serviceHelperService.authenticationService.getUser();
          if (permissionUtils.hasPermissionPath(user, "/" + serverPaths.noteDelete) || user.roles.includes(User.ROLES.admin)) {
            return;
          }
          return Promise.reject('This action is for management use only.');
        })
        .then(() => {
            return this.jsonService.json(serverPaths.noteDelete, requestEvent)
        .then((res) => {
            this.openAcknowledge();
            this.notes.splice(index, 1);
        })
        .catch(err => this.snackBar.open(err.message, 'Delete Failed'))
      })
    // })
  }

  previewDetail(note) {
    const dialgref = this.dialog.open(PreviewDetailDialogComponent, {data: {detail: note}})
  }

  openAcknowledge() {
    const dialog = this.dialog.open(AcknowledgeDialogComponent);
    setTimeout(() => {
      dialog.close();
    }, 2000);
  }

  onNextPage() {
    if(!this.pagination.hasNext) return;
    this.pagination.currentPage++;
    // this.setPagination();
    this.getDocs(this.pagination);
  }

  onPrevPage() {
    if(!this.pagination.hasPrev) return;
    this.pagination.currentPage--;
    // this.setPagination();
    this.getDocs(this.pagination);
  }

  setPagination(pagination) {
    this.pagination.totalPages = Math.ceil(pagination.totalRecords / this.pagination.perPage) || 1;
    this.pagination.totalRecords = pagination.totalRecords;
    this.pagination.hasPrev = this.pagination.currentPage > 1; 
    this.pagination.hasNext = this.pagination.currentPage < this.pagination.totalPages;
  }

}

export interface IPagination {
  totalRecords?: number;
  totalPages?: number;
  currentPage?: number;
  hasNext?: boolean;
  hasPrev?: boolean;
  perPage?: number;
}
