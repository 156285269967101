<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
    text-transform: capitalize;
  }

  .label {
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    width: 100px;
  }

  .data {
    font-size: 12px;
    display: inline-block;
  }

  .row {
    margin-bottom: 8px;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mr-2 {
    margin-right: 12px;
  }
</style>

<h2 mat-dialog-title class="modal-title">{{ data.type }} Payment</h2>
<mat-dialog-content class="mat-typography content">
  <div>
    <div class="row">
      <div class="label">Amount</div>
      <div class="data">${{data.payment.price.amount}}</div>
    </div>
    <div class="row">
      <div class="label">DateTime</div>
      <div class="data">{{formatTimestamp(data.payment.paymentTimestamp) | date:timeFormat: custom.timezone}} {{custom.timezone}}</div>
    </div>
    <div class="row">
      <div class="label">Status</div>
      <div class="data">{{data.payment.status}}</div>
    </div>
    <div class="row">
      <div class="label">Sub Status</div>
      <div class="data">{{data.payment.subStatus}}</div>
    </div>
    <div class="row">
      <div class="label">Type</div>
      <div class="data">{{data.payment.type}}</div>
    </div>
    <div class="row">
      <div class="label">Sub Type</div>
      <div class="data">{{data.payment.subType}}</div>
    </div>
    <div class="row">
      <div class="label">Updater</div>
      <div class="data">{{data.payment.updaterId ? data.userInfo[data.payment.updaterId]?.getFullName() : systemUsername}}</div>
    </div>
    <mat-form-field appearance="outline" floatLabel="always" class="w-100" *ngIf="data.type === 'refund'">
      <mat-label>Amount</mat-label>
      <input matInput [(ngModel)]="amount" (change)="handleChange($event)" type="number" placeholder="Refund Amount" step=".01">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" *ngIf="data.type === 'refund'" [disabled]="!isAdjustable()" (click)="onRefund()" cdkFocusInitial class="mr-2">Refund</button>
  <button mat-stroked-button color="primary" *ngIf="data.type === 'void'" [disabled]="!isVoidable()" (click)="onVoid()" cdkFocusInitial>Void</button>
</mat-dialog-actions>