<div class="optout">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>
          Opt Out a user from showing up on our site
        </span>
        <div>
          <a class="mr-2" mat-stroked-button color="primary" [routerLink]="['/'+adminPaths.optOutViewV1]">Opt out v1</a>
          <button mat-icon-button color="primary" (click)="init()">
            <i class="material-icons">cached</i>
          </button>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>Email</span>
            <i class="material-icons" matTooltip="Input Email here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="Email"
              [(ngModel)]="input.email" />
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Brand</span>
            <i class="material-icons" matTooltip="Input Brand here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-select [(ngModel)]="input.brandId" placeholder="Brand">
              <mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search(input)" [disabled]="!isValid()">Search</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div>
        <table mat-table [dataSource]="optOuts" multiTemplateDataRows>
          <ng-container matColumnDef="expander">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="expandedElement = expandedElement === element ? null : element">
                <i class="material-icons">
                  {{expandedElement !== element ? 'add_box' : 'remove'}}
                </i>
              </button>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.createdTimestamp | toDateTime}} </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="id"> Id </th>
            <td mat-cell *matCellDef="let element" class="id"> {{element._id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="brandId">
            <th mat-header-cell *matHeaderCellDef class="id"> Brand Id </th>
            <td mat-cell *matCellDef="let element"> {{element.brandId}} </td>
          </ng-container>
  
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
          </ng-container>
  
          <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef> Provider </th>
            <td mat-cell *matCellDef="let element"> {{element.provider}} </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
          </ng-container>

          <ng-container matColumnDef="targetId">
            <th mat-header-cell *matHeaderCellDef> Target Id </th>
            <td mat-cell *matCellDef="let element"> {{element.targetId}} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
              <div class="status" [class.requested]="element.status === 'requested'">
                <div class="mark"></div>
                <span>{{ element.status }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
              <button color="primary" *ngIf="element.status === 'requested'" (click)="optOut(element)">Opt-Out</button>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <p><code>{{ getRaw(element) }}</code></p>
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [class.example-expanded-row]="expandedElement === element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
