<div class="timesheet">
  <div class="timesheet__container">
    <h2 class="timesheet-title" *ngIf="user">
      Time Sheet for {{user.firstName}} {{user.lastName}}
    </h2>
    <div class="timesheet-datepick">
      <mat-form-field appearance="outline">
        <mat-label>Enter start date of week (Sunday)</mat-label>
        <input matInput [matDatepickerFilter]="rangeFilter" [matDatepicker]="picker" [ngModel]="startDate" (ngModelChange)="handleChangeStartDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button mat-raised-button color="primary" class="timesheet-add" (click)="addTimesheet()">Add</button>
    </div>
    <div class="timesheet__card">
      <!-- <table mat-table [dataSource]="getTimesheetGroups()" multiTemplateDataRows> -->
      <table mat-table [dataSource]="timesheets" multiTemplateDataRows>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button color="primary" mat-icon-button (click)="onExpandRow(element)">
              <i *ngIf="!isRowExpanded(element)" class="material-icons">add_box</i>
              <i *ngIf="isRowExpanded(element)" class="material-icons">remove</i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Edit / Save </th>
          <td mat-cell *matCellDef="let element">
            <!-- Empty since it is summary row -->
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Delete </th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="warn" (click)="deleteTimesheetsByDate(element.date)">Delete</button>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let element">
            {{formatDate(element.date)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="timeIn">
          <th mat-header-cell *matHeaderCellDef> Time In </th>
          <td mat-cell *matCellDef="let element">
            {{getHoursMinuteFromTimestamp(element.timeIn)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="timeOut">
          <th mat-header-cell *matHeaderCellDef> Time Out </th>
          <td mat-cell *matCellDef="let element">
            {{getHoursMinuteFromTimestamp(element.timeOut)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="activeHours">
          <th mat-header-cell *matHeaderCellDef> Active Hours </th>
          <td mat-cell *matCellDef="let element">
            {{convertTimestampToHoursMinute(element.activeHours)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef> Notes </th>
          <td mat-cell *matCellDef="let element">
            {{getLastNote(element.notes)}}
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" colspan="8">
            <div class="timesheet-group-detail" [@detailExpand]="isRowExpanded(element) ? 'expanded' : 'collapsed'">
              <table mat-table [dataSource]="timesheetGroupedByDate[element.date]">
                <ng-container matColumnDef="status">
                  <td mat-cell *matCellDef="let childRecord">
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="edit">
                  <td mat-cell *matCellDef="let childRecord">
                    <!-- Empty since it is summary row -->
                    <button *ngIf="!childRecord.tempClient.editing" mat-stroked-button color="primary" (click)="toggleEdit(childRecord);">Edit</button>
                    <button *ngIf="childRecord.tempClient.editing" mat-raised-button color="primary" (click)="toggleEdit(childRecord);">Save</button>
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="delete">
                  <td mat-cell *matCellDef="let childRecord">
                    <button *ngIf="!childRecord.tempClient.editing" mat-raised-button color="warn" (click)="deleteTimesheet(childRecord)">Delete</button>
                    <button *ngIf="childRecord.tempClient.editing" mat-stroked-button color="warn" (click)="childRecord.tempClient.editing = false;">Cancel</button>
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="date">
                  <td mat-cell *matCellDef="let childRecord">
                    <span *ngIf="!childRecord.tempClient.editing">
                      {{formatDate(childRecord.date)}}
                    </span>
                    <mat-form-field *ngIf="childRecord.tempClient.editing" class="w-form-100">
                      <mat-label>Choose a date</mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="editData.date" [disabled]="!canEditNote()">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="timeIn">
                  <td mat-cell *matCellDef="let childRecord">
                    <span *ngIf="!childRecord.tempClient.editing">
                      {{getHoursMinuteFromTimestamp(childRecord.timeIn)}}
                    </span>
                    <mat-form-field *ngIf="childRecord.tempClient.editing" class="w-form-100">
                      <input matInput [(ngModel)]="editData.timeIn" [disabled]="!canEditNote()">
                    </mat-form-field>
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="timeOut">
                  <td mat-cell *matCellDef="let childRecord">
                    <span *ngIf="!childRecord.tempClient.editing">
                      {{getHoursMinuteFromTimestamp(childRecord.timeOut)}}
                    </span>
                    <mat-form-field *ngIf="childRecord.tempClient.editing" class="w-form-100">
                      <input matInput [(ngModel)]="editData.timeOut" [disabled]="!canEditNote()">
                    </mat-form-field>
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="activeHours">
                  <td mat-cell *matCellDef="let childRecord">
                    <span *ngIf="!childRecord.tempClient.editing">
                      {{convertTimestampToHoursMinute(childRecord.activeHours)}}
                    </span>
                    <span *ngIf="childRecord.tempClient.editing">
                      {{editData.activeHours}}
                    </span>
                  </td>
                </ng-container>
        
                <ng-container matColumnDef="notes">
                  <td mat-cell *matCellDef="let childRecord">
                    <ng-container *ngIf="!childRecord.tempClient.editing">
                      <div *ngFor="let note of childRecord.notes">{{note}}</div>
                    </ng-container>
                    <mat-form-field *ngIf="childRecord.tempClient.editing" class="w-form-100">
                      <input matInput [(ngModel)]="editData.note">
                    </mat-form-field>
                  </td>
                </ng-container>
        
                <tr mat-row *matRowDef="let childRecord; columns: timesheetColumns;" class="timesheet-group-row">
                </tr>
              </table>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="timesheetColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: timesheetColumns;" class="timesheet-group-row"
          [class.example-expanded-row]="isRowExpanded(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="timesheet-detail-row"></tr>
      </table>
    </div>
  </div>
</div>