import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {ResponseEvent} from '../../common/event/responseEvent';
import {User} from '../../common/models/user/user';
import {LogUtils} from '../../common/utils/logUtils';
import {ModelBase} from '../../common/models/modelBase';
import {Permission} from '../../common/models/permission';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {CrudService} from '../../clientCommon/services/crud.service';
import {emailUtils} from '../../common/utils/emailUtils';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import {adminPaths} from 'src/common/helpers/pathHelpers';

@Component({
  templateUrl: './userMgmt.component.html',
  styleUrls: ['./userMgmt.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class UserMgmtComponent implements OnInit {

  private readUrl = '/api/manage/admin/user/read';
  private createUrl = '/api/manage/admin/user/create';
  private updateUrl = '/api/manage/admin/user/update';

  users: User[] = [];
  permissions: Permission[] = [];
  roles = User.ROLES;
  status = ModelBase.STATUS;
  newUser: User = new User();

  adminPaths = adminPaths;
  userColumns = ['expander', 'email', 'firstName', 'lastName', 'password', 'role', 'permission', 'status'];
  editData = {};

  constructor(private jsonService: JsonService,
              private crudService: CrudService,
              private spinnerService: SpinnerService,
              private serviceHelperService: ServiceHelperService,
              public snackBar: MatSnackBar) {
    this.init();
  }

  ngOnInit() {
  }

  init(): Promise<any> {
    const promises = [];
    promises.push(this.findUsers());
    promises.push(this.findPermissions());

    this.setUserBrand(this.newUser.draft);

    this.spinnerService.spin();
    return Promise.all(promises).catch((e) => {
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  findUsers(): Promise<any> {
    return this.jsonService.json(this.readUrl, {}).then((responseEvent: ResponseEvent) => {
      this.users = responseEvent.getDocs();
      this.users.forEach((user) => {
        this.editData[user._id] = {
          email: false,
          firstName: false,
          lastName: false,
          password: false,
        }
        this.setUserBrand(user.draft); // To set brandId correctly. can be removed in the future. if all set correctly
      });
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  setUserBrand(user: User) {
    user.brandId = 'admin';
  }

  findPermissions(): Promise<any> {
    const selectQuery: any = {type: Permission.TYPE.site};
    return this.crudService.read(CrudService.URL.admin, collectionClassHelper.getCollectionName(Permission), selectQuery).then((results) => {
      this.permissions = results;
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  isValid(user: User) {
    if (!emailUtils.validateEmail(user.draft.email)) {
      return false;
    }

    if (user._id) {
      if (!user.draft.password) {
        delete user.draft.password;
      }
      return user.isDiffDraft();
    } else {
      return user.draft.isValidToCreate();
    }
  }

  create() {
    this.spinnerService.spin();
    this.jsonService.json(this.createUrl, this.newUser).then((responseEvent: ResponseEvent) => {
      this.newUser = new User();
      this.snackBar.open('User Creation', 'Success', {duration: 2500});
      return this.init();
    }).catch((e) => {
      this.snackBar.open('User Creation', 'Error', {duration: 2500});
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  update(user: User) {
    if (!this.isValid(user)) return;

    this.spinnerService.spin();
    this.serviceHelperService.emailService.checkEmailAddress(user.draft.email).then(() => {
      this.jsonService.json(this.updateUrl, user).then((responseEvent: ResponseEvent) => {
        this.snackBar.open('User Update', 'Success', {duration: 2500});
        return this.init();
      }).catch((e) => {
        this.snackBar.open('User Update', 'Error', {duration: 2500});
        LogUtils.error(e);
      })
    }).catch((e) => {
      this.snackBar.open('Invalid Email Address', 'Error', {duration: 2500});
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  updatePermission(user: User, event) {
    user.draft.permissionIds[0] = event.value;
    setTimeout(()=> {
      this.update(user);
    }, 500);
  }

  updateStatus(user: User, event) {
    user.draft.status = event.value;
    setTimeout(()=> {
      this.update(user);
    }, 500);
  }

  updateRole(user: User, event) {
    user.draft.roles = [event.value];
    setTimeout(()=> {
      this.update(user);
    }, 500);
  }
}
