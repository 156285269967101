<style>
  .signup {
    display: flex;
    height: 100vh;
    font-family: Roboto, sans-serif;
  }

  .signup-left {
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
  }

  .signup-form {
    padding-left: 160px;
    padding-right: 140px;
  }

  .signup-form-title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 60px;
  }

  .w-100 {
    width: 100%;
  }

  .form-group {
    margin-bottom: 16px;
  }

  .form-check-group {
    margin-bottom: 40px;
  }

  .signup-right {
    width: 50%;
    background-image: url('../../assets/bg_login.jpg');
    position: relative;
  }

  .signup-right::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(9, 58, 161, 0.75);
  }

  .signup-brand {
    position: absolute;
    top: 36px;
    left: 46px;
    color: #313aa1;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
  }
</style>

<div class="signup">
  <div class="signup-left">
    <div class="signup-brand">Logo Name</div>
    <div class="signup-form w-100">
      <div class="signup-form-title">CSR App Login</div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Username</mat-label>
          <input matInput type="text" class="form-control" name="username" (keydown)="keyDownEvent($event)"
            [(ngModel)]="model.username" required />
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-100" floatLabel="always">
          <mat-label>Password</mat-label>
          <input matInput type="password" name="password" (keydown)="keyDownEvent($event)" [(ngModel)]="model.password"
            required />
        </mat-form-field>
      </div>
      <div class="form-check-group">
        <mat-checkbox color="primary">Remember Me</mat-checkbox>
      </div>
      <div>
        <button mat-raised-button color="primary" class="w-100" [disabled]="!isValid()" (click)="login()">Login</button>
      </div>
    </div>
  </div>
  <div class="signup-right"></div>
</div>