<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .row {
    display: flex;
    align-items: baseline;
  }

  .label {
    width: 60px;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 900;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }
</style>

<h2 mat-dialog-title class="modal-title">Send Email</h2>
<mat-dialog-content class="mat-typography content">
  <div class="row">
    <div class="label">To:</div>
    <div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Email</mat-label>
        <input type="text" matInput>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label">CC:</div>
    <div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>CC</mat-label>
        <input type="text" matInput>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label">Subject:</div>
    <div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Subject</mat-label>
        <input type="text" matInput>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label">Response Template:</div>
    <div>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Email</mat-label>
        <mat-select>
          <mat-option value="">Select Response</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div>
    <mat-form-field class="w-100" appearance="outline" floatLabel="always">
      <mat-label>Compose Email</mat-label>
      <textarea matInput rows="10" placeholder="Compose Email"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Submit</button>
</mat-dialog-actions>