<div class="unsubscribe">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>
          Unsubscribe
        </span>
        <div>
          <button mat-icon-button color="primary" (click)="init()">
            <i class="material-icons">cached</i>
          </button>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>Search Email List</span>
            <i class="material-icons" matTooltip="Input Customer Email here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" placeholder="Email" [(ngModel)]="input.email" />
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Search SMS List</span>
            <i class="material-icons" matTooltip="Input Customer Phone number here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" maxlength="14" (input)="formatInput($event)" placeholder="Phone" [(ngModel)]="input.phone" />
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search(input)">Search</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div>
        <table mat-table [dataSource]="unsubscribeableItems" multiTemplateDataRows>
          <ng-container matColumnDef="expander">
            <th mat-header-cell *matHeaderCellDef>
              <button mat-icon-button color="primary" (click)="toggleAll()">
                <i class="material-icons">{{!isAllExpanded(element) ? 'add_box' : 'remove'}}</i>
              </button>
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary"
                (click)="toggleExpand(element)">
                <i class="material-icons">
                  {{!isExpanded(element) ? 'add_box' : 'remove'}}
                </i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
              <button mat-raised-button color="warn" (click)="unsubscribe(unsubscribeableItems)">Unsubscribe All</button>
            </th>
            <td mat-cell *matCellDef="let element">
              <button mat-raised-button color="warn" (click)="unsubscribe([element])">Unsubscribe</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let element">
              {{element.id}}<div *ngIf="notFound" class="not-found">Not Found</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="dateSubscribed">
            <th mat-header-cell *matHeaderCellDef> Date Subscribed </th>
            <td mat-cell *matCellDef="let element"> {{getDateString(element.subscribedTimestamp)}} </td>
          </ng-container>

          <ng-container matColumnDef="dateUnsubscribed">
            <th mat-header-cell *matHeaderCellDef> Date Unsubscribed </th>
            <td mat-cell *matCellDef="let element"> {{getDateString(element.unsubscribedTimestamp)}} </td>
          </ng-container>

          <ng-container matColumnDef="brand">
            <th mat-header-cell *matHeaderCellDef> Brand </th>
            <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
          </ng-container>

          <ng-container matColumnDef="uri">
            <th mat-header-cell *matHeaderCellDef> URI </th>
            <td mat-cell *matCellDef="let element"> {{element.uri}} </td>
          </ng-container>

          <ng-container matColumnDef="disclosure">
            <th mat-header-cell *matHeaderCellDef> Disclosure </th>
            <td mat-cell *matCellDef="let element"> {{element.disclosure}} </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail"
                [@detailExpand]="isExpanded(element) ? 'expanded' : 'collapsed'">
                <p *ngIf="element.detail"><code>{{getRaw(element.detail)}}</code></p>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
            [class.example-expanded-row]="isExpanded(element)">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>