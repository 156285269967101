<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .row,
  .full-width {
    padding-bottom: 15px;
  }

  .col {
    width: 45%;
  }

  label {
    font-size: 12px;
  }

  input {
    margin-top: 5px;
    background: #F8F9FE;
    padding: 10px 15px;
    width: 90%;
    border: 1px solid #CBD6E2;
    border-radius: 2px;
  }

  #subject,
  #body {
    width: 95%;
  }

  .button-wrapper {
    width: 100%;
    text-align: center;
  }

  #submit {
    background: #673ab7;
    color: #fff;
    padding: 10px 25px;
    border: none;
    border-radius: 2px;
  }

  .d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close {
    background-color: #673ab7;
    color: #fff;
  }

  h2 {
    margin: 0;
  }
</style>
<div class="modal">
  <div class="d-flex modal-title">
    <h2 mat-dialog-title>UXHISTORY FILTER</h2>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div>
    <form [formGroup]="nameForm" autocomplete="off">

      <div class="row">
        <div class="col">
          <label for="">Start Date:</label><br>
          <input type="date" id="startdate" name="startdate" formControlName="startdate">
        </div>
        <div class="col">
          <label for="">End Date:</label><br>
          <input type="date" id="endate" name="enddate" formControlName="enddate">
        </div>
      </div>

      <div class="button-wrapper">
        <button id="submit" type="button" (click)="handleSave()">Submit</button>
      </div>
    </form>

  </div>
</div>