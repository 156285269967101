<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }
</style>

<h2 mat-dialog-title class="modal-title">Add a Note</h2>
<mat-dialog-content class="mat-typography content">
  <mat-form-field class="w-100" appearance="outline" floatLabel="always">
    <mat-label>Enter customer note here</mat-label>
    <textarea matInput rows="10" [(ngModel)]="note"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [mat-dialog-close]="note" cdkFocusInitial>Save</button>
</mat-dialog-actions>