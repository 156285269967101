<div class="management">
    <mat-card class="add-edit">
      <mat-card-header>
        <mat-card-title>
          <span>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-select [ngModel]="selectedValue" (ngModelChange)="handleChangeData($event)">
                <mat-option *ngFor="let item of dataModel" [value]="item.value">
                  {{ item.key }}
                </mat-option> 
              </mat-select>
            </mat-form-field>
          </span>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="tooltip-label">
              <span>History Date</span>
              <i class="material-icons" matTooltip="Timestamp search" matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <input matInput (keydown)="keyDownEvent($event)" placeholder="from Timestamp" [(ngModel)]="fromDate">               
            </mat-form-field>
          </div>
          <div class="col">
            <div class="tooltip-label">&nbsp;</div>
            <mat-form-field appearance="outline">
              <input matInput (keydown)="keyDownEvent($event)" placeholder="to Timestamp" [(ngModel)]="toDate">
            </mat-form-field>
          </div>
          <div class="col">
            <div class="tooltip-label">
              <span>Type</span>
              <i class="material-icons" matTooltip="Schedule Type" matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-select [ngModel]="selectedType" (ngModelChange)="handleChangeType($event)">
                <mat-option *ngFor="let item of typeModel" [value]="item.value">
                  {{ item.key }}
                </mat-option> 
              </mat-select>
            </mat-form-field>
            <!-- <div style="padding-top: 15px;">
              <label for="uxcompServerOnly">UXC </label>
              <input class="tristate-checkbox"
                     type="checkbox" 
                     id="isUXC"
                     [indeterminate]="searchOptions.filters.isUXC === false" 
                     [ngModel]="searchOptions.filters.isUXC" 
                     (ngModelChange)="checkModelChange(searchOptions.filters.isUXC, 'isUXC')"
                     >  

              &nbsp;<span>UXL </span>
              <input class="tristate-checkbox"
                     type="checkbox" 
                     id="isUXL"
                     [indeterminate]="searchOptions.filters.isUXL === false" 
                     [ngModel]="searchOptions.filters.isUXL" 
                     (ngModelChange)="checkModelChange(searchOptions.filters.isUXL, 'isUXL')"
                     >   
              &nbsp;<span>UXCC </span>  
              <input class="tristate-checkbox"
                    type="checkbox" 
                    id="isUXCC"
                    [indeterminate]="searchOptions.filters.isUXCC === false" 
                    [ngModel]="searchOptions.filters.isUXCC" 
                    (ngModelChange)="checkModelChange(searchOptions.filters.isUXCC, 'isUXCC')"
                    >
              &nbsp;<span>UXComp </span> 
              <input class="tristate-checkbox"
                     type="checkbox" 
                     id="isUXComp"
                     [indeterminate]="searchOptions.filters.isUXComp === false" 
                     [ngModel]="searchOptions.filters.isUXComp" 
                     (ngModelChange)="checkModelChange(searchOptions.filters.isUXComp, 'isUXComp')"
                     > 
            </div> -->
          </div>        
        </div>              
        <div class="row">
          <div class="col">
            <div class="tooltip-label">
              <span>Search World</span>
              <i class="material-icons" matTooltip="Input name or id here." matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <input matInput (keydown)="keyDownEvent($event)" placeholder="Search word" [(ngModel)]="searchOptions.filters.value">
            </mat-form-field>
          </div>          
          <div class="col action">
            <button mat-raised-button color="primary" (click)="search()">Search</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="searched">
    <div *ngIf="isLoadMore"><button mat-raised-button color="secondary" (click)="loadMore()">load more</button></div>
    <ng-container *ngIf="commerceSchedules.length">
      <div>
        <scheduleHistory [commerceSchedules]="commerceSchedules" type='uxConfigs'></scheduleHistory>
      </div>
    </ng-container>    
  </div>
  