<div class="container">
    <table class="table">
        <thead>
            <th class="w-250">
                Actions <br />
                <div class="action-subtitles">
                    <span class="sm-title">Expand</span>
                    <span class="sm-title">Close</span>
                    <span class="sm-title">Delete</span>
                    <span class="sm-title">Search</span>
                    <span class="sm-title">Attachement</span>
                </div>
            </th>
            <th>Owner</th>
            <th>Customer ID</th>
            <th>Name</th>
            <th>To</th>
            <th>From</th>
            <th>Subject</th>
            <th>Date</th>
        </thead>
        <tbody *ngIf="tableData && tableData.length > 0">
            <ng-container *ngFor="let record of tableData; let i = index;">
                <tr *ngIf="!record?.obj?.raw?.meta?.meta['x-sieve-redirected-from']">
                    <td class="td mw-160 w-250">
                        <div class="d-flex justify-content-start">
                            <span class="cursor-pointer"
                                *ngIf="!(expandable.isExpanded && expandable.id==record.obj?._id)"
                                (click)="expandable.isExpanded=true;expandable.id=record.obj?._id;getEmailOnDemand(record.obj?.raw?._id)">
                                <img src="assets/icons/expand.svg" alt="">
                            </span>
                            <span class="cursor-pointer" *ngIf="expandable.isExpanded && expandable.id==record.obj?._id"
                                (click)="expandable.isExpanded=false;expandable.id=''">
                                <img src="assets/icons/collapse.svg" alt="">
                            </span>
                            <span class="cursor-pointer"
                                *ngIf="record.obj?.status == 'active'  || record.obj?.status == 'archived'"
                                (click)="_onUpdateStatus(record.obj?._id, 'closed')">
                                <img src="assets/icons/close.svg" alt="">
                            </span>
                            <span class="cursor-pointer" *ngIf="record.obj?.status == 'closed'"
                                (click)="_onUpdateStatus(record.obj?._id, 'reopen')">
                                <img class="action-icon" src="assets/icons/reopen.svg" alt=""><br />
                                <span class="light-sm">Reopen</span>
                            </span>
                            <span class="cursor-pointer" *ngIf="record.obj?.status == 'archived'"
                                (click)="_onUpdateStatus(record.obj?._id, 'active')">
                                <img class="action-icon" src="assets/icons/undo.svg" alt=""><br />
                                <span class="light-sm">Undo</span>
                            </span>
                            <span *ngIf="record.obj?.status == 'active' || record.obj?.status ==  'closed'"
                                class="cursor-pointer" (click)="_onUpdateStatus(record.obj?._id, 'archived')">
                                <img src="assets/icons/remove.svg" alt="">
                            </span>
                            <span class="cursor-pointer" (click)="viewDetail(record?.obj?.customer?.email)">
                                <img src="assets/icons/search.svg" alt="">
                            </span>
                            <span *ngIf="record.obj.raw?.meta?.hasAttachment" class="cursor-pointer"
                                (click)="viewDetail(record?.obj?.customer?.email)">
                                <img src="assets/icons/vector.svg" alt="" title="Attachment">
                            </span>
                        </div>
                    </td>



                    <!-- CSR Owner -->
                    <td [ngClass]="{'active': record.obj.csr && csr._id == record.obj.csr._id}"
                        class="td mw-190 text-center">
                        <ng-container
                            *ngIf="!(selectedRecord.idOwner === record.obj._id && selectedRecord.isEditOwner)">
                            <span *ngIf="record.obj.csr">
                                {{record.obj?.csr?.firstName + " " + record.obj?.csr?.lastName}} ddd
                            </span>
                            <span *ngIf="!record.obj.csr">
                                No owner found <br />
                                <span (click)="changeAssignee(record)" class="light-xsm">Assign Owner</span>
                            </span>
                            <span *ngIf="record.obj?.csr" (click)="changeAssignee(record)">sas
                                <img class="ml-2" src="assets/icons/edit.svg" alt="">
                            </span>
                        </ng-container>
                        <span class="text-left"
                            *ngIf="(selectedRecord.idOwner === record.obj._id && selectedRecord.isEditOwner)">
                            <form class="example-form">
                                <input type="text" placeholder="Select CSR Assignee" [(ngModel)]="keywords"
                                    class="form-control purple-bg" matInput (input)="searchSubject.next($event)"
                                    [matAutocomplete]="auto">
                                <span class="cursor-pointer" (click)="_onReassign()">Save</span>
                                <mat-autocomplete (optionSelected)="selectOption($event)" [displayWith]="displayFn"
                                    #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of searchedCsrs" [value]="option">
                                        {{option.obj?.firstName + " " + option.obj?.lastName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </form>
                        </span>
                    </td>













                    <td class="td">
                        <ng-container *ngIf="!(selectedRecord.idCdp === record.obj._id && selectedRecord.isEditCdp)">
                            <span class="cdp-link" *ngIf="record.obj?.customer"
                                (click)="_onAssignedTo(record.obj?.raw?._id,record.obj?._id, record.obj?.customer?._id, record.obj?.from, record.obj?.to, record.obj?.subject, emailBodyData)">{{record.obj?.customer?._id}}</span>
                            <span *ngIf="!record.obj?.customer">N/A</span><br>
                            <span class="pull-right" (click)="changeCustomerAssignee(record)">Edit</span>
                        </ng-container>
                        <span class="d-flex" *ngIf="selectedRecord.idCdp == record.obj._id && selectedRecord.isEditCdp">
                            <ng-container *ngIf="!selectedRecord.isHintClicked">
                                <div class="d-flex flex-column">
                                    <input placeholder="Enter customer details" class="custom-input-edit" type="text"
                                        [(ngModel)]="cdpLink">
                                    <span class="hint" (click)="toggleHintClicked(); getNonMemberCDP(record?.obj?.raw?.meta?.fromName);">Can't find customer?</span>
                                </div>
                                <span class="ml-2 cursor-pointer"
                                    (click)="_onCdpSave(record.obj?.raw?._id,record.obj?._id)">save</span><br />
                            </ng-container>
                            <ng-container *ngIf="selectedRecord.isHintClicked">
                                <div class="d-flex flex-column">
                                    <span class="cdp-link" (click)="toggleHintClicked() ;redirectToCustomer(nonMemberCDPData)">{{ nonMemberCDPData ||"DummyBrandName.com"}}</span>
                                </div>
                            </ng-container>
                        </span>
                    </td>

                    <td class="td"> {{record?.obj?.customer ? record?.obj?.customer?.firstName + " " +
                        record?.obj?.customer?.lastName : 'N/A'}}</td>
                    <td class="td">{{record?.obj?.raw?.meta?.fromName}}</td>
                    <td class="td"> {{record?.obj?.customer?.email || 'N/A'}}</td>
                    <td class="td">{{record?.obj?.raw?.meta?.subject}}</td>
                    <td class="td">{{record?.obj?.raw?.createdTimestamp| date : 'medium'}}</td>
                </tr>
                <tr class="row-expanded" *ngIf="expandable.isExpanded && expandable.id==record.obj?._id">
                    <ng-container *ngIf="record.obj.raw?.meta?.hasAttachment else showObject">
                        <td colspan="8" style="max-width: 100%;">
                            <div style="width: 100%;" [(innerHTML)]="emailBodyData"></div>
                        </td>
                    </ng-container>
                    <ng-template #showObject>
                        <td colspan="8" style="max-width: 100%;">
                            <div [(innerHTML)]="emailBodyData" style="width: 100%;"></div>
                        </td>
                    </ng-template>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <!-- <div class="d-flex" *ngIf="pagination.totalRecords > 0">
        <div class="ml-auto d-flex">
            <span class="m-auto m-10">Results per page</span>

            <mat-form-field class="m-auto" appearance="outline" floatLabel="always" style="width: 85px; margin-bottom: -18px;">
                <mat-select (valueChange)="onSelectChange($event)" [(ngModel)]="pagination.perPage">
                    <mat-option *ngFor="let page of [5, 25, 50, 75, 100, 125]" [value]="page">{{ page }}</mat-option>
                </mat-select>
            </mat-form-field>

            <span class="m-auto m-10">
                {{pagination.currentPage}} of {{pagination.totalPages}} pages
            </span>
            <span class="mt-auto mb-auto m-10">
                <mat-icon [ngClass]="{'disabled': !pagination.hasPrev, 'dark-gray': pagination.hasPrev}" aria-hidden="false" (click)="_onPrevPage()" aria-label="Example home icon">keyboard_arrow_left</mat-icon>
                <mat-icon [ngClass]="{'disabled': !pagination.hasNext, 'dark-gray': pagination.hasNext}" aria-hidden="false" (click)="_onNextPage()" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
            </span>
        </div>
    </div>
</div> -->