import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JsonService } from '../../clientCommon/services/json.service';
import { User } from '../../common/models/user/user';
import { ResponseEvent } from '../../common/event/responseEvent';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelBase } from '../../common/models/modelBase';
import { CommerceOfferRuleKeyDetail, CommerceOrder } from '../../common/models/commerce/commerceOrder';
import { CommerceSchedule } from '../../common/models/commerce/commerceSchedule';
import { commerceUrls } from '../../common/models/commerce/commerceEvent';
import { SpinnerService } from '../../clientCommon/services/spinner.service';
import { ArrayUtils } from '../../common/utils/arrayUtils';
import {CommercePrice, SequenceOptions} from '../../common/models/commerce/commercePrice';
import { CommerceTransaction } from '../../common/models/commerce/commerceTransaction';
import { CommercePayment } from '../../common/models/commerce/commercePayment';
import { adminPaths, serverPaths } from '../../common/helpers/pathHelpers';
import { CommerceToken } from '../../common/models/commerce/commerceToken';
import { isNumber } from 'util';
import { collectionClassHelper } from '../../common/decorators/database/collectionClass';
import { UxComposite } from '../../common/models/ux/uxComposite';
import { UxHelper } from '../../clientCommon/helper/uxHelper';
import { CommerceService } from '../../clientCommon/services/commerce.service';
import { CreditCardInputHelper } from '../../clientCommon/helper/creditCardInputHelper';
import { Note } from '../../common/models/note';
import { AdminAuthGuard, CsrAuthGuard } from '../guards';
import { objectUtils } from '../../common/utils/objectUtils';
import { custom } from '../custom/custom.class';
import { LogUtils } from '../../common/utils/logUtils';
import { MatDialog } from '@angular/material/dialog';
import { configUtils } from '../utils/ConfigUtils';
import {
  AddCallDialog,
  AddCommerceOfferDialog,
  AddEmailDialog,
  AddNoteDialog,
  BillingInfoDialog,
  ChargebackDialog,
  ConfirmationDialog,
  RefundPaymentDialog,
  ViewNoteDialog
} from './customer/dialog.component';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { EmailService } from 'src/clientCommon/services/email.service';
import { phoneUtils } from '../../common/utils/phoneUtils';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { CommerceOffer, CommerceOfferDetail } from '../../common/models/commerce/commerceOffer';
import { commerceUtils } from '../../common/utils/commerceUtils';
import { CommerceContent } from '../../common/models/commerce/commerceContent';
import { peopleSearchProductKeys } from '../../common/custom/peopleSearch/peopleSearchProductKeys';
import { idProtectionProductKeys } from "../../common/custom/idProtection/idProtectionKeys";
import * as moment from "moment";
import { lastValueFrom } from 'rxjs';

@Component({
    templateUrl: 'payment.component.html',
    styleUrls: ['payment.component.scss'],
})
export class PaymentComponent extends BaseDirective implements OnInit {
    paymentTypes = CommercePayment.TYPE;
    uxHelper: UxHelper = new UxHelper();
    initDone = false;
    periodUnits;
    customerUxComposite: UxComposite;
    adminUxComposite: UxComposite;
    commerceOrders: CommerceOrder[] = [];
    commerceSchedules: CommerceSchedule[] = [];
    commerceToken: CommerceToken;
    commercePayments: CommercePayment[] = [];
    commercePayment: CommercePayment;
    commercePaymentId: string;
    adminFlag = false;
    hash;
    baseCommerceOrder: any = {};
    savedReports: CommerceContent[] = [];
    userId: string;
    user: User;

    brands = [];
    agentPhoneNumber = '';
    agentAddress = '';
    companyName = '';

    creditCardInputHelper = new CreditCardInputHelper();
    searchInfo = [];
    offerRulesKey = "comp.csr.downsell.offer.rules";
    commerceOfferOptions: Record<string, {
        id: string,
        downsell: boolean
    }> = {};
    commerceOffers: CommerceOffer[] = [];
    customerUserInfo: { [userId: string]: User } = {};

    cancellableIndex = -1;
    cancelledIndex = -1;
    detailedSchedules = false;

    scheduleColumns = ['type', 'id', 'updater', 'amount', 'scheduleDate', 'action'];

    custom = custom;
    timeFormat = 'yyyy-MM-dd hh:mm:ss aa';
    minDate = new Date();
    maxDate = new Date(new Date().getTime() + 31536000000); // 1 Year from now

    alertDuration = 5000;
    editSummary = false;

    constructor(private jsonService: JsonService,
        private spinnerService: SpinnerService,
        private adminAuthGuard: AdminAuthGuard,
        private csrAuthGuard: CsrAuthGuard,
        private commerceService: CommerceService,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private router: Router,
        private emailService: EmailService,
        private bottomSheet: MatBottomSheet,
        serviceHelperService: ServiceHelperService,
        activatedRoute: ActivatedRoute,
      ) 
    {
        super(serviceHelperService, activatedRoute);
        this.uxHelper.prefix = ``;
        this.periodUnits = ArrayUtils.toArray(CommercePrice.UNIT);
    }

    ngOnInit() {
        return super.baseInit().then(() => {
          this.adminUxComposite = this.uxComposite;
        }).then(() => {
          return this.route.params.subscribe((params) => {
            this.commercePaymentId = params.commercePaymentId;
          });
        }).then(() => {
          return this.init(this.commercePaymentId);
        });
    }    

    init(commercePaymentId) {

        this.adminFlag = this.adminAuthGuard.hasAdminRole();
        this.spinnerService.spin();
    
  
        this.hash = this.commerceService.createHash();
        return this.jsonService.json(commerceUrls.csrFindPayment + '/' + commercePaymentId, {}).then((responseEvent: ResponseEvent) => {
          this.commerceOrders = [];
          this.commerceSchedules = [];

          this.customerUxComposite = null;
          this.savedReports = [];
    
          const docs = responseEvent.getDocs();
          LogUtils.info(docs);
          docs.forEach(async (obj: ModelBase<any>) => {
            if (obj instanceof User) {
              this.user = obj;
            } else if (obj instanceof CommerceOrder) {
              if (obj.commerceOffer?.nonMemberOnly === true) {
                this.baseCommerceOrder = obj;
              }
              if (!obj.isTrivialOrder()) {
                obj.toggle('show');
              }
              if (obj.commerce3ds) {
                delete obj.commerce3ds.draft;
                delete obj.commerce3ds.appliedCommercePayments;
              }
              this.addProcessCounts(obj);
              this.commerceOrders.push(obj);
              obj.getCommercePayments().forEach((payment) => {
                this.commercePayments.push(payment);
              })
            } else if (obj instanceof CommerceSchedule) {
              this.commerceSchedules.push(obj);
            } else if (obj instanceof UxComposite) {
              await this.processUxComposite(obj);
            } else if (obj instanceof CommerceToken) {
              this.commerceToken = obj;
            } else if (obj instanceof CommerceContent) {
              this.savedReports.push(obj);
            } else if (obj instanceof CommercePayment) {
                this.commercePayment = obj;
            } else {
              LogUtils.error('Wrong doc', obj, obj instanceof UxComposite);
            }
          });
    
          if (this.customerUxComposite && this.commerceOrders && this.commerceOrders.length) {
            this.brands = configUtils.getBrands(this.customerUxComposite);
            this.agentAddress = this.customerUxComposite.getUxcomp('comp.brand.address.twoline');
            this.agentPhoneNumber = this.customerUxComposite.getUxcomp('comp.brand.customer.phone');
            this.companyName = this.customerUxComposite.getUxcomp('comp.brand.company.name');
    
            this.commerceOrders.forEach((commerceOrder) => {
              this.processCommerceOrder(this.customerUxComposite, commerceOrder);
            });
    
            this.creditCardInputHelper.setDummyAddress(this.customerUxComposite);
          }
    
          this.cancellableIndex = this.commerceOrders.findIndex(commerceOrder => commerceOrder.isCancellable());
          this.cancelledIndex = this.commerceOrders.findIndex(commerceOrder => commerceOrder.isCancelled());
    
          if (responseEvent.data.searchInfo) {
            this.searchInfo = responseEvent.data.searchInfo;
          }
    
          this.commerceOrders.sort((a, b) => {
            return b.createdTimestamp - a.createdTimestamp;
          });
    
          if (responseEvent.data.userInfo) {
            Object.keys(responseEvent.data.userInfo).forEach((key) => {
              this.customerUserInfo[key] = new User(responseEvent.data.userInfo[key]);
            });
    
          }
        }).then(() => {
          const commerceOfferIds = this.customerUxComposite.getUxcomp("comp.csr.downsell.offers.rules.ids");
          if (!commerceOfferIds?.length) {
            return Promise.reject('CommerceOffers are not available.');
          }
          return this.serviceHelperService.commerceService.findOffers(commerceOfferIds).then((commerceOffers) => {
            this.commerceOffers = commerceOffers;
          }).catch((e) => {
            LogUtils.error("AddonComponent:Finding offer error", e);
          });
          
        }).catch((e) => {
          LogUtils.error(e);
        }).then(() => {
          this.initDone = true;
          this.spinnerService.unspin();
        });
    }

    addProcessCounts(commerceOrder: CommerceOrder) {
        if (commerceOrder && commerceOrder.tempClientSecured && commerceOrder.tempClientSecured.processes) {
          commerceOrder.tempClientSecured.processCounts = [];
          Object.keys(commerceOrder.tempClientSecured.processes).forEach((productKey) => {
            const process = commerceOrder.tempClientSecured.processes[productKey];
            const processInfo = {
              productKey: productKey,
              quantity: process.quantity,
              process: process.process,
            };
            commerceOrder.tempClientSecured.processCounts.push(processInfo);
            LogUtils.info(commerceOrder._id, commerceOrder.commerceOffer.name, commerceOrder.commerceOffer.description, processInfo);
          });
        }
    }

    processUxComposite(uxComposite: UxComposite) {
        this.customerUxComposite = uxComposite;
        //this.trackingDisplay = uxComposite.get('comp.admin.csr.customer.tracking.display');
        const commerceOfferIds = this.customerUxComposite.getUxcomp("comp.csr.downsell.offers.rules.ids");
        return Promise.resolve(true).then(() => {
          if (commerceOfferIds?.length) {
            return this.serviceHelperService.commerceService.findOffers(commerceOfferIds).then((commerceOffers) => {
              this.commerceOffers = commerceOffers;
            }).catch((e) => {
              LogUtils.error("AddonComponent:Finding offer error", e);
            });
          }
          return;
        })
    }   

    processCommerceOrder(uxComposite: UxComposite, commerceOrder: CommerceOrder) {
        if (commerceOrder) {
          if (commerceOrder.commerceOfferRuleKey) {
            const rulesExplained = [];
            Object.keys(commerceOrder.commerceOfferRuleKey).forEach((key) => {
              const rule = commerceOrder.commerceOfferRuleKey[key];
              let value = '';
              if (key.startsWith('email')) {
                const emailKey = 'comp.email.campaign.' + rule;
                Object.keys(uxComposite.ids).forEach((id) => {
                  if (id.startsWith(emailKey + '.')) {
                    if (value) {
                      value += '|';
                    }
                    value += uxComposite.ids[id];
                  }
                });
                value = emailKey + ' ' + value + ' ';
              } else {
                value = uxComposite.get(rule);
              }
    
              rulesExplained.push({
                type: key,
                key: rule,
                rule: value,
              });
            });
            commerceOrder.tempClient.commerceOfferRuleExplained = rulesExplained;
          }
    
          const commercePayments = commerceOrder.getCommercePayments();
          if (commercePayments && commercePayments.length && this.commerceSchedules && this.commerceSchedules.length) {
            commercePayments.forEach((commercePayment) => {
              this.commerceSchedules.forEach((commerceSchedule) => {
                if (commerceSchedule.scheduleData.failedCommercePaymentId === commercePayment._id ||
                  commerceSchedule.scheduleData.parentCommercePaymentId === commercePayment._id) {
                  let reason = 'parent';
                  if (commerceSchedule.scheduleData.failedCommercePaymentId === commercePayment._id) {
                    reason = 'failed';
                  }
                  commercePayment.tempClient.delayRefund = {
                    reason: reason,
                    commerceScheduleId: commerceSchedule._id,
                    amount: Math.abs(commerceSchedule.scheduleData.amount),
                    dueTimestamp: commerceSchedule.dueTimestamp,
                  };
                }
              });
            });
          }
        }
    }     
    filterCommerceSchedules() {
        if (this.detailedSchedules) {
          return this.commerceSchedules;
        } else {
          return this.commerceSchedules.filter(schedule => {
            const order = this.commerceOrders.find(order => order._id === schedule.commerceOrderId);
            return !order.isTrivialOrder();
          })
        }
    }   
    reflectCommerceScheduleDate(date: Date, commerceSchedule: CommerceSchedule) {
        commerceSchedule.draft.dueTimestamp = date.getTime();
    }    
    isValidUpdateCommerceSchedule(commerceSchedule: CommerceSchedule) {
        const dueDate = new Date(commerceSchedule.dueTimestamp);
        const draftDueDate = new Date(commerceSchedule.draft.dueTimestamp);
    
        if (!isNumber(commerceSchedule.draft.scheduleData.amount) || !isNumber(commerceSchedule.draft.dueTimestamp)) {
          return false;
        }
    
        return (commerceSchedule.scheduleData.amount !== commerceSchedule.draft.scheduleData.amount) ||
          (!(
            (dueDate.getFullYear() === draftDueDate.getFullYear()) &&
            (dueDate.getMonth() === draftDueDate.getMonth()) &&
            (dueDate.getDate() === draftDueDate.getDate())
          ));
    }  
    // isExpanded(ids: string[], obj) {
    //     return ids.includes(obj._id)
    // }   
    updateCommerceOrder(commerceOrder: CommerceOrder) {
        this.updateCommerce(commerceOrder);
    } 
    updateCommerce(commerceObj: ModelBase<any>) {
        const obj = commerceObj.getSaveSet();
        obj._id = commerceObj._id;
        const input: any = {};
        if (commerceObj instanceof CommerceSchedule) {
          input.commerceSchedules = [obj];
        } else if (commerceObj instanceof CommerceOrder) {
          input.commerceOrders = [obj];
        } else if (commerceObj instanceof CommerceToken) {
          input.commerceTokens = [obj];
        } else {
          LogUtils.error('wrong obj', commerceObj);
          return;
        }
    
        this.spinnerService.spin();
        this.jsonService.json(commerceUrls.csrUpdate, input).then((responseEvent: ResponseEvent) => {
          this.init(this.userId);
        }).catch((e) => {
          LogUtils.error(e);
        }).then(() => {
        });
    }      
    

    async updateUser(user: User, confirmMsg?: string) {
      if (confirmMsg) {
        const confirmed = await this.openConfirmationDialog(confirmMsg);
        if (!confirmed) {
          return;
        }
      }
      if (user.draft.phone) {
        user.draft.phone = user.draft.phone.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "");
      }
      const obj = user.getSaveSet();
      obj._id = user._id;
      const input = { users: [obj] };
      this.emailService.checkEmailAddress(user.draft.email).then((valid) => {
        if (valid) {
          this.spinnerService.spin();
          this.jsonService.json(serverPaths.manageCsrUpdateCustomer, input).then((responseEvent: ResponseEvent) => {
            this.init(this.commercePaymentId);
          }).then(() => {
            this.editSummary = false;
          }).catch((e) => {
            LogUtils.error(e);
            this.spinnerService.unspin();
            if (e?.responseCode === 409) {
              this.snackBar.open(`This email address has been already registered with ${this.customerUxComposite.brandName}. Please enter a different email address`, 'Error', { duration: this.alertDuration });
            }
          });
        } else {
          this.snackBar.open('Invalid Email', 'Error', { duration: this.alertDuration });
        }
      }).catch(() => {
        this.snackBar.open('Invalid Email', 'Error', { duration: this.alertDuration });
      });
    }

    private openConfirmationDialog(message: string): Promise<boolean> {
      let dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: false
      });
  
      dialogRef.componentInstance.confirmMessage = message;
  
      return lastValueFrom(dialogRef.afterClosed());
    }    

}