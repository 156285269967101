
import {Injectable} from "@angular/core";
import {CommerceContent} from "../../../common/models/commerce/commerceContent";
import {CommerceService} from "../../services/commerce.service";
import {AuthenticationService, CrudService, UxcService} from "../../services";
import {ProgressorService} from "../../services/ProgressorService";
import {StorageService} from "../../services/storage.service";
import {LogUtils} from "../../../common/utils/logUtils";
import {serverPaths} from "../../../common/helpers/pathHelpers";
import {ResponseEvent} from "../../../common/event/responseEvent";
import {PeopleSearchContentHelper, peopleSearchContentHelper} from "../../../common/custom/peopleSearch/peopleSearchHelper";
import {SearchedObject} from "../../../common/custom/models/peopleSearch/searchedObject";
import {CommerceOrder} from "../../../common/models/commerce/commerceOrder";
import {CommerceOffer} from "../../../common/models/commerce/commerceOffer";
import {customServerPaths} from "../../../common/custom/customPathHelpers";
import {collectionClassHelper} from "../../../common/decorators/database/collectionClass";
import {SearchedPerson} from "../../../common/custom/models/peopleSearch/searchedPerson";
import {objectUtils} from "../../../common/utils/objectUtils";
import {ServiceHelperService} from "../../services/serviceHelper.service";
import {UxComposite} from "../../../common/models/ux/uxComposite";
import {peopleSearchProductKeys} from "../../../common/custom/peopleSearch/peopleSearchProductKeys";
import {commerceUtils} from "../../../common/utils/commerceUtils";
import {FileUtils} from "../../../common/utils/fileUtils";

@Injectable()
export class PeopleSearchService {
  // defined variables to avoid use of magic strings
  sessionStorageKey = "peopleSearchService";

  keys = {
    commerceContentId: "commerceContentId",
    searchedObject: "searchedObject",
  };

  commerceContent: CommerceContent;

  /**
   *
   * @param commerceService for all the sales,commerceContent and order functions
   * @param uxcService for all the uxc,uxCompostie related functions
   * @param crudService simple service to perform crud operation on certain db collection
   * @param storageService to get manage access to session storage
   * @param progressorService have all the function to manage the progessors/loader
   * @param authenticationService to get user authentication information
   */
  constructor(
    private commerceService: CommerceService,
    private uxcService: UxcService,
    private crudService: CrudService,
    private storageService: StorageService,
    private progressorService: ProgressorService,
    private authenticationService: AuthenticationService
  ) {}

  // this clear the commerce content and storage on can be called on a event like next page
  clear() {
    this.commerceContent = null;
    this.clearStorage();
    this.progressorService.init();
  }
  clearStorage() {
    this.saveStorage({});
  }

  // access the session storage for sessionStorageKey
  getStorage() {
    let storage = this.storageService.getSession(this.sessionStorageKey);
    if (!storage) {
      storage = {};
    }
    return storage;
  }

  // save in session storage against the sessionStorageKey
  saveStorage(storage) {
    this.storageService.setSession(this.sessionStorageKey, storage);
  }

  /**
   *
   * @param key against which storage needed to be get
   * @returns storage value against the provided key
   */
  getStorageValue(key) {
    let storage = this.getStorage();
    return storage[key];
  }

  /**
   *
   * @param key against which the value will be stored in session storage
   * @param value to be stored in session storage against the key
   */
  setStorageKeyValue(key, value) {
    let storage = this.getStorage();
    storage[key] = value;
    this.saveStorage(storage);
  }

  hasCommerceContent(): boolean {
    return !!this.getCommerceContentId();
  }

  // return the data from session storage for this.keys.commerceContentId
  getCommerceContentId() {
    return this.getStorageValue(this.keys.commerceContentId);
  }

  /**
   * set the commerceContent against 'this.keys.commerceContentId' in sessionStorage
   * @param commerceContent to be added in sessionStorage
   */
  setCommerceContent(commerceContent: CommerceContent) {
    try {
      this.setStorageKeyValue(this.keys.commerceContentId, commerceContent._id);
      this.commerceContent = commerceContent;
      LogUtils.debug(commerceContent);
    } catch (e) {
      LogUtils.error(e);
    }
  }

  /**
   *
   * @param uxComposite
   * @returns
   */
  getCommerceContent(uxComposite: UxComposite): Promise<any> {
    return Promise.resolve()
      .then(() => {
        uxComposite.setCode("contentInfo", null);
        if (this.commerceContent) {
          return this.commerceContent;
        } else if (this.getCommerceContentId()) {
          return this.commerceService
            .request(serverPaths.commerceActionFindContent, {
              commerceContentId: this.getCommerceContentId(),
              accessType: "update",
            })
            .then((responseEvent: ResponseEvent) => {
              let commerceContent = responseEvent.getSingleDoc();
              this.setCommerceContent(commerceContent);
              return commerceContent;
            });
        } else {
          return Promise.reject(false);
        }
      })
      .then((commerceContent) => {
        peopleSearchContentHelper.processResult(uxComposite, commerceContent);
        return commerceContent;
      })
      .then((commerceContent) => {
        return this.addSelectedPersonInfoIntoUxComposite(commerceContent).then(
          () => {
            return commerceContent;
          }
        );
      });
  }

  setSearchedObject(object: SearchedObject<any>) {
    this.setStorageKeyValue(this.keys.searchedObject, object);
  }

  getSearchedObject() {
    return this.getStorageValue(this.keys.searchedObject);
  }

  hasOrder(productKeys: string[]): Promise<any> {
    return Promise.resolve()
      .then(() => {
        let names = this.commerceService.getProductUxcompNamesByProductKeys(
          productKeys
        );
        return this.commerceService.hasOrder(names);
      })
      .then((commerceOrders: CommerceOrder[]) => {
        if (commerceOrders.length > 0) {
          return Promise.resolve(commerceOrders);
        } else {
          return Promise.reject(false);
        }
      });
  }

  findPeopleSearchProductIdFromCommerceOffers(
    productKey,
    commerceOffers: CommerceOffer[]
  ) {
    let productId = null;
    if (commerceOffers) {
      commerceOffers.some((commerceOffer) => {
        commerceOffer.commerceProducts.some((commerceProduct) => {
          if (productKey === commerceProduct.contentType) {
            productId = commerceProduct._id;
            return true;
          }
        });

        if (productId) {
          return true;
        }
      });
    }

    return productId;
  }

  createContent(commerceOrderId, contentInfos) {    
    return this.commerceService
      .request(serverPaths.commerceCustomerCreateContent, {
        userId: this.authenticationService.getUserId(),
        commerceOrderId: commerceOrderId,
        contentInfos: contentInfos,
      })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent.getDocs();
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  clearExtraUserInput(userInput) {
    userInput.maxAge = undefined;
    userInput.minAge = undefined;
  }

  peopleSearch(productKey, userInput, page, perPage, keys, inputTrackingSearch?): Promise<any> {
    let uxComposite;
    let timestamp = `${Date.now()}-${Math.random()}`;
    this.setStorageKeyValue("timestamp", timestamp);
    return Promise.resolve()
      .then(() => {
        return this.uxcService.getUxComposite();
      })
      .then((newUxComposite) => {
        uxComposite = newUxComposite;
        uxComposite.setCode("contentInfo", null);
        const input = objectUtils.clone(userInput);

        // phone data check
        if (input?.phone) {
          input.phone = input?.phone.replace(/[()\s-]/g, '');
        }

        if (productKey === peopleSearchProductKeys.phoneSearch) {
          if (input?.fname) delete input['fname'];
          if (input?.mname) delete input['mname'];
          if (input?.lname) delete input['lname'];
          if (input?.extId) delete input['extId'];
          if (input?.street) delete input['street'];
          if (input?.city) delete input['city'];
          if (input?.state) delete input['state'];
          if (input?.zip) delete input['zip'];
        }

        this.clearExtraUserInput(userInput);
        return this.commerceService.request(
          customServerPaths.contentPeopleSearchPeopleSearch,
          {
            productKey: productKey,
            userInput: input,
            page: page,
            perPage: perPage,
            inputTrackingSearch,
            keys: keys,
          }
        );
    }).then((responseEvent: ResponseEvent) => {
      if (responseEvent.isSuccess()) {
        if (timestamp === this.getStorageValue("timestamp")) {
          let commerceContent = responseEvent.getSingleDoc();
          this.setCommerceContent(commerceContent);
          peopleSearchContentHelper.processResult(uxComposite, commerceContent);
          return commerceContent;
        } else {
          LogUtils.error("Timestamp mismatch", timestamp);
          return Promise.reject(false);
        }
      } else {
        return Promise.reject(responseEvent);
      }
    }).catch((e) => {
      if (e instanceof ResponseEvent) {
        LogUtils.debug(e);
      } else {
        LogUtils.error(e);
      }
      return Promise.reject(e);
    });
  }

  criminalPhoto(photoUrl): Promise<any> {
    return this.commerceService.request(customServerPaths.contentPeopleSearchCriminalPhoto, {
      url: photoUrl
    });
  }

  hasNextSearch(): boolean {
    let flag = false;
    if (
      this.commerceContent &&
      this.commerceContent.getMainRaw() &&
      this.commerceContent.getMainRaw().extra
    ) {
      flag =
        this.commerceContent.getMainRaw().extra.page <
        this.commerceContent.getMainRaw().extra.totalPages;
    }

    return flag;
  }

  submitAnswer(data): Promise<any> {
    let collectionName = collectionClassHelper.getCollectionName(
      CommerceContent
    );
    return this.crudService
      .pushInput(collectionName, this.commerceContent._id, data)
      .then(() => {})
      .catch((e) => {
        LogUtils.error(e);
      });
  }

  optOutRequest(targetId, type, provider, referenceId, referenceCollectionName, emailCampaignKey, email, fullName, address, phoneNumber): Promise<any> {
    return this.commerceService.request(serverPaths.optOutOptOutRequest, {
      targetId: targetId,
      type: type,
      provider: provider,
      referenceId: referenceId,
      referenceCollectionName: referenceCollectionName,
      emailCampaignKey: emailCampaignKey,
      email: email,
      fullName: fullName,
      address: address,
      phoneNumber: phoneNumber,
    }).then((responseEvent: ResponseEvent) => {
      return responseEvent;
    }).catch((e) => {
      LogUtils.error(e);
      return Promise.reject(e);
    });
  }

  optOutConfirm(id, hash): Promise<any> {
    return this.commerceService
      .request(serverPaths.optOutOptOutConfirm, {
        id: id,
        hash: hash,
      })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent;
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  getContentInfoBySelectedObject(
    selectedObject: SearchedObject<any>,
    productKey,
    searchContent
  ) {
    if (selectedObject) {
      return {
        id: selectedObject.extId,
        providers: selectedObject.providers,
        productKey: productKey,
        firstName: (<SearchedPerson>selectedObject).fName,
        lastName: (<SearchedPerson>selectedObject).lName,
        middleName: (<SearchedPerson>selectedObject).mName,
        cityState: (<SearchedPerson>selectedObject).cityState,
        addresses: (<SearchedPerson>selectedObject).addresses,
        searchContent: searchContent,
      };
    }
  }

  findSelectedPerson(commerceContent: CommerceContent): SearchedPerson | null {
    let id: string | null = null;
    let person = null;

    let searchedObject = this.getSearchedObject();

    if (searchedObject && searchedObject.extId) {
      let id = null;
      return searchedObject;
    } else if (commerceContent.inputs) {
      objectUtils
        .clone(commerceContent.inputs)
        .reverse()
        .some((input: string) => {
          let value = input.split(":");
          if (value[0] === PeopleSearchContentHelper.QUESTION_TYPE.person) {
            id = value[1];
            return true;
          }
        });
      
      commerceContent
        .getMainRaw()
        ?.tempClient?.processed?.person?.some?.((searchedPerson) => {
          if (searchedPerson.extId === id) {
            person = searchedPerson;
            return true;
          }
        });
    }

    return person;
  }

  setSearchPersonToSearch(
    serviceHelperService: ServiceHelperService,
    person: SearchedPerson,
    rawFlag = false
  ) {
    serviceHelperService.userInputService.clear();
    this.clear();

    let userInput = serviceHelperService.userInputService.userInput;
    userInput.searchObjectId = person.extId;
    userInput.fname = person.fName;
    userInput.lname = person.lName;
    userInput.raw = !!rawFlag;
    serviceHelperService.userInputService.storeUserInput();

    this.setSearchedObject(person);
  }

  addSelectedPersonInfoIntoUxComposite(commerceContent: CommerceContent) {
    return Promise.resolve()
      .then(() => {
        return this.uxcService.getUxComposite();
      })
      .then((uxComposite: UxComposite) => {
        let replace = uxComposite.get("comp.people-search.replace.unknown");
        let selectedPerson = this.findSelectedPerson(commerceContent);

        if (selectedPerson?.tempClient) {
          let code = {
            fullName: selectedPerson.tempClient.fullName,
            age: selectedPerson.age ? selectedPerson.age : replace,
            firstName: selectedPerson.fName,
            middleName: selectedPerson.mName,
            lastName: selectedPerson.lName,
            city:
              selectedPerson.tempClient.address &&
              selectedPerson.tempClient.address.city
                ? selectedPerson.tempClient.address.city
                : replace,
            state:
              selectedPerson.tempClient.address &&
              selectedPerson.tempClient.address.state
                ? selectedPerson.tempClient.address.state
                : replace,
            replace: replace,
          };
          uxComposite.setCode("selectedPerson", code);
        }
      });
  }

  subscribe(commerceContent: CommerceContent, flag): Promise<any> {
    return this.commerceService
      .request(customServerPaths.contentPeopleSearchSubscribe, {
        commerceContentId: commerceContent._id,
        subscribeStatus: flag,
      })
      .then((responseEvent: ResponseEvent) => {
        return responseEvent;
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  findCommerceContent(id, key, uxComposite: UxComposite) {
    return Promise.resolve()
      .then(() => {
        if (id && key) {
          return this.commerceService
            .request(serverPaths.commerceActionFindContent, {
              commerceContentId: id,
              key: key,
              accessType: "read",
            })
            .then((responseEvent: ResponseEvent) => {
              let commerceContent: CommerceContent = responseEvent.getSingleDoc();
              return commerceContent;
            });
        } else {
          return Promise.reject(false);
        }
      })
      .then((commerceContent) => {
        peopleSearchContentHelper.processResult(uxComposite, commerceContent);
        return commerceContent;
      });
  }

  findPdfCommerceOrders(): Promise<any> {
    return Promise.resolve()
      .then(() => {
        return this.hasOrder([peopleSearchProductKeys.pdf]);
      })
      .then((commerceOrders: CommerceOrder[]) => {
        if (commerceOrders && commerceOrders.length > 0) {
          return Promise.resolve(commerceOrders);
        } else {
          return Promise.reject(commerceOrders);
        }
      });
  }

  canDownloadPdf(commerceContent: CommerceContent) {
    let flag = false;
    return Promise.resolve()
      .then(() => {
        if (
          commerceContent?.tempClientSecured?.childProductKeys?.indexOf(
            peopleSearchProductKeys.pdf
          ) !== -1
        ) {
          flag = true;
        }
      })
      .then(() => {
        if (!flag) {
          return this.findPdfCommerceOrders();
        }
      })
      .then((commerceOrders: CommerceOrder[]) => {
        if (!flag && commerceOrders) {
          if (
            commerceUtils.canProcessSomeCommerceOrders(
              commerceOrders,
              peopleSearchProductKeys.pdf
            )
          ) {
            flag = true;
          }
        }
      })
      .catch((e) => {
        LogUtils.error(e);
      })
      .then(() => {
        return flag;
      });
  }

  findPeopleSearchOnConfirmationAndSetUxComposite(
    uxComposite,
    commerceOrderIds
  ) {
    let options = { teaser: false };
    return this.commerceService
      .findCommerceContentsByCommerceOrderIds(commerceOrderIds, options)
      .then((commerceContents: CommerceContent[]) => {
        if (commerceContents && commerceContents.length) {
          commerceContents = commerceContents.sort((a, b) => {
            return a.createdTimestamp - b.createdTimestamp;
          });
          commerceContents.forEach((commerceContent) => {
            peopleSearchContentHelper.processResult(
              uxComposite,
              commerceContent
            );
          });

          if (commerceContents && commerceContents.length) {
            let searchedPersons = peopleSearchContentHelper.getSearchedPersons(
              commerceContents[0]
            );
            if (searchedPersons && searchedPersons.length) {
              uxComposite.setCode("selectedPerson", searchedPersons[0]);
              LogUtils.debug(searchedPersons[0]);
            }
          }
          uxComposite.setCode("commerceContents", commerceContents);
        }
        return commerceContents;
      })
      .catch((e) => {
        LogUtils.error(e);
        return Promise.reject(e);
      });
  }

  getPdfDownloadLink(uxComposite, commerceContent: CommerceContent) {
    if(!commerceContent) {
      return
    }    
    let pdfFileName = "report.pdf";
    const pdfDownloadProductKey = peopleSearchProductKeys.pdf;
    const fileType = FileUtils.TYPE.pdf;
    pdfFileName = serverPaths.commerceActionDownloadContent + "/" + commerceContent._id + "/" + pdfDownloadProductKey + "/" + fileType + "/" + uxComposite.brandName + "-";
    if (commerceContent.productKey === peopleSearchProductKeys.nameSearch) {
      pdfFileName += "Person-";
    } else if (commerceContent.productKey === peopleSearchProductKeys.phoneSearch) {
      pdfFileName += "Phone-";
    }
    try {
      pdfFileName += commerceContent.getMainRaw().tempClient.processed.person[0].tempClient.fullName;
    } catch (e) {
      pdfFileName += commerceContent._id;
    }

    pdfFileName += ".pdf";
    return pdfFileName;

  }
}
