import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './acknowledge-dialog.component.html',
  styleUrls: ['./acknowledge-dialog.component.scss']
})
export class AcknowledgeDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
