<mat-card class="detail-dialog-card">    
    <div class="__card-header">
        <h2>
            <span class="__span-center">Note Details</span>
            <span style="position: absolute;">
                <mat-icon class="__font-lg" mat-dialog-close>highlight_off</mat-icon>
            </span>
        </h2>
    </div>
    
    <mat-card-content>
        <div class="__main-content">
            <mat-dialog-content class="mat-typography">
                <p class="mb-35 __fs-20 lh-md fw-300">{{data?.detail}}</p>
            </mat-dialog-content>        
        </div>        
    </mat-card-content>
</mat-card>