import { adminPaths } from "src/common/helpers/pathHelpers";
import {UxComposite} from "../../common/models/ux/uxComposite";

export class ConfigUtils {
  getConfig(uxComposite: UxComposite) {
    const config = uxComposite.get('comp.config');
    return config;
  }

  getConfigAdmin(uxComposite: UxComposite) {
    const config = uxComposite.get('comp.config.admin');
    return config;
  }

  getBrands(uxComposite: UxComposite) {
    const config = this.getConfig(uxComposite);
    return config.brands;
  }

  getEmailConfig(uxComposite: UxComposite) {
    const config = this.getConfig(uxComposite);
    return config.email;
  }

  getCsrPathFromVersion(uxComposite: UxComposite): string {
    const config = this.getConfigAdmin(uxComposite);

    const version = config?.customerDetails?.version;

    switch (version) {
      case 'v1':
        return adminPaths.csrCustomerV1;
      case 'v2':
        return adminPaths.csrCustomerV2;
      default:
        return adminPaths.csrCustomer;
    }
  }
}

export const configUtils = new ConfigUtils();
