<div class="optout">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>
          Notes
        </span>
        <div>
          <button mat-icon-button color="primary" (click)="init()">
            <i class="material-icons">cached</i>
          </button>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>Updater ID</span>
            <i class="material-icons" matTooltip="Input Updater ID here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="Updater ID"
              [(ngModel)]="input.updaterId" />
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Reference Collection</span>
            <i class="material-icons" matTooltip="Select Reference Collection here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-select [(ngModel)]="input.referenceCollection" placeholder="Reference Collection">
              <!-- <mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}</mat-option> -->
              <mat-option [value]="'users'">Users</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search(input)" [disabled]="!isValid()">Search</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div>
        <table mat-table [dataSource]="notes" multiTemplateDataRows>
          <ng-container matColumnDef="expander">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="expandedElement = expandedElement === element ? null : element">
                <i class="material-icons">
                  {{expandedElement !== element ? 'add_box' : 'remove'}}
                </i>
              </button>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element?.createdTimestamp | toDateTime}} </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef class="id"> Id </th>
            <td mat-cell *matCellDef="let element" class="id"> {{element?._id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="updaterId">
            <th mat-header-cell *matHeaderCellDef class="id"> Updater Id </th>
            <td mat-cell *matCellDef="let element"> {{element?.user?.email}} </td>
          </ng-container>
  
          <ng-container matColumnDef="referenceCollection">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{element?.referenceCollection}} </td>
          </ng-container>
  
          <ng-container matColumnDef="categories">
            <th mat-header-cell *matHeaderCellDef> Categories </th>
            <td mat-cell *matCellDef="let element"> {{element?.categories}} </td>
          </ng-container>

          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef> Note </th>
            <td mat-cell *matCellDef="let element"> {{element?.note}} </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
              <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <p><code>{{ getRaw(element) }}</code></p>
              </div>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" [class.example-expanded-row]="expandedElement === element">
          </tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20]"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
