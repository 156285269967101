import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-info-overlay',
  templateUrl: './info-overlay.component.html',
  styleUrls: ['./info-overlay.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoOverlayComponent implements OnInit {

  @Input() infoTemplate: any = "";
  constructor() { }

  ngOnInit(): void {
    
  }

}
