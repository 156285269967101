<h2 mat-dialog-title>UXC Build Progress</h2>
<mat-dialog-content class="mat-typography">
  <label>Duration: {{totalDuration}}s - Total: {{totalCount}} - Done - {{finishedCount}} - Success: {{successCount}} - Failed: {{failedCount}}</label>
  <mat-progress-bar mode="determinate" [value]="curProgress"></mat-progress-bar>
  <br>
  <p *ngFor="let status of statusData">
    <strong>UXC ID</strong>&nbsp;
    <span>{{status.uxcId}}</span>&nbsp;
    <strong>Status</strong>&nbsp;
    <span>{{status.status}}</span>&nbsp;
    <ng-container *ngIf="status.endTimestamp">
      <strong>Duration</strong>&nbsp;
      <span>{{getDuration(status)}}s</span>
    </ng-container>
  </p>
  <ng-container *ngIf="failedUxcs">
    <strong>Failed UXCs</strong>
    <p style="word-break: break-all;">{{failedUxcs}}</p>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>