import {Component, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {ResponseEvent} from '../../common/event/responseEvent';
import {ActivatedRoute} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {ManagedMail} from '../../common/models/user/managedMail';
import {objectUtils} from '../../common/utils/objectUtils';
import {inputUtils} from '../../clientCommon/utils/inputUtils';
import {emailUtils} from '../../common/utils/emailUtils';
import {LogUtils} from '../../common/utils/logUtils';

@Component({
  templateUrl: './mailSentV1.component.html'
})

export class MailSentComponentV1 implements OnInit {
  managedMails: ManagedMail[] = [];
  input: any = {};
  prevInput: any = {};
  more = false;

  constructor(private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrMailSent, {}).then((responseEvent: ResponseEvent) => {
      this.managedMails = responseEvent.getDocs();
      this.managedMails.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  getClasses(obj) {
    const def = collectionClassHelper.getCollectionName(obj);
    return `${def} ${obj.status} ${obj.subStatus}`;
  }

  getRaw(obj) {
    const cloned = objectUtils.clone(obj);
    delete (cloned.draft);
    return JSON.stringify(cloned, null, 4);
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search(this.input);
    }
  }

  search(input, aggregate?: boolean) {
    objectUtils.deleteEmptyProperties(input);
    this.prevInput = objectUtils.clone(input);
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrMailSent, input).then((responseEvent: ResponseEvent) => {
      this.managedMails = responseEvent.getDocs();
      this.managedMails.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });

  }

  isValid() {
    return this.input && emailUtils.validateEmail(this.input.email);
  }

  unsubscribe(managedMail) {
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrMailUnsubscribe, {email: managedMail.email}).then((responseEvent: ResponseEvent) => {
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    }).then(() => {
      return this.search(this.input);
    });
  }
}
