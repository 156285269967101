
<h2 mat-dialog-title class="modal-title">Attachments <span class="close" data-dismiss="modal" aria-label="Close"
    [mat-dialog-close]="">&times;</span></h2>
<mat-dialog-content class="mat-typography content">
  <div class="card">
    <div class="card-header">
      <span class="attText">Attachment</span> <span class="dtaText adjustMargin">Date/Time Added</span> <span
        class="dlText">Delete</span>
    </div>
  </div>
  <div cdkDropListGroup>
    <div class="example-container">  
      <div
        cdkDropList
        [cdkDropListData]="files"
        class="example-list"
        (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let f of attachmentItemLists;index as i" cdkDrag>
          <div class="card-attachment-file" algin="center">
            <span class="attText attSetText"><img class="setImageSize" src="../../../assets/logos/pdfIconImage.png" />
              &nbsp;<a  (click)="onDownloadAttachment(f.attachmentUniqueId,f.contentType)">{{f.fileName ? "Attachment" : "Unknown Attachment"}}&nbsp;{{i+1}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a></span> 
              <span class="dtaText">{{f.createdDate| date:'yyyy-MM-dd h:mm:ss'}}</span>
            <span  class="dlText" (click)="onDelAttachment(f.attachmentUniqueId)"><img class="glyphicon" src="../../../assets/icons/icons8-trash.svg" alt=""/> </span>
          </div>
        </div>
        <div *ngIf="hideAttList">
          <div class="example-box" *ngFor="let f of getFiless;index as i" cdkDrag>
            <div class="card-attachment-file" algin="center">
              <span class="attText attSetText"><img class="setImageSize" src="../../../assets/logos/pdfIconImage.png" />
                &nbsp;<a>{{f.name ? "Attachment":""}}&nbsp;{{attachmentItemLists.length + i + 1}}</a></span>
                <span *ngIf="!unHideShow" class="dtaText adjustLastDateMargin">{{currentDate | date:'yyyy-MM-dd HH:mm:ss'}}</span> 
              <span *ngIf="!unHideShow" class="dlText" (click)="removeAttachment(f.name)"><img class="glyphicon" src="../../../assets/icons/icons8-trash.svg" alt=""/>  </span>
            </div>
          </div>
        </div>    
        <div *ngIf="showAttList">
          <div class="example-box" *ngFor="let f of showFiless;index as i" cdkDrag>
            <div class="card-attachment-file" algin="center">
              <span class="attText attSetText"><img class="setImageSize" src="../../../assets/logos/pdfIconImage.png" />
                &nbsp;<a>{{f.name ? "Attachment":""}}{{attachmentItemLists.length + i + 1}}&nbsp;</a></span>
                <span *ngIf="!unHideShow" class="dtaText">{{f.lastModifiedDate | date:'yyyy-MM-dd HH:mm:ss'}}</span> 
              <span *ngIf="!unHideShow" class="dlText" (click)="onDelAttachment(f.attachmentUniqueId)"><img class="glyphicon" src="../../../assets/icons/icons8-trash.svg" alt=""/> </span>
              <span *ngIf="unHideShow" class="unHideShowCloseIcon" (click)="onDelAttachment(f.attachmentUniqueId)">x</span>
              <span *ngIf="unHideShow"class="unMuttedText">Uploading Now</span>
              <div class="progress" *ngIf="progressShow">
              <div *ngIf="progress" class="progress-bar" [style.width]="progress + '%'"></div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
</mat-dialog-content>
<mat-dialog-actions align="center">
  <div class="card-attachment">
    <div class="card-body">
      <h4>Add Attachment</h4>
      <form action="/attachments" method="POST" enctype="multipart/form-data">
        <button class="choosefile" onclick="document.getElementById('getFile').click()">Browse Files</button>
      <input  type='file'
       accept=".jpg,.jpeg,.png,application/pdf"
       name="file" 
       class="browsFile"
       id="getFile"
       (change)="onSave($event)"
       multiple="multiple"
       #inputField>
      <p class="handleErrorText" *ngIf="fileSizeAlert"><b>Your file size exceeds the limit 20MB. You upload file maximum 20MB only.</b></p>
      </form>
    </div>
  </div>
  <button mat-raised-button color="primary" [disabled]="!inputField ? 'disabled': null" (click)="onSubmit()">Save</button>
  <a #downloadButton [href]="downloadLink" download hidden>Download this</a>
</mat-dialog-actions>