import { CSRFactFindersTableComponent } from '../csr/factFinders/factFinders-table/factFinders-table.component';
import { CSRFactFindersComponent } from '../csr/factFinders/factFinders.component';
import {PeopleSearchService} from "../../clientCommon/custom/services/peopleSearch.service";
import {OverlayModule} from '@angular/cdk/overlay';
import { CSREmailComponent } from "../csr/email/email.component";
import { UxHistoryComponent } from "../csr/ux-history/ux-history.component";
import {MatTabsModule} from '@angular/material/tabs'
import { CSREmailTableComponent } from "../csr/email/email-table/email-table.component";
import { UxHistoryTableComponent } from '../csr/ux-history/ux-history-table/ux-history-table.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete'; 
import { ReactiveFormsModule } from "@angular/forms";
import {MatPaginatorModule} from '@angular/material/paginator';
import { UxcHistoryComponent } from '../uxc/uxcHistory.component';
import { UxRevisionHistoryComponent } from '../uxc/uxRevisionHistory.component';

export const CUSTOM_DECLARATIONS = [
  CSREmailComponent,
  CSREmailTableComponent,
  UxHistoryComponent,
  UxcHistoryComponent,
  UxHistoryTableComponent,
  CSRFactFindersComponent,
  CSRFactFindersTableComponent
];
export const CUSTOM_IMPORTS = [
  ReactiveFormsModule,
  OverlayModule,
  MatTabsModule,
  MatAutocompleteModule,
  MatPaginatorModule,
];
export const CUSTOM_PROVIDERS = [
  PeopleSearchService,
];
