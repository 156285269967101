<style>
  #alertMessageDialog {
    padding: 15px;
    min-width: 300px;
    white-space: pre-line;
  }

  #alertMessageDialog > h2 {
    margin: 0;
    margin-bottom: 50px;
    font-weight: normal;
    font-size: 18px;
  }

  #alertMessageDialog > div.buttons {
    display: flex;
    justify-content: center;
  }

  #alertMessageDialog > div.buttons > button {
    padding: 8px 10px;
    min-width: 90px;
    cursor: pointer;
  }
</style>

<div id="alertMessageDialog">
  <h2 md-dialog-title>{{message}}</h2>
  <div class="buttons">
    <button class="mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base" (click)="dialogRef.close(true)">Close</button>
  </div>
</div>