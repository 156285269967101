<div class="__notes">
    <mat-card>
      <!-- <mat-card-header> -->
          <div class="row">
              <div class="col">
                  <h1 class="text-center __shade-black">Manage Notes ({{pagination.totalRecords}})</h1>
              </div>
          </div>
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-select (valueChange)="onSelectChange($event)" [(ngModel)]="selectedBrand"  placeholder="Brand Name" [value]="selectedBrand">
                      <mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}</mat-option>
                      <!-- <mat-option [value]="'users'">Users</mat-option> -->
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline" floatLabel="always">
                    <input matInput type="text"  placeholder="Enter Note ID" [(ngModel)]="noteId" /> 
                </mat-form-field>
            </div>
            <div class="col ml-auto">
                <div class="">
                    <button mat-raised-button color="primary" class="__btn-lg" (click)="search()">Search</button>
                  </div>
            </div>
        </div>  
        <!-- </mat-card-header> -->
      <mat-card-content>

        <table class="table" style="border: 1px solid lightgray; border-radius: 12px;">
            <thead>
                <th>Brand Name</th>
                <th><span class="text-center">Note ID</span>/<br><span class="__light-gray font-sm">Customer ID</span></th>
                <th>
                    <span class="text-center">
                        Customer Email
                    </span>  <br><span class="__light-gray font-sm">(Note created by)</span></th>
                <th>
                    <span class="text-center">Date</span><br><span class="__light-gray font-sm">(Note created date)</span>
                </th>
                <th>Type</th>
                <th>Note Details</th>
                <th>Actions</th>
            </thead>
            <tbody>
                <tr *ngFor="let note of notes; let i = index;">
                    
                    <td>{{note.obj?.brandName || 'N/A'}}</td>
                    <td>{{note.obj?._id || null}}</td>
                    <td>{{note.obj?.user?.email || 'N/A'}}</td>
                    <td>{{note.obj?.createdTimestamp | date }}</td>
                    <td>
                        <span *ngIf="note.obj?.privateFlag">Private</span>
                        <span *ngIf="!note.obj?.privateFlag">Public</span>
                    </td>
                    <td>
                        {{note.obj?.note.slice(0, 25)}} <span *ngIf="note.obj && note.obj.note && note.obj.note.length > 25">...</span>
                        <span *ngIf="note.obj && note.obj.note && note.obj.note.length > 25" (click)="previewDetail(note.obj.note)" class="__color-blue __pointer" style="margin: 0 8px;">read more</span></td>
                    <td>
                        <span class="__d-block">
                            <a href="/admin/csr/customer/{{note.obj?.user?._id}}">View Customer profile</a> <br>
                        </span>
                        <span class="__d-block __mt-17">
                            <a class="mt-2 __ul __pointer __color-blue" (click)="onDeleteWithConfirm(note.obj._id, i)">Delete Note</a>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="notes.length == 0">
                    <td colspan="6"><p class="text-center">No notes has been found</p></td>
                </tr>
                
            </tbody>
        </table>
        <div class="d-flex">
            <div class="ml-auto d-flex">
                <span class="m-auto m-10">Results per page</span>
                
                    <mat-form-field class="m-auto" appearance="outline" floatLabel="always" style="width: 85px; margin-bottom: -18px;">
                        <mat-select (valueChange)="onSelectChange($event)" [(ngModel)]="pagination.perPage">
                          <mat-option *ngFor="let page of [5, 25, 50, 75, 100, 125]" [value]="page">{{ page }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                
                <span class="m-auto m-10">
                    {{pagination.currentPage}} of {{pagination.totalPages}} pages 
                </span>
                <span class="mt-auto mb-auto m-10">
                    <mat-icon [ngClass]="{'disabled': !pagination.hasPrev, 'dark-gray': pagination.hasPrev}" aria-hidden="false" (click)="onPrevPage()" aria-label="Example home icon">keyboard_arrow_left</mat-icon>
                    <mat-icon [ngClass]="{'disabled': !pagination.hasNext, 'dark-gray': pagination.hasNext}" aria-hidden="false" (click)="onNextPage()" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
                </span>
            </div>
        </div>
          
        
        <div class="spacer"></div>
        
      </mat-card-content>
    </mat-card>
  </div>

  