<style>
  a {
    text-decoration: none;
  }

  .cancelled a {
    color: red;
  }

  .inactive a {
    color: gray;
  }

  .failed a {
    text-decoration: line-through;
    color: gray;
  }

  .else a {
    color: gray;
  }

  .brandId {
    width: 150px;
  }

  .unique ::ng-deep .mat-mdc-text-field-wrapper {
    background-color: #bbe6bb;
  }

  .searching ::ng-deep .mat-mdc-text-field-wrapper {
    background: yellow;
  }

  .full {
    width: 100%;
  }

  .inline {
    display: inline-block;
  }

  .property {
    margin-right: 10px;
  }

  .w-100 {
    width: 100%;
  }

  .status {
    text-transform: capitalize;
  }

  .recursive {
    background-color: #0000ff;
  }

  .recursive .mat-cell {
    color: white !important;
  }

  .mat-header-cell {
    background-color: #eef1ff;
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 8px;
    color: #74779a;
  }

  .users-wrapper {
    border: 1px solid rgba(0, 0, 0, .12);
  }

  .mat-column-brand {
    width: 200px;
  }

  .mat-column-zipCode {
    width: 150px;
  }

  .mat-column-brand {
    width: 200px;
  }

  .mat-column-card {
    width: 250px;
  }

  .mat-column-phone {
    width: 150px;
  }

  .mat-column-lastBill {
    width: 150px;
  }

  .mat-column-status {
    width: 250px;
  }

  .usersMgmt-filter {
    display: flex;
  }

  .search-field {
    margin-left: 8px;
  }

  .search-field:first-of-type {
    margin-left: auto;
  }
</style>
<mat-card id="userMgmt">
  <mat-card-header>
    <mat-card-title>
      Customers
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-form-field class="full" [class.searching]="onSmartSearch" [class.unique]="users.length === 1">
        <input matInput #smartSearchInput type="text" (keydown)="keyDownSmartSearchEvent($event)"
          placeholder="fname:first lname:last email:email@email.com phone:2342342342 l4:1234 f4:1234 brand:pr"
          [ngModel]="smartSearch" (ngModelChange)="onSmartSearchChange($event)">
      </mat-form-field>
    </div>
    <div class="usersMgmt-filter">
      <mat-form-field appearance="outline" floatLabel="always" class="search-field">
        <mat-label>Filter by Brand</mat-label>
        <mat-select class="brandId" name="brandId" placeholder="Brand" [ngModel]="input.user.brandId"
          (ngModelChange)="input.user.brandId = $event; filterUsers();">
          <mat-option *ngFor="let brand of brands" [value]="brand.id">
            {{ brand.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" class="search-field">
        <mat-label>Filter by State</mat-label>
        <mat-select class="brandId" name="states" placeholder="State" [ngModel]="input.user.state"
          (ngModelChange)="input.user.state = $event; filterUsers();">
          <mat-option *ngFor="let state of states" [value]="state.code">
            {{ state.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="users-wrapper">
      <table mat-table [dataSource]="filteredUsers" class="w-100">
        <ng-container matColumnDef="recursive">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox color="primary" (click)="createRecursiveSearchQuery(element)"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef> Brand </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)"> {{getBrandName(element.brandId)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="zipCode">
          <th mat-header-cell *matHeaderCellDef> ZipCode </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)">
            <span *ngFor="let commerceToken of element?.tempClient?.commerceTokens">
              {{commerceToken?.billingAddress?.zip}}<br>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)">
            <div *ngFor="let commerceToken of element?.tempClient?.commerceTokens">
              {{commerceToken?.billingAddress?.firstName}} {{commerceToken?.billingAddress?.lastName}}<br>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="card">
          <th mat-header-cell *matHeaderCellDef> Card </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)">
            <span *ngFor="let commerceToken of element?.tempClient?.commerceTokens">
              {{getCardInfo(commerceToken)}}<br>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)">
            {{ element.phone }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastBill">
          <th mat-header-cell *matHeaderCellDef> Last Bill </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)">
            {{ getDate(element.tempClient?.lastFulfilledCommercePayment?.paymentTimestamp) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element" (click)="viewDetailPage(element)">
            <span *ngFor="let commerceOrder of element?.tempClient?.commerceOrders">
              <span
                *ngIf="!commerceOrder?.tempClient?.commerceOrderSubStatusCancel">{{getDate(commerceOrder.orderTimestamp)}}</span>
              <span
                *ngIf="commerceOrder?.tempClient?.commerceOrderSubStatusCancel">{{getDate(commerceOrder?.tempClient?.commerceOrderSubStatusCancel?.timestamp)}}</span>
              <span class="status">
                {{ getStatus(commerceOrder) }}
              </span>
              <br>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: userColumns;" [class.recursive]="row.tempClient?.recursive"></tr>
      </table>
    </div>
    <button [disabled]="!more" (click)="loadMore()">Load More</button>
  </mat-card-content>
</mat-card>