import { Component, Input } from "@angular/core";

@Component({
    selector: 'uxHistory',
    templateUrl: 'uxHistory.component.html'
})

export class UxHistoryComponent {
    @Input() uxHistory = [];
    @Input() id: string;
    @Input() type: string = '';

    ngOnInit() {
      this.uxHistory = this.uxHistory.filter(item => item[0].referenceId && item[0].referenceId == this.id)
    }
}
