<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }
</style>

<h2 mat-dialog-title class="modal-title">Note Detail</h2>
<mat-dialog-content class="mat-typography content">
  <editor [init]="{
    height: 500,
    menubar: true,
    plugins: [
      'advlist autolink lists link image charmap print',
      'preview anchor searchreplace visualblocks code',
      'fullscreen insertdatetime media table paste',
      'help wordcount'
    ],
    toolbar:
      'undo redo | formatselect | image bold italic | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | help'
  }" [(ngModel)]="data.note"></editor>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="handleSave()">Save</button>
  <button mat-stroked-button color="primary" [mat-dialog-close]="data.note.note">Close</button>
</mat-dialog-actions>