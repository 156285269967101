<div class="permissions">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>Permissions</span>
        <div>
          <button mat-icon-button color="primary" (click)="init()" class="mr-2"><i
              class="material-icons">cached</i></button>
          <a mat-stroked-button color="primary" [routerLink]="['/'+adminPaths.permissionsV1]">Permissions v1</a>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
        <div class="row">
          <div class="col">
            <div class="tooltip-label">
              <span>Name</span>
              <i class="material-icons" matTooltip="Select uxc type you wanna add" matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="newPermission.draft.name" />
            </mat-form-field>
          </div>
          <div class="col">
            <div class="tooltip-label">
              <span>Description</span>
              <i class="material-icons" matTooltip="Select uxc type you wanna add" matTooltipPosition="after">help</i>
            </div>
            <mat-form-field appearance="outline">
              <input matInput type="text" name="description" placeholder="Description"
                [(ngModel)]="newPermission.draft.description" />
            </mat-form-field>
          </div>
          <div class="col">
            <div class="tooltip-label">
              <span>Path</span>
              <i class="material-icons" matTooltip="Select uxc type you wanna add" matTooltipPosition="after">help</i>
            </div>
            <div *ngFor="let path of newPermission.draft.paths; let i = index" class="expanded-content">
              <div class="input">
                <mat-form-field appearance="outline">
                  <input matInput type="text" [placeholder]="path.key" [(ngModel)]="path.key" />
                </mat-form-field>
              </div>
              <div class="pt-1">
                <button mat-icon-button color="primary" (click)="removeItemInArray(newPermission.draft.paths,i)"><i
                    class="material-icons">delete</i></button>
              </div>
            </div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Path</mat-label>
                <input matInput #newPath type="text" placeholder="Path"
                  (change)="addPath(newPermission.draft, newPath)" />
              </mat-form-field>
            </div>
          </div>

          <div class="col">
            <div class="tooltip-label">
              <span>Property</span>
              <i class="material-icons" matTooltip="Select uxc type you wanna add" matTooltipPosition="after">help</i>
            </div>
            <div *ngFor="let property of newPermission.draft.properties; let i = index" class="expanded-content">
              <div class="input">
                <mat-form-field appearance="outline">
                  <input matInput type="text" [placeholder]="property.key" [(ngModel)]="property.key" />
                </mat-form-field>
              </div>
              <div class="pt-1">
                <button mat-icon-button color="primary" (click)="removeItemInArray(newPermission.draft.properties,i)"><i
                    class="material-icons">delete</i></button>
              </div>
            </div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Property</mat-label>
                <input matInput #newProperty type="text" placeholder="Property"
                  (change)="addProperty(newPermission.draft, newProperty)" />
              </mat-form-field>
            </div>
          </div>

          <div class="col action">
            <button mat-raised-button color="primary" [disabled]="(!newPermission.draft.name)"
              (click)="add()">Add</button>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let permission of permissions; let i = index">
        <div *ngIf="i === 0" class="row">
          <div class="col primary">Name</div>
          <div class="col primary">Description</div>
          <div class="col primary">Path</div>
          <div class="col primary">Property</div>
          <div class="col action"></div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" floatLabel="always">
              <input matInput type="text" name="name" placeholder="Name" [(ngModel)]="permission.draft.name" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline" floatLabel="always">
              <input matInput type="text" name="description" placeholder="Description"
                [(ngModel)]="permission.draft.description" />
            </mat-form-field>
          </div>
          <div class="col expanded">
            <div *ngFor="let path of permission.draft.paths; let i = index" class="expanded-content">
              <div class="input">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput type="text" [placeholder]="path.key" [(ngModel)]="path.key" />
                </mat-form-field>
              </div>
              <div class="action small">
                <button mat-icon-button color="primary" (click)="removeItemInArray(permission.draft.paths,i)"><i
                    class="material-icons">delete</i></button>
              </div>
            </div>
            <div>
              <mat-form-field appearance="outline" floatLabel="always">
                <input matInput #newPath type="text" placeholder="Path" (change)="addPath(permission.draft, newPath)" />
              </mat-form-field>
            </div>
          </div>
          <div class="col expanded">
            <div *ngFor="let property of permission.draft.properties; let i = index" class="expanded-content">
              <div class="input">
                <mat-form-field appearance="outline" floatLabel="always">
                  <input matInput type="text" [placeholder]="property.key" [(ngModel)]="property.key" />
                </mat-form-field>
              </div>
              <div class="action small">
                <button mat-icon-button color="primary" (click)="removeItemInArray(permission.draft.properties,i)"><i
                    class="material-icons">delete</i></button>
              </div>
            </div>
            <div>
              <mat-form-field appearance="outline" floatLabel="always">
                <input matInput #newProperty type="text" placeholder="Property"
                  (change)="addProperty(permission.draft, newProperty)" />
              </mat-form-field>
            </div>
          </div>
          <div class="col action small">
            <button mat-raised-button color="primary" [disabled]="(!permission.isDiffDraft())" (click)="update(permission)">Update</button>
          </div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>