<div class="mailsent">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <span>Mail Sent</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>Email</span>
            <i class="material-icons" matTooltip="Input Email here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <input matInput type="text" (keydown)="keyDownEvent($event)" placeholder="Email"
              [(ngModel)]="input.email" />
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Brand</span>
            <i class="material-icons" matTooltip="Input Brand here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-select [(ngModel)]="input.brandId" placeholder="Brand">
              <mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search(input)" [disabled]="!isValid()">Search</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="row">
        <div class="col">
          <table mat-table [dataSource]="managedMails" multiTemplateDataRows>
            <ng-container matColumnDef="expander">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="primary"
                  (click)="expandedElement = expandedElement === element ? null : element">
                  <i class="material-icons">
                    {{expandedElement !== element ? 'add_box' : 'remove'}}
                  </i>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> ID </th>
              <td mat-cell *matCellDef="let element"> {{ element._id }} </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{ element.createdTimestamp | toDateTime }} </td>
            </ng-container>

            <ng-container matColumnDef="brand">
              <th mat-header-cell *matHeaderCellDef> Brand </th>
              <td mat-cell *matCellDef="let element"> {{ element.brandId }} </td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
            </ng-container>

            <ng-container matColumnDef="subStatus">
              <th mat-header-cell *matHeaderCellDef> Sub Status </th>
              <td mat-cell *matCellDef="let element">
                <div class="substatus" [class.active]="element.subStatus !== 'unsubscribed'">
                  <div class="mark"></div>
                  <span>{{ element.subStatus }}</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <button *ngIf="element.subStatus !== 'unsubscribed'" color="primary" (click)="unsubscribe(element)" [disabled]="!isValid()">Unsubscribe</button>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <br>
                  <code>{{getRaw(element)}}</code>
                  <br>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
              [class.example-expanded-row]="expandedElement === element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <!-- <div *ngFor="let managedMail of managedMails" class="searched-content">
    <div class="row">
      <div class="col id">
        {{managedMail._id}}    
      </div>
      <div class="col id">
        {{managedMail.email}}
      </div>
      <div class="col">
        {{managedMail.status}}
      </div>
      <div class="col">
        {{managedMail.subStatus}}
      </div>
      <div class="col action">
        <div class="mb-1">
          <button mat-raised-button (click)="managedMail.toggle('show')" color="primary">Expand</button>
        </div>
        <div *ngIf="!managedMail.isUnsubscribed()">
          <button mat-raised-button color="warn" (click)="unsubscribe(managedMail)" [disabled]="!isValid()">Unsubscribe</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <code *ngIf="managedMail.getToggle('show')" >{{getRaw(managedMail)}}</code>
      </div>
    </div>
  </div> -->
</div>