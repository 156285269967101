<style>
  .inline {
    display: inline-block;
  }

  .id {
    color: grey;
    font-size: 0.9em;
    width: 165px;
  }

  mat-select {
    width: 100px;
  }

  mat-form-field {
    200px;
  }
</style>
<mat-card id="userMgmt">
  <mat-card-header>
    <mat-card-title>
      User Mgmt Tool
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngFor="let user of users" class="user baseline">
      <mat-card>
        <mat-card-content id="{{user._id}}">

          <div class="inline id">{{user._id}}</div>
          <mat-form-field><input matInput type="text" [placeholder]="user.email" [(ngModel)]="user.draft.email"/></mat-form-field>
          <mat-form-field><input matInput type="text" [placeholder]="user.firstName" [(ngModel)]="user.draft.firstName"/></mat-form-field>
          <mat-form-field><input matInput type="text" [placeholder]="user.lastName" [(ngModel)]="user.draft.lastName"/></mat-form-field>
          <mat-form-field><input matInput type="text" placeholder="Password" [(ngModel)]="user.draft.password"/></mat-form-field>
          <mat-select placeholder="Status" [(ngModel)]="user.draft.status" required="">
            <mat-option [value]="status.active">{{ status.active }}</mat-option>
            <mat-option [value]="status.inactive">{{ status.inactive }}</mat-option>
          </mat-select>
          <mat-select placeholder="Permission" [ngModel]="user.draft?.permissionIds[0]" (selectionChange)="updatePermission(user, $event)">
            <mat-option *ngFor="let permission of permissions" [value]="permission._id">{{ permission.name }}</mat-option>
          </mat-select>
          <span *ngIf="user.hasRoles([roles.admin])">Admin</span>
          <span *ngIf="user.hasRoles([roles.csr])">CSR</span>
          <button (click)="update(user)" [disabled]="!isValid(user)">Update</button>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <mat-card>
      <mat-card-subtitle>
        New User
      </mat-card-subtitle>
      <mat-card-content id="newUser">
        <mat-form-field><input matInput type="text" placeholder="email" [(ngModel)]="newUser.draft.email"/></mat-form-field>
        <mat-form-field><input matInput type="text" placeholder="First Name" [(ngModel)]="newUser.draft.firstName"/></mat-form-field>
        <mat-form-field><input matInput type="text" placeholder="Last Name" [(ngModel)]="newUser.draft.lastName"/></mat-form-field>
        <mat-form-field><input matInput type="password" placeholder="Password" [(ngModel)]="newUser.draft.password"/></mat-form-field>

        <mat-select placeholder="Permission" [ngModel]="newUser.draft?.permissionIds[0]" (selectionChange)="updatePermission(newUser, $event)">
          <mat-option *ngFor="let permission of permissions" [value]="permission._id">{{ permission.name }}</mat-option>
        </mat-select>
        <mat-select placeholder="Role" [(ngModel)]="newUser.draft.roles[0]">
          <mat-option [value]="roles.csr">{{ roles.csr }}</mat-option>
          <mat-option [value]="roles.admin">{{ roles.admin }}</mat-option>
        </mat-select>
        <mat-select placeholder="Status" [(ngModel)]="newUser.draft.status">
          <mat-option [value]="status.active">{{ status.active }}</mat-option>
          <mat-option [value]="status.inactive">{{ status.inactive }}</mat-option>
        </mat-select>
        <button (click)="create()" [disabled]="!isValid(newUser)">Create</button>
      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>
