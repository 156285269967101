<div class="customer" *ngIf="initDone">
  <div class="customer-side">
    <mat-card>
      <div (click)="init(userId)" class="brand-bg">
        <img [src]="'/assets/logos/' + commerceOrders[0].brandId + '.png'" alt="brand" style="height: 60px;">
      </div>
      <h2 class="customer-side-title" (click)="init(userId)">{{ customerUxComposite?.brandDomain }}</h2>
      <p class="customer-side-info">{{companyName}}</p>
      <p class="customer-side-info" [innerHTML]="agentAddress"></p>
      <p class="customer-side-info">{{agentPhoneNumber}}</p>
      <p class="customer-side-subtitle">Customer summary</p>
      <div class="d-flex">
        <div class="ml-auto">
          <a [routerLink]="['/'+adminPaths.csrCustomerV1]+'/'+userId" mat-stroked-button color="primary"
            class="mr-2">V1</a>
          <a [routerLink]="['/'+adminPaths.csrCustomer]+'/'+userId" mat-stroked-button color="primary"
            class="mr-2">V3</a>
          <button mat-stroked-button color="primary" (click)="openBillingInfoModal()">Billing Info</button>
        </div>
      </div>
      <mat-card class="summary">
        <mat-card-title>Click Field to Edit</mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="label">Customer ID</div>
            <div class="form">
              <span class="value">{{ user._id }}</span>
            </div>
          </div>
          <div class="row">
            <div class="label">Credit Cards</div>
            <div class="form column">
              <p class="value" *ngFor="let commerceToken of user.tempClient.commerceTokens | reverse; let i = index ">
                <span *ngIf="i==0">Credit Card: {{getCardInfo(commerceToken)}}<br></span>
                <span *ngIf="i!==0">Old Credit Card: {{getCardInfo(commerceToken)}}<br></span>
                Name on card: {{commerceToken.billingAddress.firstName}} {{commerceToken.billingAddress.lastName}}<br>
                Address: {{getAddress(commerceToken)}}<br>
                Expiration: {{commerceToken.expiration.month}}/{{commerceToken.expiration.year % 100}}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="label">Customer Name</div>
            <div class="form">
              <ng-container *ngIf="!editSummary">
                <span class="value">{{user.draft.firstName}} {{user.draft.lastName}}</span>
                <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
              </ng-container>
              <div *ngIf="editSummary">
                <div>
                  <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                    <mat-label>First name</mat-label>
                    <input matInput type="text" [(ngModel)]="user.draft.firstName">
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                    <mat-label>Last name</mat-label>
                    <input matInput type="text" [(ngModel)]="user.draft.lastName">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="label">Email Address</div>
            <div class="form">
              <ng-container *ngIf="!editSummary">
                <span class="value">{{ user.draft.email }}</span>
                <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
              </ng-container>
              <ng-container *ngIf="editSummary">
                <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                  <mat-label>Email</mat-label>
                  <input matInput type="text" [(ngModel)]="user.draft.email">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="label">Phone Number</div>
            <div class="form">
              <ng-container *ngIf="!editSummary">
                <span *ngIf="user.draft.phone" class="value">{{user.draft.phone}}</span>
                <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
              </ng-container>
              <ng-container *ngIf="editSummary">
                <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                  <mat-label>Phone number</mat-label>
                  <input matInput type="text" maxlength="14" [value]="user.draft.phone"
                    (input)="formatInput($event.target.value)">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="label">Member Since</div>
            <div class="form">
              <ng-container>
                <span class="value" [ngClass]="{enlarged: editSummary}">{{ (formatTimestamp(user.createdTimestamp) |
                  date:timeFormat:custom.timezone) }}</span>
              </ng-container>
            </div>
          </div>
          <div class="row" *ngIf="adminFlag">
            <div class="label">Brand ID</div>
            <div class="form">
              <ng-container *ngIf="!editSummary">
                <span class="value">{{ user.draft.brandId }}</span>
                <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
              </ng-container>
              <ng-container *ngIf="editSummary">
                <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                  <mat-label>Brand ID</mat-label>
                  <input matInput type="text" [(ngModel)]="user.draft.brandId">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row" *ngIf="adminFlag">
            <div class="label">UXC</div>
            <div class="form">
              <ng-container *ngIf="!editSummary">
                <span class="value">{{ user.draft.uxcId }}</span>
                <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
              </ng-container>
              <ng-container *ngIf="editSummary">
                <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                  <mat-label>UXC ID</mat-label>
                  <input matInput type="text" [(ngModel)]="user.draft.uxcId">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row" *ngIf="adminFlag">
            <div class="label">UXL</div>
            <div class="form">
              <ng-container *ngIf="!editSummary">
                <span class="value">{{ user.draft.uxlId }}</span>
                <mat-icon (click)="editSummary = !editSummary" class="edit">edit</mat-icon>
              </ng-container>
              <ng-container *ngIf="editSummary">
                <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                  <mat-label>UXL ID</mat-label>
                  <input matInput type="text" [(ngModel)]="user.draft.uxlId">
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row">
            <div class="label">IP Address</div>
            <div class="form">
              <span class="value">{{ commerceToken?.ipAddress }}</span>
            </div>
          </div>
          <div class="row">
            <div class="label">Device</div>
            <div class="form">
              <span class="value">{{ user.connectionInfo?.device !== 'phone' ? 'Desktop' : 'Mobile' }}</span>
            </div>
          </div>
          <div class="row">
            <div class="label">Status</div>
            <div class="form">
              <span class="value">{{ user.status }}</span>
              <button (click)="
                    user.draft.status = user.status === 'active' ? 'banned' : 'active'; 
                    updateUser(user, (user.status === 'active' ? 'Ban' : 'Active') + ' this user. (' + user.firstName + ' ' + user.lastName + ')')
                  ">
                {{ user.status === 'active' ? 'Ban' : 'Active' }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="label">Sub Status</div>
            <div class="form">
              <span class="value">{{ user.subStatus }}</span>
            </div>
          </div>
          <div class="row" *ngIf="editSummary">
            <button mat-raised-button color="primary" class="mr-2" [disabled]="!user.isDiffDraft()"
              (click)="updateUser(user)">Save</button>
            <button mat-stroked-button color="primary" (click)="editSummary = !editSummary">Cancel</button>
          </div>
        </mat-card-content>
      </mat-card>
      <p class="customer-side-subtitle">Customer Activity</p>
      <div class="d-flex">
        <div class="ml-auto">
          <a mat-stroked-button color="primary"
            href="https://{{customerUxComposite.brandDomain}}{{user.tempClient.autoLoginLink}}" target="_blank"
            class="ml-2">
            Login
          </a>
        </div>
      </div>
      <mat-card class="activity">
        <div *ngIf="customerUxComposite?.uxConfigId" class="activity__uxcinfo">
          <strong>UXC:</strong> <span>{{customerUxComposite.uxConfigName}}</span><br>
          <strong>UXL:</strong> <span>{{customerUxComposite.uxLayoutName}}</span>
        </div>
        <div class="table-responsive">
          <table mat-table [dataSource]="commerceUserActivityDataSource" class="w-100">

            <ng-container matColumnDef="dateTime">
              <th mat-header-cell *matHeaderCellDef> Date/Time </th>
              <td mat-cell *matCellDef="let element">
                {{formatTimestamp(element.timestamp) | date:timeFormat:custom.timezone}} </td>
            </ng-container>

            <ng-container matColumnDef="report">
              <th mat-header-cell *matHeaderCellDef> Report </th>
              <td mat-cell *matCellDef="let element">
                <ng-template
                  [ngIf]="trackingDisplay[element.action] && trackingDisplay[element.action][element.keyName]"
                  [ngIfElse]="defaultTrackingDisplay">
                  {{trackingDisplay[element.action][element.keyName]}}
                </ng-template>
                <ng-template #defaultTrackingDisplay>
                  {{element.action}}{{element.action ? ', ' : ''}}{{element.keyName}}
                </ng-template>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="activityColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: activityColumns;"></tr>
          </table>
        </div>
      </mat-card>
      <mat-card class="activity">
        <h4>Saved Reports ({{savedReports.length}})</h4>
        <div class="activity__uxcinfo">
          <div *ngFor="let report of savedReports" class="enhanced-report">
            <ng-container *ngIf="report.tempClient?.enhancedPurchased">
              <img src="assets/premium.svg" alt="premium">
            </ng-container>
            <p>
              <ng-container *ngIf="report.lastViewedTimestamp">
                {{report.lastViewedTimestamp | date : 'YYYY-MM-dd hh:mm:ss' : 'EDT'}}&nbsp;
              </ng-container>
              <ng-container *ngIf="!report.lastViewedTimestamp">
                {{report.changedTimestamp | date : 'YYYY-MM-dd hh:mm:ss' : 'EDT'}}&nbsp;
              </ng-container>
              <strong>{{getShortTitleByCommerceContent(report)}}</strong>&nbsp;
              {{getLocation(report)}}
            </p>
          </div>
        </div>
      </mat-card>
      <mat-card class="activity">
        <h4>My Searches ({{searchInfo.length}})</h4>
        <div class="activity__uxcinfo">
          <div *ngFor="let search of searchInfo">
            {{search.createdTimestamp | date : 'YYYY-MM-dd hh:mm:ss' : 'EDT'}}
            <strong>
              <ng-container *ngIf="!search.contentInfo.id">{{ search.name }}&nbsp;</ng-container>
              <ng-container *ngIf="search.contentInfo.id">{{ search.contentInfo.firstName }}
                {{search.contentInfo.lastName}}&nbsp;</ng-container>
            </strong>
            <ng-container *ngIf="search.contentType === 'phoneSearch'">N/A</ng-container>
            <ng-container *ngIf="search.contentType !== 'phoneSearch'">
              {{ search.contentInfo.cityState }}&nbsp;
              <ng-container *ngIf="search.contentInfo.minAge">+ {{ search.contentInfo.minAge }} yrs old</ng-container>
              <ng-container *ngIf="search.contentInfo.maxAge">- {{ search.contentInfo.maxAge }} yrs old</ng-container>
            </ng-container>
          </div>
        </div>
      </mat-card>
    </mat-card>
  </div>
  <div class="customer-content">
    <mat-card>
      <div class="d-flex customer-content-communications-actions">
        <p class="customer-content-subtitle mb0">Customer Communications</p>
        <div class="ml-20">
          <!-- <button mat-raised-button color="primary" (click)="openAddCallModal()">Call</button>
          <button mat-raised-button color="primary" (click)="openAddEmailModal()">Send Email</button> -->
          <button mat-raised-button color="primary" (click)="openAddNoteModal()">Add Note</button>
        </div>
      </div>
      <mat-card class="communications">
        <mat-card-content>
          <div class="table-responsive custom-accordion">
            <div class="acc-header d-flex flex-row head plr">
              <span class="acc_th">Type</span>
              <span class="acc_th_md">Time/Date</span>
              <span class="acc_th"> From </span>
              <span class="acc_th">To</span>
              <span class="acc_th_lg">Message</span>
              <span class="acc_th ml-auto w-15">Action</span>
            </div>

            <cdk-accordion class="custom-accordion" style="padding-left: 15px; padding-right: 15px;">
              <cdk-accordion-item *ngFor="let element of notes; let index = index;" #accordionItem="cdkAccordionItem"
                class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
                [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
                <!-- <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                  {{ item.title }}
                  <span class="example-accordion-item-description">
                    Click to {{ accordionItem.expanded ? 'close' : 'open' }}
                  </span>
                </div> -->
                <div class="acc-header d-flex flex-row plr border_bottom">
                  <!-- Type Field -->
                  <span class="acc_th"> {{element.type || 'Note'}}</span>
                  <!-- Time Field -->
                  <span class="acc_td_md">{{formatTimestamp(element.createdTimestamp) |
                    date:timeFormat:custom.timezone}}</span>
                  <!-- From Field -->
                  <span class="acc_th"> {{customerUserInfo[element.updaterId]?.getFullName()}}</span>
                  <!-- To Field -->
                  <div class="acc_th">
                    <span *ngIf="!element.type"></span>
                    <span *ngIf="element.type == 'Post-Office'">{{user.draft.firstName}}</span>
                    <span *ngIf="element.type == 'Email'">{{user.email || "N/A"}}</span>
                    <span *ngIf="element.type == 'Call'">{{user.phone || "N/A"}}</span>
                  </div>
                  <!-- Message Field -->
                  <span class="acc_th_lg" [innerHTML]="ellipseNote(element.note)"></span>
                  <!-- Action Field -->
                  <span class="acc_th ml-auto w-15">
                    <button mat-raised-button color="primary" (click)="openViewNoteModal(element)">Edit</button>
                    <button mat-icon-button color="primary" (click)="accordionItem.toggle();">
                      <ng-container *ngIf="!accordionItem.expanded"><i class="material-icons">add_box</i>
                      </ng-container>
                      <ng-container *ngIf="accordionItem.expanded"><i class="material-icons">remove</i>
                      </ng-container>
                    </button>
                    <button mat-raised-button color="primary" (click)="appendToEmailBody(element)">Reply</button>
                  </span>
                </div>
                <div class="acc_body bg-lightest-gray" role="region"
                  [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                  [attr.aria-labelledby]="'accordion-header-' + index" [innerHTML]="element.note">
                </div>
              </cdk-accordion-item>
            </cdk-accordion>


          </div>
        </mat-card-content>
      </mat-card>


      <div class="d-flex customer-content-transactions-actions">
        <p class="customer-content-subtitle mb0">Customer Transactions</p>
        <div class="ml-20">
          <button mat-raised-button color="primary" *ngIf="cancellableIndex > -1" class="mb-1"
            (click)="cancelAll()">Cancel All</button>
          <button mat-raised-button color="primary" *ngIf="cancellableIndex === -1 && cancelledIndex > -1" class="mb-1"
            (click)="uncancelAll()">Uncancel All</button>
          <button *ngIf="commerceOffers?.length" mat-raised-button color="primary" class="mb-1 ml-20"
            (click)="showAddOfferModal()">Add Offer</button>
        </div>
      </div>
      <mat-card class="transactions">
        <mat-card-content>
          <div class="table-responsive">
            <table mat-table [dataSource]="commerceOrders" class="w-100" multiTemplateDataRows>
              <ng-container matColumnDef="expander">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button color="primary" (click)="toggleExpandAll()">
                    <ng-container *ngIf="!isAllExpanded()"><i class="material-icons">add_box</i></ng-container>
                    <ng-container *ngIf="isAllExpanded()"><i class="material-icons">remove</i></ng-container>
                  </button>
                </th>
                <td mat-cell *matCellDef="let commerceOrder">
                  <button mat-icon-button color="primary" (click)="toggleExpand(expandedOrderIds, commerceOrder)">
                    <ng-container *ngIf="!isExpanded(expandedOrderIds, commerceOrder)"><i
                        class="material-icons">add_box</i>
                    </ng-container>
                    <ng-container *ngIf="isExpanded(expandedOrderIds, commerceOrder)"><i
                        class="material-icons">remove</i>
                    </ng-container>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="orderId">
                <th mat-header-cell *matHeaderCellDef class="id"> Id </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong><span
                      (click)="toggleExpand(expandedOrderDebugIds, commerceOrder)">{{commerceOrder._id}}</span></strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="offerName">
                <th mat-header-cell *matHeaderCellDef class="id"> Offer Name </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong>{{commerceOrder.commerceOffer.name}}</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="dateTime">
                <th mat-header-cell *matHeaderCellDef class="id"> Capture Date </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong></strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef class="amount"> Collected </th>
                <td mat-cell *matCellDef="let commerceOrder" class="amount">
                  <strong>${{ commerceOrder.calculateValue().amount | number : '1.2-2' }}</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="refunded">
                <th mat-header-cell *matHeaderCellDef class="id"> Refunded </th>
                <td mat-cell *matCellDef="let commerceOrder" class="id">
                  <strong>{{ calculateRefundAmount(commerceOrder) | number : '1.2-2' }}</strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Sub-status | Date </th>
                <td mat-cell *matCellDef="let commerceOrder">
                  <strong>
                    {{formatTimestamp(commerceOrder.orderTimestamp) | date:timeFormat:custom.timezone}}&nbsp;
                    {{comereOrder?.status || 'Active'}}<br>
                    <ng-container *ngFor="let update of getUpdates(commerceOrder)" class="commerceOrderUpdate">
                      <ng-container
                        *ngIf="checkOrder(update, commerceOrder) && customerUserInfo[update.updaterId]?.getFullName()">
                        <ng-container *ngIf="commercePayment?.type != 'dispute'">
                        {{formatTimestamp(update.timestamp) | date:timeFormat: custom.timezone}}&nbsp;
                        {{formatSubStatus(update, commerceOrder.tempClient.updates)}}&nbsp;
                        {{customerUserInfo[update.updaterId]?.getFullName()}} | {{getMMDD(update.timestamp)}}
                        </ng-container>
                        <ng-container *ngIf="commercePayment?.type === 'dispute'">
                         <span clsss="dispute-text"> {{formatTimestamp(update.timestamp) | date:timeFormat: custom.timezone}}&nbsp;
                        {{formatSubStatus(update, commerceOrder.tempClient.updates)}}&nbsp;
                        {{customerUserInfo[update.updaterId]?.getFullName()}} | {{getMMDD(update.timestamp)}}
                        </span>
                        </ng-container>
                        <br>
                      </ng-container>
                    </ng-container>
                  </strong>
                </td>
              </ng-container>

              <ng-container matColumnDef="action" class="action">
                <th mat-header-cell *matHeaderCellDef class="action"> Action </th>
                <td mat-cell *matCellDef="let commerceOrder">
                  <ng-container *ngIf="isModifiable(commerceOrder)">
                    <button mat-raised-button color="primary" class="w-100 mb-1" *ngIf="commerceOrder.isCancellable()"
                      (click)="cancel(commerceOrder)">Cancel</button>
                    <button mat-raised-button color="primary" class="w-100 mb-1" *ngIf="commerceOrder.isCancelled()"
                      (click)="uncancel(commerceOrder)">Reactivate</button>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let commerceOrder" colspan="9"
                  [class.inactive]="!isExpanded(expandedOrderIds, commerceOrder)" class="expanded-summary">
                  <div class="payment-detail"
                    [@detailExpand]="isExpanded(expandedOrderIds, commerceOrder) ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="commerceOrder.getCommercePayments()" class="w-100 suborder"
                      multiTemplateDataRows>
                      <ng-container matColumnDef="expander">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let commercePayment">
                          <button mat-icon-button color="primary" style="visibility: hidden;">
                            <i class="material-icons">add_box</i>
                          </button>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="orderId">
                        <th mat-header-cell *matHeaderCellDef class="id"> Id </th>
                        <td mat-cell *matCellDef="let commercePayment" class="id">
                          <ng-container *ngIf="commercePayment?.type != 'dispute'">
                          <span
                            (click)="toggleExpand(expandedPaymentIds, commercePayment)">{{commercePayment._id}}</span>
                          </ng-container>
                          <ng-container *ngIf="commercePayment?.type === 'dispute'">
                            <span class="dispute-text"
                              (click)="toggleExpand(expandedPaymentIds, commercePayment)">{{commercePayment._id}}</span>
                            </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="offerName">
                        <th mat-header-cell *matHeaderCellDef class="id"> Offer Name </th>
                        <td mat-cell *matCellDef="let commercePayment" class="id">
                          <ng-container *ngIf="commercePayment?.type != 'dispute'">
                           {{commerceOrder.commerceOffer.name}} 
                          </ng-container>
                          <ng-container *ngIf="commercePayment?.type === 'dispute'">
                          <span class="dispute-text">{{commerceOrder.commerceOffer.name}}</span> 
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="dateTime">
                        <th mat-header-cell *matHeaderCellDef class="id"> Offer Date </th>
                        <td mat-cell *matCellDef="let commercePayment" class="id">
                          <span class="dispute-text">{{formatTimestamp(commercePayment.paymentTimestamp) |
                            date:timeFormat:custom.timezone}}</span><br />
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef class="amount"> Billed </th>
                        <td mat-cell *matCellDef="let commercePayment" class="amount">
                          {{ commercePayment?.type != 'dispute' ? (commercePayment.price.amount > 0 ? '$' +
                          (commercePayment.price.amount | number : '1.2-2')  : '') : ''  }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="refunded">
                        <th mat-header-cell *matHeaderCellDef class="id"> Refunded </th>
                        <td mat-cell *matCellDef="let commercePayment" class="id">
                          <ng-container *ngIf="commercePayment?.type != 'dispute'">
                         {{ commercePayment.price.amount < 0 ? '-$' +-commercePayment.price.amount : ''  }}
                          </ng-container>
                          <ng-container *ngIf="commercePayment?.type === 'dispute'">
                            <span class="dispute-text">Dispute Request</span>
                            <p><span class="dispute-text dispute-bold">{{commercePayment.parentId}}</span></p>
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let commercePayment">
                          <ng-container *ngIf="commercePayment?.type != 'dispute'">
                          <span>{{formatTimestamp(commercePayment.paymentTimestamp) |
                            date:timeFormat:custom.timezone}}</span><br />
                          </ng-container>
                          <ng-container *ngIf="commercePayment?.type === 'dispute'">
                            <span class="dispute-text">{{formatTimestamp(commercePayment.paymentTimestamp) |
                              date:timeFormat:custom.timezone}}</span><br />
                          </ng-container>  
                          <ng-container *ngIf="commercePayment.status === 'fulfilled'">        
                            <ng-container *ngIf="commercePayment?.type != 'dispute'">
                            {{commercePayment.status}}
                            {{customerUserInfo[commercePayment.updaterId]?.getFullName()}}
                            </ng-container>                    
                            <ng-container *ngIf="commercePayment?.type != 'dispute'">
                              <span class="dispute-text">
                                {{commercePayment.status}}
                            {{customerUserInfo[commercePayment.updaterId]?.getFullName()}}
                              </span>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="commercePayment.status !== 'fulfilled'">
                            {{ commercePayment?.paymentInfo?.params?.responsetext }}
                            <br />
                            {{ commercePayment?.paymentInfo?.params?.processor_id }}
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="action" class="action">
                        <th mat-header-cell *matHeaderCellDef class="action"> Action </th>
                        <td mat-cell *matCellDef="let commercePayment">
                          <ng-container *ngIf="commercePayment.isAdjustable()">
                            <button mat-raised-button color="primary" class="w-100 mb-1"
                              (click)="openRefundPaymentModal(commerceOrder, commercePayment, 'refund', $event)">Refund</button>
                            <button mat-raised-button color="primary" class="w-100 mb-1"
                              *ngIf="isVoidable(commercePayment)"
                              (click)="openRefundPaymentModal(commerceOrder, commercePayment, 'void', $event)">Void</button>
                          </ng-container>
                          <ng-container *ngIf="commercePayment?.type == 'dispute'">
                              <button mat-raised-button color="primary" class="w-100 mb-1"
                              *ngIf="commercePayment?.type == 'dispute'"
                              (click)="openChargebackModal(commerceOrder, commercePayment, 'chargeback', $event)">Represent</button>  
                          </ng-container>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let commercePayment" colspan="9"
                          [class.inactive]="!isExpanded(expandedPaymentIds, commercePayment)" class="expanded-summary">
                          <div class="payment-detail"
                            [@detailExpand]="isExpanded(expandedPaymentIds, commercePayment) ? 'expanded' : 'collapsed'">
                            <app-transaction-detail [commerceOrder]="commerceOrder" [commercePayment]="commercePayment"
                              [userInfo]="customerUserInfo" (updateCommerceOrder)="updateCommerceOrder($event)"
                              [uxComposite]="customerUxComposite" [isOrder]="false">
                            </app-transaction-detail>
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="paymentColumns"></tr>
                      <tr mat-row *matRowDef="let commercePayment; columns: paymentColumns;" class="payment-row"
                        [ngClass]="getPaymentClasses(commercePayment)"
                        [class.payment-expanded-row]="isExpanded(expandedPaymentIds, commercePayment)"></tr>
                      <tr mat-row *matRowDef="let commercePayment; columns: ['expandedDetail']"
                        class="payment-detail-row {{isExpanded(expandedPaymentIds, commercePayment) ? 'expanded' : ''}}">
                      </tr>
                    </table>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDebug">
                <td mat-cell *matCellDef="let commerceOrder" colspan="9"
                  [class.inactive]="!isExpanded(expandedOrderDebugIds, commerceOrder)" class="expanded-summary">
                  <div class="payment-detail"
                    [@detailExpand]="isExpanded(expandedOrderDebugIds, commerceOrder) ? 'expanded' : 'collapsed'">
                    <app-transaction-detail [commerceOrder]="commerceOrder"
                      [commercePayment]="findOrderPayment(commerceOrder)" [userInfo]="customerUserInfo"
                      (updateCommerceOrder)="updateCommerceOrder($event)" [uxComposite]="customerUxComposite"
                      [isOrder]="true">
                    </app-transaction-detail>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="paymentColumns"></tr>
              <tr mat-row *matRowDef="let commerceOrder; columns: paymentColumns;" class="payment-row"
                [ngClass]="getClasses(commerceOrder)"
                [class.payment-expanded-row]="isExpanded(expandedOrderIds, commerceOrder)"></tr>
              <tr mat-row *matRowDef="let commerceOrder; columns: ['expandedDebug']"
                class="payment-detail-row {{isExpanded(expandedOrderDebugIds, commerceOrder) ? 'expanded' : ''}}"></tr>
              <tr mat-row *matRowDef="let commerceOrder; columns: ['expandedDetail']"
                class="payment-detail-row {{isExpanded(expandedOrderIds, commerceOrder) ? 'expanded' : ''}}"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card class="common-mat-card">
        <ng-container *ngIf="adminUxComposite">
          <app-csr-action-recommender [from]="customerUxComposite?.getUxcomp('comp.brand.customer.email')" [user]="user"
            [uxComposite]="adminUxComposite" [baseCommerceOrder]="baseCommerceOrder"
            [agentAddress]="customerUxComposite.getUxcomp('comp.brand.address.twoline')"
            [agentPhoneNumber]="customerUxComposite.getUxcomp('comp.brand.customer.phone')"
            [warningMessage]="adminUxComposite.getUxcomp('comp.brand.customer.warning.message')"
            [brandName]="user.draft.brandId" [noteData]="noteData" (onSuccess)="init(userId);">
          </app-csr-action-recommender>
        </ng-container>
      </mat-card>

      <mat-card class="common-mat-card">
        <div class="d-flex customer-content-schedules-actions">
          <p class="customer-content-subtitle mb0">Billing Schedules</p>
          <div class="ml-auto">
            <button mat-stroked-button color="primary" (click)="detailedSchedules = true" *ngIf="!detailedSchedules">
              <mat-icon>unfold_more</mat-icon> More Details
            </button>
            <button mat-stroked-button color="primary" (click)="detailedSchedules = false" *ngIf="detailedSchedules">
              <mat-icon>unfold_less</mat-icon> Fewer Details
            </button>
          </div>
        </div>
      </mat-card>
      <mat-card class="schedules">
        <mat-card-content>
          <div class="table-responsive">
            <table mat-table [dataSource]="filterCommerceSchedules()" class="w-100">

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef class="saleType"> Type </th>
                <td mat-cell *matCellDef="let element" class="saleType">
                  {{element?.scheduleData?.type === paymentTypes.refund ? "Refund" : "Sale"}}</td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="id"> ID / Order ID </th>
                <td mat-cell *matCellDef="let element" class="id">{{element._id}}<br />{{element.commerceOrderId}}</td>
              </ng-container>

              <ng-container matColumnDef="updater">
                <th mat-header-cell *matHeaderCellDef> Updater </th>
                <td mat-cell *matCellDef="let element">{{customerUserInfo[element.updaterId]?.getFullName()}}</td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef class="amount"> Amount </th>
                <td mat-cell *matCellDef="let element" class="amount">
                  <mat-form-field class="w-100 amount" appearance="outline" floatLabel="always">
                    <mat-label>Amount</mat-label>
                    <input matInput type="number" [placeholder]="'$'+element.scheduleData.amount"
                      [(ngModel)]="element.draft.scheduleData.amount">
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="scheduleDate">
                <th mat-header-cell *matHeaderCellDef> Schedule Date </th>
                <td mat-cell *matCellDef="let element">
                  <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                    <mat-label>Schedule Date</mat-label>
                    <input matInput [matDatepicker]="picker"
                      [placeholder]="(formatTimestamp(element.dueTimestamp) | date:timeFormat:custom.timezone) + ' ' +custom.timezone"
                      [min]="minDate" [max]="maxDate" 
                      [selected]="formatTimestamp(element.draft.dueTimestamp)"
                      (dateChange)="reflectCommerceScheduleDate($event.value,element)">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action"> Action </th>
                <td mat-cell *matCellDef="let element" class="action">
                  <button mat-raised-button color="primary" [disabled]="!isValidUpdateCommerceSchedule(element)"
                    (click)="updateCommerceSchedule(element)">Update</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="scheduleColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: scheduleColumns;"></tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card>
  </div>
</div>
