import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {JsonService} from '../../clientCommon/services/json.service';
import {User} from '../../common/models/user/user';
import {ResponseEvent} from '../../common/event/responseEvent';
import {ActivatedRoute} from '@angular/router';
import {ModelBase} from '../../common/models/modelBase';
import {CommerceOrder} from '../../common/models/commerce/commerceOrder';
import {CommerceSchedule} from '../../common/models/commerce/commerceSchedule';
import {commerceUrls} from '../../common/models/commerce/commerceEvent';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {ArrayUtils} from '../../common/utils/arrayUtils';
import {CommercePrice} from '../../common/models/commerce/commercePrice';
import {CommerceTransaction} from '../../common/models/commerce/commerceTransaction';
import {CommercePayment} from '../../common/models/commerce/commercePayment';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {CommerceToken} from '../../common/models/commerce/commerceToken';
import {isNumber} from 'util';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {UxComposite} from '../../common/models/ux/uxComposite';
import {UxHelper} from '../../clientCommon/helper/uxHelper';
import {CommerceService} from '../../clientCommon/services/commerce.service';
import {CreditCardInputHelper} from '../../clientCommon/helper/creditCardInputHelper';
import {Note} from '../../common/models/note';
import {AdminAuthGuard, CsrAuthGuard} from '../guards';
import {objectUtils} from '../../common/utils/objectUtils';
import {custom} from '../custom/custom.class';
import {LogUtils} from '../../common/utils/logUtils';
import * as moment from "moment";

@Component({
  templateUrl: './customerv1.component.html'
})

export class CustomerComponentV1 implements OnInit {
  paymentTypes = CommercePayment.TYPE;
  serverPaths = serverPaths;
  userId: string;
  user: User;
  uxHelper: UxHelper = new UxHelper();
  uxComposite: UxComposite;
  commerceOrders: CommerceOrder[] = [];
  commerceSchedules: CommerceSchedule[] = [];
  commerceToken: CommerceToken;
  notes: Note[] = [];
  minDate = new Date();
  maxDate = new Date(new Date().getTime() + 31536000000); // 1 Year from now
  periodUnits;
  userInfo: { [userId: string]: User } = {};
  mailTrackings: any = {};
  commerceOrderMailTrackings = {};
  custom = custom;
  timeFormat = 'yyyy-MM-dd hh:mm:ss aa';
  adminFlag = false;

  hash;
  creditCardInputHelper = new CreditCardInputHelper();

  newNote: Note;
  alertDuration = 5000;

  trackingDisplay: any = {};
  advanced = false;
  systemUsername = 'System';
  initDone = false;

  details = {
    avsresponse: {
      X: 'Street Address and 9 Digit Zip Exact Match',
      Y: 'Street Address and 5 Digit Zip Exact Match',
      M: 'Street Address and 5 Digit Zip Exact Match',
      A: 'Street Address Exact Match Only, No Zip Match',
      W: 'No Street Address Match, 9 Digit Zip Exact Match Only',
      Z: 'No Street Address Match, 5 Digit Zip Exact Match Only',
      N: 'No Street Address Match, No Zip Match',
      U: 'AVS System Unavailable',
      R: 'Issuer AVS System Unavailable',
      E: 'AVS Data Invalid Error',
      S: 'AVS Not Supported',
      B: 'AVS Not Available',
      O: 'AVS Not Available',
      0: 'AVS Not Available',
      D: 'International - Street Address and Zip Exact Match',
      P: 'International - No Street Address Match, Zip Exact Match Only',
      L: 'International - No Street Address Match, Zip Exact Match Only',
      C: 'International - No Address or ZIP Match',
      G: 'International - International Issuer System Unavailable',
      I: 'International -Address Information Not Verified by Issuer',
      F: 'United Kingdom Only -Street Address and Zip Exact Match',
    },
    cvvresponse: {
      M: 'CVV2/CVC2 Match',
      N: 'CVV2/CVC2 No Match',
      P: 'Not Processed',
      S: 'Merchant has indicated that CVV2/CVC2 is not present on card',
      U: 'Issuer is not certified and/or has not provided Visa encryption keys',
      X: 'No Response From Issuer – Issuer Error',
    }
  };

  constructor(private jsonService: JsonService,
              private spinnerService: SpinnerService,
              private adminAuthGuard: AdminAuthGuard,
              private csrAuthGuard: CsrAuthGuard,
              private commerceService: CommerceService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) {

    this.uxHelper.prefix = ``;
    this.periodUnits = ArrayUtils.toArray(CommercePrice.UNIT);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.userId;
      return this.init(this.userId);
    });
  }

  init(userId) {
    this.adminFlag = this.adminAuthGuard.hasAdminRole();
    this.spinnerService.spin();

    this.newNote = new Note();
    this.hash = this.commerceService.createHash();
    this.jsonService.json(commerceUrls.csrFindCustomer + '/' + userId, {}).then((responseEvent: ResponseEvent) => {
      this.commerceOrders = [];
      this.commerceSchedules = [];
      this.uxHelper.uxComposite = null;
      this.notes = [];
      this.uxComposite = null;

      const docs = responseEvent.getDocs();
      LogUtils.info(docs);
      docs.forEach((obj: ModelBase<any>) => {
        if (obj instanceof User) {
          this.user = obj;
        } else if (obj instanceof CommerceOrder) {
          if (!obj.isTrivialOrder()) {
            obj.toggle('show');
          }
          if (obj.commerce3ds) {
            delete obj.commerce3ds.draft;
            delete obj.commerce3ds.appliedCommercePayments;
          }
          this.addProcessCounts(obj);
          this.commerceOrders.push(obj);
        } else if (obj instanceof CommerceSchedule) {
          this.commerceSchedules.push(obj);
        } else if (obj instanceof UxComposite) {
          this.processUxComposite(obj);
        } else if (obj instanceof CommerceToken) {
          this.commerceToken = obj;
        } else if (obj instanceof Note) {
          this.notes.push(obj);
        } else {
          LogUtils.error('Wrong doc', obj, obj instanceof UxComposite);
        }
      });

      if (this.uxComposite && this.commerceOrders && this.commerceOrders.length) {
        this.commerceOrders.forEach((commerceOrder) => {
          this.processCommerceOrder(this.uxComposite, commerceOrder);
        });
      }

      this.commerceOrders.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });
      this.notes.sort((a, b) => {
        return b.createdTimestamp - a.createdTimestamp;
      });

      if (responseEvent.data.userInfo) {
        Object.keys(responseEvent.data.userInfo).forEach((key) => {
          this.userInfo[key] = new User(responseEvent.data.userInfo[key]);
        });
      }
      this.mailTrackings = responseEvent.data.mailTrackings;
      this.commerceOrderMailTrackings = this.getCommerceOrderTrackings(this.mailTrackings);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.initDone = true;
      this.spinnerService.unspin();
    });
  }

  reflectCommerceScheduleDate(date: Date, commerceSchedule: CommerceSchedule) {
    commerceSchedule.draft.dueTimestamp = date.getTime();
  }


  isValidUpdateCommerceSchedule(commerceSchedule: CommerceSchedule) {
    const dueDate = new Date(commerceSchedule.dueTimestamp);
    const draftDueDate = new Date(commerceSchedule.draft.dueTimestamp);

    if (!isNumber(commerceSchedule.draft.scheduleData.amount) || !isNumber(commerceSchedule.draft.dueTimestamp)) {
      return false;
    }

    return (commerceSchedule.scheduleData.amount !== commerceSchedule.draft.scheduleData.amount) ||
      (!(
        (dueDate.getFullYear() === draftDueDate.getFullYear()) &&
        (dueDate.getMonth() === draftDueDate.getMonth()) &&
        (dueDate.getDate() === draftDueDate.getDate())
      ));
  }

  isValidUpdateCommerceOrder(commerceOrder: CommerceOrder) {
    return commerceOrder.isDiffDraft();
  }

  updateUser(user: User) {
    const obj = user.getSaveSet();
    obj._id = user._id;
    const input = {users: [obj]};

    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrUpdateCustomer, input).then((responseEvent: ResponseEvent) => {
      this.init(this.userId);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  updateCommerce(commerceObj: ModelBase<any>) {
    const obj = commerceObj.getSaveSet();
    obj._id = commerceObj._id;
    const input: any = {};
    if (commerceObj instanceof CommerceSchedule) {
      input.commerceSchedules = [obj];
    } else if (commerceObj instanceof CommerceOrder) {
      input.commerceOrders = [obj];
    } else if (commerceObj instanceof CommerceToken) {
      input.commerceTokens = [obj];
    } else {
      LogUtils.error('wrong obj', commerceObj);
      return;
    }

    this.spinnerService.spin();
    this.jsonService.json(commerceUrls.csrUpdate, input).then((responseEvent: ResponseEvent) => {
      this.init(this.userId);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }


  updateCommerceSchedule(commerceSchedule: CommerceSchedule) {
    commerceSchedule.draft.scheduleData.amount = Math.abs(commerceSchedule.draft.scheduleData.amount);
    if (commerceSchedule.draft.scheduleData.type === CommercePayment.TYPE.refund) {
      commerceSchedule.draft.scheduleData.amount *= -1;
    }
    this.updateCommerce(commerceSchedule);
  }

  updateCommerceOrder(commerceOrder: CommerceOrder) {
    this.updateCommerce(commerceOrder);
  }

  convertAdjustAmount(amount) {
    return -1 * Math.abs(amount);
  }

  isAdjustable(commercePayment: CommercePayment, amount) {
    return amount && commercePayment.isAdjustable({code: commercePayment.price.code, amount: this.convertAdjustAmount(amount)});
  }

  isVoidable(commercePayment: CommercePayment): boolean {
    return commercePayment.isVoidable();
  }

  refund(commerceOrder: CommerceOrder, commerceTransaction: CommerceTransaction, commercePayment: CommercePayment, amount) {
    const message = `Refund of $${amount}?`;
    if (confirm(message)) {
      return this.adjustPayment(CommercePayment.TYPE.refund, commerceOrder, commerceTransaction, commercePayment, amount);
    }
  }

  void(commerceOrder: CommerceOrder, commerceTransaction: CommerceTransaction, commercePayment: CommercePayment) {
    const message = `Full refund of $${commercePayment.calculateRemainingValue().amount}?`;
    if (confirm(message)) {
      let type = CommercePayment.TYPE.void;
      if (commercePayment.adjustment && commercePayment.adjustment.amount < 0) {
        type = CommercePayment.TYPE.refund;
      }
      return this.adjustPayment(type, commerceOrder, commerceTransaction, commercePayment, commercePayment.calculateRemainingValue().amount);
    }
  }

  adjustPayment(type: string, commerceOrder: CommerceOrder, commerceTransaction: CommerceTransaction, commercePayment: CommercePayment, amount) {
    const input: any = {};
    input.adjustment = {code: commercePayment.price.code, amount: this.convertAdjustAmount(amount)};
    input.type = type;
    input.commerceOrderId = commerceOrder._id;
    input.commercePaymentId = commercePayment._id;
    input.commerceOrderRevisionId = commerceOrder.currentModelRevisionId;
    input.commercePaymentRevisionId = commercePayment.currentModelRevisionId;
    this.spinnerService.spin();
    this.jsonService.json(commerceUrls.csrAdjustment, input).then((responseEvent: ResponseEvent) => {
    }).catch((e) => {
      LogUtils.error(e);
      window.alert('Refund/Void error');
    }).then(() => {
      return this.cancelChildren(commerceOrder, true);
    }).then(() => {
      this.init(this.userId);
      this.spinnerService.unspin();
    });
  }

  codeOutput(obj) {
    return JSON.stringify(obj, null, 4);
  }

  getClasses(obj) {
    const def = collectionClassHelper.getCollectionName(obj);
    return `${def} ${obj.status} ${obj.subStatus}`;
  }

  isUpdatableCreditCard() {
    return !!this.uxComposite.get(this.commerceService.getUpdateCreditCardOfferRuleUxcompKey());
  }

  updateCreditCard() {
    this.spinnerService.spin();
    return this.commerceService.updateCreditCard(this.hash, this.user, this.uxComposite, this.creditCardInputHelper).catch((e) => {
      LogUtils.error(e);
    }).then((result) => {
      this.init(this.userId);
    });
  }

  addNote() {
    this.newNote.draft.referenceCollection = collectionClassHelper.getCollectionName(User);
    this.newNote.draft.referenceId = this.userId;
    const input = {notes: [this.newNote]};
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrSaveNotes, input).then((responseEvent: ResponseEvent) => {
      this.snackBar.open('Note', 'Add', {duration: this.alertDuration});
      this.init(this.userId);
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  clearPassword(user) {
    if (user) {
      const input = {userId: user._id};
      this.spinnerService.spin();
      this.jsonService.json(serverPaths.manageCsrClearPassword, input).then((responseEvent: ResponseEvent) => {
        this.snackBar.open('Done', 'Clear Password', {duration: this.alertDuration});
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        this.spinnerService.unspin();
      });
    }
  }

  cancel(commerceOrder: CommerceOrder, recursive = true) {
    if (commerceOrder.isCancellable()) {
      this.spinnerService.spin();
      this.commerceService.request(serverPaths.manageCsrOrderCancel, {
        userId: this.user._id,
        orderIds: [commerceOrder._id]
      }).then((responseEvent: ResponseEvent) => {
        LogUtils.debug(responseEvent);
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        return this.cancelChildren(commerceOrder, recursive);
      }).then(() => {
        return this.init(this.userId);
      });
    }
  }

  cancelChildren(commerceOrder: CommerceOrder, recursive = true) {
    return Promise.resolve().then(() => {
      const promises = [];
      if (recursive) {
        this.commerceOrders.forEach((childCommerceOrder) => {
          if (childCommerceOrder.parentId === commerceOrder._id) {
            promises.push(this.cancel(childCommerceOrder, recursive));
          }
        });
      }
      return Promise.all(promises);
    });
  }


  uncancel(commerceOrder: CommerceOrder) {
    if (commerceOrder.isCancelled()) {
      this.spinnerService.spin();
      this.commerceService.request(serverPaths.manageCsrOrderUncancel, {
        userId: this.user._id,
        orderIds: [commerceOrder._id]
      }).then((responseEvent: ResponseEvent) => {
        LogUtils.debug(responseEvent);
        // do nothing
      }).catch((e) => {
        LogUtils.error(e);
      }).then(() => {
        return this.init(this.userId);
      });
    }
  }

  isModifiable(commerceOrder: CommerceOrder) {
    let flag = true;

    if (!(commerceOrder.isCancelled() || commerceOrder.isCancellable())) {
      flag = false;
    }

    if (flag) {
      let schedule: CommerceSchedule;
      this.commerceSchedules.some((commerceSchedule) => {
        if (commerceSchedule.commerceOrderId === commerceOrder._id) {
          schedule = commerceSchedule;
          return true;
        }
      });

      if (schedule) {
        flag = !schedule.isExpire();
      }
    }

    return flag;
  }

  isProtectedCommercePayment(commerceOrder: CommerceOrder, commercePayment: CommercePayment) {
    if (commerceOrder.commerce3ds && commerceOrder.commerce3ds.appliedCommercePaymentIds.indexOf(commercePayment._id) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  getCommerceOrderTrackings(mailTracking) {
    const commerceOrderMailTrackings = {};
    if (objectUtils.isObject(mailTracking)) {
      Object.keys(mailTracking).forEach((key) => {
        if (mailTracking[key].hooks &&
          mailTracking[key].hooks[0] &&
          mailTracking[key].hooks[0].param &&
          mailTracking[key].hooks[0].param.collections &&
          mailTracking[key].hooks[0].param.collections.commerceOrders &&
          mailTracking[key].hooks[0].param.collections.commerceOrders.length) {
          mailTracking[key].hooks[0].param.collections.commerceOrders.forEach((commerceOrderId) => {
            const actions = [];
            Object.keys(mailTracking[key].actions).forEach((actionsKey) => {
              mailTracking[key].actions[actionsKey].forEach((action) => {
                actions.push({
                  action: actionsKey,
                  timestamp: action.timestamp,
                  keyName: mailTracking[key].keyName,
                });
              });
            });
            commerceOrderMailTrackings[commerceOrderId] = actions;
          });
        }
      });
    }
    return commerceOrderMailTrackings;
  }

  processUxComposite(uxComposite: UxComposite) {
    this.uxComposite = uxComposite;
    this.uxHelper.uxComposite = uxComposite;
    this.trackingDisplay = uxComposite.get('comp.admin.csr.customer.tracking.display');
  }

  formatTimestamp(timestamp) {
    if (!timestamp) {
      timestamp = 0;
    }
    return moment(timestamp).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss")
  }

  processCommerceOrder(uxComposite: UxComposite, commerceOrder: CommerceOrder) {
    if (commerceOrder) {
      if (commerceOrder.commerceOfferRuleKey) {
        const rulesExplained = [];
        Object.keys(commerceOrder.commerceOfferRuleKey).forEach((key) => {
          const rule = commerceOrder.commerceOfferRuleKey[key];
          let value = '';
          if (key.startsWith('email')) {
            const emailKey = 'comp.email.campaign.' + rule;
            Object.keys(uxComposite.ids).forEach((id) => {
              if (id.startsWith(emailKey + '.')) {
                if (value) {
                  value += '|';
                }
                value += uxComposite.ids[id];
              }
            });
            value = emailKey + ' ' + value + ' ';
          } else {
            value = uxComposite.get(rule);
          }

          rulesExplained.push({
            type: key,
            key: rule,
            rule: value,
          });
        });
        commerceOrder.tempClient.commerceOfferRuleExplained = rulesExplained;
      }

      const commercePayments = commerceOrder.getCommercePayments();
      if (commercePayments && commercePayments.length && this.commerceSchedules && this.commerceSchedules.length) {
        commercePayments.forEach((commercePayment) => {
          this.commerceSchedules.forEach((commerceSchedule) => {
            if (commerceSchedule.scheduleData.failedCommercePaymentId === commercePayment._id ||
              commerceSchedule.scheduleData.parentCommercePaymentId === commercePayment._id) {
              let reason = 'parent';
              if (commerceSchedule.scheduleData.failedCommercePaymentId === commercePayment._id) {
                reason = 'failed';
              }
              commercePayment.tempClient.delayRefund = {
                reason: reason,
                commerceScheduleId: commerceSchedule._id,
                amount: Math.abs(commerceSchedule.scheduleData.amount),
                dueTimestamp: commerceSchedule.dueTimestamp,
              };
            }
          });
        });
      }
    }
  }

  addProcessCounts(commerceOrder: CommerceOrder) {
    if (commerceOrder && commerceOrder.tempClientSecured && commerceOrder.tempClientSecured.processes) {
      commerceOrder.tempClientSecured.processCounts = [];
      Object.keys(commerceOrder.tempClientSecured.processes).forEach((productKey) => {
        const process = commerceOrder.tempClientSecured.processes[productKey];
        const processInfo = {
          productKey: productKey,
          quantity: process.quantity,
          process: process.process,
        };
        commerceOrder.tempClientSecured.processCounts.push(processInfo);
        LogUtils.info(commerceOrder._id, commerceOrder.commerceOffer.name, commerceOrder.commerceOffer.description, processInfo);
      });
    }
  }
}
