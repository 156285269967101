<div class="contentmgmt">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Content Management
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="row">
        <div class="col">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input new Name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newContent.draft.name">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Description</span>
            <i class="material-icons" matTooltip="Input new Description here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput placeholder="Name" [(ngModel)]="newContent.draft.description">
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Options</span>
            <i class="material-icons" matTooltip="Input new Options here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <textarea rows="5" matInput placeholder="options" [ngModel]="newContent.draft.tempClient.optionsString" (input)="processContentOptionsEvent($event, newContent.draft)"></textarea>
          </mat-form-field>
        </div>
        <div class="col">
          <div class="tooltip-label">
            <span>Content</span>
            <i class="material-icons" matTooltip="Input new Content here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <textarea rows="5" matInput placeholder="Content" [(ngModel)]="newContent.draft.content"></textarea>
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" [disabled]="(!newContent.draft.name) || (!newContent.draft.content) || (newContent.isActive())" (click)="addContent()">Add</button>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="row">
        <div class="col id">
          <div class="tooltip-label">
            <span>Name</span>
            <i class="material-icons" matTooltip="Input Name here." matTooltipPosition="after">help</i>
          </div>
          <mat-form-field appearance="outline">
            <input matInput (keydown)="keyDownEvent($event)" placeholder="Name" [(ngModel)]="searchOptions.filters.contents.value">
          </mat-form-field>
        </div>
        <div class="col action">
          <button mat-raised-button color="primary" (click)="search()">Search</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="searched" *ngIf="results.contents?.length">
    <div class="searched-title">Content</div>
    <div class="searched-content" *ngFor="let content of results.contents">
      <div class="row" *ngIf="!content.tempClient.edit">
        <div class="info">
          <div class="info-section copy" (click)="copyId(content)"><label>Content</label><span>{{content._id}}</span></div>
          <div class="info-section copy" (click)="copyName(content)"><label>Name</label><span>{{content.name}}</span></div>
          <div class="info-section"><label>Desc</label><span>{{content.description}}</span></div>
        </div>
        <div class="col">
          <div class="row mb-1">
            <div class="col primary">Options</div>
            <div class="col primary">Content</div>
          </div>
          <div class="row mb-1">
            <div class="col">{{ content.draft.tempClient.optionsString }}</div>
            <div class="col">{{ content.draft.content }}</div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="content.tempClient.edit">
        <div class="info">
          <div class="info-section form">
            <label (click)="copyId(content)" class="copy">Content</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Content</mat-label>
                <input matInput placeholder="ID" [disabled]="true" [(ngModel)]="content._id">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section form">
            <label (click)="copyName(content)" class="copy">Name</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" [(ngModel)]="content.draft.name">
              </mat-form-field>
            </span>
          </div>
          <div class="info-section form">
            <label>Desc</label>
            <span>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Desc</mat-label>
                <input matInput placeholder="Desc" [(ngModel)]="content.draft.description">
              </mat-form-field>
            </span>
          </div>
        </div>
        <div class="col">
          <div class="row mb-1">
            <div class="col primary">Options</div>
            <div class="col primary">Content</div>
          </div>
          <div class="row mb-1">
            <div class="col">
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>Options</mat-label>
                <textarea rows="5" matInput placeholder="options" [ngModel]="content.draft.tempClient.optionsString" (input)="processContentOptionsEvent($event, content.draft)"></textarea>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>Content</mat-label>
                <input matInput placeholder="Content" [(ngModel)]="content.draft.content">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <ng-container *ngIf="!content.tempClient.edit">
          <button mat-raised-button color="primary" (click)="content.tempClient.edit = true" class="mr-2">Edit</button>
        </ng-container>
        <ng-container *ngIf="content.tempClient.edit">
          <button mat-raised-button color="primary" (click)="update(content)" [disabled]="!content.isDiffDraft()">Save</button>
        </ng-container>
      </div>
    </div>
  </div>
</div>