<p class="customer-side-subtitle">Customer Activity</p>

<div class="d-flex">
    <div class="ml-auto">
      <ng-template [ngIf]="user.status === 'banned'">
        <a mat-stroked-button color="primary"
            
            class="ml-2">
            ban user
        </a>
      </ng-template>
      <ng-template [ngIf]="user.status === 'active'">
        <a mat-stroked-button color="primary"
            href="https://{{customerUxComposite.brandDomain}}{{user.tempClient.autoLoginLink}}" target="_blank"
            class="ml-2">
            Login
        </a>
      </ng-template>  
    </div>
</div>

<mat-card class="activity">
    <div *ngIf="customerUxComposite?.uxConfigId" class="activity__uxcinfo">
        <strong>UXC:</strong> <span>{{customerUxComposite.uxConfigName}}</span><br>
        <strong>UXL:</strong> <span>{{customerUxComposite.uxLayoutName}}</span>
    </div>
    <div class="table-responsive">
        <table mat-table [dataSource]="commerceUserActivityDataSource" class="w-100">

            <ng-container matColumnDef="dateTime">
              <th mat-header-cell *matHeaderCellDef> Date/Time </th>
              <td mat-cell *matCellDef="let element">
                {{(element.timestamp | formatTimestamp) | date:timeFormat:custom.timezone}} </td>
            </ng-container>

            <ng-container matColumnDef="report">
              <th mat-header-cell *matHeaderCellDef> Report </th>
              <td mat-cell *matCellDef="let element">
                <ng-template
                  [ngIf]="trackingDisplay[element.action] && trackingDisplay[element.action][element.keyName]"
                  [ngIfElse]="defaultTrackingDisplay">
                  {{trackingDisplay[element.action][element.keyName]}}
                </ng-template>
                <ng-template #defaultTrackingDisplay>
                  {{element.action}}{{element.action ? ', ' : ''}}{{element.keyName}}
                </ng-template>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="activityColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: activityColumns;"></tr>
        </table>
    </div>
</mat-card>

<mat-card class="activity">
        <h4>Saved Reports ({{savedReports.length}})</h4>
        <div class="activity__uxcinfo">
          <div *ngFor="let report of savedReports" class="enhanced-report">
            <ng-container *ngIf="report.tempClient?.enhancedPurchased">
              <img src="assets/premium.svg" alt="premium">
            </ng-container>
            <p>
              <ng-container *ngIf="report.lastViewedTimestamp">
                {{report.lastViewedTimestamp | date : 'YYYY-MM-dd hh:mm:ss' : 'EDT'}}&nbsp;
              </ng-container>
              <ng-container *ngIf="!report.lastViewedTimestamp">
                {{report.changedTimestamp | date : 'YYYY-MM-dd hh:mm:ss' : 'EDT'}}&nbsp;
              </ng-container>
              <strong>{{getShortTitleByCommerceContent(report)}}</strong>&nbsp;
              {{getLocation(report)}}
            </p>
          </div>
        </div>
</mat-card>

<mat-card class="activity">
        <h4>My Searches ({{searchInfo.length}})</h4>
        <div class="activity__uxcinfo">
          <div *ngFor="let search of searchInfo">
            {{search.createdTimestamp | date : 'YYYY-MM-dd hh:mm:ss' : 'EDT'}}
            <strong>
              <ng-container *ngIf="!search.contentInfo.id">{{ search.name }}&nbsp;</ng-container>
              <ng-container *ngIf="search.contentInfo.id">{{ search.contentInfo.firstName }}
                {{search.contentInfo.lastName}}&nbsp;</ng-container>
            </strong>
            <ng-container *ngIf="search.contentType === 'phoneSearch'">N/A</ng-container>
            <ng-container *ngIf="search.contentType !== 'phoneSearch'">
              {{ search.contentInfo.cityState }}&nbsp;
              <ng-container *ngIf="search.contentInfo.minAge">+ {{ search.contentInfo.minAge }} yrs old</ng-container>
              <ng-container *ngIf="search.contentInfo.maxAge">- {{ search.contentInfo.maxAge }} yrs old</ng-container>
            </ng-container>
          </div>
        </div>
</mat-card>