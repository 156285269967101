import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { User } from 'src/common/models/user/user';
import { CommerceOrder } from 'src/common/models/commerce/commerceOrder';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UxComposite } from 'src/common/models/ux/uxComposite';
import { Note } from 'src/common/models/note';
import { collectionClassHelper } from '../../../common/decorators/database/collectionClass';
import { SpinnerService } from 'src/clientCommon/services/spinner.service';
import { JsonService } from 'src/clientCommon/services';
import { adminPaths, serverPaths } from '../../../common/helpers/pathHelpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogUtils } from '../../../common/utils/logUtils';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { emailUtils } from '../../../common/utils/emailUtils';
import { custom } from '../../custom/custom.class';
import * as moment from "moment";
import { CommerceToken } from 'src/common/models/commerce/commerceToken';
import { configUtils } from 'src/app/utils/ConfigUtils';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-csr-customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer-summary.component.scss'],
})

export class CustomerSummaryComponent implements OnInit {
  @Input() user: User;
  @Input() baseCommerceOrder: CommerceOrder;
  @Input() uxComposite: UxComposite;
  @Input() customerUxComposite: UxComposite;
  @Input() commerceToken: CommerceToken;
  @Input() router: Router;
  @Input() agentAddress: any = "";
  @Input() agentPhoneNumber: any = "";
  @Input() brandName: string = "";
  @Input() noteData: any;
  @Input() editSummary: any = false;
  @Input() adminFlag: any = false;
  @Output() notifyUpdateUser: EventEmitter<{user:User, confirmMsg?: string}> = new EventEmitter();

  timeFormat = 'yyyy-MM-dd hh:mm:ss aa';
  custom = custom;

  //@Output() onSuccess = new EventEmitter<void>();

  ngOnInit() {

  }
  detailLink() {
    //if (!this.csrAuthGuard.canActivatePath(adminPaths.csrCustomer)) return;
    const csrPath = configUtils.getCsrPathFromVersion(this.uxComposite);
    this.router.navigate([csrPath + '/' + this.user._id]);
  }
  updateUser(user, message?: string) {
    this.notifyUpdateUser.emit({user, confirmMsg: message});
  }

  getAddress(commerceToken) {
    const { street1, state, city, zip } = commerceToken.billingAddress;
    return `${street1} ${city} ${state} ${zip}`;
  }
  
  getCardInfo(commerceToken) {
    let ccNumber = '';
    if (commerceToken) {
      const bin = commerceToken.bin;
      const last4 = commerceToken.last4;
      const length = commerceToken?.length - last4?.length - bin?.length;
      if (length) {
        ccNumber = bin;
        ccNumber += new Array(length).fill('*').join('');
        ccNumber += last4;
      }
    }

    return ccNumber;
  }
}  