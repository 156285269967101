<mat-card class="confirm-dialog-card">
    <div class="__card-header">
        <h2>
            <span class="__span-center">Note Details</span>
            <span style="position: absolute;">
                <mat-icon class="__font-lg" mat-dialog-close>highlight_off</mat-icon>
            </span>
        </h2>
    </div>
    <mat-card-content>
        <div class="__main-content">
            <mat-dialog-content class="mat-typography">
            <p class="text-center mb-35 __fs-20">Are you sure you want to delete this note ?</p>
        </mat-dialog-content>
        <mat-dialog-actions align="center">
            <div class="d-flex">
                <button mat-stroked-button mat-dialog-close class="__outline-default">Close</button>
                <button mat-stroked-button color="primary" class="__outline-primary" [mat-dialog-close]="true" cdkFocusInitial>Delete</button>
            </div>
        </mat-dialog-actions>
        </div>
        
    </mat-card-content>
</mat-card>