import {creditCardUtils, CreditCardUtils} from '../../common/utils/creditCardUtils';
import {CommerceAddress} from '../../common/models/commerce/commerceAddress';
import {ArrayUtils} from '../../common/utils/arrayUtils';
import {BankIdentificationNumber} from '../../common/models/bankIndentificationNumber';
import {LogUtils} from '../../common/utils/logUtils';
import {ServiceHelperService} from '../services/serviceHelper.service';
import {UxComposite} from '../../common/models/ux/uxComposite';
import { UxHelper } from './uxHelper';
import { objectUtils } from '../../common/utils/objectUtils';
import { randomUtils } from '../../common/utils/randomUtils';

export class CreditCardInputHelper {
  // credit card payment proprties defination, will be required for payment processing
  ccExpYears;
  ccExpMonths;
  ccExp4Digits;
  ccNumber: string;
  ccNumberFormatted;
  ccExpMonth;
  ccExpYear;
  ccCvv;
  billingAddress: CommerceAddress;
  cardType;
  cardTypeName;
  bin: string;
  binObj: BankIdentificationNumber;
  termsOfUseChecked: boolean = true;
  notConsumerReportingAgencyChecked: boolean = true;

  fullName: string;

  fullNameRegex = new RegExp("^[_0-9a-z- ]*$", "i");
  nameRegex = /^[a-zA-Z]([a-zA-Z0-9-_])*$/;

  showStreetInput: boolean = false;

  private streetRuleUpdater: (() => void) | null;

  private readonly failedCards = new Set<string>();

  static readonly billingRuleAddressUxCompKey = 'comp.billing.rule.address';

  constructor() {
    this.init();
  }

  // this initliaze default values for credit card properties
  init() {
    this.billingAddress = new CommerceAddress();
    this.billingAddress.street1 = "";
    this.billingAddress.city = "Los Angeles";
    this.billingAddress.state = "ca";
    this.billingAddress.zip = "";
    this.billingAddress.country = "us";
    this.billingAddress.firstName = "";
    this.billingAddress.lastName = "";
    this.fullName = "";
    this.billingAddress.bogusFields = {
      street1: true,
      city: true,
      state: true,
      country: true,
    };

    this.ccExpYears = creditCardUtils.getYearsArray(20);
    this.ccExpMonths = ArrayUtils.toArray(CreditCardUtils.expMonths);

    this.failedCards.clear();
  }

  // reset credit card properties
  clearCC() {
    this.ccNumber = "";
    this.ccExpMonth = "";
    this.ccExpYear = "";
    this.ccCvv = "";
    this.ccNumberFormatted = "";
    this.ccExp4Digits = "";
    this.cardType = "";
    this.cardTypeName = "";
    this.bin = "";
  }

  /**
   * Setting dummy address from uxc
   */
  setDummyAddress(uxComposite: UxComposite): boolean {
    const rule = uxComposite.get(CreditCardInputHelper.billingRuleAddressUxCompKey);

    if(rule?.street1 || rule?.street1 === null) {
      this.billingAddress.street1 = rule.street1;
      return true;
    }

    return false;
  }

  /**
   * this check the credit card number and set credit card type and its name i.e Visa, Mastercard
   * @param value credit card number
   */
  findCardType(value) {
    this.cardType = creditCardUtils.findCardType(value);
    this.cardTypeName = creditCardUtils.getCardTypeName(this.cardType);
  }

  /**
   * convert input value to required credit card number format
   * @param $event to get its targeted element value
   */
  formatCCNumber($event) {
    let value = creditCardUtils.formatCCNumber($event.target.value);
    this.ccNumberFormatted = value;
    $event.target.value = value;
    this.ccNumber = value.replace(/ /g, "");

    this.streetRuleUpdater?.();
  }

   /**
   * convert cvv number to required format
   * @param $event to get its targeted element value
   */
  formatCvv($event) {
    let value = creditCardUtils.formatCvv(this.cardType, $event.target.value);
    this.ccCvv = value;
    $event.target.value = value;
  }

  /**
   * convert zip number to required format
   * @param $event to get its targeted element value
   */
  formatZip($event) {
    let value = creditCardUtils.formatZip($event.target.value);
    this.billingAddress.zip = value;
    $event.target.value = value;
  }

  /**
   * convert credit card expiry to required credit card number format
   * @param $event to get its targeted element value
   */
  formatExp4Digits($event) {
    let value = creditCardUtils.formatExp4Digit($event.target.value);
    this.ccExp4Digits = value;
    $event.target.value = value;

    let strippedValue = value.replace(/[^0-9]/g, "");
    if (strippedValue.length >= 2) {
      let month = parseInt(strippedValue.substring(0, 2));
      this.ccExpMonth = month;
    }
    if (strippedValue.length > 2) {
      this.ccExpYear = parseInt('20' + strippedValue.substring(2));
    } else {
      this.ccExpYear = undefined;
    }
  }

  /**
   * @returns classNames seperated by spaces for all the credit card input/properties
   */
  getBillingInfoClasses() {
    let classNames = "";

    classNames += this.getBillingInfoClass(creditCardUtils.isValidCCNumber(this.ccNumber), this.ccNumber, "CcNumber ");
    classNames += this.getBillingInfoClass(creditCardUtils.findCardType(this.ccNumber), this.ccNumber, "CcType ");
    classNames += this.getBillingInfoClass(creditCardUtils.isValidCvv(this.ccNumber, this.ccCvv), this.ccCvv, "CcCvv ");
    classNames += this.getBillingInfoClass(creditCardUtils.isValidExp(this.ccExpMonth, this.ccExpYear), this.ccExpMonth, "CcExpMonth ");
    classNames += this.getBillingInfoClass(creditCardUtils.isValidExp(this.ccExpMonth, this.ccExpYear), this.ccExpYear, "CcExpYear ");

    return classNames;
  }

  /**
   * @param check boolean that tells billing info validity
   * @param variable it contain property/input i.e ccNumber, ccCvv...
   * @param name class name i.e 'ccNumber'
   * @returns className for provided input/propery of creditcard i.e ccNumber, ccCvv...
   */
  getBillingInfoClass(check: boolean, variable, name) {
    let className;
    if (!variable) {
      className = "empty";
    } else if (check) {
      className = "valid";
    } else {
      className = "error";
    }

    className += name;
    return className;
  }

  // check credit card number validaty(length, cardType & format)
  isValidCCNumber() {
    return creditCardUtils.isValidCCNumber(this.ccNumber);
  }

  // check credit card expiry
  isValidCCExp() {
    return creditCardUtils.isValidExp(this.ccExpMonth, this.ccExpYear);
  }

  // check cvv validaty by credit card type
  isValidCCCvv() {
    return creditCardUtils.isValidCvv(this.ccNumber, this.ccCvv);
  }

  /**
   * @returns true if all the credit card input fields are valid
   */
  isValidBillingInfo() {
    return creditCardUtils.isValid(this.ccNumber, this.ccExpMonth, this.ccExpYear, this.ccCvv);
  }

  /**
   * 
   * @returns true only if billngAdress firstName is provided and valid (by validating through name regex)
   */
  isValidFirstName() {
    if (!this.billingAddress.firstName) {
      return false;
    }

    if (this.billingAddress.firstName && !this.billingAddress.firstName.trim()) {
      return false;
    }

    if (!this.nameRegex.test(this.billingAddress.firstName)) {
      return false;
    }

    return true;
  }

  /**
   * 
   * @returns true only if billngAdress lastName is provided and valid (by validating through name regex)
   */
  isValidLastName() {
    if (!this.billingAddress.lastName) {
      return false;
    }

    if (this.billingAddress.lastName && !this.billingAddress.lastName.trim()) {
      return false;
    }

    if (!this.nameRegex.test(this.billingAddress.lastName)) {
      return false;
    }

    return true;
  }

    /**
   * 
   * @returns true only if billngAdress fullName is provided, valid (by validating through name regex) and have space in between
   */
  isValidFullName() {
    if (!this.fullName) {
      return false;
    }

    if (!this.fullNameRegex.test(this.fullName)) {
      return false;
    }

    if (this.fullName.indexOf(" ") === -1) {
      return false;
    }

    if (this.fullName.indexOf(" ") === (this.fullName.length - 1)) {
      return false;
    }

    return true;
  }

  /**
   * 
   * @returns true onlu if provided code is valid (validating using regex)
   */
  isValidZip() {
    return !!this.billingAddress.zip.match('^[0-9]{5}$');
  }

  isFailedCard() {
    return this.failedCards.has(this.ccNumber);
  }

  addFailedCard(cardNumber: string) {
    this.failedCards.add(cardNumber);
  }

  clearFailedCards() {
    this.failedCards.clear();
  }

  isValidStreet() {
    return this.billingAddress.street1?.length > 2;
  }

  /**
   * convert input value to required credit card number format and processCCNumber to check its type
   * @param $event to get its targeted element value
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   */
  processCCNumberInput($event, serviceHelperService: ServiceHelperService) {
    this.formatCCNumber($event);
    return this.processCCNumber($event.target.value, serviceHelperService);
  }

  /**
   * this format the credit card number and find credit card type and its name i.e Visa, Mastercard
   * @param ccNumber credit card number
   * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
   */
  processCCNumber(ccNumber, serviceHelperService: ServiceHelperService) {
    this.ccNumberFormatted = creditCardUtils.formatCCNumber(ccNumber);
    this.findCardType(this.ccNumber);
    // this.findBin(serviceHelperService);
  }

  toggleTermsOfUse(){
    this.termsOfUseChecked = !this.termsOfUseChecked;
  }

  toggleConsumerReportingDisclosure(){
    this.notConsumerReportingAgencyChecked = !this.notConsumerReportingAgencyChecked
  } 


  /**
   * this get's the bank identification number object having bank detail using commerceService
   * @param serviceHelperService will provide access to different required services such as commerceService, uxc service etc.
   */
  findBin(serviceHelperService: ServiceHelperService) {
    let newBin = "";
    if (this.ccNumber && this.ccNumber.length > 5) {
      newBin = this.ccNumber.substring(0, 6);
    }

    if (newBin) {
      if (this.bin !== newBin) {
        this.bin = newBin;
        serviceHelperService.commerceService.findBin(newBin).then((binObj: BankIdentificationNumber) => {
          this.binObj = binObj;
        }).catch((e) => {
          LogUtils.error("SignUpPeopleSearch.findBin", e);
        });
      }
    }
  }

  // Use the default rule ('street') 
  // and apply the additional rule ('billing.street.rule') if there is one.
  // The additional rule enables/disables the street input based on the card number.
  setStreetRuleUpdater(uxHelper: UxHelper) {
    this.showStreetInput = false;

    const streetRule = uxHelper.getUxcomp('billing.street.rule');

    if (!streetRule) {
      this.streetRuleUpdater = null;

      return;
    }

    this.streetRuleUpdater = () => {
      const rule = objectUtils.findRuleFromConditionedRules(
        { creditCardInputHelper: this, },
        streetRule,
      );
  
      if (rule?.['candidates']) {
        const chosen = (randomUtils.chooseWeightedRandom(rule['candidates']) ?? '')?.toString();

        this.showStreetInput = chosen === 'true';
      } else {
        this.showStreetInput = false;
      }

      if (this.showStreetInput) {
        this.billingAddress.bogusFields.street1 = false; 
      }
    };

    this.streetRuleUpdater();
  }
}
