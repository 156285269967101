import {RequestEvent} from "../../event/requestEvent";
import {UserInput} from "../user/userInput";
import {UserInfo} from "../user/userInfo";
import {User} from "../user/user";
import {CommerceTransactionCollection} from "./commerceTransactionCollection";
import {ResponseEvent} from "../../event/responseEvent";
import {CommerceOfferRuleKey, CommerceOfferRuleKeyDetail, CommerceOrder} from "./commerceOrder";
import {CommerceTransaction} from "./commerceTransaction";
import {CommerceSchedule} from "./commerceSchedule";
import {CommercePayment} from "./commercePayment";
import {CommerceToken} from "./commerceToken";
import {CommerceAddress} from "./commerceAddress";
import {CommerceOffer, CommerceOfferDetail} from "./commerceOffer";
import {UxComposite} from "../ux/uxComposite";
import {CommerceContent} from "./commerceContent";
import {Commerce3ds} from "./commerce3ds";
import {BankIdentificationNumber} from "../bankIndentificationNumber";
import {CommerceBillingRouting} from "./commerceBillingRouting";

export var commerceUrls = {
  // CSR URLs
  csrFindCustomer: "/api/manage/csr/commerce/customer/find",
  csrUpdate: "/api/manage/csr/commerce/update",
  csrAdjustment: "/api/manage/csr/commerce/adjustment",
  csrChargeback: "/api/manage/csr/commerce/customer/chargeback",
  csrChargebackEmail: "/api/manage/csr/commerce/customer/chargebackEmail",
  csrFindPayment: "/api/manage/csr/commerce/customer/payment/find",
  csrFindOrder: "/api/manage/csr/commerce/customer/order/find",
};

export class CommerceRequestEvent extends RequestEvent {
  commerceResponseEvent?: CommerceResponseEvent;
  commerceResponseEvents?: CommerceResponseEvent[] = [];
  depth = 0;
  subType;

  seriesId: string;
  id: string;
  user: User;
  commerceOrder: CommerceOrder;
  commerceSchedule: CommerceSchedule;
  commerceTransactionCollection: CommerceTransactionCollection;
  commerceTransaction: CommerceTransaction;
  commercePayment: CommercePayment;
  commerceToken: CommerceToken;
  commerceContent: CommerceContent;

  commerceTransactions: CommerceTransaction[] = [];
  commercePayments: CommercePayment[] = [];
  commerceOrders: CommerceOrder[] = [];
  commerceContents: CommerceContent[] = [];
  commerceOffers: CommerceOffer[] = [];
  commerce3ds: Commerce3ds;

  uxComposite: UxComposite;
  routingType: string;

  prevCommerceBillingRoutings: {
    [offerIdsCombined: string]: CommerceBillingRouting[]
  } = {};
  prevCommercePayment: CommercePayment;
  prevCommercePayments: CommercePayment[] = [];
  error: Error;
  updaterId: string;
  updater: User;
  brandId: string;
  content?;
  binObj: BankIdentificationNumber;
  preCheck;

  clearCommerce() {
    Object.keys(this).forEach((key) => {
      if (key.startsWith("commerce")) {

        if (Array.isArray(this[key])) {
          this[key] = [];
        } else {
          this[key] = null;
        }
      }
    });

    this.commerceResponseEvent = new CommerceResponseEvent();
    this.commerceResponseEvent.status = ResponseEvent.STATUS.fail;
    this.preCheck = null;
  }
}

export class CommerceSaleRequestEvent extends CommerceRequestEvent {
  public static CHECK = {
    preCheck: "preCheck",
  };

  public static CHECK_ACTION = {
    decline: "decline",
  };

  declare param: {
    hash: string;
    userInput?: UserInput,
    userInfo: UserInfo,
    ccNumber?: string,
    ccExpMonth?: string,
    ccExpYear?: string,
    ccCvv?: string,
    billingAddress?: CommerceAddress,
    signUp: boolean,
    refer?: any;
    commerceTransactionCollectionId?: string,
    uxcId: string,
    uxlId: string,
    commerce3dsId: string;
    threeDS?: {
      status: string,
      authenticationValue: string,
      eci: string,
      dsTransId: string,
      acsTransId: string,
      cardToken: string,
      protocolVersion: string,
      scaIndicator: any,
    },
    token: {
      id: string,
      last4: string,
      expYear: number,
      expMonth: number,
      cardType: string,
      billingZip: string,
    },

    commerceOfferIds: string[],
    parentCommerceOrderId: string,
    disablePreviousTokens: boolean,
    declineIfSameActiveToken: boolean,

    offerRuleKey: string;
    offerRuleKeyDetails: CommerceOfferRuleKeyDetail[],

    commerceOfferDetails: CommerceOfferDetail[];
    updaterId?: string;
    commerceContentId?: string;
    recaptchaToken?: string;
    preFailed: boolean;
    uniqueId?: string;
    contentInfos: any;
    trackingKey?: string;
    thinMatch?: any;
    upsellKey?: string;

  };

  currentOfferRuleKeyDetail: CommerceOfferRuleKeyDetail;
  prevCommerceOrderIds: string[] = [];
  recaptchaFailed: boolean = false;

  public isNonMemberOnlyOffers() {
    let memberOnlyFlag = false;
    this.commerceOffers.some((commerceOffer) => {
      if (commerceOffer.nonMemberOnly) {
        memberOnlyFlag = true;
        return true;
      }
    });

    return memberOnlyFlag;
  }

  getCurrentCommerceOfferRuleKey(): CommerceOfferRuleKey {
    if (this.uxComposite && this.param && this.param.offerRuleKey && this.currentOfferRuleKeyDetail && this.currentOfferRuleKeyDetail.key) {
      const commerceOfferRuleKeys = this.uxComposite.get(this.param.offerRuleKey);
      if (commerceOfferRuleKeys && commerceOfferRuleKeys?.[this.currentOfferRuleKeyDetail.key]) {
        return commerceOfferRuleKeys[this.currentOfferRuleKeyDetail.key][0];
      }
    }
  }

}

export class CommerceRuleKey {
  static billing_sale = {
    retry: {
      quadzero: "retry.quadzero",
      dropCvv: "retry.dropCvv",
      decline: "retry.decline",
      autoDecline: "retry.autoDecline",
      quadzero_cascade: "retry.quadzero.cascade",
      dropCvv_cascade: "retry.dropCvv.cascade",
      decline_cascade: "retry.decline.cascade",
      autoDecline_cascade: "retry.autoDecline.cascade",
    },
    passthru: {
      prepaidDecline: "passthru.prepaidDecline",
      prepaidDecline_cascade: "passthru.prepaidDecline.cascade",
      gateway: "passthru.gateway",
      gateway_cascade: "passthru.gateway.cascade",
      decline: "passthru.decline",
      decline_cascade: "passthru.decline.cascade",
      decline_validate: "passthru.decline.validate",
    },
  }

}


export class CommerceResponseEvent extends ResponseEvent {
  cascade?: boolean;
  uxComposite?: UxComposite;
}

