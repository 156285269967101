<div class="customer" *ngIf="initDone">

    <!-- Custommer Information -->
    <div class="customer-side">        
        <mat-card>
          <div (click)="init(userId)" class="brand-bg">
            <img [src]="'/assets/logos/' + commerceOrders[0].brandId + '.png'" alt="brand" style="height: 60px;">
          </div>
          <h2 class="customer-side-title" (click)="init(userId)">{{ customerUxComposite?.brandDomain }}</h2>
          <p class="customer-side-info">{{companyName}}</p>
          <p class="customer-side-info" [innerHTML]="agentAddress"></p>
          <p class="customer-side-info">{{agentPhoneNumber}}</p>
          <p></p>          
          <!-- <div class="d-flex">
            <div class="ml-auto">
              <a [routerLink]="['/'+adminPaths.csrCustomerV1]+'/'+userId" mat-stroked-button color="primary"
                class="mr-2">V1</a>
              <a [routerLink]="['/'+adminPaths.csrCustomerV2]+'/'+userId" mat-stroked-button color="primary"
                class="mr-2">V2</a>
              <button mat-stroked-button color="primary" (click)="openBillingInfoModal()">Billing Info</button>
            </div>
          </div> -->
    
          <ng-container *ngIf="adminUxComposite">
            <app-csr-customer-summary [from]="customerUxComposite?.getUxcomp('comp.brand.customer.email')" [user]="user"
              [uxComposite]="adminUxComposite" [baseCommerceOrder]="baseCommerceOrder"
              [commerceToken]="commerceToken"
              [router]="router"
              [agentAddress]="customerUxComposite.getUxcomp('comp.brand.address.twoline')"
              [agentPhoneNumber]="customerUxComposite.getUxcomp('comp.brand.customer.phone')"
              [warningMessage]="adminUxComposite.getUxcomp('comp.brand.customer.warning.message')"
              [brandName]="user.draft.brandId" [editSummary]="editSummary" [adminFlag]="adminFlag"
              (notifyUpdateUser)="updateUser($event.user, $event.confirmMsg);"
            >
            </app-csr-customer-summary>
          </ng-container>
    
          <ng-container *ngIf="adminUxComposite">
            <app-csr-customer-activity [from]="customerUxComposite?.getUxcomp('comp.brand.customer.email')" [user]="user"
              [uxComposite]="adminUxComposite" [baseCommerceOrder]="baseCommerceOrder"
              [customerUxComposite]="customerUxComposite"
              [commerceToken]="commerceToken"
              [commerceUserActivityDataSource]="commerceUserActivityDataSource"
              [savedReports]="savedReports"
              [searchInfo]="searchInfo"
              [trackingDisplay]="trackingDisplay"
              [agentAddress]="customerUxComposite.getUxcomp('comp.brand.address.twoline')"
              [agentPhoneNumber]="customerUxComposite.getUxcomp('comp.brand.customer.phone')"
              [warningMessage]="adminUxComposite.getUxcomp('comp.brand.customer.warning.message')"
              [brandName]="user.draft.brandId" [editSummary]="editSummary" [adminFlag]="adminFlag"
            >
            </app-csr-customer-activity>
          </ng-container>
          
        </mat-card>
    </div>


    <div class="customer-content">
        <h2>Customer Order Info</h2>
        <!-- CommercePayment, CommerceOrder -->
        <!-- <mat-card class="common-mat-card">
            <div class="d-flex customer-content-transactions-actions">
                <p class="customer-content-subtitle mb0">Customer Payment</p>
            </div>
        </mat-card>
        <mat-card class="common-mat-card">            
            <div class="payment-detail">
                <app-payment-detail [commerceOrder]="commerceOrder" [commercePayment]="commercePayment"
                    [userInfo]="customerUserInfo" (updateCommerceOrder)="updateCommerceOrder($event)"
                    [uxComposite]="customerUxComposite" [isOrder]="false">
                </app-payment-detail>
            </div>            
        </mat-card> -->
        <!-- <mat-card class="common-mat-card">
            <div class="d-flex customer-content-transactions-actions">
                <p class="customer-content-subtitle mb0">Customer Order Info</p>
            </div>    
        </mat-card> -->
        <mat-card class="common-mat-card">            
            <div class="payment-detail">
                <app-order-detail [commerceOrder]="commerceOrders[0]" [commercePayment]="commercePayment"
                    [userInfo]="customerUserInfo" (updateCommerceOrder)="updateCommerceOrder($event)"
                    [uxComposite]="customerUxComposite" [isOrder]="true">
                </app-order-detail>
            </div>            
        </mat-card>
        <!--  Billing Schedules  -->
        <mat-card class="common-mat-card">
            <div class="d-flex customer-content-transactions-actions">
                <p class="customer-content-subtitle mb0">Billing Schedules</p>
            </div>    
        </mat-card>
        <mat-card class="schedules">
            <mat-card-content>
            <div class="table-responsive">
                <table mat-table [dataSource]="filterCommerceSchedules()" class="w-100">

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef class="saleType"> Type </th>
                    <td mat-cell *matCellDef="let element" class="saleType">
                    {{element?.scheduleData?.type === paymentTypes.refund ? "Refund" : "Sale"}}</td>
                </ng-container>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef class="id"> ID / Order ID </th>
                    <td mat-cell *matCellDef="let element" class="id">{{element._id}}<br />{{element.commerceOrderId}}</td>
                </ng-container>

                <ng-container matColumnDef="updater">
                    <th mat-header-cell *matHeaderCellDef> Updater </th>
                    <td mat-cell *matCellDef="let element">{{customerUserInfo[element.updaterId]?.getFullName()}}</td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef class="amount"> Amount </th>
                    <td mat-cell *matCellDef="let element" class="amount">
                    <mat-form-field class="w-100 amount" appearance="outline" floatLabel="always">
                        <mat-label>Amount</mat-label>
                        <input matInput type="number" [placeholder]="'$'+element.scheduleData.amount"
                        [(ngModel)]="element.draft.scheduleData.amount">
                    </mat-form-field>
                    </td>
                </ng-container>

                <ng-container matColumnDef="scheduleDate">
                    <th mat-header-cell *matHeaderCellDef> Schedule Date </th>
                    <td mat-cell *matCellDef="let element">
                    <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                        <mat-label>Schedule Date</mat-label>
                        <input matInput [matDatepicker]="picker"
                        [placeholder]="((element.dueTimestamp | formatTimestamp) | date:timeFormat:custom.timezone) + ' ' +custom.timezone"
                        [min]="minDate" [max]="maxDate" 
                        [selected]="element.draft.dueTimestamp | formatTimestamp"
                        (dateChange)="reflectCommerceScheduleDate($event.value,element)">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="action"> Action </th>
                    <td mat-cell *matCellDef="let element" class="action">
                    <button mat-raised-button color="primary" [disabled]="!isValidUpdateCommerceSchedule(element)"
                        (click)="updateCommerceSchedule(element)">Update</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="scheduleColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: scheduleColumns;"></tr>
                </table>
            </div>
            </mat-card-content>
        </mat-card>

    </div>
</div>