import {CustomersComponent} from "./customers.component";
import {CustomerComponentV2} from "./customerv2.component";
import {MailSentComponent} from "./mailSent.component";
import {TrackingComponent} from "./tracking.component";
import {OptOutComponent} from "./optOut.component";
import {CustomerComponentV1} from "./customerv1.component";
import {TrackingComponentV1} from "./trackingV1.component";
import {OptOutComponentV1} from "./optOutV1.component";
import {MailSentComponentV1} from "./mailSentV1.component";
import {NotesComponent} from "./notes.component";
import {TransactionDetailComponent} from "./customer/transaction-detaill.component";
import {UnsubscribeComponent} from "./unsubscribe.component";
import {AddNoteDialog, AddEmailDialog, AddCallDialog, BillingInfoDialog, ChargebackDialog, RefundPaymentDialog, ViewNoteDialog, AddCommerceOfferDialog,ViewAttachmentDialog,  ViewSearchDialog, viewUxHistorySearchDialog} from "./customer/dialog.component";
import {ActionRecommenderComponent} from "./customer/action-recommender.component";
import {TimeSheetComponent} from "./timeSheet.component";
import {TimeSheetSummaryComponent} from "./timeSheetSummary.component";
import {AddTimesheetDialog} from "./timesheet/addTimesheetModal.component";
import { CustomerComponent } from "./customer.component";
import { TransactionDetailComponentV3 } from "./customer/transaction-detaill-v3.component";
import { CustomerSummaryComponent } from "./customer/customer-summary.component";
import { CustomerActivityComponent } from "./customer/customer-activity.component";
import { PaymentComponent } from "./payment.component";
import { PaymentsComponent } from "./payments.component";
import { PaymentDetailComponent } from "./customer/payment-detail.component";
import { OrdersComponent } from "./orders.component";
import { OrderComponent } from "./order.component";
import { OrderDetailComponent } from "./customer/order-detail.component";
import { ScheduleHistorysComponent } from "./scheduleHistorys.component";
import { ScheduleHistoryComponent } from "./scheduleHistory.component";

export const CSR_DECLARATIONS = [
  CustomersComponent,
  CustomerComponent,
  CustomerComponentV2,
  PaymentComponent,
  PaymentsComponent,
  OrderComponent,
  OrdersComponent,
  MailSentComponent,
  TrackingComponent,
  OptOutComponent,
  AddNoteDialog,
  AddEmailDialog,
  AddCallDialog,
  BillingInfoDialog,
  ChargebackDialog,
  RefundPaymentDialog,
  ViewNoteDialog,
  ViewSearchDialog,
  ViewAttachmentDialog,
  CustomerComponentV1,
  TrackingComponentV1,
  OptOutComponentV1,
  MailSentComponentV1,
  NotesComponent,
  TransactionDetailComponent,
  TransactionDetailComponentV3,
  PaymentDetailComponent,
  OrderDetailComponent,
  UnsubscribeComponent,
  ActionRecommenderComponent,
  AddCommerceOfferDialog,
  TimeSheetComponent,
  TimeSheetSummaryComponent,
  AddTimesheetDialog,
  viewUxHistorySearchDialog,
  CustomerSummaryComponent,
  CustomerActivityComponent,
  ScheduleHistorysComponent,
  ScheduleHistoryComponent,
];
