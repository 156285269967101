import {CollectionClass} from "../../decorators/database/collectionClass";
import {CompositeClassField} from "../../decorators/database/compositeClassField";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {IndexField} from "../../decorators/database/indexField";
import {ManualField} from "../../decorators/database/manualField";
import {NoCyclicField} from "../../decorators/database/noCyclicField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {TransientField} from "../../decorators/database/transientField";
import {commerceUtils} from "../../utils/commerceUtils";
import {LogUtils} from "../../utils/logUtils";
import {ModelBase} from "../modelBase";
import {ModelRevision} from "../modelRevision";
import {User} from "../user/user";
import {Commerce3ds} from "./commerce3ds";
import {CommerceBillingRouting} from "./commerceBillingRouting";
import {CommerceOffer, CommerceOfferDetail} from "./commerceOffer";
import {CommercePayment} from "./commercePayment";
import {CommercePrice} from "./commercePrice";
import {CommerceToken} from "./commerceToken";
import {CommerceTransaction} from "./commerceTransaction";
import {CommerceTransactionCollection} from "./commerceTransactionCollection";

export var commerceOrderIndex = {
  zip: "zp:",
  phone: "ph:",

  orderNumericId: "nid:",
  reversedOrderNumericId: "rnid:",

  creditCardType: "CCT:",
  creditCardLast4: "CL4:",
  creditCardExp: "CEx:",
  payeeId: "CPe:",
  bin: "bin:",
};

export class CommerceOfferRuleKey {
  public static KEY = {
    commerceOfferIds: "commerceOfferIds",
    billingSaleRuleKey: "billingSaleRuleKey",
    billingBinRuleKey: "billingBinRuleKey",
    billingRetryRuleKey: "billingRetryRuleKey",
    emailCampaignKey: "emailCampaignKey",
  };

  offerKey?: string;
  commerceOfferIds?: string[];
  billingSaleRuleKey: string;
  billingBinRuleKey: string;
  billingRetryRuleKey: string;
  emailCampaignKey: string;
}

export class CommerceOfferRuleKeyDetail {
  key: string;
  commerceOfferIds?: string[];
}

const SUB_STATUS = {
  none: "none",
  cancelled: "cancelled",
  suspended: "suspended",
  expired: "expired",
}


@CollectionClass({name: "commerceOrders"})
export class CommerceOrder extends ModelBase<CommerceOrder> {
  public static SUB_STATUS = {
    none: "none",
    cancelled: "cancelled",
    suspended: "suspended",
    expired: "expired",
  };
  public static STATUS_MAPPING = {
    [ModelBase.STATUS.active+"|"+ SUB_STATUS.cancelled]: "Cancelled",
    [ModelBase.STATUS.active+"|"+ SUB_STATUS.none]: "Active",
    [ModelBase.STATUS.failed+"|"+ SUB_STATUS.none]: "Failed",
    [ModelBase.STATUS.inactive+"|"+ SUB_STATUS.expired]: "Expired",
    [ModelBase.STATUS.inactive+"|"+ SUB_STATUS.suspended]: "Suspended"
  }
  @IndexField() declare subStatus: string;

  @IndexField() statusIdentifier: string;
  statusReason: string;
  statusDescription: string;

  @IndexField() uxcId: string;
  @IndexField() uxlId: string;
  prevUx: { timestamp: number, uxcId: string, uxlId: string }[] = [];

  @IndexField() @ForeignKeyField(User) payeeId: string;
  @IndexField() @ForeignKeyField(User) payerId: string;
  @IndexField() referrerId: string;

  @CompositeClassField(CommercePrice) commercePrice: CommercePrice;
  sequence: number;
  retry: number;
  quantity: number;
  note: string;
  @IndexField() ipAddress: string;
  @ManualField processed: number = 0;

  @IndexField() orderTimestamp: number;

  @IndexField() @ReferenceField(CommerceTransactionCollection) @ForeignKeyField(CommerceTransactionCollection) commerceTransactionCollectionIds: string[] = [];
  @TransientField @ForeignObjectField("commerceTransactionCollectionIds") commerceTransactionCollections: CommerceTransactionCollection[];

  @IndexField() @ReferenceField(CommerceToken) @ForeignKeyField(CommerceToken) commerceTokenId: string;
  @TransientField @ForeignObjectField("commerceTokenId") commerceToken: CommerceToken;

  @IndexField() @ForeignKeyField(CommerceOffer) commerceOfferId: string;
  @TransientField @ForeignObjectField("commerceOfferId") commerceOffer: CommerceOffer;
  @ForeignKeyField(ModelRevision) commerceOfferRevisionId: string;
  @TransientField @ForeignObjectField("commerceOfferRevisionId") commerceOfferRevision: ModelRevision;

  @CompositeClassField(CommerceOfferDetail) commerceOfferDetail: CommerceOfferDetail;

  @IndexField() @ReferenceField(Commerce3ds) @ForeignKeyField(Commerce3ds) commerce3dsId;
  @TransientField @ForeignObjectField("commerce3dsId") commerce3ds: Commerce3ds;
  @CompositeClassField(CommerceBillingRouting) commerceBillingRouting: CommerceBillingRouting;

  @ForeignKeyField(CommerceOrder) prevCommerceOrderIds: string[] = [];
  @NoCyclicField @TransientField @ForeignObjectField("prevCommerceOrderIds") prevCommerceOrders: CommerceOrder[];
  public sessionDuration: number;

  commerceOfferRuleKey: CommerceOfferRuleKey;
  @IndexField() commerceContentIds: string[] = [];
  commerceOfferTypes: string[] = [];

  refer: any = {};
  meta: any = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): CommerceOrder {
    let obj: CommerceOrder = super.getSecuredDoc();

    obj.meta = {
      thinMatch: this.meta?.thinMatch,
    };
    obj.subStatus = this.subStatus;
    obj.sequence = this.sequence;
    obj.quantity = this.quantity;
    obj.note = this.note;
    obj.processed = this.processed;
    obj.orderTimestamp = this.orderTimestamp;

    obj.commerceTransactionCollectionIds = this.commerceTransactionCollectionIds;
    obj.commerceOfferId = this.commerceOfferId;
    obj.commerceOfferRevisionId = this.commerceOfferRevisionId;
    obj.commerceOfferDetail = this.commerceOfferDetail;
    obj.commerceContentIds = this.commerceContentIds;

    obj.meta = {
      thinMatch: this?.meta?.thinMatch,
    }
    return obj;
  }

  getCommerceTransaction(commerceTransactionId): CommerceTransaction {
    let result;
    if (this.commerceTransactionCollections) {
      this.commerceTransactionCollections.some((commerceTransactionCollection) => {
        if (this.commerceTransactionCollections) {
          commerceTransactionCollection.commerceTransactions.some((commerceTransaction) => {
            if (commerceTransaction._id === commerceTransactionId) {
              result = commerceTransaction;
              return true;
            }
          });
        }
        if (!!result) {
          return true;
        }
      });
    }

    return result;
  }

  getCommercePayments(): CommercePayment[] {
    let commercePayments: CommercePayment[] = [];
    if (this.commerceTransactionCollections) {
      this.commerceTransactionCollections.forEach((commerceTransactionCollection) => {
        if (commerceTransactionCollection.commerceTransactions) {
          commerceTransactionCollection.commerceTransactions.forEach((commerceTransaction) => {
            if (commerceTransaction) {
              commercePayments = commercePayments.concat(commerceTransaction.commercePayments)
            }
          });
        }
      });
    }

    return commercePayments;
  }

  getCommercePayment(commercePaymentId): CommercePayment {
    let result;
    if (this.commerceTransactionCollections) {
      this.commerceTransactionCollections.some((commerceTransactionCollection) => {
        if (this.commerceTransactionCollections) {
          commerceTransactionCollection.commerceTransactions.some((commerceTransaction) => {
            commerceTransaction.commercePayments.some((commercePayment) => {
              if (commercePayment._id === commercePaymentId) {
                result = commercePayment;
                return true;
              }
            });
            if (!!result) {
              return true;
            }
          });
        }
        if (!!result) {
          return true;
        }
      });
    }

    return result;
  }

  calculateValue(): { code: string, amount: number } {
    let code;
    let value = 0;

    if (this.commerceTransactionCollections) {
      this.commerceTransactionCollections.forEach((commerceTransactionCollection) => {
        if (commerceTransactionCollection.commerceTransactions) {
          commerceTransactionCollection.commerceTransactions.some((commerceTransaction) => {
            if (commerceTransaction.commerceOfferIds.indexOf(this.commerceOfferId) !== -1) {
              if (commerceTransaction.status === ModelBase.STATUS.fulfilled) {
                let price = commerceTransaction.getPrice(this.commerceOfferId);
                if (!code) {
                  code = price.code;
                }
                if (code != price.code) {
                  LogUtils.error(this._id);
                  throw Error("Code does not match");
                }

                value += commerceTransaction.getTotalPrice().amount;
              }
              return true;
            }
          });
        }
      });
    }

    return {code: code, amount: Math.round(value * 100) / 100};
  }

  isCancellable() {
    return (!this.isTrivialOrder()) && (this.status === ModelBase.STATUS.active && this.subStatus === CommerceOrder.SUB_STATUS.none) ||
      (this.status === ModelBase.STATUS.inactive && this.subStatus === CommerceOrder.SUB_STATUS.suspended);
  }

  isCancelled() {
    return this.subStatus === CommerceOrder.SUB_STATUS.cancelled;
  }

  isOneTimePurchase() {
    return (this.commerceOffer.commercePrice.prices.length && 
      this.commerceOffer.commercePrice.prices[0].period && 
      this.commerceOffer.commercePrice.prices[0].period.unit === 'y' && 
      this.commerceOffer.commercePrice.prices[0].period.quantity > 900
    );
  }

  pushNewUxIfNeeded() {
    if (this.throwErrorIfNotBase()) {
      if ((this.uxcId !== this.draft.uxcId) || (this.uxlId !== this.draft.uxlId)) {
        let timestamp = new Date().getTime();
        this.draft.prevUx.push({timestamp: timestamp, uxcId: this.draft.uxcId, uxlId: this.draft.uxlId});
      }
    }
  }

  isTrivialOrder() {
    return (this.status === ModelBase.STATUS.rejected) ||
      (this.status === ModelBase.STATUS.failed) ||
      (this.commerceOfferRevision.getInstance().commercePrice.isZeroAmount());
  }

  isBillable(): boolean {
    if (!(
      (this.status == ModelBase.STATUS.active && this.subStatus == CommerceOrder.SUB_STATUS.none) ||
      (this.status == ModelBase.STATUS.inactive && this.subStatus == CommerceOrder.SUB_STATUS.suspended)
    )) {
      // only "active:none" or "inactive:suspended" status are billable
      return false;
    }

    const sequenceOptions = commerceUtils.createSequenceOptions(this);
    if (!this.commercePrice.hasPrices(this.sequence + 1, 0, sequenceOptions)) {
      return false;
    }

    return true;
  }

  isExpirable(): boolean {
    const sequenceOptions = commerceUtils.createSequenceOptions(this);
    return (!this.commercePrice.hasPrices(this.sequence + 1, 0, sequenceOptions)) ||
      (this.subStatus === CommerceOrder.SUB_STATUS.cancelled);
  }

  isShowMyAccount(): boolean {
    return (this.isActive() || this.isCancelled() || this.isCancellable()) && (!this.isTrivialOrder());
  }
}
