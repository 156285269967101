import {ServiceHelperService} from "../services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {urlUtils} from "../utils/urlUtils";

// static declaration for all the themes/layouts to avoid magic strings
export class ThemeConstant {
  public static THEME = {
    particle_1: 'particle_1',
    default_1_2: 'default_1_2',
    default_1_3: 'default_1_3',
    ux_2_0: "ux_2_0",
    ux_3_0: "ux_3_0",
    ux_4_0: "ux_4_0",
    ux_5_0: "ux_5_0",
    ux_5_1: "ux_5_1",
    ux_5_2: "ux_5_2",
  };

}

/**
 * @fileoverview this will be extended by child components to access theme constants or domain key
 */
export class CustomBaseDirective {
  themes = ThemeConstant.THEME;
  domainKey = "";

/**
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet. *
 */
  constructor(public serviceHelperService: ServiceHelperService,
              public activatedRoute: ActivatedRoute) {
    serviceHelperService.activatedRoute = activatedRoute;
    this.domainKey = urlUtils.get2ndLevelDomain(window.location.hostname);
  }
}
