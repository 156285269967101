<style>
  .w-100 {
    width: 100%;
  }

  .modal-title {
    background: #673ab7;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px;
    padding: 8px 24px;
    color: white;
    font-weight: 600;
  }

  .content {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .row {
    display: flex;
    justify-content: space-between;
  }

  .row,
  .full-width {
    padding-bottom: 15px;
  }

  .col {
    width: 45%;
  }

  label {
    font-size: 12px;
  }

  input {
    margin-top: 5px;
    background: #F8F9FE;
    padding: 10px 15px;
    width: 90%;
    border: 1px solid #CBD6E2;
    border-radius: 2px;
  }

  #subject,
  #body {
    width: 95%;
  }

  .button-wrapper {
    width: 100%;
    text-align: center;
  }

  #submit {
    background: #673ab7;
    color: #fff;
    padding: 10px 25px;
    border: none;
    border-radius: 2px;
  }

  .d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close {
    background-color: #673ab7;
    color: #fff;
  }

  h2 {
    margin: 0;
  }
</style>
<div class="modal">
  <div class="d-flex modal-title">
    <h2 mat-dialog-title>PP CB Representment</h2>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="handleClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <mat-dialog-actions align="left">
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>Send Email</button>
  </mat-dialog-actions>
  <div [innerHTML]="chagebackHTML" style="overflow-y: scroll;"></div>
</div>