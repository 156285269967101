<style>
  mat-card {
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(201, 213, 216, 0.6);
    border-radius: 5px;
    margin-top: 48px;
    margin-left: -32px;
    margin-right: -32px;
  }

  mat-card-header {
    border-bottom: 2px solid #e3e8ff;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 32px;
    padding-right: 32px;
  }

  mat-card-title {
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  mat-card-content {
    padding: 8px 0;
  }

  .parent {}

  .child {}

  .base {
    border: 1px solid black;
  }

  .depth {
    margin: 10px;
    font-size: 12px;
  }

  .uxcomp {
    padding-left: 16px;
  }
</style>
<mat-card *ngIf="uxDetail" class="mat-elevation-z6">
  <mat-card-header>
    <mat-card-title>
      <span>
        UX Detail
      </span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngFor="let depth of depths">
      <div class="depth" [class.parent]="depth<0" [class.child]="depth>0" [class.base]="depth ==0">
        <div class="{{record.type}}" *ngFor="let record of getDetailByDepth(depth)">
          {{record.type}}
          {{record.id}}
          {{record.name}}
          <div class="uxcomp" *ngFor="let uxcompId of record.childUxcompIds">
            <ng-container *ngIf="uxcompId && uxDetail.uxcomp[uxcompId]">
              {{uxcompId}}
              {{uxDetail.uxcomp[uxcompId].name}}
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
