import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { User } from 'src/common/models/user/user';
import { CommerceOrder } from 'src/common/models/commerce/commerceOrder';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UxComposite } from 'src/common/models/ux/uxComposite';
import { Note } from 'src/common/models/note';
import { collectionClassHelper } from '../../../common/decorators/database/collectionClass';
import { SpinnerService } from 'src/clientCommon/services/spinner.service';
import { JsonService } from 'src/clientCommon/services';
import { serverPaths } from '../../../common/helpers/pathHelpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogUtils } from '../../../common/utils/logUtils';
import { ServiceHelperService } from '../../../clientCommon/services/serviceHelper.service';
import { emailUtils } from '../../../common/utils/emailUtils';

interface RefundScenario {
  description: string;
  mood: string;
  series: string;
  refund: number;
  ra: boolean;
  weight: number;
  generalInstructionName: string;
  generalEmailName: string;
  refundEmailName: string;
  category: string;
}

@Component({
  selector: 'app-csr-action-recommender',
  templateUrl: './action-recommender.component.html',
  styleUrls: ['./action-recommender.component.scss'],
})

export class ActionRecommenderComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() baseCommerceOrder: CommerceOrder;
  @Input() uxComposite: UxComposite;
  @Input() customerUxComposite: UxComposite;

  // input for email auto fill closing
  @Input() agentAddress: any = "";
  @Input() agentPhoneNumber: any = "";
  @Input() brandName: string = "";
  @Input() noteData: any;

  // warning message
  @Input() warningMessage: string = "Remember: Cancel Account, Refund Account, Save Phone Number";

  @Output() onSuccess = new EventEmitter<void>();
  draftMessage: '';
  isShowMessage = false;

  refundScenario: RefundScenario[] = [];

  checkLabels: Record<string, string> = {};
  scenariosByCategory: Array<RefundScenario[]> = [];
  categoryNames: string[] = [];
  refundAmount = 0;

  refundLabels = {};

  categories = {};

  customerMood = 'N';
  customerType = 'C';
  userActionsList = [];
  emailList: Record<string, boolean> = {};
  instructionTexts = [];
  refundType = '';

  baseTemplate = '';
  emailData = '';
  canSave = false;
  scheduledAmount = 0;
  allAmount = 0;

  generalEmails = [];
  refundEmails = [];

  @Input() from = '';
  to = '';
  subject = '';
  outboundEmailEnabled = false;
  testimonials: Record<string, string> = {};

  constructor(
    private spinnerService: SpinnerService,
    private jsonService: JsonService,
    private snackBar: MatSnackBar,
    private serviceHelperService: ServiceHelperService
  ) {
  }

  initActionRecommender() {
    if (this.uxComposite) {
      this.outboundEmailEnabled = this.uxComposite.getUxcomp('comp.csr.action-recommender.outbound.enable');
      const commerceToken = this.baseCommerceOrder.getCommercePayments()[0]?.commerceToken;
      this.scheduledAmount = this.baseCommerceOrder?.commerceOffer?.commercePrice?.prices[1]?.amount;
      this.baseCommerceOrder?.commerceOffer?.commercePrice?.prices?.forEach((price) => this.allAmount += price.amount);
      const billingDates = this.baseCommerceOrder.tempClientSecured.billingDates || [];
      if (billingDates.length >= 2) {
        const gap = (billingDates[1] - billingDates[0]) / 1000 / 3600 / 24;
        this.uxComposite.setCode('trialLength', `${gap} days`);
      }
      if (billingDates.length >= 3) {
        const gap = (billingDates[2] - billingDates[1]) / 1000 / 3600 / 24;
        if (gap > 7) {
          this.uxComposite.setCode('billingCycle', 'MONTHLY');
        } else {
          this.uxComposite.setCode('billingCycle', 'WEEKLY');
        }
      }
      this.uxComposite.setCode('brand', this.user.brandId);
      this.uxComposite.setCode('brandName', this.capitalize(this.brandName));
      this.uxComposite.setCode('twoLinAddress', this.capitalize(this.agentAddress));
      this.uxComposite.setCode('brandPhone', this.capitalize(this.agentPhoneNumber));
      this.uxComposite.setCode('domain', this.baseCommerceOrder.meta.host.substring(this.baseCommerceOrder.meta.host.lastIndexOf(".www") + 1));
      this.uxComposite.setCode('customerId', this.user._id + `${this.getMMDD()}`);
      this.uxComposite.setCode('amount', this.refundAmount);
      this.uxComposite.setCode('brandAddress', this.user.brandId);
      this.uxComposite.setCode('today', new Date().toLocaleDateString().split(',')[0]);
      this.uxComposite.setCode('last4', commerceToken?.last4);
      this.uxComposite.setCode('expDate', `20${commerceToken?.expiration?.year}/${commerceToken?.expiration.month}`);
      this.uxComposite.setCode('ipAddress', commerceToken?.ipAddress);
      this.uxComposite.setCode('device', this.baseCommerceOrder.meta.device);
      this.uxComposite.setCode('zipCode', commerceToken?.billingAddress?.zip);
      this.uxComposite.setCode('email', commerceToken?.billingAddress?.email);
      this.uxComposite.setCode('billingName', `${commerceToken?.billingAddress?.firstName} ${commerceToken?.billingAddress?.lastName}`);
      this.uxComposite.setCode('checkbox.non-member-instruct', true);
      this.uxComposite.setCode('checkbox', {});
      this.refundScenario = this.uxComposite.getUxcomp('comp.csr.action-recommender.config') || [];
      this.refundLabels = this.uxComposite.getUxcomp('comp.csr.action-recommender.refund-labels') || {};
      this.categories = this.uxComposite.getUxcomp('comp.csr.action-recommender.categories') || {};
      this.baseTemplate = this.baseTemplate.concat(this.uxComposite.getUxcomp('comp.csr.action-recommender.base-template'));
      this.serviceHelperService.actionRecommenderService.setBaseTemplate(this.baseTemplate);
    }
    this.categoryNames = Object.keys(this.categories).map((k) => this.categories[k]);
    this.findCustomerActions();
  }

  capitalize(brandId: string): any {
    return brandId.charAt(0).toUpperCase() + brandId.slice(1)
  }

  ngOnInit() {
    this.to = this.user.email;
    this.subject = this.uxComposite.getUxcomp(`comp.csr.action-recommender.emails.subject`)
  }

  ngOnChanges(changes: SimpleChanges) {
    const uxComposite = changes.uxComposite;
    if (this.noteData) {
      this.baseTemplate = this.baseTemplate.concat(this.noteData)
      this.canSave = true
    }
    if (uxComposite) {
      this.initActionRecommender();
    }
  }

  findCustomerActions() {
    this.scenariosByCategory = [];
    Object.keys(this.categories).forEach((category) => {
      const scenariosByCategory = this.refundScenario.filter((rs) => rs.category === category);
      const scenariosByInstrut: Record<string, RefundScenario[]> = {};
      scenariosByCategory.forEach(scenario => {
        if (!scenariosByInstrut[scenario.description]) {
          scenariosByInstrut[scenario.description] = [scenario];
        } else {
          scenariosByInstrut[scenario.description].push(scenario);
        }
      })
      const resultScenarios = [];
      Object.keys(scenariosByInstrut).forEach((instruct) => {
        if (scenariosByInstrut[instruct].length === 1) {
          resultScenarios.push(scenariosByInstrut[instruct][0]);
        } else {
          const index = scenariosByInstrut[instruct].findIndex((scenario) => {
            let sx = '';
            switch (this.baseCommerceOrder.sequence) {
              case 0:
                sx = 'S0';
                break;
              case 1:
                sx = 'S1';
                break;
              default:
                sx = 'S2+';
                break;
            }
            if (scenario.series == sx) {
              if (scenario.mood === this.customerMood) {
                return true;
              } else if (scenario.mood === '') {
                return true;
              }
            }
            return false;
          });
          if (index > -1) {
            resultScenarios.push(scenariosByInstrut[instruct][index]);
          }
        }
      });
      this.scenariosByCategory.push(resultScenarios);
    });
  }

  handleUserAction(event: MatCheckboxChange) {
    if (!this.canSave) {
      this.canSave = true;
    }
    if (event.checked) {
      this.userActionsList.push(event.source.value);
    } else {
      this.userActionsList = this.userActionsList.filter((action) => action !== event.source.value);
    }
    this.findAllRecommendedData();
  }

  handleMoodChange(mood: string) {
    this.customerMood = mood;
    this.findCustomerActions();
    this.findAllRecommendedData();
  }

  handleTypeChange(type: string) {
    this.serviceHelperService.actionRecommenderService.setCustomerType(type)
    this.customerType = type;
    this.findCustomerActions();
    this.findAllRecommendedData();
  }

  handleChangeRefundAmount(value) {
    this.refundAmount = value;
    this.uxComposite.setCode('amount', this.refundAmount);
    this.findAllRecommendedData();
  }

  findAllRecommendedData() {
    const availableScenarios = this.refundScenario.filter((scenario) => {
      let sx = '';
      switch (this.baseCommerceOrder.sequence) {
        case 0:
          sx = 'S0';
          break;
        case 1:
          sx = 'S1';
          break;
        default:
          sx = 'S2+';
          break;
      }
      if (scenario.mood !== this.customerMood) {
        if (scenario.mood === '') {
          if (scenario.series !== sx) {
            if (scenario.series === '') {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        if (scenario.series === '') {
          return true;
        }
        return scenario.series === sx;
      }
    }).filter((scenario) => {
      return this.userActionsList.includes(scenario.generalInstructionName);
    }).sort((scenarioA, scenarioB) => {
      if (scenarioB.weight < scenarioA.weight) {
        return -1;
      }
      else if (scenarioB.weight > scenarioA.weight) {
        return 1;
      }
      return 0;
    });

    this.refundType = '';
    this.instructionTexts = [];
    if (availableScenarios.length) {
      const scenario = availableScenarios[0];
      if (scenario.refund > -1) {
        this.refundType = this.refundLabels[`${scenario.refund}`];
      } else {
        this.refundType = this.refundLabels['All'];
      }
      if (scenario.ra) {
        this.refundType += `. ${this.refundLabels['RA']}`;
      } else if (!scenario.ra) {
        this.refundType += `. ${this.refundLabels['NoRA']}`;
      }
    }
    const generalInstructions = [];
    this.userActionsList.forEach((checkitem) => {
      if (this.getInstruction(checkitem)) {
        generalInstructions.push(this.getInstruction(checkitem));
      }
    });
    const refundInstructions = availableScenarios.filter((s) => !!s.refundEmailName).sort((scenarioA, scenarioB) => {
      if (scenarioB.weight < scenarioA.weight) {
        return -1;
      }
      else if (scenarioB.weight > scenarioA.weight) {
        return 1;
      }
      return 0;
    }).map((s) => {
      return this.getInstruction(s.generalInstructionName);
    });
    this.instructionTexts = [...generalInstructions, refundInstructions[0]].filter((v, i, a) => a.indexOf(v) === i);
    this.generalEmails = availableScenarios.filter((s) => !!s.generalEmailName);
    this.refundEmails = availableScenarios.slice(0, 1);
    this.uxComposite.setCode('checkbox', {});
    this.generalEmails.forEach((email) => {
      this.uxComposite.setCode(`checkbox.${email.generalInstructionName}`, true);
    });
    this.refundEmails.forEach((email) => {
      this.uxComposite.setCode(`checkbox.${email.refundEmailName}`, true);
    })

    // when checkbox change, also re add auto file closing at the end
    if (this.noteData){
      this.baseTemplate = this.uxComposite.getUxcomp('comp.csr.action-recommender.base-template');
      this.baseTemplate = this.baseTemplate.concat(this.noteData)
    }
    else{
      this.baseTemplate = this.uxComposite.getUxcomp('comp.csr.action-recommender.base-template');
    }
  }

  // validate the emails accordingly
  validEmails(checkSubject: boolean) {
    if (!this.from || !this.to) {
      return false;
    }
    if (!this.subject && checkSubject) {
      return false;
    }
    if (!emailUtils.validateEmail(this.from)) {
      return false;
    }
    let flag = true;
    this.to.split(",").some((email) => {
      if (!emailUtils.validateEmail(email)) {
        flag = false;
        return true;
      }
    })
    return flag;
  }

  // create email notes after validating email accordingly
  createNewNote(value) {
    if (!this.validEmails(false)) {
      this.snackBar.open('From and to field must not be empty', 'error', { duration: 2000 });
      return;
    }
    let sx = '';
    let sequence = '';
    switch (this.baseCommerceOrder.sequence) {
      case 0:
        sx = 'S0';
        sequence = '0';
        break;
      case 1:
        sx = 'S1';
        sequence = '1';
        break;
      default:
        sx = 'S2+';
        sequence = '1+';
        break;
    }
    const newNote = new Note();
    const descriptions = [];
    const emails = [];
    const scenarios: RefundScenario[] = [];
    Object.values(this.scenariosByCategory).forEach((values) => scenarios.push(...values));
    this.userActionsList.forEach((action) => {
      let s = scenarios.find((s) => s.generalInstructionName === action);
      if (s) {
        descriptions.push(s.description);
        emails.push(s.generalEmailName);
      } else {
        descriptions.push();
        emails.push();
      }
    })
    newNote.draft.referenceCollection = collectionClassHelper.getCollectionName(User);
    newNote.draft.referenceId = this.user._id;
    if(value == false){
      newNote.draft.type = 'Call';
      }
      else{
      if(this.customerType == 'C'){newNote.draft.type = 'Call';}
      else if(this.customerType == 'E'){newNote.draft.type = 'Email';}
      else if(this.customerType == 'PO'){newNote.draft.type = 'Post-Office';}
      else{newNote.draft.type = 'Email';}
      }
    newNote.draft.note = this.baseTemplate;
    newNote.draft.from = this.from;
    newNote.draft.to = this.to;
    newNote.draft.subject = this.subject;
    newNote.draft.data = {
      actionRecommends: {
        descriptions: this.userActionsList,
        amount: this.refundAmount,
        mood: this.customerMood,
        refundLabel: this.refundEmails[0]?.refundEmailName || (this.refundEmails[0]?.ra ? 'ra' : ''),
        emails: emails,
        sequence: sequence,
      },
      testimonials: this.testimonials,
    };
    return newNote;
  }

  // Save and send email after click on button accordingly
  handleSendEmails(value) {
    this.isShowMessage = true;
    const newNote = this.createNewNote(value);
    const input = { notes: [newNote], supportEmail: this.from };
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrSendEmail, input).then(() => {
      this.canSave = false;
      this.spinnerService.unspin();
      this.snackBar.open('Email', 'Sent', { duration: 2000 });
      this.onSuccess.emit();
    }).catch((e) => {
      this.spinnerService.unspin();
      LogUtils.error(e);
    });

  }

  // Save email in form of notes and do not send email
  handleSaveEmails(value) {
    this.isShowMessage = true;
    const newNote = this.createNewNote(value);
    const input = { notes: [newNote] };
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.manageCsrSaveNotes, input).then(() => {
      this.canSave = false;
      this.spinnerService.unspin();
      this.snackBar.open('Note', 'Add', { duration: 2000 });
      this.onSuccess.emit();
    }).catch((e) => {
      this.spinnerService.unspin();
      LogUtils.error(e);
    }).then(() => { });
  }

  isChecked(value) {
    return this.userActionsList.includes(value);
  }

  getInstruction(key: string) {
    return this.uxComposite.getUxcomp(`comp.csr.action-recommender.instructions.${key}`) || '';
  }

  getEmail(key: string) {
    return this.uxComposite.getUxcomp(`comp.csr.action-recommender.emails.${key}`) || '';
  }

  getRefundEmail(key: string) {
    return this.uxComposite.getUxcomp(`comp.csr.action-recommender.refund-emails.${key}`) || '';
  }

  getMMDD() {
    let d = new Date();
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let ddmm = `${month > 9 ? month : '0' + month}${day > 9 ? day : '0' + day}`;
    return ddmm;
  }
}
