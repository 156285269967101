<style>
  #confirmationDialog {
    padding: 15px;
    min-width: 300px;
    white-space: pre-line;
  }

  #confirmationDialog > h2 {
    margin: 0;
    margin-bottom: 50px;
    font-weight: normal;
    font-size: 18px;
  }

  #confirmationDialog > div.buttons {
    display: flex;
    justify-content: space-between;
  }

  #confirmationDialog > div.buttons > button {
    padding: 8px 10px;
    min-width: 90px;
    cursor: pointer;
  }

  #confirmationDialog > div.buttons > button.cancel {
    border: 1px solid red;
  }

  #confirmationDialog > div.buttons > button.confirm {
    border: 1px solid #e2e2e2;
  }

  .loader {
    width: 36px;
    height: 36px;
    border: 5px solid rgb(121, 51, 185);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }

  .progress {
    .time {
      top: -12px;
      position: relative;
      left: -12px;
    }
  }
</style>

<div id="confirmationDialog">
  <h2 md-dialog-title>{{confirmMessage}}</h2>
  <div class="buttons">
    <button class="mdc-button mdc-button--raised mat-mdc-raised-button mat-mdc-button-base" (click)="dialogRef.close(false)">Cancel</button>
    @if (showConfirmButton) {
      <button class="mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base" (click)="dialogRef.close(true)">Confirm</button>
    }
    @else {
      <div class="progress">
        <span class="time">Confirm button in {{ progressSeconds }} seconds.</span>
        <span class="loader"></span>
      </div>
    }
  </div>
</div>