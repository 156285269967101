<style>
  .spinner {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: black;
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 99999;
  }

  .inline {
    display: inline-block;
  }

  a {
    margin: 0 5px;
  }

  .header {
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: #673ab7;
    padding: 18px 48px;
  }

  .header .logo {
    font-size: 20px;
    font-weight: 700;
  }

  .header,.nav-container a {
    text-decoration: none;
    color: #673ab7;
    margin: 0 12px;
  }

  .nav-container {
    font-family: 'Roboto', sans-serif;
    width: 240px;
    padding: 12px 0;
    text-align: center;
  }

  .nav-container a {
    width: 100%;
  }

  .layout {
    height: 100vh;
  }

  .sidebar-link {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .header .clock-type{
    margin-left: 8px;
  }

  .header .timetracker {
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  .header .timetracker .stopped {
    color: #673ab7;
  }

  .header .timetracker .active {
    color: #f44336;
  }

  .header .timetracker span {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
  }

  .header .first-action {
    margin-left: auto;
  }
</style>
<mat-sidenav-container class="layout" (backdropClick)="close()">
  <mat-sidenav #sidenav disableClose position='end'>
    <div class="nav-container">
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.uxcFullMgmt)" [routerLink]="['/'+adminPaths.uxcFullMgmt]">UXC</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.productFullMgmt)" [routerLink]="['/'+adminPaths.productFullMgmt]">Products</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.userMgmt)" [routerLink]="['/'+adminPaths.userMgmt]">Users</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="adminAuthGuard.canActivatePath(adminPaths.permissions)" [routerLink]="['/'+adminPaths.permissions]">Permissions</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.mailSent)" [routerLink]="['/'+adminPaths.mailSent]">MailSent</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.tracking)" [routerLink]="['/'+adminPaths.tracking]">Tracking</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.contentMgmt)" [routerLink]="['/'+adminPaths.contentMgmt]">Content</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.emailMgmt)" [routerLink]="['/'+adminPaths.emailMgmt]">Emails</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.notes)" [routerLink]="['/'+adminPaths.notes]">Notes</a>
      </div>
      <!-- <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.uxHistoryMgmt)" [routerLink]="['/'+adminPaths.uxHistoryMgmt]">Ux History</a>
      </div> -->
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.unsubscribe)" [routerLink]="['/'+adminPaths.unsubscribe]">Unsubscribe</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.timesheets)" [routerLink]="['/'+adminPaths.timesheets]">Timesheet</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.mainSentV1)" [routerLink]="['/'+adminPaths.mainSentV1]">MailSent V1</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.trackingV1)" [routerLink]="['/'+adminPaths.trackingV1]">Tracking V1</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.optOutViewV1)" [routerLink]="['/'+adminPaths.optOutViewV1]">OptOut V1</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.commercePayment)" [routerLink]="['/'+adminPaths.commercePayment]">Transaction</a>
      </div>
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.commerceOrder)" [routerLink]="['/'+adminPaths.commerceOrder]">Orders</a>
      </div>      
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.uxcHistory)" [routerLink]="['/'+adminPaths.uxcHistory]">UXC History</a>
      </div>      
      <div class="sidebar-link">
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.scheduleHistory)" [routerLink]="['/'+adminPaths.scheduleHistory]">Database</a>
      </div>      

      <div class="sidebar-link">
        <a *ngIf="adminAuthGuard.canActivatePath(adminPaths.uxcFullMgmt) || csrAuthGuard.canActivatePath(adminPaths.csrCustomers)" [routerLink]="['/login']">Logout</a>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="header" *ngIf="isLoggedIn">
      <span class="logo">Logo Name</span>
      <div class="timetracker" *ngIf="timesheetService.isCsr()">
        <button (click)="timesheetService.addNewRecord()" class="clock-type" mat-stroked-button *ngIf="!timesheetService.checkLastStatus()" color="primary">Clock In</button>
        <button (click)="timesheetService.addNewRecord()" class="clock-type" mat-stroked-button *ngIf="timesheetService.checkLastStatus()" color="warn">Clock Out</button>
        <span>Current Time ET: {{timesheetService.getDateTime(false)}}</span>
        <span *ngIf="timesheetService.lastRecord" [class.stopped]="!timesheetService.checkLastStatus()" [class.active]="!timesheetService.checkLastStatus()">Last Action: {{timesheetService.lastRecord?.type}} | {{timesheetService.getDateTime(true)}}</span>
      </div>
      <div class="timetracker">
        <span>{{timesheetService.getLastCsrEvent()}}</span>
      </div>
      <div>
        <a class="first-action __under-line" (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
          <span >Info</span>
        </a>
        <a class="first-action" *ngIf="csrAuthGuard.canActivatePath(adminPaths.emailMgmt)" [routerLink]="['/'+adminPaths.emailMgmt]">Emails</a>
        <a class="first-action" *ngIf="csrAuthGuard.canActivatePath(adminPaths.csrCustomers)" [routerLink]="['/'+adminPaths.csrCustomers]">Customers</a>
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.optOutView)" [routerLink]="['/'+adminPaths.optOutView]">OptOut</a>
        <a *ngIf="csrAuthGuard.canActivatePath(adminPaths.factFinders)" [routerLink]="['/'+adminPaths.factFinders]">Fact Finders</a>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="isOpen"
        >
        <ng-container>          
            <app-info-overlay [infoTemplate]="info"></app-info-overlay>            
          </ng-container>
        </ng-template>
      </div>
      <button mat-icon-button (click)="sidenav.open()">
        <i class="material-icons">list</i>
      </button>
    </div>
    <router-outlet></router-outlet>
    <ng-container *ngIf="spinnerService.spinner">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
  </mat-sidenav-content>  
</mat-sidenav-container>


