import { LogUtils } from '../../../common/utils/logUtils';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { adminPaths, serverPaths } from 'src/common/helpers/pathHelpers';
import { EmailRecord } from 'src/common/models/emailRecord';
import { Note } from 'src/common/models/note';
import { ModelBase } from 'src/common/models/modelBase';

@Component({
  selector: 'app-csr-factFinders',
  templateUrl: './factFinders.component.html',
  styleUrls: ['./factFinders.component.scss']
})
export class CSRFactFindersComponent implements OnInit {
  tabs = {
    0: () => { },
    1: () => { },
    2: () => { },
    3: () => { }
  };

  currentStatus = ModelBase.STATUS.locked;
  tableData= [];
  csr = {};
  emailRecords: EmailRecord[] = [];
  closedRecords: EmailRecord[] = [];
  archivedRecords: EmailRecord[] = [];
  allRecords: EmailRecord[] = [];

  pagination = {
    totalPages: 1,
    currentPage: 1,
    totalRecords: 0,
    perPage: 25,
    hasNext: false,
    hasPrev: false
  };
  
  emailBody = {};

  constructor(private serviceHelperService: ServiceHelperService, private snackBar: MatSnackBar) {
    this.csr = this.serviceHelperService.authenticationService.getUser();

    this.tabs[0] = this.getEmailRecords;
  }

  ngOnInit(): void {
  }

  getEmailRecords() {
    this.currentStatus = 'active';
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    return;
  }

  getClosedEmailRecords() {
    this.currentStatus = 'closed';
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    
  }

  getAllRecords() {
    this.currentStatus = 'all';
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1 };
    return;
  }

  // onNextPage() {
  //   if (!this.pagination.hasNext) { return; }
  //   this.pagination.currentPage++;
  // }

  onSelectChange(event) {
    this.pagination = { ...this.pagination, currentPage: 1, totalPages: 1, perPage: event.perPage };
  }

  // onPrevPage() {
  //   if (!this.pagination.hasPrev) { return; }
  //   this.pagination.currentPage--;
  // }

  test(event) {
    switch (event.index) {
      case 0:
        this.currentStatus = ModelBase.STATUS.locked;
        break;
      case 1:
        this.currentStatus = ModelBase.STATUS.done;
        break;
      case 2:
        this.currentStatus = ModelBase.STATUS.inProgress;
        break;

      default:
        this.getAllRecords();
        break;
    }
  }

}
