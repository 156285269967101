
<div class="csr-email">
        <div id="search-btn-wrapper">
            <button id="search-btn" mat-raised-button color="primary" (click)="searchModal()">SEARCH</button>
            <a id="clear-btn" class="under-line" (click)="clearFilter()">Clear Filter</a>
        </div>
    <mat-tab-group *ngIf="uxc" (selectedTabChange)="test($event)">
        <mat-tab> 
            <ng-template mat-tab-label>
                <span>UX-COMP</span>
              </ng-template>
            <app-ux-history-table
                              (onPaginationChange)="onPaginationChanged($event)"
                              [pagination]="pagination"
                              (onNextPage)="onNextPage()"
                              (onPrevPage)="onPrevPage()"
                              [tableData]="allRecords"></app-ux-history-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>UXCC</span>
              </ng-template>
            <app-ux-history-table 
                              (onPaginationChange)="onPaginationChanged($event)"
                              [pagination]="pagination"
                              (onNextPage)="onNextPage()"
                              (onPrevPage)="onPrevPage()"
                              [tableData]="allRecords"></app-ux-history-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>UXL</span>
              </ng-template>
            <app-ux-history-table 
                              (onPaginationChange)="onPaginationChanged($event)"
                              [pagination]="pagination"
                              (onNextPage)="onNextPage()"
                              (onPrevPage)="onPrevPage()"
                              [tableData]="allRecords"></app-ux-history-table>
        </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>UXC</span>
                    </ng-template>
                    <app-ux-history-table 
                                        (onPaginationChange)="onPaginationChanged($event)" 
                                        [pagination]="pagination"
                                        (onNextPage)="onNextPage()" 
                                        (onPrevPage)="onPrevPage()" 
                                        [tableData]="allRecords"></app-ux-history-table>
                </mat-tab>

    </mat-tab-group>
    <div class="d-flex" *ngIf="pagination.totalRecords > 0">
      <div class="ml-auto d-flex">
          <span class="m-auto m-10">Results per page</span>
          
          <mat-form-field class="m-auto" appearance="outline" floatLabel="always" style="width: 85px; margin-bottom: -18px;">
              <mat-select (valueChange)="onSelectChange($event)" [(ngModel)]="pagination.perPage">
                  <mat-option *ngFor="let page of [5, 25, 50, 75, 100, 125]" [value]="page">{{ page }}</mat-option>
              </mat-select>
          </mat-form-field>
          
          <span class="m-auto m-10">
              {{pagination.currentPage}} of {{pagination.totalPages}} pages 
          </span>
          <span class="mt-auto mb-auto m-10">
              <mat-icon [ngClass]="{'disabled': !pagination.hasPrev, 'dark-gray': pagination.hasPrev}" aria-hidden="false" (click)="onPrevPage()" aria-label="Example home icon">keyboard_arrow_left</mat-icon>
              <mat-icon [ngClass]="{'disabled': !pagination.hasNext, 'dark-gray': pagination.hasNext}" aria-hidden="false" (click)="onNextPage()" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
          </span>
      </div>
  </div>
</div>
  
