import {Injectable} from '@angular/core';
import {JsonService} from '../../clientCommon/services';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {TimeSheet} from '../../common/models/timesheet';
import {User} from '../../common/models/user/user';
import {ResponseEvent} from '../../common/event/responseEvent';
import {RequestEvent} from '../../common/event/requestEvent';
import {LogUtils} from '../../common/utils/logUtils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ServiceHelperService} from 'src/clientCommon/services/serviceHelper.service';
import momenttz from 'moment-timezone';

@Injectable()
export class TimesheetService {
  public lastRecord: TimeSheet;
  public lastCsr: User;
  public lastCsrRecord: TimeSheet;
  public currentTimestamp = 0;

  constructor(private snackBar: MatSnackBar,
              private jsonService: JsonService,
              private serviceHelperService: ServiceHelperService) {
  }

  findLatestTimeSheet() {
    let requestEvent = new RequestEvent();
    this.jsonService.json(serverPaths.timeSheetGetLatest, requestEvent).then((responseEvent: ResponseEvent) => {
      this.lastRecord = responseEvent.getSingleDoc();
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  findLatestGlobalTimeSheet() {
    let requestEvent = new RequestEvent();
    this.jsonService.json(serverPaths.timeSheetGetGlobalLatest, requestEvent).then((responseEvent: ResponseEvent) => {
      this.lastCsrRecord = responseEvent.getSingleDoc();
      this.lastCsr = new User(responseEvent.data.user);
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  addNewRecord() {
    let requestEvent = new RequestEvent();
    this.jsonService.json(serverPaths.timeSheetCreate, requestEvent).catch((e) => {
      LogUtils.error(e);
    }).then((responseEvent: ResponseEvent) => {
      this.lastRecord = responseEvent.getSingleDoc();
      this.snackBar.open(`Timer ${this.lastRecord.type === TimeSheet.TYPE.clock_in ? 'Started' : 'Stopped'}`, 'Timer', {duration: 2000});
    });
  }

  isCsr() {
    const user = this.serviceHelperService.authenticationService.getUser();
    if (!user) {
      return false;
    }
    return user.roles.includes(User.ROLES.csr);
  }

  checkLastStatus() {
    return this.lastRecord?.type === TimeSheet.TYPE.clock_in;
  }

  isDST(d: Date) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) != d.getTimezoneOffset();
  }

  convertFullDigit(number: number) {
    if (number < 10) {
      return `0${number}`;
    } else {
      return `${number}`;
    }
  }

  getDateTimeString(date: Date) {
    const str = momenttz(date).tz('America/New_York').format("YYYY/MM/DD HH:mm:ss");
    return str;
  }

  getDateTime(isLast = false) {
    let date: Date;
    if (isLast) {
      date = new Date(this.lastRecord.timestamp);
    } else {
      date = new Date(this.currentTimestamp);
    }
    return this.getDateTimeString(date);
  }

  getCurrentTimestamp() {
    this.currentTimestamp = momenttz().tz('America/New_York').toDate().getTime();
  }

  getLastCsrEvent() {
    if (!this.lastCsr || !this.lastCsrRecord) {
      return '';
    }
    if (this.lastCsrRecord.type === TimeSheet.TYPE.clock_in) {
      return `${this.getDateTimeString(new Date(this.lastCsrRecord.timestamp))}: ${this.lastCsr.firstName} ${this.lastCsr.lastName} is here.`
    }
    return `${this.getDateTimeString(new Date(this.lastCsrRecord.timestamp))}: ${this.lastCsr.firstName} ${this.lastCsr.lastName} says "Bye!".`
  }
}
