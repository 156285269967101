import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { EmailRecord } from "../../common/models/emailRecord";
import { ManageEmailService } from '../../clientCommon/services/manageEmail.service';
import { ModelBase } from 'src/common/models/modelBase';
import { LogUtils } from 'src/common/utils/logUtils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { ActivatedRoute } from '@angular/router';
import { configUtils } from '../utils/ConfigUtils';
import { User } from 'src/common/models/user/user';
import { commerceUrls } from '../../common/models/commerce/commerceEvent';
import { JsonService } from 'src/clientCommon/services';
import { ResponseEvent } from '../../common/event/responseEvent';
import { CommerceOrder } from '../../common/models/commerce/commerceOrder';
import { SpinnerService } from "src/clientCommon/services/spinner.service";
import { UxHelper } from "src/clientCommon/helper/uxHelper";

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailComponent extends BaseDirective implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentView = "inbox";
  inboxEmails: MatTableDataSource<EmailRecord>;
  inboxDisplayedColumns = ["select", "date", "brand", "from", "subject", "status"];
  currentEmail: EmailRecord;
  isShowingDetail = false;
  editBilling = '';
  editData = '';
  editGeneral = '';
  attachments: File[] = [];
  showEmailReplyPopup = false;
  focusOnEditSubject = false;
  isUnlink = true;
  replyMessage = '';
  emailContent: SafeHtml;
  parentContent: SafeHtml;
  moveDirectory: string;
  customerId = '';
  currentCustomer: User = new User();
  lastBilled = '';
  total = 0;
  curFolder = 'INBOX';
  listItems: any[] = [];
  curPage = 0;
  isLoadingResults = false;
  totalCount = 0;
  emails: EmailRecord[] = [];

  replyType = 'reply';
  fontBarVisible = false;
  directories = [];
  templates = [];

  directoryItems = [];
  resultMenu = {};
  uxHelper: UxHelper = new UxHelper();

  @ViewChild('addAttachment') addAttachment: ElementRef<HTMLInputElement>;

  constructor(
    public serviceHelperService: ServiceHelperService,
    public activatedRoute: ActivatedRoute,
    private manageEmailService: ManageEmailService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private jsonService: JsonService,
    private spinnerService: SpinnerService
  ) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    return super.baseInit().then(() => {
      if (this.uxComposite) {
        this.uxHelper.uxComposite = this.uxComposite;
        const data = configUtils.getEmailConfig(this.uxComposite);
        this.directories = data?.directories?.map(data => data.directory) || [];
        this.templates = data?.templates || [];

        this.directories.forEach((menu) => {
          const dirs = menu.split("|");
          let currentDirLevel = this.resultMenu;
          dirs.forEach((dir) => {
            if (!currentDirLevel[dir]) {
              currentDirLevel[dir] = {};
            }
            currentDirLevel = currentDirLevel[dir];
          });
        });

        this.directoryItems = [this.convertTree("Move To", { "Move To": this.resultMenu })];
      }
      return this.findAllEmails(this.curFolder);
    })
  }

  ngAfterViewInit() {
    this.paginator.page.asObservable().subscribe((data) => {
      this.curPage = data.pageIndex;
      this.findAllEmails(this.curFolder);
    })
  }

  convertTree(curKey: string, curLevel: any) {
    const isEmptyObject = !!curLevel?.[curKey] 
      && typeof curLevel[curKey] === 'object'
      && Object.keys(curLevel[curKey]).length === 0;

    if (isEmptyObject) {
      return {
        name: curKey,
        children: [],
      };
    }

    const childTree = Object.keys(curLevel[curKey]).map((childKey) =>
      this.convertTree(childKey, curLevel[curKey])
    );
    return {
      name: curKey,
      children: childTree,
    };
  };

  setCurrentContent(content: EmailRecord) {
    this.currentEmail = content;
    this.replyMessage = '--------------------------' + '\n' + this.currentEmail.body + '\n' + '--------------------------' + '\n';
    this.isShowingDetail = true;
    this.isUnlink = !content.ownerId;
    this.total = 0;
    let first = true;
    if (content.ownerId) {
      this.jsonService.json(commerceUrls.csrFindCustomer + '/' + content.ownerId, {}).then((responseEvent: ResponseEvent) => {
        const docs = responseEvent.getDocs();
        docs.forEach((obj: ModelBase<any>) => {
          if (obj instanceof User) {
            this.currentCustomer = obj;
          } else if (obj instanceof CommerceOrder) {
            const payments = obj.getCommercePayments();
            payments.forEach((payment) => {
              this.total += payment.price.amount;
            })
            if (first) {
              first = false;
              this.lastBilled = `$${payments[0].price.amount} on ${new Date(payments[0].paymentTimestamp).toLocaleDateString()}`
            }
          }
        });
      })
    }
    this.emailContent = this.sanitizer.bypassSecurityTrustHtml(content.body);
    if (content.parentId) {
      const repliedContent = this.inboxEmails?.data?.find(email => email._id === content.parentId)?.body;
      this.parentContent = this.sanitizer.bypassSecurityTrustHtml(repliedContent);
    }
  }

  goBackToList() {
    this.findAllEmails(this.curFolder);
    this.isShowingDetail = false;
    this.parentContent = null;
  }

  onAddAttachment() {
    this.addAttachment.nativeElement.click();
  }

  onEditSubject() {
    this.showEmailReplyPopup = true;
    this.focusOnEditSubject = true;
  }

  onReplyPopup() {
    this.showEmailReplyPopup = true;
    this.focusOnEditSubject = false;
  }

  findAllEmails(foldername) {
    this.spinnerService.spin();
    this.manageEmailService.getAllEmails(foldername, this.curPage).then((result: ResponseEvent) => {
      const emailResults = result.getDocs();
      this.totalCount = result.data.totalCount;
      this.isShowingDetail = false;
      this.emails = emailResults;
    }).catch((err) => {
      LogUtils.error(err);
    }).finally(() => this.spinnerService.unspin());
  }

  linkOrUnlinkCustomer(isLink) {
    let customerId = this.customerId;
    if (!isLink) customerId = this.currentEmail.ownerId;
    this.manageEmailService.linkUnlink(this.currentEmail._id, customerId, isLink).then(() => {
      this.isUnlink = !isLink;
    }).catch((err) => {
      LogUtils.error(err);
    });
  }

  findEmailsByStatus(status: string) {
    this.manageEmailService.getEmailsByStatus(status).then(results => {
      this.emails = results;
    }).catch((err) => {
      LogUtils.error(err);
    });
  }

  onReplyTo() {
    this.manageEmailService.replyToEmail(this.currentEmail._id, this.replyMessage).then(success => {
      this.snackBar.open(success, 'close');
      this.findAllEmails(this.curFolder);
    }).catch(err => {
      this.snackBar.open(err, 'close');
      LogUtils.error(err);
    });
  }

  onStatusUpdate() {
    const updateStatus = this.currentEmail.status === ModelBase.STATUS.active ? ModelBase.STATUS.fulfilled : ModelBase.STATUS.active;
    this.manageEmailService.updateEmailStatus(this.currentEmail._id, updateStatus).then(success => {
      this.snackBar.open(success, 'close');
      this.findAllEmails(this.curFolder);
    }).catch(err => {
      this.snackBar.open(err, 'close');
      LogUtils.error(err);
    })
  }

  handleMoveDirectory(name) {
    this.manageEmailService.moveDirectory(this.currentEmail._id, name).then(success => {
      this.snackBar.open(success, 'close');
      // this.findAllEmails(this.curFolder);
    }).catch(err => {
      this.snackBar.open(err, 'close');
      LogUtils.error(err);
    })
  }

  onMoveDirectory(name) {
    this.isShowingDetail = false;
    this.curFolder = name;
    this.findAllEmails(this.curFolder);
  }

  handleChangeTemplate(template: any) {
    const data = template.value;
    this.replyMessage = this.currentEmail.body + '\r\n' + '--------------------------' + '\r\n' + this.uxHelper.getValue(data.key);
  }
}
