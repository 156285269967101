import {UserMgmtComponent} from "./userMgmt.component";
import {UserMgmtComponentV1} from "./userMgmtV1.component";
import {PermissionsComponent} from "./permissions.component";
import {PermissionsComponentV1} from "./permissionsV1.component";

export const USERS_DECLARATIONS = [
  UserMgmtComponent,
  UserMgmtComponentV1,
  PermissionsComponent,
  PermissionsComponentV1,
];
