import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent implements OnInit {
  @Input() items: any[];
  @ViewChild('childMenu') public childMenu;

  @Output() onClickedMenu = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
  }

  handleClick(name, index) {
    let updateName = name;
    if (index > -1)
      updateName = this.items[index].name + '|' + updateName;
    this.onClickedMenu.emit(updateName);
  }
}
