import {IndexField} from "../../decorators/database/indexField";
import {ModelBase} from "../modelBase";
import {CollectionClass} from "../../decorators/database/collectionClass";

@CollectionClass({name: "managedPhone", revisionEnabled: false})
export class ManagedPhone extends ModelBase<ManagedPhone> {
  public static SUB_STATUS = {
    subscribed: "subscribed",
    unsubscribed: "unsubscribed",
    none: "none",
  };

  @IndexField({unique: true}) public uniqueId: string; // Brand id + email. This is for unique-check
  @IndexField() public phone: string;
  @IndexField() declare public subStatus: string;

  uxcId: string;
  uxlId: string;
  @IndexField() sessionId: string;
  disclaimerUxcompId: string;
  disclaimerRevisionId: string;
  provider: string;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getSecuredDoc(): ManagedPhone {
    var obj: ManagedPhone = super.getSecuredDoc();

    obj.phone = this.phone;

    return obj;
  }

  createUniqueId() {
    return `${this.brandId}|${this.phone}|${this.provider}`.toLowerCase();
  }

  isSubscribed() {
    return this.subStatus === ManagedPhone.SUB_STATUS.subscribed;
  }
}
