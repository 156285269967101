import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Guard} from '../../clientCommon/helper/guard';
import {ServiceHelperService} from '../../clientCommon/services/serviceHelper.service';

@Injectable()
export class AuthGuard extends Guard {

  constructor(serviceHelperService: ServiceHelperService) {
    super(serviceHelperService);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.serviceHelperService.authenticationService.getUser();
    if (user) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.serviceHelperService.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
