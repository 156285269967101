import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../clientCommon/services/index';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {User} from '../../common/models/user/user';
import {adminPaths} from '../../common/helpers/pathHelpers';
import {ReferService} from '../../clientCommon/services/refer.service';
import {inputUtils} from '../../clientCommon/utils/inputUtils';

@Component({
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  model: any = {};
  returnUrl: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              public spinnerService: SpinnerService,
              private referService: ReferService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  login() {
    this.spinnerService.spin();
    this.authenticationService.loginManager(this.model.username, this.model.password).then((user: User) => {
      this.referService.setReferUser(user);
      if (user.isValid([User.ROLES.admin])) {
        this.router.navigate([adminPaths.uxcFullMgmt]);
      } else {
        this.router.navigate([adminPaths.csrCustomers]);
      }
    }).catch((e) => {
      if (e === false) {
        this.snackBar.open('Login Fail', 'Error', {duration: 2500});
      } else {
        this.snackBar.open('Login error', 'Error', {duration: 2500});
      }
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  isValid() {
    return this.model.username && this.model.password && (!this.spinnerService.isSpinning());
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.login();
    }
  }
}
