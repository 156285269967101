import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {SpinnerService} from '../../clientCommon/services/spinner.service';
import {collectionClassHelper} from '../../common/decorators/database/collectionClass';
import {CrudService} from '../../clientCommon/services/crud.service';
import {Permission} from '../../common/models/permission';
import {ModelBase} from '../../common/models/modelBase';
import {LogUtils} from '../../common/utils/logUtils';

@Component({
  templateUrl: './permissionsV1.component.html'
})

export class PermissionsComponentV1 implements OnInit {

  alertDuration = 5000;

  permissions: Permission[] = [];
  newPermission: Permission;

  constructor(private crudService: CrudService,
              private spinnerService: SpinnerService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): Promise<any> {
    return this.init();
  }

  init(): Promise<any> {
    this.newPermission = new Permission();
    this.newPermission.type = Permission.TYPE.site;
    return this.search();
  }

  search(): Promise<any> {
    const selectQuery: any = {type: Permission.TYPE.site};
    this.spinnerService.spin();
    return this.crudService.read(CrudService.URL.admin, collectionClassHelper.getCollectionName(Permission), selectQuery).then((results) => {
      this.permissions = results;
    }).catch((e) => {
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  add(): Promise<any> {
    const obj = this.newPermission;
    if (obj.draft.name) {
      obj.draft.status = ModelBase.STATUS.active;

      this.spinnerService.spin();
      return this.crudService.create(CrudService.URL.admin, obj).then(() => {
        this.snackBar.open('adding done.' + obj.draft.name, 'Add', {duration: this.alertDuration});
      }).then(() => {
        this.newPermission = new Permission();
        return this.search();
      }).catch((e) => {
        LogUtils.error(e);
        this.snackBar.open('ERROR adding ' + obj.draft.name, 'Add', {duration: this.alertDuration});
      }).then(() => {
        this.spinnerService.unspin();
      });
    }
  }

  update(obj: Permission): Promise<any> {
    this.spinnerService.spin();
    return this.crudService.updateDoc(CrudService.URL.admin, [obj]).then((result) => {
      this.snackBar.open('updating done. ' + obj.draft.name, 'Update', {duration: this.alertDuration});
    }).then(() => {
      return this.search();
    }).catch((e) => {
      this.snackBar.open('ERROR updating ' + obj.draft.name, 'Error', {duration: this.alertDuration});
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  addPath(permission: Permission, path) {
    permission.paths.push({key: path.value});
    path.value = '';
  }

  addProperty(permission: Permission, property) {
    permission.properties.push({key: property.value});
    property.value = '';
  }

  removeItemInArray(array: any[], index) {
    array.splice(index, 1);
  }
}
