<div class="csr-email">
    <mat-tab-group *ngIf="csr" (selectedTabChange)="test($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <span>Open</span>
            </ng-template>
            <app-factFinders-table *ngIf="currentStatus == 'locked'" [currentStatus]="currentStatus"></app-factFinders-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>Complete</span>
            </ng-template>
            <app-factFinders-table *ngIf="currentStatus == 'done'" [currentStatus]="currentStatus"></app-factFinders-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>My Tasks</span>
            </ng-template>
            <app-factFinders-table *ngIf="currentStatus == 'inProgress'" [currentStatus]="currentStatus"></app-factFinders-table>
        </mat-tab>
    </mat-tab-group>
</div>