import {LogUtils} from "../utils/logUtils";

export class TestHelper {

  initEnvironmentClient() {
    LogUtils.setTest();
  }

  beforeEachClient() {
    localStorage.setItem('uxcId', '5a907426d7f5b24c5863931a');
    localStorage.setItem('uxlId', '5ac98e7e52766f1ecb32f81e');
  }

  beforeEachAdmin() {

  }
}

export const testHelper = new TestHelper ();
