<style>
    #uxcMgmt {
      word-break: break-all;
    }
  
    .inline {
      display: inline-block;
    }
  
    .vertical-middle {
      vertical-align: middle;
    }
  
    .action {
      cursor: pointer;
    }
  
    .handle {
      cursor: move;
    }
  
    .removed input,
    .removed textarea {
      text-decoration: line-through !important;
    }
  
    mat-form-field {
      font-size: 0.9em;
    }
  
    mat-form-field.id {
      width: 210px;
    }
  
    .weight {
      width: 45px;
    }
  
    .mat-select {
      width: 100px;
    }
  
    .mode-view .id {
      width: 200px;
      color: rgba(0, 0, 0, 0.50);
      font-size: 0.9em;
    }
  
    .mode-view .name,
    .mode-view .desc,
    .mode-view .slotType-weight,
    .mode-view .contentType,
    .mode-view .flag {
      color: rgba(0, 0, 0, 0.50);
      font-size: 0.7em;
    }
  
    mat-form-field.name,
    mat-form-field.uxcDesc,
    mat-form-field.uxlDesc,
    mat-form-field.uxccDesc,
    mat-form-field.uxcompDesc,
    .uxcWrapper .mode-view .uxcInfo,
    .uxlWrapper .mode-view .uxlInfo,
    .uxccWrapper .mode-view .uxccInfo,
    .uxccWrapper .mode-view .childUxccInfo,
    .uxccWrapper .mode-edit .childUxccInfo mat-form-field.id,
    .uxcompWrapper .mode-view .uxcompInfo {
      width: 500px;
    }
  
    .uxcWrapper .mode-view .uxlInfo,
    .uxlWrapper .mode-view .uxccInfo,
    .uxlWrapper .mode-edit .uxccInfo mat-form-field.id,
    .uxcompWrapper .mode-view .uxcompContentInfo,
    .uxcompWrapper .mode-edit .uxcompContentInfo mat-form-field.content {
      width: 800px;
    }
  
    /* Edit Uxc */
    .uxcWrapper .mode-edit .uxlInfo mat-form-field.id {
      width: 475px;
    }
  
    .uxcWrapper .mode-view .uxlInfo .name {
      width: 750px;
    }
  
    /* Edit Uxl */
    .uxlWrapper .mode-view .uxccInfo .name {
      width: 650px;
    }
  
    /* Edit Uxcc */
    .uxccWrapper .mode-view .childUxccInfo .name {
      width: 450px;
    }
  
    .uxccWrapper .mode-view .uxcompInfo,
    .uxccWrapper .mode-edit .uxcompInfo mat-form-field.id {
      width: 300px;
    }
  
    .uxccWrapper .mode-view .uxcompInfo .name {
      width: 250px;
    }
  
    /* Edit Uxcomp */
    .uxcompWrapper .mode-view .uxcompContentInfo .content {
      color: rgba(0, 0, 0, 0.50);
      font-size: 0.7em;
      overflow: hidden;
    }
  
    .uxcompWrapper.summaryContent .mode-view .uxcompContentInfo .content {
      max-height: 200px;
    }
  
    .uxcompWrapper .mode-edit .uxcompContentInfo mat-form-field.content textarea {
      height: 165px;
  
    }
  
    .opacity50 {
      opacity: 0.5;
    }
  
    .orphan {
      background-color: #ffff00;
      font-weight: bold;
    }
  
    .uxDetail {
      font-size: 0.7em;
    }
  
  </style>
  <mat-card id="uxcMgmt">
    <mat-card-header>
      <mat-card-title>
        UX Management
      </mat-card-title>
    </mat-card-header>
  
  
    <mat-card-header>
      <mat-card-subtitle>
        UX Add
        <i class="material-icons action" (click)="addFlag = !addFlag">edit</i>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content *ngIf="addFlag">
      <mat-card class="uxcCreate">
        <mat-card-header>
          <mat-card-subtitle>
            UXConfig
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="name">
          <mat-form-field><input matInput placeholder="Name" [(ngModel)]="newUxc.draft.name"></mat-form-field>
          <mat-form-field><input matInput placeholder="Description" [(ngModel)]="newUxc.draft.description"></mat-form-field>
          <div class="inline">
            <div *ngFor="let uxLayoutId of newUxc.draft.uxLayoutIds; let i = index">
              <mat-form-field>
                <input matInput [(ngModel)]="newUxc.draft.uxLayoutIds[i]">
                <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(newUxc.draft.uxLayoutIds,i)">clear</i></button>
              </mat-form-field>
              <mat-select placeholder="Type" [ngModel]="newUxc.draft.slotTypes[uxLayoutId]?.slotType"
                          (ngModelChange)="newUxc.draft.setSlotType(uxLayoutId,'slotType', $event)">
                <mat-option *ngFor="let slotType of slotTypes" [value]="slotType.key">{{slotType.value}}</mat-option>
              </mat-select>
              <mat-form-field class="desc">
                <input matInput type="text" placeholder="Desc"
                       [ngModel]="newUxc.draft.slotTypes[uxLayoutId]?.desc"
                       (ngModelChange)="newUxc.draft.setSlotType(uxLayoutId,'desc', $event)">
              </mat-form-field>
              <mat-form-field class="weight" *ngIf="isWeightedUxLayout(newUxc.draft,uxLayoutId)">
                <input matInput type="text" placeholder="Weight"
                       [ngModel]="newUxc.draft.slotTypes[uxLayoutId]?.weight"
                       (ngModelChange)="newUxc.draft.setSlotType(uxLayoutId,'weight', $event)">
              </mat-form-field>
            </div>
            <mat-form-field><input matInput #newUxcUxlId placeholder="UXL ID" (change)="addItemToArray(newUxc.draft.uxLayoutIds,newUxcUxlId)"></mat-form-field>
          </div>
          <button [disabled]="newUxc.isActive()" (click)="addUxc()">Add</button>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="uxlCreate">
        <mat-card-header>
          <mat-card-subtitle>
            UXLayout
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="name">
          <mat-form-field><input matInput placeholder="Name" [(ngModel)]="newUxl.draft.name"></mat-form-field>
          <mat-form-field><input matInput placeholder="Description" [(ngModel)]="newUxl.draft.description"></mat-form-field>
          <div class="inline">
            <div *ngFor="let uxccId of newUxl.draft.uxComponentCollectionIds; let i = index ">
              <mat-form-field>
                <input matInput [(ngModel)]="newUxl.draft.uxComponentCollectionIds[i]">
                <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(newUxl.draft.uxComponentCollectionIds,i)">clear</i></button>
              </mat-form-field>
            </div>
            <mat-form-field><input matInput #newUxlUxccId placeholder="UXCC" (change)="addItemToArray(newUxl.draft.uxComponentCollectionIds,newUxlUxccId)"></mat-form-field>
          </div>
          <button [disabled]="newUxl.isActive()" (click)="addUxl()">Add</button>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="uxccCreate">
        <mat-card-header>
          <mat-card-subtitle>
            UXCC
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="name">
          <mat-form-field><input matInput placeholder="Name" [(ngModel)]="newUxcc.draft.name"></mat-form-field>
          <mat-form-field><input matInput placeholder="Description" [(ngModel)]="newUxcc.draft.description"></mat-form-field>
          <div class="inline">
            <div *ngFor="let uxccId of newUxcc.draft.childUxccIds; let i = index ">
              <mat-form-field>
                <input matInput [placeholder]="newUxcc.getChildUxCCById(uxccId)?.name" [(ngModel)]="newUxcc.draft.childUxccIds[i]">
                <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(newUxcc.draft.childUxccIds,i)">clear</i></button>
              </mat-form-field>
            </div>
            <mat-form-field><input matInput #newUxccUxccId placeholder="UXCC ID" (change)="addItemToArray(newUxcc.draft.childUxccIds,newUxccUxccId)"></mat-form-field>
          </div>
          <div class="inline">
            <div *ngFor="let uxcompId of newUxcc.draft.uxComponentIds; let i = index ">
              <mat-form-field>
                <input matInput [(ngModel)]="newUxcc.draft.uxComponentIds[i]">
                <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(newUxcc.draft.uxComponentIds,i)">clear</i></button>
              </mat-form-field>
            </div>
            <mat-form-field><input matInput #newUxccUxcompId placeholder="UXComponent ID" (change)="addItemToArray(newUxcc.draft.uxComponentIds,newUxccUxcompId)">
            </mat-form-field>
          </div>
          <button [disabled]="newUxcc.isActive()" (click)="addUxcc()">Add</button>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="uxcompCreate">
        <mat-card-header>
          <mat-card-subtitle>
            UXComponent
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="name">
            <input matInput placeholder="Name" [(ngModel)]="newUxComponent.draft.name">
            <label mat-icon-button matPrefix>comp.</label>
          </mat-form-field>
          <mat-form-field><input matInput placeholder="Description" [(ngModel)]="newUxComponent.draft.description"></mat-form-field>
          <mat-select placeholder="Type" [(ngModel)]="newUxComponent.draft.type">
            <mat-option *ngFor="let compType of uxComponentTypes" [value]="compType.key">{{compType.value}}</mat-option>
          </mat-select>
          <mat-form-field><textarea #newUxlComponentContent matInput placeholder="Content" [(ngModel)]="newUxComponent.draft.content"></textarea></mat-form-field>
          <mat-checkbox [(ngModel)]="newUxComponent.draft.serverOnly">ServerOnly</mat-checkbox>
          <button [disabled]="newUxComponent.isActive()" (click)="addUxComponent()">Add</button>
          <i *ngIf="!newUxComponent.getToggle('uncheck')" class="material-icons action vertical-middle" [class.opacity50]="!newUxComponent.draft.check(false)"
             (click)="newUxComponent.toggle('uncheck')">check_circle_outline</i>
          <i *ngIf="newUxComponent.getToggle('uncheck')" class="material-icons action vertical-middle" [class.opacity50]="!newUxComponent.draft.check(false)" (click)="newUxComponent.toggle('uncheck')">account_circle</i>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  
  
    <mat-card-header>
      <mat-card-subtitle>
        UX Search
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field><input matInput (keydown)="keyDownEvent($event)" placeholder="UXC" [(ngModel)]="searchOptions.filters.uxConfigs.value"></mat-form-field>
      <mat-form-field><input matInput (keydown)="keyDownEvent($event)" placeholder="UXL" [(ngModel)]="searchOptions.filters.uxLayouts.value"></mat-form-field>
      <mat-form-field><input matInput (keydown)="keyDownEvent($event)" placeholder="UXCC" [(ngModel)]="searchOptions.filters.uxComponentCollections.value"></mat-form-field>
      <mat-form-field><input matInput (keydown)="keyDownEvent($event)" placeholder="UXComp" [(ngModel)]="searchOptions.filters.uxComponents.value"></mat-form-field>
      <button (click)="search()">Search</button>
    </mat-card-content>
  
  
    <mat-card-header>
      <mat-card-subtitle>
        UX Edit
        <button *ngIf="isOrphanCheckable()" (click)="findOrphanUx()">OrphanCheckAllSearched</button>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="edit">
  
      <mat-card class="uxcEdit" *ngIf="results.uxConfigs.length">
        <mat-card-header>
          <mat-card-subtitle>
            UXConfig
            <button (click)="buildUxComposites()">BuildAllSearched</button>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-card *ngFor="let uxConfig of results.uxConfigs">
            <mat-card-content class="uxcWrapper {{uxConfig.draft.status}}">
              <div class="mode-edit inline" *ngIf="uxConfig.tempClient.edit">
                <div class="uxcInfo inline">
                  <div>
                    <mat-form-field class="id">
                      <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="uxConfig._id">
                      <button mat-icon-button matPrefix><i class="material-icons action" (click)="toggleStatus(uxConfig)">clear</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxConfig)">perm_device_information</i></button>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="name">
                      <input matInput placeholder="Name" [(ngModel)]="uxConfig.draft.name">
                      <button mat-icon-button matPrefix=""><i class="material-icons action" (click)="copyName(uxConfig.draft)">perm_identity</i></button>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="desc uxcDesc"><input matInput placeholder="Description" [(ngModel)]="uxConfig.draft.description"></mat-form-field>
                  </div>
                </div>
                <div class="uxlInfo inline">
                  <div *ngFor="let uxLayoutId of uxConfig.draft.uxLayoutIds; let i = index"
                       (dragover)="dragOver($event,uxConfig.draft.uxLayoutIds,i)"
                       (drop)="drop($event,uxConfig.draft.uxLayoutIds,i)">
                    <ng-container *ngIf="(!uxConfig.getUxLayoutById(uxLayoutId)) || uxConfig.getUxLayoutById(uxLayoutId)?.isActive()">
                      <mat-form-field class="id">
                        <input matInput [placeholder]="uxConfig.getUxLayoutById(uxLayoutId)?.name" [(ngModel)]="uxConfig.draft.uxLayoutIds[i]">
                        <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(uxConfig.draft.uxLayoutIds,i)">clear</i></button>
                        <button mat-icon-button matSuffix=""><i class="material-icons handle"
                                                                [draggable]="true"
                                                                (dragstart)="dragStart($event,uxConfig.draft.uxLayoutIds,i)"
                                                                (dragend)="dragEnd($event,uxConfig.draft.uxLayoutIds,i)">drag_handle</i></button>
                        <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxConfig.getUxLayoutById(uxLayoutId))">perm_device_information</i></button>
                        <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyName(uxConfig.getUxLayoutById(uxLayoutId))">perm_identity</i></button>
                      </mat-form-field>
                      <mat-select placeholder="Type" [ngModel]="uxConfig.draft.slotTypes[uxLayoutId]?.slotType"
                                  (ngModelChange)="uxConfig.draft.setSlotType(uxLayoutId,'slotType', $event)">
                        <mat-option *ngFor="let slotType of slotTypes" [value]="slotType.key">{{slotType.value}}</mat-option>
                      </mat-select>
                      <mat-form-field class="desc">
                        <input matInput type="text" placeholder="Desc"
                               [ngModel]="uxConfig.draft.slotTypes[uxLayoutId]?.desc"
                               (ngModelChange)="uxConfig.draft.setSlotType(uxLayoutId,'desc', $event)">
                      </mat-form-field>
                      <mat-form-field class="weight" *ngIf="isWeightedUxLayout(uxConfig.draft,uxLayoutId)">
                        <input matInput type="text" placeholder="Weight"
                               [ngModel]="uxConfig.draft.slotTypes[uxLayoutId]?.weight"
                               (ngModelChange)="uxConfig.draft.setSlotType(uxLayoutId,'weight', $event)">
                      </mat-form-field>
                    </ng-container>
                  </div>
                  <mat-form-field><input matInput #newUxlId placeholder="UXL ID" (change)="addItemToArray(uxConfig.draft.uxLayoutIds,newUxlId)"></mat-form-field>
                  <i class="material-icons action" (click)="duplicate(uxConfig)">file_copy</i>
                </div>
                <i class="material-icons action" (click)="update(uxConfig)" *ngIf="uxConfig.isDiffDraft()">update</i>
              </div>
              <div class="mode-view inline" *ngIf="!uxConfig.tempClient.edit">
                <div class="uxcInfo inline">
                  <div>
                    <div class="inline">
                      <i class="material-icons action" (click)="copyId(uxConfig)">perm_device_information</i>
                      <i class="material-icons action" (click)="copyName(uxConfig)">perm_identity</i>
                    </div>
                    <div class="id inline">
                      {{uxConfig._id}}
                    </div>
                  </div>
                  <div>
                    <div class="name inline">
                      {{uxConfig.name}}
                    </div>
                  </div>
                  <div class="desc">{{uxConfig.description}}</div>
                </div>
                <div class="uxlInfo inline">
                  <div *ngFor="let uxLayoutId of uxConfig.uxLayoutIds; let i = index">
                    <div *ngIf="(!uxConfig.getUxLayoutById(uxLayoutId)) || uxConfig.getUxLayoutById(uxLayoutId)?.isActive()">
                      <div class="inline">
                        <i class="material-icons action" (click)="copyId(uxConfig.getUxLayoutById(uxLayoutId))">perm_device_information</i>
                        <i class="material-icons action" (click)="copyName(uxConfig.getUxLayoutById(uxLayoutId))">perm_identity</i>
                      </div>
                      <div class="inline">
                        <div>
                          <div class="id inline">
                            {{uxLayoutId}}
                          </div>
                          <div class="inline">
                            <div *ngIf="uxConfig.slotTypes[uxLayoutId]?.slotType == SLOT_TYPES.default" class="slotType slotType-weight inline ">{{uxConfig.slotTypes[uxLayoutId]?.weight}}:</div>
                            <div class="desc slotType slotType-desc inline">{{uxConfig.slotTypes[uxLayoutId]?.desc}}</div>
                          </div>
                        </div>
                        <div class="name">
                          {{uxConfig.getUxLayoutById(uxLayoutId)?.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="actions inline">
                <i class="material-icons action" (click)="uxConfig.tempClient.edit=!uxConfig.tempClient.edit">edit</i>
                <i class="material-icons action" (click)="buildUxComposite(uxConfig._id)">build</i>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="uxlEdit" *ngIf="results.uxLayouts.length">
        <mat-card-header>
          <mat-card-subtitle>
            UXLayout
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-card *ngFor="let uxLayout of results.uxLayouts">
  
            <mat-card-content class="uxlWrapper {{uxLayout.draft.status}}">
              <div class="mode-edit inline" *ngIf="uxLayout.tempClient.edit">
                <div class="uxlInfo inline">
                  <div>
                    <mat-form-field class="id">
                      <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="uxLayout._id">
                      <button mat-icon-button matPrefix><i class="material-icons action" (click)="toggleStatus(uxLayout)">clear</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxLayout)">perm_device_information</i></button>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="name">
                      <input matInput placeholder="Name" [(ngModel)]="uxLayout.draft.name">
                      <button mat-icon-button matPrefix=""><i class="material-icons action" (click)="copyName(uxLayout.draft)">perm_identity</i></button>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="desc uxlDesc"><input matInput placeholder="Description" [(ngModel)]="uxLayout.draft.description"></mat-form-field>
                  </div>
                </div>
                <div class="uxccInfo inline">
                  <div *ngFor="let uxccId of uxLayout.draft.uxComponentCollectionIds; let i = index "
                       (dragover)="dragOver($event,uxLayout.draft.uxComponentCollectionIds,i)"
                       (drop)="drop($event,uxLayout.draft.uxComponentCollectionIds,i)">
                    <mat-form-field class="id" *ngIf="(!uxLayout.getUxCCById(uxccId)) || uxLayout.getUxCCById(uxccId)?.isActive()">
                      <input matInput [placeholder]="uxLayout.getUxCCById(uxccId)?.name" [(ngModel)]="uxLayout.draft.uxComponentCollectionIds[i]">
                      <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(uxLayout.draft.uxComponentCollectionIds,i)">clear</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons handle"
                                                              [draggable]="true"
                                                              (dragstart)="dragStart($event,uxLayout.draft.uxComponentCollectionIds,i)"
                                                              (dragend)="dragEnd($event,uxLayout.draft.uxComponentCollectionIds,i)">drag_handle</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxLayout.getUxCCById(uxccId))">perm_device_information</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyName(uxLayout.getUxCCById(uxccId))">perm_identity</i></button>
                    </mat-form-field>
                  </div>
                  <mat-form-field><input matInput #newUxccId placeholder="UXCC ID" (change)="addItemToArray(uxLayout.draft.uxComponentCollectionIds,newUxccId)"></mat-form-field>
                  <i class="material-icons action" (click)="duplicate(uxLayout)">file_copy</i>
                </div>
                <i class="material-icons action" (click)="update(uxLayout)" *ngIf="uxLayout.isDiffDraft()">update</i>
              </div>
  
              <div class="mode-view inline" *ngIf="!uxLayout.tempClient.edit">
                <div class="uxlInfo inline">
                  <div>
                    <div class="inline">
                      <i class="material-icons action" (click)="copyId(uxLayout)">perm_device_information</i>
                      <i class="material-icons action" (click)="copyName(uxLayout)">perm_identity</i>
                    </div>
                    <div class="id inline">
                      {{uxLayout._id}}
                    </div>
                  </div>
                  <div>
                    <div class="name inline">
                      {{uxLayout.name}}
                    </div>
                    <div class="inline flag orphan" *ngIf="isOrphan(uxLayout)"> Orphan</div>
                  </div>
                  <div class="desc uxlDesc">{{uxLayout.description}}</div>
                </div>
                <div class="uxccInfo inline">
                  <div *ngFor="let uxccId of uxLayout.uxComponentCollectionIds; let i = index ">
                    <div *ngIf="(!uxLayout.getUxCCById(uxccId)) || uxLayout.getUxCCById(uxccId)?.isActive()">
                      <div class="inline">
                        <i class="material-icons action" (click)="copyId(uxLayout.getUxCCById(uxccId))">perm_device_information</i>
                        <i class="material-icons action" (click)="copyName(uxLayout.getUxCCById(uxccId))">perm_identity</i>
                      </div>
                      <div class="inline">
                        <div class="id">
                          {{uxccId}}
                        </div>
                        <div class="name">
                          {{uxLayout.getUxCCById(uxccId)?.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="actions inline">
                <i class="material-icons action" (click)="uxLayout.tempClient.edit=!uxLayout.tempClient.edit">edit</i>
                <i class="material-icons action" (click)="findUxDetail(uxLayout._id)">search</i>
              </div>
            </mat-card-content>
  
            <mat-card-content *ngIf="hasDetail(uxLayout)">
              <div class="uxDetail">
                <uxDetail [uxDetail]="uxLayout.tempClient.uxDetail"></uxDetail>
              </div>
            </mat-card-content>
  
          </mat-card>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="uxccEdit" *ngIf="results.uxComponentCollections.length">
        <mat-card-header>
          <mat-card-subtitle>
            UXCC
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-card *ngFor="let uxComponentCollection of results.uxComponentCollections">
            <mat-card-content class="uxccWrapper {{uxComponentCollection.draft.status}}">
              <div class="mode-edit inline" *ngIf="uxComponentCollection.tempClient.edit">
  
                <div class="uxccInfo inline">
                  <div>
                    <mat-form-field class="id">
                      <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="uxComponentCollection._id">
                      <button mat-icon-button matPrefix><i class="material-icons action" (click)="toggleStatus(uxComponentCollection)">clear</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxComponentCollection)">perm_device_information</i></button>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="name">
                      <input matInput placeholder="Name" [(ngModel)]="uxComponentCollection.draft.name">
                      <button mat-icon-button matPrefix=""><i class="material-icons action" (click)="copyName(uxComponentCollection.draft)">perm_identity</i></button>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="desc uxccDesc"><input matInput placeholder="Description" [(ngModel)]="uxComponentCollection.draft.description"></mat-form-field>
                  </div>
                </div>
  
                <div class="childUxccInfo inline">
                  <div *ngFor="let uxccId of uxComponentCollection.draft.childUxccIds; let i = index "
                       (dragover)="dragOver($event,uxComponentCollection.draft.childUxccIds,i)"
                       (drop)="drop($event,uxComponentCollection.draft.childUxccIds,i)">
                    <mat-form-field class="id" *ngIf="(!uxComponentCollection.getChildUxCCById(uxccId)) || uxComponentCollection.getChildUxCCById(uxccId)?.isActive()">
                      <input matInput [placeholder]="uxComponentCollection.getChildUxCCById(uxccId)?.name" [(ngModel)]="uxComponentCollection.draft.childUxccIds[i]">
                      <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(uxComponentCollection.draft.childUxccIds,i)">clear</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons handle"
                                                              [draggable]="true"
                                                              (dragstart)="dragStart($event,uxComponentCollection.draft.childUxccIds,i)"
                                                              (dragend)="dragEnd($event,uxComponentCollection.draft.childUxccIds,i)">drag_handle</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxComponentCollection.getChildUxCCById(uxccId))">perm_device_information</i>
                      </button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyName(uxComponentCollection.getChildUxCCById(uxccId))">perm_identity</i></button>
                    </mat-form-field>
                  </div>
                  <mat-form-field><input matInput #newUxccId placeholder="UXCC ID" (change)="addItemToArray(uxComponentCollection.draft.childUxccIds,newUxccId)">
                  </mat-form-field>
                </div>
                <div class="uxcompInfo inline">
                  <div *ngFor="let uxcompId of uxComponentCollection.draft.uxComponentIds; let i = index "
                       (dragover)="dragOver($event,uxComponentCollection.draft.uxComponentIds,i)"
                       (drop)="drop($event,uxComponentCollection.draft.uxComponentIds,i)">
                    <mat-form-field class="id" *ngIf="(!uxComponentCollection.getUxCompById(uxcompId)) || uxComponentCollection.getUxCompById(uxcompId)?.isActive()">
                      <input matInput [placeholder]="uxComponentCollection.getUxCompById(uxcompId)?.name" [(ngModel)]="uxComponentCollection.draft.uxComponentIds[i]">
                      <button mat-icon-button matPrefix><i class="material-icons action" (click)="removeItemInArray(uxComponentCollection.draft.uxComponentIds,i)">clear</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons handle"
                                                              [draggable]="true"
                                                              (dragstart)="dragStart($event,uxComponentCollection.draft.uxComponentIds,i)"
                                                              (dragend)="dragEnd($event,uxComponentCollection.draft.uxComponentIds,i)">drag_handle</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxComponentCollection.getUxCompById(uxcompId))">perm_device_information</i>
                      </button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyName(uxComponentCollection.getUxCompById(uxcompId))">perm_identity</i></button>
                    </mat-form-field>
                  </div>
                  <mat-form-field><input matInput #newUxcompId placeholder="UXComp ID" (change)="addItemToArray(uxComponentCollection.draft.uxComponentIds,newUxcompId)">
                  </mat-form-field>
                  <i class="material-icons action" (click)="duplicate(uxComponentCollection)">file_copy</i>
                </div>
                <i class="material-icons action" (click)="update(uxComponentCollection)" *ngIf="uxComponentCollection.isDiffDraft()">update</i>
              </div>
  
              <div class="mode-view inline" *ngIf="!uxComponentCollection.tempClient.edit">
                <div class="uxccInfo inline">
                  <div>
                    <div class="inline">
                      <i class="material-icons action" (click)="copyId(uxComponentCollection)">perm_device_information</i>
                      <i class="material-icons action" (click)="copyName(uxComponentCollection)">perm_identity</i>
                    </div>
                    <div class="id inline">
                      {{uxComponentCollection._id}}
                    </div>
                  </div>
                  <div>
                    <div class="name inline">
                      {{uxComponentCollection.name}}
                    </div>
                    <div class="inline flag orphan" *ngIf="isOrphan(uxComponentCollection)"> Orphan</div>
                  </div>
                  <div class="desc uxccDesc">{{uxComponentCollection.description}} </div>
                </div>
                <div class="childUxccInfo inline">
                  <div *ngFor="let uxccId of uxComponentCollection.draft.childUxccIds; let i = index ">
                    <div *ngIf="(!uxComponentCollection.getChildUxCCById(uxccId)) || uxComponentCollection.getChildUxCCById(uxccId)?.isActive()">
                      <div class="inline">
                        <i class="material-icons action" (click)="copyId(uxComponentCollection.getChildUxCCById(uxccId))">perm_device_information</i>
                        <i class="material-icons action" (click)="copyName(uxComponentCollection.getChildUxCCById(uxccId))">perm_identity</i>
                      </div>
                      <div class="inline">
                        <div class="id">
                          {{uxccId}}
                        </div>
                        <div class="name">
                          {{uxComponentCollection.getChildUxCCById(uxccId)?.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uxcompInfo inline">
                  <div *ngFor="let uxcompId of uxComponentCollection.draft.uxComponentIds; let i = index ">
                    <div *ngIf="(!uxComponentCollection.getUxCompById(uxcompId)) || uxComponentCollection.getUxCompById(uxcompId)?.isActive()">
                      <div class="inline">
                        <i class="material-icons action" (click)="copyId(uxComponentCollection.getUxCompById(uxcompId))">perm_device_information</i>
                        <i class="material-icons action" (click)="copyName(uxComponentCollection.getUxCompById(uxcompId))">perm_identity</i>
                      </div>
                      <div class="inline">
                        <div class="id">
                          {{uxcompId}}
                        </div>
                        <div class="name">
                          {{uxComponentCollection.getUxCompById(uxcompId)?.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="actions inline">
                <i class="material-icons action" (click)="uxComponentCollection.tempClient.edit=!uxComponentCollection.tempClient.edit">edit</i>
                <i class="material-icons action" (click)="findUxDetail(uxComponentCollection._id)">search</i>
              </div>
            </mat-card-content>
  
            <mat-card-content *ngIf="hasDetail(uxComponentCollection)">
              <div class="uxDetail">
                <uxDetail [uxDetail]="uxComponentCollection.tempClient.uxDetail"></uxDetail>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
  
      <mat-card class="uxcompEdit" *ngIf="results.uxComponents.length">
        <mat-card-header>
          <mat-card-subtitle>
            UXComponent
            <i class="material-icons action" (click)="fullContentFlag = !fullContentFlag">fullscreen</i>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-card *ngFor="let uxcomp of results.uxComponents">
            <mat-card-content class="uxcompWrapper {{fullContentFlag?'fullContent':'summaryContent'}} {{uxcomp.draft.status}}">
              <div class="mode-edit inline" *ngIf="uxcomp.tempClient.edit">
  
                <div class="uxcompInfo inline">
                  <div>
                    <mat-form-field class="id inline">
                      <input matInput class="id" placeholder="ID" [disabled]="true" [(ngModel)]="uxcomp._id">
                      <button mat-icon-button matPrefix><i class="material-icons action" (click)="toggleStatus(uxcomp)">clear</i></button>
                      <button mat-icon-button matSuffix=""><i class="material-icons action" (click)="copyId(uxcomp)">perm_device_information</i></button>
                    </mat-form-field>
                    <mat-select class="inline" placeholder="Type" [(ngModel)]="uxcomp.draft.type" [disabled]="!uxcomp.draft.isActive()">
                      <mat-option *ngFor="let compType of uxComponentTypes" [value]="compType.key">{{compType.value}}</mat-option>
                    </mat-select>
                    <mat-checkbox class="inline" [(ngModel)]="uxcomp.draft.serverOnly">ServerOnly</mat-checkbox>
                    <i class="material-icons action inline" (click)="duplicate(uxcomp)">file_copy</i>
                  </div>
                  <div>
                    <mat-form-field class="name">
                      <input matInput placeholder="Name" [(ngModel)]="uxcomp.draft.name">
                      <button mat-icon-button matPrefix=""><i class="material-icons action" (click)="copyName(uxcomp.draft)">perm_identity</i></button>
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field class="desc uxcompDesc"><input matInput placeholder="Description" [(ngModel)]="uxcomp.draft.description"></mat-form-field>
                  </div>
                </div>
  
                <div class="uxcompContentInfo inline">
                  <mat-form-field class="content"><textarea matInput [(ngModel)]="uxcomp.draft.content"></textarea></mat-form-field>
                </div>
                <i class="material-icons action vertical-middle" (click)="update(uxcomp)" *ngIf="uxcomp.isDiffDraft()">update</i>
                <i *ngIf="!uxcomp.getToggle('uncheck')" class="material-icons action vertical-middle" [class.opacity50]="!uxcomp.draft.check(false)" (click)="uxcomp.toggle('uncheck')">check_circle_outline</i>
                <i *ngIf="uxcomp.getToggle('uncheck')" class="material-icons action vertical-middle" [class.opacity50]="!uxcomp.draft.check(false)" (click)="uxcomp.toggle('uncheck')">account_circle</i>
              </div>
  
              <div class="mode-view inline" *ngIf="!uxcomp.tempClient.edit">
                <div class="uxcompInfo inline">
                  <div>
                    <div class="inline">
                      <i class="material-icons action" (click)="copyId(uxcomp)">perm_device_information</i>
                      <i class="material-icons action" (click)="copyName(uxcomp)">perm_identity</i>
                    </div>
                    <div class="id inline">
                      {{uxcomp._id}}
                    </div>
                    <div class="inline contentType">
                      {{uxcomp.type}}
                    </div>
                    <div class="inline flag" *ngIf="uxcomp.serverOnly">
                      , ServerOnly
                    </div>
                  </div>
                  <div>
                    <div class="name inline">
                      {{uxcomp.name}}
                    </div>
                    <div class="inline flag orphan" *ngIf="isOrphan(uxcomp)"> Orphan</div>
                  </div>
                  <div class="desc uxcompcDesc">{{uxcomp.description}}</div>
                </div>
                <div class="uxcompContentInfo inline" *ngIf="uxcomp.isActive()">
                  <div class="content">{{uxcomp.content}}</div>
                </div>
              </div>
  
              <div class="actions inline">
                <i class="material-icons action" (click)="uxcomp.tempClient.edit=!uxcomp.tempClient.edit">edit</i>
                <i class="material-icons action" (click)="findUxDetail(uxcomp._id)">search</i>
              </div>
            </mat-card-content>
  
  
            <mat-card-content *ngIf="hasDetail(uxcomp)">
              <div class="uxDetail">
                <uxDetail [uxDetail]="uxcomp.tempClient.uxDetail"></uxDetail>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
  
  
    </mat-card-content>
  </mat-card>
  
  