import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JsonService } from '../../clientCommon/services/json.service';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../clientCommon/services/spinner.service';
import { serverPaths } from '../../common/helpers/pathHelpers';
import { LogUtils } from '../../common/utils/logUtils';
import { BaseDirective } from 'src/clientCommon/directives/BaseDirective';
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { configUtils } from '../utils/ConfigUtils';
import { phoneUtils } from "../../common/utils/phoneUtils";
import { ResponseEvent } from '../../common/event/responseEvent';
import { User } from '../../common/models/user/user';
import { ManagedMail } from '../../common/models/user/managedMail';
import { UxComposite } from '../../common/models/ux/uxComposite';
import { objectUtils } from '../../common/utils/objectUtils';
import { ManagedPhone } from '../../common/models/user/managedPhone';

interface UnsubscribeableItem {
  id: string;
  subscribedTimestamp: number;
  unsubscribedTimestamp: number;
  brand: string;
  uri: string;
  disclosure: string;
  force: boolean;
  detail: ManagedMail | ManagedPhone;
  type: 'phone' | 'email';
}

@Component({
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UnsubscribeComponent extends BaseDirective implements OnInit {
  phoneNumber = '';
  brandId = '';
  serverPaths = serverPaths;
  brands = [];
  phoneUtils = phoneUtils;
  input = {
    email: '',
    phone: '',
  }
  expandedElements = [];
  columnsToDisplay = ['expander', 'action', 'id', 'dateSubscribed', 'dateUnsubscribed', 'brand', 'uri', 'disclosure'];

  unsubscribeableItems: UnsubscribeableItem[] = [];
  notFound = false;

  constructor(private jsonService: JsonService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    serviceHelperService: ServiceHelperService,
    activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  ngOnInit() {
    this.init();
  }

  init() {
    return super.baseInit().then(() => {
      this.brands = configUtils.getBrands(this.uxComposite);
    });
  }

  formatInput(event) {
    let value = event.target.value;
    let formatted = "";
    if (value) {
      value = value.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "");
      let length = value.length;
      if (length > 0) {
        formatted = "(" + value.substring(0, 3);
      }
      if (length > 3) {
        formatted += ") " + value.substring(3, 6);
      }
      if (length > 6) {
        formatted += "-" + value.substring(6, 10);
      }
    }
    this.input.phone = formatted;
  }

  formatPhoneValue(v) {
    let value = v;
    let formatted = "";
    if (value) {
      value = value.replace(/(?:(?:^[^0-9]*1*)|(?:[^0-9]))/g, "");
      let length = value.length;
      if (length > 0) {
        formatted = "(" + value.substring(0, 3);
      }
      if (length > 3) {
        formatted += ") " + value.substring(3, 6);
      }
      if (length > 6) {
        formatted += "-" + value.substring(6, 10);
      }
    }
    return formatted;
  }

  isValidPhoneNumber() {
    return this.phoneUtils.validDigits(this.phoneNumber)
  }

  search(input) {
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.unsubscribeSearch, { input }).then((responseEvent: ResponseEvent) => {
      this.unsubscribeableItems = [];
      const docs = responseEvent.getDocs();
 
      let phoneNumberFound = false;
      this.notFound = true;
      docs.sort((docA, docB) => docA.createdTimestamp - docB.createdTimestamp).forEach((doc) => {
        if (doc instanceof ManagedMail) {
          this.notFound = false;
          const newUxcomposite = new UxComposite(doc.tempClient.uxComposite);
          const domain = newUxcomposite.getUxcomp('comp.brand.domain');
          const uri = domain + "/" + this.customClientPaths.salesNameSearchSignup;
          const terms = domain + "/" + this.clientPaths.terms;
          let unsubscribedTimestamp = 0;
          if (doc.subStatus === ManagedMail.SUB_STATUS.unsubscribed) {
            unsubscribedTimestamp = doc.changedTimestamp;
          }
          const temp = new ManagedMail(doc);
          temp.tempClient = {};
          this.unsubscribeableItems.push({
            uri,
            force: false,
            id: doc.email,
            subscribedTimestamp: doc.createdTimestamp,
            unsubscribedTimestamp: unsubscribedTimestamp,
            brand: doc.brandId,
            disclosure: terms,
            detail: temp,
            type: 'email'
          })
        } else if (doc instanceof ManagedPhone) {
          phoneNumberFound = true;
          this.notFound = false;
          const newUxcomposite = new UxComposite(doc.tempClient.uxComposite);
          const domain = newUxcomposite.getUxcomp('comp.brand.domain');
          const uri = domain + "/" + this.customClientPaths.salesNameSearchSignup;
          const terms = domain + "/" + this.clientPaths.terms;
          let unsubscribedTimestamp = 0;
          if (doc.subStatus === ManagedPhone.SUB_STATUS.unsubscribed) {
            unsubscribedTimestamp = doc.changedTimestamp;
          }
          const temp = new ManagedPhone(doc);
          temp.tempClient = {};
          this.unsubscribeableItems.push({
            uri,
            force: false,
            id: doc.phone,
            subscribedTimestamp: doc.createdTimestamp,
            unsubscribedTimestamp: unsubscribedTimestamp,
            brand: doc.brandId,
            disclosure: terms,
            detail: temp,
            type: 'phone'
          })
          if (doc.provider === 'slooce') {
            this.unsubscribeableItems.push({
              uri: '',
              force: true,
              id: doc.phone,
              subscribedTimestamp: 0,
              unsubscribedTimestamp: 0,
              brand: '',
              disclosure: '',
              detail: null,
              type: 'phone'
            })
          }
        }
      })
      if (!phoneNumberFound) {
        this.unsubscribeableItems.push({
          uri: '',
          force: true,
          id: input.phone,
          subscribedTimestamp: 0,
          unsubscribedTimestamp: 0,
          brand: '',
          disclosure: '',
          detail: null,
          type: 'phone'
        })
      }
    }).catch((e) => {
      this.unsubscribeableItems = [];
      this.snackBar.open('Customer search failed', 'Error', {duration: 2000});
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  unsubscribe(unsubscribeItems: UnsubscribeableItem[]) {
    const emails = [];
    const phones = [];
    const forcedPhones = [];
    unsubscribeItems.forEach((item) => {
      if (!item.force) {
        const obj = objectUtils.clone(item.detail);
        delete obj.tempClient;
        if (item.type === 'email') {
          if (item.detail.subStatus !== ManagedMail.SUB_STATUS.unsubscribed) {
            emails.push(new ManagedMail(obj));
          }
        } else {
          if (item.detail.subStatus !== ManagedPhone.SUB_STATUS.unsubscribed) {
            phones.push(new ManagedPhone(obj));
          }
        }
      } else {
        forcedPhones.push(item.id);
      }
    });
    this.spinnerService.spin();
    this.jsonService.json(serverPaths.unsubscribeUnsubscribe, { emails, phones, forcedPhones }).then((responseEvent: ResponseEvent) => {
      LogUtils.info('Success');
      this.snackBar.open(`Unsubscried emails and phones for ${unsubscribeItems.map((item) => item.id).join(", ")}`, 'Success', { duration: 2000 })
      this.search(this.input);
    }).catch((e) => {
      this.snackBar.open(`Unsubscription failed for ${unsubscribeItems.map((item) => item.id).join(", ")}`, 'Error', {duration: 2000})
      LogUtils.error(e);
      this.spinnerService.unspin();
    });
  }

  toggleExpand(element) {
    const index = this.expandedElements.findIndex(e => e === element);
    if (index > -1) {
      this.expandedElements.splice(index, 1);
    } else {
      this.expandedElements.push(element);
    }
  }

  isExpanded(element) {
    return !!this.expandedElements.find((e) => e === element);
  }

  toggleAll() {
    if (this.expandedElements.length !== this.unsubscribeableItems.length) {
      this.expandedElements = this.unsubscribeableItems;
    } else {
      this.expandedElements = [];
    }
  }

  isAllExpanded() {
    if (this.unsubscribeableItems.length === 0) {
      return false;
    }
    return this.expandedElements.length === this.unsubscribeableItems.length;
  }

  getDateString(timestamp: number) {
    if (timestamp === 0) {
      return '';
    }
    return new Date(timestamp).toDateString();
  }

  getRaw(obj) {
    const cloned = objectUtils.clone(obj);
    delete (cloned.draft);
    return JSON.stringify(cloned, null, 4);
  }
}
