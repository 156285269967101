<style>
  code {
    white-space: pre-wrap;
    font-size: 0.7em;
  }

  .commerceOrders {
    margin-bottom: 10px;
  }

  .commerceOrders.active.none {
    background-color: #95d495;
  }

  .commerceOrders.active.cancelled,
  .commerceOrders.inactive.cancelled {
    background-color: #e2cc30;
  }

  .commerceOrders.inactive.suspended {
    background-color: orange;
  }

  .commerceOrders.failed {
    background-color: #776262;
  }

  .commerceOrders.inactive.expired {
    background-color: dimgray;
  }

  .commerceTransactionCollections {
    margin-bottom: 5px;
  }

  .commerceTransactionCollections.fulfilled {
    background-color: #c9ecc9;
  }

  .commerceTransactionCollections.rejected {
    background-color: #efa5a5;
  }

  #uxc {
    font-size: 0.7em;
  }

  .unit {
    width: 70px;
  }

  .commercePayment {
    margin-bottom: 20px;
  }

  .commercePayment.rejected {
    background-color: #efa5a5;
  }

  .commercePayment.additional {
    border-top: 2px solid white;
    padding-top: 20px;
  }


  .mpi3ds-protected {
    font-weight: bold;
  }

  .ccCvv,
  .ccZip {
    width: 50px;
  }

  .tracking.action {
    display: inline-block;
    width: 60px;
  }

  .tracking.keyName {
    display: inline-block;
    width: 220px;
  }
</style>
<mat-card *ngIf="initDone" id="userMgmt">
  <mat-card-header>
    <mat-card-title>
      Customer
      <button mat-fab (click)="init(userId)"><i class="material-icons">cached</i></button>
      <button (click)="advanced = !advanced"><i class="material-icons">format_line_spacing</i></button>
    </mat-card-title>
    <mat-card-subtitle *ngIf="user">
      <div *ngIf="csrAuthGuard.canActivatePath('/'+serverPaths.manageCsrUpdateCustomer)">
        <ng-container *ngIf="advanced && adminFlag">
          <mat-form-field><input matInput [placeholder]="user.brandId" [(ngModel)]="user.draft.brandId">
          </mat-form-field>
          <mat-form-field><input matInput [placeholder]="user.uxcId" [(ngModel)]="user.draft.uxcId">
          </mat-form-field>
          <mat-form-field><input matInput [placeholder]="user.uxlId" [(ngModel)]="user.draft.uxlId">
          </mat-form-field>
          <br />
        </ng-container>

        BrandId:{{user.brandId}}
        {{user._id}}
        <mat-form-field><input matInput [placeholder]="user.email" [(ngModel)]="user.draft.email">
        </mat-form-field>
        <mat-form-field><input matInput [placeholder]="user.firstName" [(ngModel)]="user.draft.firstName">
        </mat-form-field>
        <mat-form-field><input matInput [placeholder]="user.lastName" [(ngModel)]="user.draft.lastName">
        </mat-form-field>
        <button (click)="updateUser(user)" [disabled]="!user.isDiffDraft()">Update</button>
        <a href="https://{{uxComposite.brandDomain}}{{user.tempClient.autoLoginLink}}" target="_blank">Login</a>
        <button (click)="clearPassword(user)">Clear Password</button>
      </div>

      <div *ngIf="commerceToken?.status">
        <br />Token: {{commerceToken.status}} {{commerceToken.subStatus}} {{commerceToken.type}}
        {{commerceToken.bin}}
        <br />Last4: {{commerceToken.last4}}
        <ng-container *ngIf="!commerceToken.isQuadZero()"><br />Exp:
          {{commerceToken.expiration?.month}}/{{commerceToken.expiration?.year}}</ng-container>
        <br />IP: {{commerceToken.ipAddress}}
        <br />ZIP: {{commerceToken.billingAddress?.zip}}
        <br />Email: {{commerceToken.billingAddress?.email}}
        <br />Name: {{commerceToken.billingAddress?.firstName}} {{commerceToken.billingAddress?.lastName}}
        <div *ngIf="advanced">
          {{codeOutput(commerceToken.commerceBillingRouting)}}
        </div>
        <div *ngIf="advanced">
          First Name
          <mat-form-field>
            <input matInput type="text" [placeholder]="commerceToken.billingAddress.firstName"
              [(ngModel)]="commerceToken.draft.billingAddress.firstName">
          </mat-form-field>
          City
          <mat-form-field>
            <input matInput type="text" [placeholder]="commerceToken.billingAddress.city"
                   [(ngModel)]="commerceToken.draft.billingAddress.city">
          </mat-form-field>
          State
          <mat-form-field>
            <input matInput type="text" [placeholder]="commerceToken.billingAddress.state"
                   [(ngModel)]="commerceToken.draft.billingAddress.state">
          </mat-form-field>
          <button [disabled]="!commerceToken.isDiffDraft()"
            (click)="updateCommerce(commerceToken)">Update</button>
        </div>
        <div id="billingInfo" [ngClass]="creditCardInputHelper.getBillingInfoClasses()"
          *ngIf="advanced && isUpdatableCreditCard() && creditCardInputHelper.billingAddress">
          <mat-form-field><input matInput [placeholder]="'First Name'" type="text" name="firstName"
              [(ngModel)]="creditCardInputHelper.billingAddress.firstName" required /></mat-form-field>
          <mat-form-field><input matInput [placeholder]="'Last Name'" type="text" name="lastName"
              [(ngModel)]="creditCardInputHelper.billingAddress.lastName" required /></mat-form-field>
          <mat-form-field><input matInput [placeholder]="'CC Number'" type="tel" name="ccNumber"
              [ngModel]="creditCardInputHelper.ccNumberFormatted"
              (input)="creditCardInputHelper.formatCCNumber($event);creditCardInputHelper.findCardType(creditCardInputHelper.ccNumber)"
              required /></mat-form-field>
          <select name="ccExpMon" [(ngModel)]="creditCardInputHelper.ccExpMonth" required>
            <option *ngFor="let month of creditCardInputHelper.ccExpMonths" [value]="month.key">
              {{month.value}}</option>
          </select>
          <select name="ccExpYear" [(ngModel)]="creditCardInputHelper.ccExpYear" required>
            <option *ngFor="let year of creditCardInputHelper.ccExpYears" [value]="year">{{year}}</option>
          </select>
          <mat-form-field class="ccCvv"><input matInput [placeholder]="'CVV'" type="tel" name="ccCvv"
              [ngModel]="creditCardInputHelper.ccCvv" (input)="creditCardInputHelper.formatCvv($event)" required />
          </mat-form-field>
          <mat-form-field class="ccZip"><input matInput [placeholder]="'ZIP'" type="tel" name="zip"
              [(ngModel)]="creditCardInputHelper.billingAddress.zip" maxlength="5" required />
          </mat-form-field>
          {{creditCardInputHelper.cardTypeName}}
          <button (click)="updateCreditCard()"
            [disabled]="!(creditCardInputHelper.isValidBillingInfo() && creditCardInputHelper.isValidZip())">Update
            Credit Card</button>
        </div>
      </div>

      <div *ngIf="advanced && uxComposite?.uxConfigId" id="uxc">
        UXC {{uxComposite.uxConfigName}}:{{uxComposite.uxConfigId}}
        UXL {{uxComposite.uxLayoutName}}:{{uxComposite.uxLayoutId}}
      </div>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <ng-container *ngFor="let commerceOrder of commerceOrders" class="commerceOrder">
      <mat-card [id]="commerceOrder._id" [ngClass]="getClasses(commerceOrder)">
        <mat-card-subtitle>
          {{commerceOrder._id}}
          Parent:{{commerceOrder.parentId}}
          {{commerceOrder.status}}
          {{commerceOrder.subStatus}}
          {{commerceOrder.statusIdentifier}}
          {{commerceOrder.statusReason}}
          {{commerceOrder.statusDescription}}
          People Reports Pulled (Usage):{{commerceOrder.processed}}
          UXC:{{commerceOrder.uxcId}} UXL:{{commerceOrder.uxlId}}
          TotalValue:${{commerceOrder.calculateValue().amount}}
          <br />
          {{commerceOrder.commerceOffer.name}}
          {{commerceOrder.commerceOffer.description}}
          <br />
          <ng-container *ngIf="commerceOrder.commerce3ds">
            (
            3DS
            Enrolled:{{commerceOrder.commerce3ds.enrolled}}
            Auth:{{commerceOrder.commerce3ds.status}}
            ECI:{{commerceOrder.commerce3ds.eci}}
            )
          </ng-container>
          <ng-container *ngIf="commerceOrder.tempClient.vmpi">
            VMPI
          </ng-container>
          <button *ngIf="isModifiable(commerceOrder)" [disabled]="!commerceOrder.isCancellable()"
            (click)="cancel(commerceOrder)">Cancel</button>
          <button *ngIf="isModifiable(commerceOrder)" [disabled]="!commerceOrder.isCancelled()"
            (click)="uncancel(commerceOrder)">Uncancel</button>

          <button (click)="commerceOrder.toggle('show')"><i class="material-icons">format_line_spacing</i></button>
        </mat-card-subtitle>
        <mat-card-content *ngIf="commerceOrder.getToggle('show')">
          <div *ngIf="advanced"> Sequence:{{commerceOrder.sequence + 1}}.{{commerceOrder.retry}}</div>
          <div *ngIf="advanced"> IP Address:{{commerceOrder.ipAddress}}</div>
          <div> Order
            DateTime:{{formatTimestamp(commerceOrder.orderTimestamp) | date:timeFormat:custom.timezone}}
            {{custom.timezoneString}}</div>
          <div *ngIf="advanced"> Last Updater:{{userInfo[commerceOrder.updaterId]?.getFullName()}}</div>
          <ng-container *ngIf="false">
            <ng-container *ngFor="let processCount of commerceOrder.tempClientSecured.processCounts">
              {{processCount.productKey}} {{processCount.process}}/{{processCount.quantity}} during
              period<br />
            </ng-container>
          </ng-container>
          <br />
          <div *ngFor="let update of commerceOrder.tempClient.updates" class="commerceOrderUpdate">
            <div *ngIf="userInfo[update.updaterId]?.getFullName() || advanced">
              {{formatTimestamp(update.timestamp) | date:timeFormat: custom.timezone}}
              {{custom.timezoneString}} S{{update.sequence}}.{{update.retry}} {{update.status}}
              {{update.subStatus}} ({{update.statusIdentifier}}
              :{{update.statusReason}})
              Updater:{{userInfo[update.updaterId]?.getFullName()}}
            </div>
          </div>
          ------------ commerceOfferRuleKey ------------
          <button *ngIf="advanced" (click)="commerceOrder.toggle('commerceOfferRuleExplained')"><i
              class="material-icons">format_line_spacing</i></button>
          <div
            *ngIf="commerceOrder.getToggle('commerceOfferRuleExplained') && commerceOrder.tempClient.commerceOfferRuleExplained as ruleExplained">

            <code>
                {{codeOutput(commerceOrder.commerceOfferRuleKey)}}
              </code>
            <div *ngFor="let value of ruleExplained">
              {{value.type}}
              {{value.key}}
              {{uxComposite.ids[value.key]}}
              <code>
                  {{codeOutput(value.rule)}}
                </code>
            </div>

          </div>

          <br />------------ Refer ------------
          <button *ngIf="advanced" (click)="commerceOrder.toggle('refer')"><i
              class="material-icons">format_line_spacing</i></button>
          <div *ngIf="commerceOrder.getToggle('refer')">
            <code>
                {{codeOutput(commerceOrder.refer)}}
                {{codeOutput(commerceOrder.meta)}}
                {{codeOutput(commerceOrder.prevUx)}}
              </code>
          </div>
          <div>
            <div style="display: inline-block">
              <ng-container *ngFor="let price of commerceOrder.draft.commercePrice.prices; index as i "
                class="commercePrice">
                <div>
                  <mat-form-field>
                    <input matInput [placeholder]="commerceOrder.commercePrice.prices[i].sequence"
                      [(ngModel)]="price.sequence">
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="number" [placeholder]="commerceOrder.commercePrice.prices[i].amount"
                      [(ngModel)]="price.amount">
                    <span matPrefix>$</span>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput [placeholder]="commerceOrder.commercePrice.prices[i].period.quantity"
                      [(ngModel)]="price.period.quantity">
                  </mat-form-field>
                  <mat-select class="unit" name="unit" [placeholder]="commerceOrder.commercePrice.prices[i].period.unit"
                    [(ngModel)]="price.period.unit">
                    <mat-option *ngFor="let unit of periodUnits" [value]="unit.key">
                      {{ unit.value }}
                    </mat-option>
                  </mat-select>
                </div>
              </ng-container>
            </div>
            <div style="display: inline-block">
              <button [disabled]="!isValidUpdateCommerceOrder(commerceOrder)"
                (click)="updateCommerceOrder(commerceOrder)">Update</button>
            </div>

          </div>


          <mat-card>
            <mat-card-subtitle>
              <div>Offer</div>
            </mat-card-subtitle>
            <mat-card-content>
              {{commerceOrder.commerceOffer._id}}
              {{commerceOrder.commerceOfferRevision._id}}
              {{commerceOrder.commerceOfferRevision.reference.name}}
              {{commerceOrder.commerceOfferRevision.reference.description}}
              <button *ngIf="advanced" (click)="commerceOrder.toggle('CommerceOfferDetail')"><i
                  class="material-icons">format_line_spacing</i></button>
              <div *ngIf="commerceOrder.getToggle('CommerceOfferDetail')">
                <code>{{codeOutput(commerceOrder.commerceOfferDetail)}}</code></div>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="commerceOrderMailTrackings[commerceOrder._id]">
            <mat-card-subtitle>
              <div>Trackings</div>
            </mat-card-subtitle>
            <mat-card-content>
              <ng-container *ngFor="let action of commerceOrderMailTrackings[commerceOrder._id]">
                <div>
                  <ng-template [ngIf]="trackingDisplay[action.action] && trackingDisplay[action.action][action.keyName]"
                    [ngIfElse]="defaultTrackingDisplay">
                    {{trackingDisplay[action.action][action.keyName]}}
                  </ng-template>
                  <ng-template #defaultTrackingDisplay>
                    <div class="tracking action">{{action.action}}</div>
                    <div class="tracking keyName">{{action.keyName}}</div>
                  </ng-template>
                  : {{formatTimestamp(action.timestamp) | date:timeFormat: custom.timezone}}
                  {{custom.timezoneString}}
                </div>

              </ng-container>
            </mat-card-content>
          </mat-card>


          <mat-card>
            <mat-card-subtitle>
              <div>GatewayTx</div>
            </mat-card-subtitle>
            <mat-card-content>
              <ng-container *ngFor="let commerceTransactionCollection of commerceOrder.commerceTransactionCollections"
                class="commerceTransactionCollection">
                <mat-card [ngClass]="getClasses(commerceTransactionCollection)">
                  <mat-card-content>
                    <div *ngIf="advanced">TxColl:{{commerceTransactionCollection._id}}
                      {{commerceTransactionCollection.status}}</div>
                    <ng-container *ngFor="let commerceTransaction of commerceTransactionCollection.commerceTransactions"
                      class="commerceTransaction">
                      <div [ngClass]="getClasses(commerceTransactionCollection)">
                        <div *ngIf="advanced">Tx:{{commerceTransaction._id}}
                          {{commerceTransaction.status}}</div>
                        <ng-container
                          *ngFor="let commercePayment of commerceTransaction.commercePayments; let i = index">
                          <div [ngClass]="'commercePayment '+getClasses(commercePayment)" [class.additional]="i>0">
                            <div *ngIf="advanced">
                              Payment:{{commercePayment._id}}
                              GatewayTxId:{{commercePayment.gatewayTxId}}
                              Sequence:{{commercePayment.sequence}}.{{commercePayment.retry}}
                            </div>
                            <div *ngIf="advanced && commercePayment.commerceToken">
                              Token:{{commercePayment.commerceToken._id}}
                              {{commercePayment.commerceToken.status}}
                              {{commercePayment.commerceToken.subStatus}}
                              <br />Card Type:{{commercePayment.commerceToken.type}}
                              <br />BIN:{{commercePayment.commerceToken.bin}}
                              <br />Last4:{{commercePayment.commerceToken.last4}}
                              <br />Exp
                              Date:{{commercePayment.commerceToken.expiration?.month}}/{{commercePayment.commerceToken.expiration?.year}}
                              <br />IP Address:{{commercePayment.commerceToken.ipAddress}}
                              <br />ZIP:{{commercePayment.commerceToken.billingAddress?.zip}}
                              <br />Email:{{commercePayment.commerceToken.billingAddress?.email}}
                              <br />Name:{{commercePayment.commerceToken.billingAddress?.firstName}}
                              {{commercePayment.commerceToken.billingAddress?.lastName}}
                              <ng-container *ngIf="commercePayment?.paymentInfo?.params as info">
                                <ng-container *ngIf="'avsresponse' as key">
                                  <ng-container *ngIf="info[key] as value">
                                    <br />{{key}}:{{value}}
                                    ({{details[key][value]}})
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="'cvvresponse' as key">
                                  <ng-container *ngIf="info[key] as value">
                                    <br />{{key}}:{{value}}
                                    ({{details[key][value]}})
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </div>
                            <div>
                              ${{commercePayment.price.amount}}
                              {{formatTimestamp(commercePayment.paymentTimestamp) | date:timeFormat: custom.timezone}}
                              {{custom.timezoneString}},
                              {{commercePayment.status}}({{commercePayment.subStatus}})
                              {{commercePayment.type}}({{commercePayment.subType}})
                              Updater:{{commercePayment.updaterId ? userInfo[commercePayment.updaterId]?.getFullName() : systemUsername}}
                              <ng-container *ngIf="commercePayment.adjustment">
                                Adjustment:${{commercePayment.adjustment.amount}}
                              </ng-container>
                              <ng-container *ngIf="commercePayment.isAdjustable()">
                                <mat-form-field>
                                  <input matInput #adjustAmount type="number" placeholder="Refund Amount">
                                </mat-form-field>
                                <button [disabled]="!isAdjustable(commercePayment,adjustAmount.value)"
                                  (click)="refund(commerceOrder,commerceTransaction,commercePayment,adjustAmount.value)">Refund
                                </button>
                                <button [disabled]="!isVoidable(commercePayment)"
                                  (click)="void(commerceOrder,commerceTransaction,commercePayment)">Void
                                </button>
                              </ng-container>

                              <ng-container *ngIf="isProtectedCommercePayment(commerceOrder,commercePayment)">
                                <span class="mpi3ds-protected"> 3DS Protected </span>
                              </ng-container>
                              <button *ngIf="advanced" (click)="commercePayment.toggle('paymentInfo')"><i
                                  class="material-icons">format_line_spacing</i></button>
                              <div *ngIf="commercePayment.tempClient.delayRefund">
                                Refund
                                ${{commercePayment.tempClient.delayRefund.amount}} will
                                be re-attempted
                                at
                                {{commercePayment.tempClient.delayRefund.dueTimestamp | date:timeFormat: custom.timezone}}
                                Schedule:
                                {{commercePayment.tempClient.delayRefund.commerceScheduleId}}
                              </div>

                              <div>
                                <code *ngIf="commercePayment.getToggle('paymentInfo')">
                                    ------------ PaymentInfo ------------
                                    {{codeOutput(commercePayment.paymentInfo)}}
                                    ------------ CommerceBillingRouting ------------
                                    {{codeOutput(commercePayment.commerceBillingRouting)}}
                                    <ng-container *ngIf="isProtectedCommercePayment(commerceOrder,commercePayment)">
                                      ------------ 3DS ------------
                                      {{codeOutput(commerceOrder.commerce3ds)}}
                                    </ng-container>
                                  </code>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </mat-card-content>
                </mat-card>
              </ng-container>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="commerceSchedules.length>0 && commerceSchedules[0]?.status">
            <mat-card-subtitle>
              <div>Schedule</div>
            </mat-card-subtitle>
            <mat-card-content>
              <ng-container *ngFor="let commerceSchedule of commerceSchedules" class="commerceTransactionCollection">
                <mat-card *ngIf="commerceSchedule.commerceOrderId == commerceOrder._id">
                  <mat-card-content>
                    <div>
                      {{commerceSchedule?.scheduleData?.type === paymentTypes.refund ? "Refund" : "Sale"}}
                      ID:{{commerceSchedule._id}},
                      updater:{{userInfo[commerceSchedule.updaterId]?.getFullName()}}</div>
                    <div>
                      <mat-form-field>
                        <input matInput type="number" [placeholder]="'$'+commerceSchedule.scheduleData.amount"
                          [(ngModel)]="commerceSchedule.draft.scheduleData.amount">
                      </mat-form-field>

                      <mat-form-field>
                        <input matInput [matDatepicker]="picker"
                          [placeholder]="(formatTimestamp(commerceSchedule.dueTimestamp) | date:timeFormat:custom.timezoneString) + ' ' +custom.timezone"
                          [min]="minDate" [max]="maxDate"
                          [ngModel]="formatTimestamp(commerceSchedule.draft.dueTimestamp)"
                          (dateChange)="reflectCommerceScheduleDate($event.value,commerceSchedule)">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>

                      <button [disabled]="!isValidUpdateCommerceSchedule(commerceSchedule)"
                        (click)="updateCommerceSchedule(commerceSchedule)">Update</button>
                    </div>
                  </mat-card-content>
                </mat-card>
              </ng-container>
            </mat-card-content>
          </mat-card>


        </mat-card-content>
      </mat-card>
    </ng-container>
  </mat-card-content>

  <mat-card-content id="note">
    <mat-card>
      <mat-card-subtitle>
        Notes
      </mat-card-subtitle>
    </mat-card>
    <mat-card-content>
      <mat-card>
        <mat-card-content>
          <mat-form-field><textarea matInput [(ngModel)]="newNote.draft.note"></textarea></mat-form-field>
          <button [disabled]="!(newNote.isDiffDraft())" (click)="addNote()">Add</button>
        </mat-card-content>
      </mat-card>
      <ng-container *ngFor="let note of notes">
        <mat-card>
          <mat-card-subtitle> {{formatTimestamp(note.createdTimestamp) | date:timeFormat:custom.timezone}}
            {{custom.timezoneString}} {{userInfo[note.updaterId]?.getFullName()}}</mat-card-subtitle>
          <mat-card-content>{{note.note}}</mat-card-content>
        </mat-card>
      </ng-container>
    </mat-card-content>
  </mat-card-content>
</mat-card>
