<div class="csr-email">
    <div id="search-btn-wrapper">
        <button id="search-btn" mat-raised-button color="primary" (click)="searchModal()">FILTER</button>
        <a id="clear-btn" (click)="clearFilter()">Clear Filter</a>
    </div>
    <mat-tab-group *ngIf="csr" (selectedTabChange)="test($event)">
        <mat-tab (click)="getEmailRecords()">
            <ng-template mat-tab-label>
                <span>Open</span>
            </ng-template>
            <app-email-table *ngIf="csr" [csr]="csr" (onPaginationChange)="onPaginationChanged($event)"
                (onAssigned)="onAssigned($event)" (onUpdateStatus)="updateStatus($event.id, $event.status)"
                (onExpand)="onExpand($event)" [thList]="thList" (onCdpSaved)="onCdpSave($event)"
                (onDemandEmail)="onDemandEmails($event)" [onDemandEmailData]="emailBody" [pagination]="pagination"
                (onNonMemberCDP)="onNonMemberCDPs($event)" [onDemandNonMemberCDPData]="nonMemberCDPID"
                (onNextPage)="onNextPage()" (onPrevPage)="onPrevPage()" [tableData]="allRecords"></app-email-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>Closed</span>
            </ng-template>
            <app-email-table *ngIf="csr" [csr]="csr" (onPaginationChange)="onPaginationChanged($event)"
                (onAssigned)="onAssigned($event)" (onUpdateStatus)="updateStatus($event.id, $event.status)"
                [thList]="thList" [val]="'reopen'" (onCdpSaved)="onCdpSave($event)"
                (onDemandEmail)="onDemandEmails($event)" [onDemandEmailData]="emailBody" [pagination]="pagination"
                (onNonMemberCDP)="onNonMemberCDPs($event)" [onDemandNonMemberCDPData]="nonMemberCDPID"
                (onNextPage)="onNextPage()" (onPrevPage)="onPrevPage()" [tableData]="allRecords"></app-email-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>Deleted</span>
            </ng-template>
            <app-email-table *ngIf="csr" [csr]="csr" (onPaginationChange)="onPaginationChanged($event)"
                (onAssigned)="onAssigned($event)" (onUpdateStatus)="updateStatus($event.id, $event.status)"
                [thList]="thList" [val]="'undo'" (onCdpSaved)="onCdpSave($event)"
                (onDemandEmail)="onDemandEmails($event)" [onDemandEmailData]="emailBody" [pagination]="pagination"
                (onNonMemberCDP)="onNonMemberCDPs($event)" [onDemandNonMemberCDPData]="nonMemberCDPID"
                (onNextPage)="onNextPage()" (onPrevPage)="onPrevPage()" [tableData]="allRecords"></app-email-table>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <span>All</span>
            </ng-template>
            <app-email-table *ngIf="csr" [csr]="csr" (onPaginationChange)="onPaginationChanged($event)"
                (onUpdateStatus)="updateStatus($event.id, $event.status)" [thList]="thList"
                (onAssigned)="onAssigned($event)" (onCdpSaved)="onCdpSave($event)"
                (onDemandEmail)="onDemandEmails($event)" [onDemandEmailData]="emailBody" [pagination]="pagination"
                (onNonMemberCDP)="onNonMemberCDPs($event)" [onDemandNonMemberCDPData]="nonMemberCDPID"
                (onNextPage)="onNextPage()" (onPrevPage)="onPrevPage()" [tableData]="allRecords"></app-email-table>
        </mat-tab>
    </mat-tab-group>


    <!-- Moadl here -->


    <div class="d-flex" *ngIf="pagination.totalRecords > 0">
        <div class="ml-auto d-flex">
            <span class="m-auto m-10">Results per page</span>

            <mat-form-field class="m-auto" appearance="outline" floatLabel="always"
                style="width: 85px; margin-bottom: -18px;">
                <mat-select (valueChange)="onSelectChange($event)" [(ngModel)]="pagination.perPage">
                    <mat-option *ngFor="let page of [25, 50, 75, 100, 125]" [value]="page">{{ page }}</mat-option>
                </mat-select>
            </mat-form-field>

            <span class="m-auto m-10">
                {{pagination.currentPage}} of {{pagination.totalPages}} pages
            </span>
            <span class="mt-auto mb-auto m-10">
                <mat-icon [ngClass]="{'disabled': !pagination.hasPrev, 'dark-gray': pagination.hasPrev}"
                    aria-hidden="false" (click)="onPrevPage()" aria-label="Example home icon">keyboard_arrow_left
                </mat-icon>
                <mat-icon [ngClass]="{'disabled': !pagination.hasNext, 'dark-gray': pagination.hasNext}"
                    aria-hidden="false" (click)="onNextPage()" aria-label="Example home icon">keyboard_arrow_right
                </mat-icon>
            </span>
        </div>
    </div>
</div>
