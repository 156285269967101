import {clientPaths} from "../../common/helpers/pathHelpers";
import {CommerceOrder} from "../../common/models/commerce/commerceOrder";
import {BaseDirective} from "../directives/BaseDirective";
import {LogUtils} from "../../common/utils/logUtils";
import {ServiceHelperService} from "../services/serviceHelper.service";
import {ActivatedRoute} from "@angular/router";
import {redirectHelper} from "../helper/redirectHelper";
import {customClientPaths} from "../../common/custom/customPathHelpers";
import {peopleSearchProductKeys} from "../../common/custom/peopleSearch/peopleSearchProductKeys";

declare var $: any;

/**
 * @fileoverview have static varibles & resuable functions needed for page, pageType & category
 */
export class CustomBaseComponent extends BaseDirective {
  // static variables defination for page to avoid use of magic strings
  public static PAGE_TYPE = {
    sales: "sales",
    member: "member",
    general: "general",
  };

  public static PAGE_CATOGORY = {
    general: "general",
    account: "account",
    nameSearch: "name-search",
    phoneSearch: "phone-search",
    inmateSearch: "inmate-search",
    nameSearchOptOut: "name-search-opt-out",
    removeInfo: "remove-info",
    review: "review",
    property: "property",
    unclaimedFunds: 'unclaimed-funds',
  };

  public static PAGE = {
    landingIntermediate: "landingIntermediate",
    article: "article",
    page: "page",
    terms: "terms",
    privacy: "privacy",
    contactUs: "contactUs",
    unsubscribe: "unsubscribe",
    suppressionList: "suppressionList",
    clickTracking: "clickTracking",
    content: "content",
    refund: "refund",
    optout: "optout",
    help: "help",
    affilliate: "affilliate",

    landing: "landing",
    loader: "loader",
    searchResult: "search-result",
    reportReview: "report-review",
    spyDashboard: "spy-dashboard",
    dashboard: "dashboard",
    payment: "payment",
    interstitial: "interstitial",

    signup: "signup",
    confirmation: "confirmation",
    confirmationIntermediate: "confirmationIntermediate",
    infoInput: "infoInput",
    cpcc: "cpcc",
    addon: "addon",

    list: "list",
    process: "process",
    detail: "detail",
    lifeEvents: "lifeEvents",

    referral: "referral",
    shareLink: "shareLink",

    // Account
    account: "account",
    profile: "profile",
    payments: "payments",
    orders: "orders",
    password: "password",
    forgotPassword: "forgotPassword",
  };

  pageType = "baseType";
  pageCategory = "baseCategory";
  page = "basePage";



  /**
 * @param serviceHelperService will provide access to different required services such as authentication service, uxc service etc.
 * @param activatedRoute provides access to information about a route associated with a component that is loaded in an outlet. *
 */
  constructor(public serviceHelperService: ServiceHelperService,
    public activatedRoute: ActivatedRoute) {
    super(serviceHelperService, activatedRoute);
  }

  onDestroy() {
    return super.onDestroy();
  }

  // will be called by child componet (BaseCompent) for intialization
  protected baseInit(): Promise<any> {
    return super.baseInit();
  }

  /**
   * @returns product key string based on page category if its for nameSearch or phoneSearch
   */
  getProductKeyByPageCategory() {
    if (this.pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearch 
        || this.pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearchOptOut
        || this.pageCategory === CustomBaseComponent.PAGE_CATOGORY.removeInfo
    ) {
      if (this.userInput.phone) {
        this.userInput.phone = undefined;
      }
      return peopleSearchProductKeys.nameSearch;
    } else if (this.pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch) {
      return peopleSearchProductKeys.phoneSearch;
    }
  }

  // check the current pageType i.e sales,member etc.
  isSalesPage() {
    return this.pageType === CustomBaseComponent.PAGE_TYPE.sales;
  }


  /**
   * @returns object having page properties
   */
  getPageTypes() {
    return {
      pageType: this.pageType,
      pageCategory: this.pageCategory,
      page: this.page,
    }
  }

  /**
   * This navigates to the url of the next page based on page information, it checks the pageInfo against each available-
   * type, category and page name then set's routing url for it
   * @param pageInfo have page information pageType, pageCategory...
   * @param options  optional options to be provided to the navigation url
   * @param param optional parameters to be attached with navigation url
   */
  goNextPage(pageInfo?: { pageType, pageCategory, page }, options?, param?) {
    if (!pageInfo) {
      pageInfo = {pageType: this.pageType, pageCategory: this.pageCategory, page: this.page}
    }
    let url;
    if (pageInfo.pageType === CustomBaseComponent.PAGE_TYPE.sales) {
      if (pageInfo.pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearch) {
        if (pageInfo.page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.salesNameSearchLoader;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.salesNameSearchSearchResult;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.salesNameSearchReportReview;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.salesNameSearchSignup;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.signup) {
          url = clientPaths.confirmationIntermediate;
        }
      } else if (pageInfo.pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch) {
        if (pageInfo.page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.salesPhoneSearchLoader;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.salesPhoneSearchSearchResult;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.salesPhoneSearchReportReview;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.salesPhoneSearchSignup;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.signup) {
          url = clientPaths.confirmationIntermediate;
        }
      } else if (pageInfo.pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearchOptOut) {
        if (pageInfo.page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.salesNameSearchOptOutLoader;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.salesNameSearchOptOutSearchResult;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.salesNameSearchOptOutInfoInput;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.infoInput) {
          // No next page
        } else if (pageInfo.page === CustomBaseComponent.PAGE.confirmation) {
          // No next page
        }
      }
    } else if (pageInfo.pageType === CustomBaseComponent.PAGE_TYPE.member) {
      if (pageInfo.pageCategory === CustomBaseComponent.PAGE_CATOGORY.nameSearch) {
        if (pageInfo.page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.memberNameSearchLoader;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.memberNameSearchSearchResult;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.memberNameSearchReportReview;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.memberNameSearchProcess;
        }
      } else if (pageInfo.pageCategory === CustomBaseComponent.PAGE_CATOGORY.phoneSearch) {
        if (pageInfo.page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.memberPhoneSearchLoader;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.memberPhoneSearchSearchResult;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.memberPhoneSearchReportReview;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.reportReview) {
          url = customClientPaths.memberPhoneSearchProcess;
        }
      } else if (pageInfo.pageCategory === CustomBaseComponent.PAGE_CATOGORY.removeInfo) {
        if (pageInfo.page === CustomBaseComponent.PAGE.landing) {
          url = customClientPaths.memberRemoveInfoLoader;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.loader) {
          url = customClientPaths.memberRemoveInfoSearchResult;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.searchResult) {
          url = customClientPaths.memberRemoveInfoSignup;
        } else if (pageInfo.page === CustomBaseComponent.PAGE.signup) {
          url = customClientPaths.memberRemoveInfoDashboard;
        }
      }
    }

    if (url) {
      if (param) {
        url += param;
      }
      return redirectHelper.redirect(this.serviceHelperService, '/' + url, options);
    } else {
      LogUtils.error(false);
      return Promise.reject(false);
    }
  }

  /**
   * this provides all the commerceOffers applicable on a cerain page category
   * @returns resolved promise with commerceOrder if orders found against the pageCategory otherwise rejected promise with null commerceOrder object
   */
  getCommerceOrdersByPageCategory(): Promise<any> {
    return Promise.resolve().then(() => {
      return this.serviceHelperService.peopleSearchService.hasOrder([this.getProductKeyByPageCategory()]);
    }).then((commerceOrders: CommerceOrder[]) => {
      if (commerceOrders && commerceOrders.length > 0) {
        return Promise.resolve(commerceOrders);
      } else {
        return Promise.reject(commerceOrders);
      }
    });
  }

}
